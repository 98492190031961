import React from "react";
import PropTypes from "prop-types";

import "./ConfirmAction.scss";

const ConfirmAction = ({ text, onCancel, onConfirm }) => {
  return (
    <div className="confirm-action">
      <p className="confirm-action__text">{text}</p>
      <div className="confirm-action__controls">
        <button
          className="confirm-action__cancel"
          type="button"
          onClick={onCancel}
        >
          Отменить
        </button>
        <button
          className="confirm-action__confirm"
          type="button"
          onClick={onConfirm}
        >
          Продолжить
        </button>
      </div>
    </div>
  );
};

ConfirmAction.propTypes = {
  text: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmAction;
