export const OBJECT_STAGE_SOLO_REQUEST = "OBJECT_STAGE_SOLO_REQUEST";
export const OBJECT_STAGE_SOLO_SUCCESS = "OBJECT_STAGE_SOLO_SUCCESS";
export const OBJECT_STAGE_SOLO_ERROR = "OBJECT_STAGE_SOLO_ERROR";
export const OBJECT_STAGE_HISTORIES_REQUEST = "OBJECT_STAGE_HISTORIES_REQUEST";
export const OBJECT_STAGE_HISTORIES_SUCCESS = "OBJECT_STAGE_HISTORIES_SUCCESS";
export const OBJECT_STAGE_HISTORIES_ERROR = "OBJECT_STAGE_HISTORIES_ERROR";
export const OBJECT_STAGE_SOLO_IMAGE_REQUEST = "OBJECT_STAGE_SOLO_IMAGE_REQUEST";
export const OBJECT_STAGE_SOLO_IMAGE_SUCCESS = "OBJECT_STAGE_SOLO_IMAGE_SUCCESS";
export const OBJECT_STAGE_SOLO_IMAGE_ERROR = "OBJECT_STAGE_SOLO_IMAGE_ERROR";
export const OBJECT_STAGE_SOLO_COMMENTS_REQUEST = "OBJECT_STAGE_SOLO_COMMENTS_REQUEST";
export const OBJECT_STAGE_SOLO_COMMENTS_SUCCESS = "OBJECT_STAGE_SOLO_COMMENTS_SUCCESS";
export const OBJECT_STAGE_SOLO_COMMENTS_ERROR = "OBJECT_STAGE_SOLO_COMMENTS_ERROR";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  item: {},
  isUpdated: false,
  histories: {
    isFetching: false,
    isUpdated: false,
    items: [],
    pagination: {},
    isError: false
  },
  images: {
    isFetching: false,
    updated: false,
    items: [],
    pagination: {},
    isError: false
  },
  comments: {
    isFetching: false,
    updated: false,
    items: [],
    pagination: {},
    isError: false
  },
  updated: false,
};

const stageSolo = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_STAGE_SOLO_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case OBJECT_STAGE_SOLO_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        item: action.payload,
      };
    }
    case OBJECT_STAGE_SOLO_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: action.payload,
        isUpdated: true,
      };
    }
    case OBJECT_STAGE_HISTORIES_REQUEST: {
      return {
        ...state,
        histories: {
          ...state.histories,
          isFetching: true,
          isError: false,
        }
      }
    }
    case OBJECT_STAGE_HISTORIES_SUCCESS: {
      return {
        ...state,
        histories: {
          ...state.histories,
          items: action.payload.value,
          pagination: action.payload.pagination,
          isFetching: false,
          isUpdated: true
        }
      }
    }
    case OBJECT_STAGE_HISTORIES_ERROR: {
      return {
        ...state,
        histories: {
          ...state.histories,
          isFetching: false,
          isError: true,
          isUpdated: true
        }
      }
    }
    case OBJECT_STAGE_SOLO_IMAGE_REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          isFetching: true,
          isError: false
        }
      }
    }
    case OBJECT_STAGE_SOLO_IMAGE_SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          isFetching: false,
          updated: true,
          items: action.payload.items,
          pagination: action.payload.pagination
        }
      }
    }
    case OBJECT_STAGE_SOLO_IMAGE_ERROR: {
      return {
        ...state,
        images: {
          ...state.images,
          isFetching: false,
          isError: true,
          updated: true
        }
      }
    }
    case OBJECT_STAGE_SOLO_COMMENTS_REQUEST: {
      return {
        ...state,
        comments: {
          ...state.images,
          isFetching: true,
          isError: false
        }
      }
    }
    case OBJECT_STAGE_SOLO_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: {
          ...state.images,
          isFetching: false,
          updated: true,
          items: action.payload.items,
          pagination: action.payload.pagination
        }
      }
    }
    case OBJECT_STAGE_SOLO_COMMENTS_ERROR: {
      return {
        ...state,
        comments: {
          ...state.images,
          isFetching: false,
          isError: true,
          updated: true
        }
      }
    }
    default: {
      return state;
    }
  }
};

export default stageSolo;
