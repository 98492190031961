import React, { Component } from "react";
import AccountWidget from "../../components/AccountWiget";
import SideMenu from "./components/Sidemenu";
import { config } from "./sidemenuConfigs";
import { connect } from "react-redux";

import "./sideMenuLayout.scss";

class SideMenuLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar(isOpen) {
    this.setState({
      isMenuOpen: typeof isOpen === "boolean" ? isOpen : !this.state.isMenuOpen,
    });
  }

  render() {
    return (
      <div className="main-layout">
        {this.state.isMenuOpen && (
          <SideMenu
            isOpen={this.state.isMenuOpen}
            toggle={this.toggleSidebar}
            config={config}
            isLogged={this.props.user}
            name={this.props.user.name}
            role={this.props.user.type_name}
          />
        )}
        <div className="main-layout-content">
          <AccountWidget
            isLogged={this.props.user}
            name={this.props.user.name}
            role={this.props.user.type_name}
            arrowBack={this.props.arrowBack}
            isMenuOpen={this.state.isMenuOpen}
            toggleMenu={this.toggleSidebar}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuLayout);
