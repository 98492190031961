import React, { useState, useEffect } from "react";

import CheckBox from "../CheckBox";
import { filterIcon } from "../../assets/img/sprite";

import OlLayerTile from "ol/layer/Tile";
import VectorTileSource from "ol/source/VectorTile";
import VectorTileLayer from "ol/layer/VectorTile";
import TileArcGISSource from "ol/source/TileArcGISRest";
import TileGrid from "ol/tilegrid/TileGrid";
import MVTFormat from "ol/format/MVT";

import { MAX_ZOOM } from "./constants";

const OBJECTS_EXTENT = [
  -2.00375070672e7,
  -3.02409714584e7,
  2.00375070672e7,
  3.02409714584e7,
];
const objectsTileSize = () =>
  [1024, 1024].map((dimension) => dimension / window.devicePixelRatio);
const OBJECTS_LAYERS = {
  lands: 21,
  buildings: 30,
  complexes: 35,
};

const CADASTER_LAYERS = {
  regions: "Единицы кадастрового деления",
  lands: "Земельные участки",
  buildings: "Здания, сооружения, объекты незавершенного строительства",
  complexes: "Единые недвижимые комплексы",
};

const calculateResolutions = (extent, tileSize) => {
  const [minX, minY, maxX, maxY] = extent;
  const height = maxX - minX;
  const width = maxY - minY;
  const maxResolution = Math.max(width / tileSize[0], height / tileSize[1]);

  const resolutions = [];
  for (let zoom = 0; zoom <= MAX_ZOOM; zoom++) {
    resolutions.push(maxResolution / Math.pow(2, zoom));
  }
  return resolutions;
};

const createMapServerSource = ({ url, tileSize, extent }) =>
  new TileArcGISSource({
    url,
    params: {
      layers: "show:",
      bboxSR: "102100",
      imageSR: "102100",
    },
    projection: "ESRI:102100",
    tileGrid: new TileGrid({
      tileSize,
      extent,
      resolutions: calculateResolutions(extent, tileSize),
    }),
  });

const useCadasterLayers = ({ showCadasterLayers }) => {
  const [layers] = useState(() => ({
    regions: new VectorTileLayer({
      source: new VectorTileSource({
        url:
          "https://pkk.rosreestr.ru/arcgis/rest/services/Hosted/caddivsion/VectorTileServer/tile/{z}/{y}/{x}.pbf",
        format: new MVTFormat(),
        maxZoom: 10,
      }),
    }),
    objects: new OlLayerTile({
      source: createMapServerSource({
        url:
          "https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/CadastreObjects/MapServer",
        tileSize: objectsTileSize(),
        extent: OBJECTS_EXTENT,
      }),
    }),
  }));

  const [layersVisibility, setLayersVisibility] = useState(() =>
    Object.fromEntries(
      Object.keys(CADASTER_LAYERS).map((name) => [name, false])
    )
  );

  useEffect(() => {
    layers.regions.setVisible(layersVisibility.regions);

    const objectsLayers = Object.entries(OBJECTS_LAYERS)
      .filter(([name, _]) => layersVisibility[name])
      .map(([_, layer]) => layer);
    layers.objects.setVisible(objectsLayers.length > 0);
    layers.objects
      .getSource()
      .updateParams({ layers: `show:${objectsLayers.join(`,`)}` });
  }, [layers, layersVisibility]);

  const layerControls = showCadasterLayers && (
    <div className="map-layers-block">
      <div className="map-layers-head"><span>Фильтры</span>{filterIcon}</div>
      <ul className="map-layers">
        {Object.entries(layersVisibility).map(([layer, isVisible]) => (
          <li className="map-layer" key={layer}>
            <CheckBox
              onChange={() =>
                setLayersVisibility({
                  ...layersVisibility,
                  [layer]: !isVisible,
                })
              }
              value={isVisible}
              label={CADASTER_LAYERS[layer]}
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return { layers, layerControls };
};

export default useCadasterLayers;
