import {
  OBJECT_HISTORY_IMAGES_REQUEST,
  OBJECT_HISTORY_IMAGES_SUCCESS,
  OBJECT_HISTORY_IMAGES_ERROR,
  OBJECT_HISTORY_COMMENTS_REQUEST,
  OBJECT_HISTORY_COMMENTS_SUCCESS,
  OBJECT_HISTORY_COMMENTS_ERROR,
  OBJECT_HISTORY_IMAGES_SET_UPDATED,
  OBJECT_HISTORY_SET_UPDATED
} from "../reducers/objectHistories";

import api from "./api";

export const fetchHistoryImages = (
  object_id,
  section_id,
  stage_id,
  task_id,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_HISTORY_IMAGES_REQUEST,
      payload: task_id
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_HISTORY_IMAGES_ERROR,
      });
      return;
    }

    fetch(
      api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}/work-history/${task_id}/image/view`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [tokenName]: token,
        },
        body: JSON.stringify({
          page: page,
          pageSize: pageSize,
        }),
      }
    )
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_HISTORY_IMAGES_ERROR,
          });
          return;
        }

        dispatch({
          type: OBJECT_HISTORY_IMAGES_SUCCESS,
          payload: {
            value: data.data.items,
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_HISTORY_IMAGES_ERROR,
        });
      });
  };
};

export const deleteHistoryImage = (objectId, sectionId, taskId, historyId, imageId) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${taskId}/work-history/${historyId}/image/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
    body: JSON.stringify({images_id: [imageId]})
  }).then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return 'success'
      } else {
        return data.data.message[0]
      }
    })
}

export const fetchHistoryComments = (
  object_id,
  section_id,
  stage_id,
  task_id,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_HISTORY_COMMENTS_REQUEST,
      payload: task_id
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_HISTORY_COMMENTS_ERROR,
      });
      return;
    }

    fetch(
      api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}/work-history/${task_id}/comment`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [tokenName]: token,
        },
        body: JSON.stringify({
          page: page,
          pageSize: pageSize,
        }),
      }
    )
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_HISTORY_COMMENTS_ERROR,
          });
          return;
        }

        dispatch({
          type: OBJECT_HISTORY_COMMENTS_SUCCESS,
          payload: {
            value: data.data.items,
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_HISTORY_COMMENTS_ERROR,
        });
      });
  };
};

export const addHistory = (
  object_id,
  section_id,
  stage_id,
  history
) => {
  return (dispatch) => {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return;
    }

    return fetch(api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}/work-history/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify(history)
    })
      .then((r) => {
        return r.json();
      })
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_HISTORY_SET_UPDATED,
            payload: false
          })
          return true
        } else {
          return data.data.message.works_history_amount
        }
      });
  };
};

export const addHistoryComment = (
  object_id,
  section_id,
  stage_id,
  task_id,
  comment
) => {
  return (dispatch) => {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return;
    }
    return fetch(api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}/work-history/${task_id}/comment/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify({comment_content: comment})
    })
      .then((r) => {
        return r.json();
      })
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_HISTORY_IMAGES_SET_UPDATED,
            payload: false
          })
        }
      });
  };
}

export const addHistoryImage = (
  object_id,
  section_id,
  stage_id,
  task_id,
  files
) => {
  return (dispatch) => {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return;
    }

    let body = new FormData();
    for(let i = 0; i < files.length; i++){
      body.append("images[]",files[i]);
    }
    return fetch(api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}/work-history/${task_id}/image/load`, {
      method: "POST",
      headers: {
        [tokenName]: token,
      },
      body: body
    })
      .then((r) => {
        return r.json();
      })
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_HISTORY_IMAGES_SET_UPDATED,
            payload: false
          })
        }
      });
  };
};

export const approveHistory =
    (object_id,
     section_id,
     stage_id,
     role,
     approve) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/object/${object_id}/section/${section_id}/task/${stage_id}/approve-${role}`, {
    method: "POST",
    headers: {
      [tokenName]: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      [`works_history_approve_${role}`]: +approve
    })
  })
    .then((r) => r.json())
    .then(data => {
      if (data._meta.isSuccessful) {
        return {message: 'success', objectCompleted: data.data.objectCompleted};
      } else {
        return {message: data.data.message};
      }
    })
}

export const deleteWorkHistory = (objectId, sectionId, taskId, workId) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }
  return fetch(`${api}/v1/object/${objectId}/section/${sectionId}/task/${taskId}/work-history/${workId}/delete`, {
    method: "POST",
    headers: {
      [tokenName]: token,
      "Content-Type": "application/json",
    },
  })
    .then((r) => r.json())
    .then(data => {
      if (data._meta.isSuccessful) {
        return 'success'
      } else {
        return data.data.message.works_history_id
      }
    })
}
