import api from "./api";
import {
  adaptGroupRatingToClient,
  adaptGroupRatingToServer,
  adaptSortToServer,
} from "../assets/js/adapters";
import { RatingRequestTypes } from "../assets/js/constants";

export const RATING_REQUEST = "RATING_REQUEST";
export const RATING_ERROR = "RATING_ERROR";
export const RATING_SUCCESS = "RATING_SUCCESS";
export const OBJECT_USER_RATING_UPDATE = "OBJECT_USER_RATING_UPDATE";

export function fetchRating(type, data) {
  return (dispatch) => {
    dispatch({
      type: RATING_REQUEST,
    });

    const tokenName = localStorage.getItem("token_name");
    const token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: RATING_ERROR,
        payload: {
          errors: ["Нет прав доступа. Пожалуйста войдите в систему"],
        },
      });
      return;
    }

    const requestData = {
      type: "GET",
      url: "",
      body: null,
    };

    switch (type) {
      case RatingRequestTypes.OBJECT:
        requestData.url = `/v1/score/${data.objectID}/users`;
        break;
      case RatingRequestTypes.OVERALL:
        requestData.url = `/v1/score/${data.role}`;
        requestData.type = "POST";
        requestData.body = {
          page: data.page,
          sort: adaptSortToServer(data.sort),
        };
        break;
      default:
        return;
    }

    fetch(api + requestData.url, {
      method: requestData.type,
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: requestData.body ? JSON.stringify(requestData.body) : null,
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: RATING_ERROR,
            payload: {
              errors: data.message,
            },
          });
        }

        dispatch({
          type: RATING_SUCCESS,
          payload: {
            items: adaptGroupRatingToClient(data.data.items),
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: RATING_ERROR,
          payload: {
            errors: er.message,
          },
        });
      });
  };
}

export function saveObjectRating(objectID) {
  return (dispatch, getState) => {
    const tokenName = localStorage.getItem("token_name");
    const token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return "Нет прав доступа. Пожалуйста войдите в систему";
    }

    const body = JSON.stringify({
      score: adaptGroupRatingToServer(getState().rating.items),
    });
    return fetch(api + `/v1/score/${objectID}/create`, {
      method: "POST",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: RATING_ERROR,
            payload: {
              errors: data.message,
            },
          });
        }
        return "success";
      })
      .catch((er) => {
        dispatch({
          type: RATING_ERROR,
          payload: {
            errors: er.message,
          },
        });
      });
  };
}

export function updatePersonRating(data) {
  return (dispatch) => {
    dispatch({
      type: OBJECT_USER_RATING_UPDATE,
      payload: data,
    });
  };
}
