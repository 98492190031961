export const OBJECT_HISTORY_IMAGES_REQUEST = "OBJECT_HISTORY_IMAGES_REQUEST";
export const OBJECT_HISTORY_IMAGES_SUCCESS = "OBJECT_HISTORY_IMAGES_SUCCESS";
export const OBJECT_HISTORY_IMAGES_ERROR = "OBJECT_HISTORY_IMAGES_ERROR";
export const OBJECT_HISTORY_COMMENTS_REQUEST = "OBJECT_HISTORY_COMMENTS_REQUEST";
export const OBJECT_HISTORY_COMMENTS_SUCCESS = "OBJECT_HISTORY_COMMENTS_SUCCESS";
export const OBJECT_HISTORY_COMMENTS_ERROR = "OBJECT_HISTORY_COMMENTS_ERROR";
export const OBJECT_HISTORY_COMMENTS_SET_UPDATED = "OBJECT_HISTORY_COMMENTS_SET_UPDATED";
export const OBJECT_HISTORY_IMAGES_SET_UPDATED = "OBJECT_HISTORY_IMAGES_SET_UPDATED";
export const OBJECT_HISTORY_SET_UPDATED = "OBJECT_HISTORY_SET_UPDATED";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  items: [],
  images: {
    id: null,
    isError: false,
    isFetching: false,
    isUpdated: false,
    items: [],
    pagination: {}
  },
  comments: {
    id: null,
    isError: false,
    isFetching: false,
    isUpdated: false,
    items: [],
    pagination: {}
  },
  isUpdated: false,
};

const objectHistories = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_HISTORY_IMAGES_REQUEST: {
      return {
        ...state,
        images: {
          ...state.images,
          id: action.payload,
          isFetching: true,
          isError: false
        }
      }
    }
    case OBJECT_HISTORY_IMAGES_SUCCESS: {
      return {
        ...state,
        images: {
          ...state.images,
          items: action.payload.value,
          pagination: action.payload.pagination,
          isFetching: false,
          isUpdated: true
        }
      }
    }
    case OBJECT_HISTORY_IMAGES_ERROR: {
      return {
        ...state,
        images: {
          isFetching: false,
          isError: true,
          isUpdated: true
        }
      }
    }
    case OBJECT_HISTORY_COMMENTS_REQUEST: {
      return {
        ...state,
        comments: {
          ...state.comments,
          id: action.payload,
          isFetching: true,
          isError: false
        }
      }
    }
    case OBJECT_HISTORY_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: {
          ...state.comments,
          items: action.payload.value,
          pagination: action.payload.pagination,
          isFetching: false,
          isUpdated: true
        }
      }
    }
    case OBJECT_HISTORY_SET_UPDATED: {
      return {
        ...state,
        isUpdated: action.payload
      }
    }
    case OBJECT_HISTORY_COMMENTS_ERROR: {
      return {
        ...state,
        comments: {
          ...state.comments,
          isFetching: false,
          isError: true,
          isUpdated: true
        }
      }
    }
    case OBJECT_HISTORY_IMAGES_SET_UPDATED: {
      return {
        ...state,
        images: {
          ...state.images,
          isUpdated: !!action.payload
        }
      }
    }
    default: {
      return state;
    }
  }
};

export default objectHistories;
