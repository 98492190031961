import React, { useState } from "react";
import PropTypes from "prop-types";

import "./Input.scss";

const Input = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [validatorsValue, setValidatorsValue] = useState("");
  const errorLabel = (
    <div className="input-label-text-error">
      {props.error || validatorsValue}
    </div>
  );

  const validate = (value) => {
    let result = "";
    props.validators.forEach((validator) => {
      let message = validator(value);
      if (!result || !message) result = message;
    });
    props.onValidationChange && props.onValidationChange(props.name, result);
    setValidatorsValue(result);
  };

  const onBlur = () => {
    if (!isTouched && !props.readOnly && !props.disabled) {
      props.validators && validate(props.value);
      setIsTouched(true);
    }
  };

  const change = (ev) => {
    if (!props.error && props.validators) {
      props.validators && validate(ev.target.value);
    }

    props.onChange(ev);
  };

  return (
    <label
      className={`input-label ${props.icon ? "_with-icon" : ""} ${
        props.isRounded ? "_rounded" : ""
      }`}
    >
      <div className="input-label-text">{props.label}</div>
      <div className="input-label-container">
        <input
          formNoValidate
          onChange={change}
          onBlur={onBlur}
          className={
            !props.error && !validatorsValue
              ? "input-input"
              : "input-input input-input-error"
          }
          type={props.type}
          value={props.value}
          placeholder={props.placeholder}
          name={props.name}
          readOnly={props.readOnly || false}
          disabled={props.disabled || false}
        />
        {props.icon}
        {props.children}
      </div>
      {props.error || validatorsValue ? errorLabel : null}
    </label>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
  validateOnStart: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  isRounded: PropTypes.bool,
};

export default Input;
