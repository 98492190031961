import React from "react";

export const userCircledIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 18C6.25 18.4142 6.58579 18.75 7 18.75C7.41421 18.75 7.75 18.4142 7.75 18H6.25ZM16.25 18C16.25 18.4142 16.5858 18.75 17 18.75C17.4142 18.75 17.75 18.4142 17.75 18H16.25ZM7.75 18V17H6.25V18H7.75ZM7.75 17C7.75 14.6528 9.6528 12.75 12 12.75V11.25C8.82436 11.25 6.25 13.8244 6.25 17H7.75ZM12 12.75C14.3472 12.75 16.25 14.6528 16.25 17H17.75C17.75 13.8244 15.1756 11.25 12 11.25V12.75ZM16.25 17V18H17.75V17H16.25ZM12 12.75C14.0711 12.75 15.75 11.0711 15.75 9H14.25C14.25 10.2427 13.2427 11.25 12 11.25V12.75ZM15.75 9C15.75 6.92894 14.0711 5.25 12 5.25V6.75C13.2427 6.75 14.25 7.75736 14.25 9H15.75ZM12 5.25C9.92888 5.25 8.25 6.92894 8.25 9H9.75C9.75 7.75736 10.7573 6.75 12 6.75V5.25ZM8.25 9C8.25 11.0711 9.92889 12.75 12 12.75V11.25C10.7573 11.25 9.75 10.2427 9.75 9H8.25ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75Z"
      fill="currentColor"
    />
  </svg>
);

export const logOutIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12H19M19 12L16 15M19 12L16 9M19 6V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V18"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const viewIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 12C19.1114 14.991 15.7183 18 12 18C8.2817 18 4.88856 14.991 3 12C5.29855 9.15825 7.99163 6 12 6C16.0084 6 18.7015 9.1582 21 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const notificationIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8.4C18 6.70261 17.3679 5.07475 16.2426 3.87452C15.1174 2.67428 13.5913 2 12 2C10.4087 2 8.88258 2.67428 7.75736 3.87452C6.63214 5.07475 6 6.70261 6 8.4C6 15.8667 3 18 3 18H21C21 18 18 15.8667 18 8.4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const menuIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 5H21M3 12H21M3 19H21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7583 17.2426L12.0009 12M12.0009 12L17.2435 6.75732M12.0009 12L6.7583 6.75732M12.0009 12L17.2435 17.2426"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const selectArrowIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const arrowBackIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12L12 18M18.5 12H6H18.5ZM6 12L12 6L6 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const plusIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10V15M5 10H10H5ZM15 10H10H15ZM10 10V5V10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const editIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 17.5H17.5M10.1848 4.85703L12.5419 2.5L16.6667 6.62479L14.3097 8.98178M10.1848 4.85703L14.3097 8.98178M10.1848 4.85703L5.51268 9.5292C5.35641 9.68545 5.26861 9.89745 5.26861 10.1185V13.898H9.04825C9.26925 13.898 9.48117 13.8103 9.6375 13.654L14.3097 8.98178"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 13L9 17L19 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkAllIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14M16 7L12 11M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const sortDroppedIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 14H2M10 10H2M6 6H2M18 18H2M19 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const sortAscIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 14H2M10 10H2M6 6H2M18 18H2M19 14V4M19 4L22 7M19 4L16 7"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const sortDescIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 10H2M10 14H2M6 18H2M18 6H2M19 10V20M19 20L22 17M19 20L16 17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const triangularArrowIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.75 10L12.75 15L17.75 10H7.75Z" fill="#999999" />
  </svg>
);

export const deleteIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 5C5.57143 7.66666 18.4286 7.66662 21 5M3.03919 4.2939C3.01449 4.10866 3.0791 3.92338 3.23133 3.81499C3.9272 3.31953 6.3142 2 12 2C17.6858 2 20.0728 3.31952 20.7687 3.81499C20.9209 3.92338 20.9855 4.10866 20.9608 4.2939L19.2616 17.0378C19.0968 18.2744 18.3644 19.3632 17.2813 19.9821L16.9614 20.1649C13.8871 21.9217 10.1129 21.9217 7.03861 20.1649L6.71873 19.9821C5.6356 19.3632 4.90325 18.2744 4.73838 17.0378L3.03919 4.2939Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export const downloadIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 20H18M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const freezeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 9L18 5.5M3 7L6.5 9L3 7ZM21 17L17.5 15L21 17ZM12 12L6.5 9L12 12ZM12 12L6.5 15L12 12ZM12 12V5V12ZM12 12V18.5V12ZM12 12L17.5 15L12 12ZM12 12L17.5 9L12 12ZM12 2V5V2ZM12 22V18.5V22ZM21 7L17.5 9L21 7ZM3 17L6.5 15L3 17ZM6.5 9L3 10L6.5 9ZM6.5 9L6 5.5L6.5 9ZM6.5 15L3 14L6.5 15ZM6.5 15L6 18.5L6.5 15ZM12 5L9.5 4L12 5ZM12 5L14.5 4L12 5ZM12 18.5L14.5 20L12 18.5ZM12 18.5L9.5 20L12 18.5ZM17.5 15L18 18.5L17.5 15ZM17.5 15L21 14L17.5 15ZM17.5 9L21 10L17.5 9Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const respondIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 5H22M22 5L19 8M22 5L19 2M13 2.04938C12.6711 2.01672 12.3375 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22C17.5228 22 22 17.5228 22 12C22 11.6625 21.9833 11.3289 21.9506 11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const enlargeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9L20 4M20 4V8M20 4H16M9 15L4 20M4 20V16M4 20H8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const collapseIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 20L15 15M15 15V19M15 15H19M4 20L9 15M9 15V19M9 15H5M20 4L15 9M15 9V5M15 9H19M4 4L9 9M9 9V5M9 9H5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const filterIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99963 3H19.9997C20.552 3 20.9997 3.44764 20.9997 3.99987L20.9999 5.58569C21 5.85097 20.8946 6.10538 20.707 6.29295L14.2925 12.7071C14.105 12.8946 13.9996 13.149 13.9996 13.4142V19.7192C13.9996 20.3698 13.3882 20.8472 12.7571 20.6894L10.7571 20.1894C10.3119 20.0781 9.99963 19.6781 9.99963 19.2192V13.4142C9.99963 13.149 9.89427 12.8946 9.70674 12.7071L3.29252 6.29289C3.10498 6.10536 2.99963 5.851 2.99963 5.58579V4C2.99963 3.44772 3.44734 3 3.99963 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const searchIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4167 12.9167L16.3334 15.8334M4.66675 9.16669C4.66675 11.9281 6.90532 14.1667 9.66675 14.1667C11.0498 14.1667 12.3018 13.6051 13.207 12.6975C14.1091 11.7931 14.6667 10.545 14.6667 9.16669C14.6667 6.40526 12.4282 4.16669 9.66675 4.16669C6.90532 4.16669 4.66675 6.40526 4.66675 9.16669Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const myObjectsIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.01L10.01 8.99889M14 9.01L14.01 8.99889M10 13.01L10.01 12.9989M14 13.01L14.01 12.9989M10 17.01L10.01 16.9989M14 17.01L14.01 16.9989M6 20.4V5.6C6 5.26863 6.26863 5 6.6 5H12V3.6C12 3.26863 12.2686 3 12.6 3H17.4C17.7314 3 18 3.26863 18 3.6V20.4C18 20.7314 17.7314 21 17.4 21H6.6C6.26863 21 6 20.7314 6 20.4Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const allObjectsIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9.01L7.01 8.99889M11 9.01L11.01 8.99889M7 13.01L7.01 12.9989M11 13.01L11.01 12.9989M7 17.01L7.01 16.9989M11 17.01L11.01 16.9989M15 21H3.6C3.26863 21 3 20.7314 3 20.4V5.6C3 5.26863 3.26863 5 3.6 5H9V3.6C9 3.26863 9.26863 3 9.6 3H14.4C14.7314 3 15 3.26863 15 3.6V9M15 21V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15M15 21V17M15 9V13M15 17V13M15 17H17M15 13H17"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const favoritesIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.58737 8.23597L11.1849 3.00376C11.5183 2.33208 12.4817 2.33208 12.8151 3.00376L15.4126 8.23597L21.2215 9.08017C21.9668 9.18848 22.2638 10.0994 21.7243 10.6219L17.5217 14.6918L18.5135 20.4414C18.6409 21.1798 17.8614 21.7428 17.1945 21.3941L12 18.678L6.80547 21.3941C6.1386 21.7428 5.35909 21.1798 5.48645 20.4414L6.47825 14.6918L2.27575 10.6219C1.73617 10.0994 2.03322 9.18848 2.77852 9.08017L8.58737 8.23597Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const archiveIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 5.25C6.58579 5.25 6.25 5.58579 6.25 6C6.25 6.41421 6.58579 6.75 7 6.75V5.25ZM17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25V6.75ZM7 8.25C6.58579 8.25 6.25 8.58579 6.25 9C6.25 9.41421 6.58579 9.75 7 9.75V8.25ZM17 9.75C17.4142 9.75 17.75 9.41421 17.75 9C17.75 8.58579 17.4142 8.25 17 8.25V9.75ZM9 16.25C8.58579 16.25 8.25 16.5858 8.25 17C8.25 17.4142 8.58579 17.75 9 17.75V16.25ZM15 17.75C15.4142 17.75 15.75 17.4142 15.75 17C15.75 16.5858 15.4142 16.25 15 16.25V17.75ZM7 6.75H17V5.25H7V6.75ZM7 9.75H17V8.25H7V9.75ZM9 17.75H15V16.25H9V17.75ZM3 11.25H2.6V12.75H3V11.25ZM2.6 11.25C1.85444 11.25 1.25 11.8544 1.25 12.6H2.75C2.75 12.6828 2.68282 12.75 2.6 12.75V11.25ZM1.25 12.6V21.4H2.75V12.6H1.25ZM1.25 21.4C1.25 22.1456 1.85444 22.75 2.6 22.75V21.25C2.68282 21.25 2.75 21.3172 2.75 21.4H1.25ZM2.6 22.75H21.4V21.25H2.6V22.75ZM21.4 22.75C22.1456 22.75 22.75 22.1456 22.75 21.4H21.25C21.25 21.3172 21.3172 21.25 21.4 21.25V22.75ZM22.75 21.4V12.6H21.25V21.4H22.75ZM22.75 12.6C22.75 11.8544 22.1456 11.25 21.4 11.25V12.75C21.3172 12.75 21.25 12.6828 21.25 12.6H22.75ZM21.4 11.25H21V12.75H21.4V11.25ZM21 11.25H3V12.75H21V11.25ZM3.75 12V2.6H2.25V12H3.75ZM3.75 2.6C3.75 2.68284 3.68284 2.75 3.6 2.75V1.25C2.85442 1.25 2.25 1.85442 2.25 2.6H3.75ZM3.6 2.75H20.4V1.25H3.6V2.75ZM20.4 2.75C20.3172 2.75 20.25 2.68282 20.25 2.6H21.75C21.75 1.85444 21.1456 1.25 20.4 1.25V2.75ZM20.25 2.6V12H21.75V2.6H20.25Z"
      fill="currentColor"
    />
  </svg>
);

export const helpCenterIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90039 8.07955C7.90039 3.30679 15.4004 3.30683 15.4004 8.07956C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 19.01L12.01 18.9989"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const userManagementIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 20C0.25 20.4142 0.585786 20.75 1 20.75C1.41421 20.75 1.75 20.4142 1.75 20H0.25ZM14.25 20C14.25 20.4142 14.5858 20.75 15 20.75C15.4142 20.75 15.75 20.4142 15.75 20H14.25ZM12.25 14C12.25 14.4142 12.5858 14.75 13 14.75C13.4142 14.75 13.75 14.4142 13.75 14H12.25ZM22.25 14.5C22.25 14.9142 22.5858 15.25 23 15.25C23.4142 15.25 23.75 14.9142 23.75 14.5H22.25ZM1.75 20V19H0.25V20H1.75ZM1.75 19C1.75 15.5482 4.54822 12.75 8 12.75V11.25C3.7198 11.25 0.25 14.7198 0.25 19H1.75ZM8 12.75C11.4518 12.75 14.25 15.5482 14.25 19H15.75C15.75 14.7198 12.2802 11.25 8 11.25V12.75ZM14.25 19V20H15.75V19H14.25ZM13.75 14C13.75 11.6528 15.6528 9.75 18 9.75V8.25C14.8244 8.25 12.25 10.8244 12.25 14H13.75ZM18 9.75C20.3472 9.75 22.25 11.6528 22.25 14H23.75C23.75 10.8244 21.1756 8.25 18 8.25V9.75ZM22.25 14V14.5H23.75V14H22.25ZM8 12.75C10.6233 12.75 12.75 10.6233 12.75 8H11.25C11.25 9.79489 9.79489 11.25 8 11.25V12.75ZM12.75 8C12.75 5.37664 10.6233 3.25 8 3.25V4.75C9.79489 4.75 11.25 6.20508 11.25 8H12.75ZM8 3.25C5.37665 3.25 3.25 5.37665 3.25 8H4.75C4.75 6.20507 6.20507 4.75 8 4.75V3.25ZM3.25 8C3.25 10.6233 5.37664 12.75 8 12.75V11.25C6.20508 11.25 4.75 9.79489 4.75 8H3.25ZM18 9.75C20.0711 9.75 21.75 8.07106 21.75 6H20.25C20.25 7.24264 19.2427 8.25 18 8.25V9.75ZM21.75 6C21.75 3.92894 20.0711 2.25 18 2.25V3.75C19.2427 3.75 20.25 4.75736 20.25 6H21.75ZM18 2.25C15.9289 2.25 14.25 3.92894 14.25 6H15.75C15.75 4.75736 16.7573 3.75 18 3.75V2.25ZM14.25 6C14.25 8.07106 15.9289 9.75 18 9.75V8.25C16.7573 8.25 15.75 7.24264 15.75 6H14.25Z"
      fill="currentColor"
    />
  </svg>
);

export const ratingIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 20H4V4M4 16.5L12 9L15 12L19.5 7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const bidIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7H12.75ZM11.25 9C11.25 9.41421 11.5858 9.75 12 9.75C12.4142 9.75 12.75 9.41421 12.75 9H11.25ZM11.4428 12.508C11.1655 12.8157 11.1902 13.29 11.498 13.5672C11.8057 13.8445 12.28 13.8198 12.5572 13.512L11.4428 12.508ZM12.5672 13.5009C12.8445 13.1932 12.8198 12.7189 12.512 12.4417C12.2043 12.1644 11.73 12.1891 11.4528 12.4969L12.5672 13.5009ZM6.39951 17.7506L5.81388 17.2821L5.81386 17.2821L6.39951 17.7506ZM4.06852 20.6643L3.48287 20.1958L3.48286 20.1958L4.06852 20.6643ZM11.25 7V9H12.75V7H11.25ZM12.5572 13.512L12.5672 13.5009L11.4528 12.4969L11.4428 12.508L12.5572 13.512ZM3.75 20.2895V5H2.25V20.2895H3.75ZM3.75 5C3.75 4.30964 4.30964 3.75 5 3.75V2.25C3.48122 2.25 2.25 3.48122 2.25 5H3.75ZM5 3.75H19V2.25H5V3.75ZM19 3.75C19.6904 3.75 20.25 4.30964 20.25 5H21.75C21.75 3.48122 20.5188 2.25 19 2.25V3.75ZM20.25 5V15H21.75V5H20.25ZM20.25 15C20.25 15.6904 19.6904 16.25 19 16.25V17.75C20.5188 17.75 21.75 16.5188 21.75 15H20.25ZM19 16.25H7.96125V17.75H19V16.25ZM7.96125 16.25C7.12582 16.25 6.33574 16.6298 5.81388 17.2821L6.98514 18.2191C7.22238 17.9226 7.58153 17.75 7.96125 17.75V16.25ZM5.81386 17.2821L3.48287 20.1958L4.65417 21.1328L6.98516 18.2191L5.81386 17.2821ZM3.48286 20.1958C3.49668 20.1785 3.52622 20.156 3.56586 20.1458C3.60041 20.137 3.6291 20.1408 3.64963 20.148C3.67017 20.1552 3.69493 20.1701 3.71639 20.1986C3.741 20.2313 3.75 20.2674 3.75 20.2895H2.25C2.25 21.5657 3.85702 22.1293 4.65418 21.1328L3.48286 20.1958Z"
      fill="currentColor"
    />
  </svg>
);
