import React, { useContext } from "react";
import { clearUser } from "../../../actions/UserActions";
import { connect, useSelector } from "react-redux";

import avatarAlt from "../img/user-avatar-alt.svg";
import down from "../img/down.svg";
import { NavLink, useHistory } from "react-router-dom";
import { ROUTES } from "../../../routes";
import {
  userCircledIcon,
  notificationIcon, arrowBackIcon,
} from "../../../assets/img/sprite";
import { UserContext } from "../../../App";
import { config } from "../../../layouts/SidemenuLayout/sidemenuConfigs";
import AccountWidget from "../accountWidget";

function Logged(props) {
  const history = useHistory();

  const userContext = useContext(UserContext);

  const isHavePermission = (permission) => {
    return userContext.permissions.filter((perm) => perm.name === permission)
      .length;
  };
  const notificationsStore = useSelector((store) => store.notifications);

  const notificationsCount = notificationsStore.isUpdated
    ? notificationsStore.count
    : userContext.unreadNotifications;

  const onClose = () => {
    let tokenName = localStorage.getItem("token_name");
    localStorage.removeItem(tokenName);
    localStorage.removeItem("token_name");
    history.replace(ROUTES.LOGIN.PATH);
    // props.clearUser();
    window.location.reload();
  };

  return (
    <nav className="account-widget account-widget-logged">
      {/*<div className="account-widget-user-container">*/}
      {/*  <div className="account-widget-info">*/}
      {/*    <span className="account-widget-name">*/}
      {/*      <img className="account-widget-avatar" alt="" src={avatarAlt} />*/}
      {/*      {props.name}*/}
      {/*    </span>*/}
      {/*    <div className="account-widget-role">{props.role}</div>*/}
      {/*  </div>*/}
      {/*  <div className="account-widget-arrow-container">*/}
      {/*    <img className="account-widget-arrow" alt="" src={down} />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <ul className="navigation">
        {config.map((item) => {
          if (!item.permission || isHavePermission(item.permission)) {
            return item.path === ROUTES.NOTIFICATION.PATH ? null : (
              <li key={item.path}>
                <NavLink
                  activeClassName="_active"
                  to={item.path}
                  className="navigation__link"
                >
                  {item.text}
                </NavLink>
              </li>
            );
          }
          return null;
        })}
      </ul>
      {props.arrowBack ? (
          <button
              className="account-widget-arrow-back"
              onClick={() => {
                history.goBack();
              }}
          >
            {arrowBackIcon}
            <span>Назад</span>
          </button>
      ) : null}
      <div className="account-widget-user-container">
        <NavLink
          className={`account-widget-control ${notificationsCount ? "_notify" : ""}`}
          to={ROUTES.NOTIFICATION.PATH}
        >
          {notificationIcon}
        </NavLink>
        <button className="account-widget-control">{userCircledIcon}</button>
        <div className="account-widget-dropdown">
          <NavLink to={"/profile"}>
            <button type="button" className="account-widget-dropdown-item">
              Личный кабинет
            </button>
          </NavLink>
          <button
            onClick={onClose}
            type="button"
            className="account-widget-dropdown-item"
          >
            Выйти
          </button>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: () => dispatch(clearUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logged);
