import React from "react";
import menu from "../../layouts/SidemenuLayout/components/Sidemenu/img/Menu.svg";
import { WindowSizeNames } from "../../assets/js/constants";

const TIP_WIDTH = 29;
const TIP_HEIGHT = 12;

const PopoverWidths = {
  MOBILE: 280,
  TABLET: 540,
  DESKTOP: 540,
};

const getWindowSizeState = (windowSizeName) => {
  return {
    isDesktop: windowSizeName === WindowSizeNames.DESKTOP,
    isTablet: windowSizeName === WindowSizeNames.TABLET,
    isMobile: windowSizeName === WindowSizeNames.MOBILE,
  };
};

const getRegistrationSteps = (windowSizeName) => {
  const popoverWidth = PopoverWidths[windowSizeName];
  const windowSizeState = getWindowSizeState(windowSizeName);

  return [
    {
      title: "Как зарегистрироваться",
      content: (
        <p>
          Заполните все поля формы и нажмите на кнопку “Регистрация”. После
          подтверждения учетной записи техническим специалистом вам будет открыт
          доступ к системе.
        </p>
      ),
      location: () => "/",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const registrationBtn = document.querySelector(
          ".account-widget ._registration"
        );
        if (!registrationBtn) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = registrationBtn.getBoundingClientRect();
        const displacement = windowSizeState.isMobile ? 60 : 70;

        return {
          popover: {
            top: `${top + height + TIP_HEIGHT + 8}px`,
            left: `${
              left - popoverWidth + TIP_WIDTH / 2 + displacement + width / 2
            }px`,
          },
          tip: {
            side: "top",
            right: `${displacement}px`,
          },
        };
      },
    },
    {
      title: "Как войти",
      content: (
        <p>
          Для авторизации в системе используйте логин и пароль, указанные при
          регистрации.
        </p>
      ),
      location: () => "/",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const registrationBtn = document.querySelector(
          ".account-widget-button._log-in"
        );
        const displacement = windowSizeState.isMobile ? 120 : 70;

        if (!registrationBtn) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = registrationBtn.getBoundingClientRect();
        return {
          popover: {
            top: `${top + height + TIP_HEIGHT + 8}px`,
            left: `${
              left - popoverWidth + TIP_WIDTH / 2 + displacement + width / 2
            }px`,
          },
          tip: {
            side: "top",
            right: `${displacement}px`,
          },
        };
      },
    },
  ];
};

const getInstructionalSteps = (windowSizeName) => {
  const popoverWidth = PopoverWidths[windowSizeName];
  const windowSizeState = getWindowSizeState(windowSizeName);

  return [
    {
      title: "Стартовая страница",
      content: <p>Здесь расположен список всех объектов системы.</p>,
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: () => ({
        popover: { top: "8%", right: `calc(50% - ${popoverWidth / 2}px)` },
      }),
    },
    {
      title: "Меню",
      content: (
        <p>
          Переключение между разделами осуществляется посредством бокового
          навигационного меню. Навигационное меню можно свернуть или развернуть
          нажатием на кнопку <img width="16" height="10" alt="" src={menu} />
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const sidemunuControl = windowSizeState.isDesktop
          ? document.querySelector(".main-layout .sidemenu-toggle")
          : document.querySelector(".main-layout .account-widget-menu-toggle");
        if (!sidemunuControl) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = sidemunuControl.getBoundingClientRect();

        const popoverTop = windowSizeState.isDesktop
          ? `${top - 8}px`
          : `${top + height + 16}px`;

        const popoverLeft = windowSizeState.isDesktop
          ? `${left + width + TIP_WIDTH - 8}px`
          : windowSizeState.isTablet
          ? `${left - popoverWidth + TIP_WIDTH / 2 + 8}px`
          : `${left - popoverWidth + TIP_WIDTH / 2 + 16}px`;

        return {
          popover: {
            top: popoverTop,
            left: popoverLeft,
            // left: `calc(50% - ${width/2}px)`,
          },
          tip: windowSizeState.isDesktop
            ? {
                side: "left",
                left: "-66px",
              }
            : {
                side: "top",
                right: `${5}px`,
              },
        };
      },
    },
    {
      title: "Личный кабинет",
      content: (
        <p>
          Здесь можно просмотреть и изменить персональные данные пользователя.
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const account = windowSizeState.isDesktop
          ? document.querySelector(".main-layout .sidemenu-user-block")
          : document.querySelector(
              ".main-layout .account-widget-user-container"
            );
        if (!account) {
          return null;
        }

        const { width, height, top, left } = account.getBoundingClientRect();

        const popoverTop = windowSizeState.isDesktop
          ? `${top - 72}px`
          : `${top + height + 16}px`;

        const popoverLeft = windowSizeState.isDesktop
          ? `${left + width + TIP_WIDTH - 8}px`
          : `${left - popoverWidth + TIP_WIDTH / 2 + 80 + 8}px`;

        return {
          popover: windowSizeState.isMobile
            ? { top: "8%", right: `calc(50% - ${popoverWidth / 2}px)` }
            : {
                top: popoverTop,
                left: popoverLeft,
              },
          tip: windowSizeState.isMobile
            ? null
            : windowSizeState.isDesktop
            ? {
                side: "left-center",
                left: "-66px",
              }
            : {
                side: "top",
                right: `${15}px`,
              },
        };
      },
    },
    {
      title: "Система поиска объектов по фильтрам (в том числе и расширенный)",
      content: (
        <p>
          Для поиска объекта задайте необходимые параметры. Кнопка “Расширенный
          поиск” открывает дополнительные критерии. Для просмотра
          отфильтрованных объектов на карте переключитесь на вкладку с
          соответствующим названием.
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const filters = document.querySelector(".main-layout .objects-search");
        if (!filters) {
          return null;
        }

        const { width, height, top, left } = filters.getBoundingClientRect();
        return {
          popover: {
            top: `${top + height + TIP_HEIGHT + 8}px`,
            left: `${left + TIP_WIDTH / 2}px`,
          },
          tip: {
            side: "top",
            left: "-50px",
          },
        };
      },
    },
    {
      title: "Объекты на карте",
      content: (
        <p>
          Переключитесь на вкладку “Карта” для просмотра расположения объектов.
          Используя колесо мыши или кнопки, можно увеличить либо уменьшить
          масштаб карты. Для просмотра информации об объекте нажмите на
          необходимую метку.
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const switchControls = document.querySelector(
          ".main-layout .objects-header-controls .tabs"
        );
        if (!switchControls) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = switchControls.getBoundingClientRect();
        return {
          popover: {
            top: `${top + height + TIP_HEIGHT + 8}px`,
            left: `${left - popoverWidth + TIP_WIDTH / 2 + width}px`,
          },
          tip: {
            side: "top",
            right: "40px",
          },
        };
      },
    },
    {
      title: 'Кнопка "Добавить объект"',
      content: (
        <p>
          Заполните обязательные поля - название, адрес, национальный проект,
          тип работ, тип объекта, точка на карте. Далее вы можете внести
          дополнительную информацию либо нажать кнопку “Сохранить объект” и
          вернуться к редактированию позднее.
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const addObjectBtn = document.querySelector(
          ".main-layout .objects-header-controls-button"
        );
        if (!addObjectBtn) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = addObjectBtn.getBoundingClientRect();
        return {
          popover: {
            top: `${top + height + TIP_HEIGHT + 8}px`,
            left: `${left + TIP_WIDTH / 2 - 20}px`,
          },
          tip: {
            side: "top",
            left: "20px",
          },
        };
      },
    },
    {
      title: "Помощь",
      content: (
        <p>
          Подробное руководство пользователя вы можете найти в боковом меню
          системы.
        </p>
      ),
      location: (user) =>
        ["construction_control", "government_customer"].includes(user.type)
          ? "/objects/all/list"
          : "/objects/my/list",
      style: (isPendingContent) => {
        if (isPendingContent) {
          return null;
        }

        const sidemunuTabs = windowSizeState.isDesktop
          ? document.querySelector(".main-layout .sidemenu-tabs")
          : document.querySelector(".main-layout");
        if (!sidemunuTabs) {
          return null;
        }

        const {
          width,
          height,
          top,
          left,
        } = sidemunuTabs.getBoundingClientRect();
        return {
          popover: windowSizeState.isDesktop
            ? {
                top: `${top + height - 94}px`,
                left: `${left + width + TIP_WIDTH - 8}px`,
              }
            : { top: "8%", right: `calc(50% - ${popoverWidth / 2}px)` },
          tip: windowSizeState.isDesktop
            ? {
                side: "left",
                left: "-66px",
              }
            : null,
        };
      },
    },
  ];
};

export { getRegistrationSteps, getInstructionalSteps };
