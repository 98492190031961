import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from "../actions/ModalAction";

const initialState = {
  isOpen: false,
  type: "",
  withoutCloseBtn: false,
  isContainer: false,
  data: {},
  render: null,
};

const Modal = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
        type: action.payload.type,
        withoutCloseBtn: action.payload.withoutCloseBtn,
        isContainer: action.payload.isContainer,
        data: action.payload.data,
        render: action.payload.render,
      };
    }
    case CLOSE_MODAL: {
      return { ...state, ...initialState };
    }
    default:
      return state;
  }
};

export default Modal;
