import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import "./Info.scss";

import SoloObjectMap from "./components/SoloObjectMap";
import ExpandablePanel from "../../../../components/ExpandablePanel";
import ObjectDesignProject from "../../../../components/ObjectDesignProject";
import ObjectEstimates from "../../../../components/ObjectEstimates";
import ObjectProjectEstimates from "../../../../components/ObjectProjectEstimates/ObjectProjectEstimates";
import CustomerInfo from "./components/CustomerInfo";
import ContractorInfo from "./components/ContractorInfo";
import {
  editObjectLinks,
  fetchSoloObject,
} from "../../../../actions/ObjectsActions";
import CheckPermissions from "../../../../components/CheckPermissions";
import InfoPanelWithEdit from "./components/InfoPanelWithEdit";
import ObjectLinks from "./components/ObjectLinks";
import AddObjectArchive from "../../../AddObject/components/AddObjectInfo/components/AddObjectArchive";
import { UserContext } from "../../../../App";
import InfoPopup from "../../../InfoPopup";

const Info = (props) => {
  const [isCustomerEditable, setIsCustomerEditable] = useState(false);
  const [isDesignerEditable, setIsDesignerEditable] = useState(false);
  const [isArchitectEditable, setIsArchitectEditable] = useState(false);
  const [isLinksEditable, setIsLinksEditable] = useState(false);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const dispatch = useDispatch();
  const getObject = () => dispatch(fetchSoloObject(props.info.id));

  const customerContractorSave = () => {
    setIsCustomerEditable(false);
    getObject();
  };

  const user = useContext(UserContext);
  const isHavePermission = (permission) => {
    return user.permissions.filter((perm) => perm.name === permission).length;
  };

  const setArchives = (archives) => {
    setIsLinksEditable(false);
    editObjectLinks(props.selectedObjectId, archives).then((r) => {
      if (r === "success") {
        props.onChangeInfo();
      }
    });
  };

  return (
    <React.Fragment>
      <SoloObjectMap
        isEditable={props.isObjectEditable}
        latitude={props.info.latitude}
        longitude={props.info.longitude}
        onPointSet={props.onPointSet}
        polygons={props.info.polygons}
        onPolygonsChange={props.onPolygonsChange}
      />

      <div className="content-card">
        <InfoPanelWithEdit
          permissionsOnEdit={[
            "perm_construction-object_update-customer-contacts",
          ]}
          onEditClick={() => setIsCustomerEditable(!isCustomerEditable)}
          isEditable={isCustomerEditable}
          label="Заказчик"
          type="customer"
          modalLabel="Назначить заказчика"
          status={props.status}
          viewSetUserPerm={"perm_user_customer"}
          setUserPerm={"perm_construction-object_assign-customer"}
          objectId={props.selectedObjectId}
          onChangeInfo={props.onChangeInfo}
        >
          <CustomerInfo
            isEditable={isCustomerEditable}
            customer={props.info.customer}
            onCancel={() => setIsCustomerEditable(false)}
            onSave={customerContractorSave}
          />
        </InfoPanelWithEdit>
      </div>

      <div className="content-card">
        <InfoPanelWithEdit
          permissionsOnEdit={[
            "perm_construction-object_update-architect-contacts",
          ]}
          onEditClick={() => setIsArchitectEditable(!isArchitectEditable)}
          isEditable={isArchitectEditable}
          withUserCreator
          label="Разработчик дизайн-проекта"
          buttonLabel="Утвердить архитектора"
          type="architect"
          modalLabel="Назначить архитектора"
          hasContractorToObject={false}
          onSetContractor={props.onOpenContractorList}
          builderId={props.info.architect.builderId || null}
          status={props.status}
          viewSetUserPerm={"perm_user_architect"}
          setUserPerm={"perm_construction-object_assign-designer"}
          objectId={props.selectedObjectId}
          onChangeInfo={props.onChangeInfo}
        >
          <ContractorInfo
            isEditable={isArchitectEditable}
            isPossibleToAdd={false}
            withoutExecutors
            type="architect"
            contractor={props.info.architect}
            onCancel={() => setIsArchitectEditable(false)}
            onSave={customerContractorSave}
          />
        </InfoPanelWithEdit>
      </div>

      <div className="content-card">
        <InfoPanelWithEdit
          permissionsOnEdit={[
            "perm_construction-object_update-designer-contacts",
          ]}
          onEditClick={() => setIsDesignerEditable(!isDesignerEditable)}
          isEditable={isDesignerEditable}
          withUserCreator
          label="Разработчик проектно-сметной документации"
          buttonLabel="Утвердить проектировщика"
          type="designer"
          modalLabel="Назначить проектировщика"
          hasContractorToObject={false}
          onSetContractor={props.onOpenContractorList}
          builderId={props.info.designer.builderId || null}
          status={props.status}
          viewSetUserPerm={"perm_user_designer"}
          setUserPerm={"perm_construction-object_assign-designer"}
          objectId={props.selectedObjectId}
          onChangeInfo={props.onChangeInfo}
        >
          <ContractorInfo
            isEditable={isDesignerEditable}
            isPossibleToAdd={false}
            withoutExecutors
            contractor={props.info.designer}
            type="designer"
            onCancel={() => setIsDesignerEditable(false)}
            onSave={customerContractorSave}
          />
        </InfoPanelWithEdit>
      </div>

      <CheckPermissions permissions={["perm_document_view"]}>
        <div className="content-card">
          {/*<ExpandablePanel label="Сметный расчет" disabled={isAvailable}>*/}
          <div className="content-card__head">
            <h3 className="content-card__title">Сметный расчет</h3>
            <CheckPermissions permissions={["perm_contractor_index"]}>
              {props.hasContractorsToObject ? (
                <button
                  className="solo-object-estimates-add-contractor"
                  onClick={props.onOpenContractorList}
                >
                  Утвердить подрядчиков
                </button>
              ) : null}
            </CheckPermissions>
          </div>
          <ObjectEstimates
            status={props.status}
            id={props.info.id}
            // hasContractors={props.hasContractorsToObject}
            // onOpenContractorList={props.onOpenContractorList}
          />
          {/*</ExpandablePanel>*/}
        </div>

        <div className="content-card">
          {props.info.jobType === "Благоустройство" ? (
            // <ExpandablePanel
            //   label="Дизайн-проект / Дефектная ведомость"
            //   disabled={isAvailable}
            // >
            <div className="content-card__item">
              <h3 className="content-card__title">
                Дизайн-проект / Дефектная ведомость
              </h3>
              <ObjectDesignProject status={props.status} id={props.info.id} />
            </div>
          ) : // </ExpandablePanel>
          null}

          {props.links.length ||
          isHavePermission("perm_construction-object-archive_create") ? (
            // <ExpandablePanel
            //   label="Связь с архивными объектами"
            //   disabled={isAvailable}
            // >
            <div className="content-card__item">
              <h3 className="content-card__title">
                Связь с архивными объектами
              </h3>
              <InfoPanelWithEdit
                permissionsOnEdit={["perm_construction-object-archive_update"]}
                onEditClick={() => setIsLinksEditable(!isLinksEditable)}
                isEditable={isLinksEditable && isAvailable}
                hasContractorToObject={props.hasContractor}
                builderId={props.builderId}
                status={props.status}
                objectId={props.selectedObjectId}
                onChangeInfo={props.onChangeInfo}
              >
                {isLinksEditable ? (
                  <AddObjectArchive
                    onSelectObjects={setArchives}
                    onCancel={() => setIsLinksEditable(false)}
                    links={props.links}
                    linksIds={props.linksIds}
                  />
                ) : (
                  <ObjectLinks links={props.links} linksIds={props.linksIds} />
                )}
              </InfoPanelWithEdit>
            </div>
          ) : null}

          {/*<ExpandablePanel*/}
          {/*  label="Проектная документация"*/}
          {/*  disabled={isAvailable}*/}
          {/*>*/}
          <div className="content-card__item">
            <h3 className="content-card__title">Проектная документация</h3>
            <ObjectProjectEstimates status={props.status} id={props.info.id} />
          </div>
          {/*</ExpandablePanel>*/}
        </div>
      </CheckPermissions>

      <InfoPopup />
    </React.Fragment>
  );
};

Info.propTypes = {
  info: PropTypes.object,
  isObjectEditable: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  onPointSet: PropTypes.func,
  onOpenContractorList: PropTypes.func,
  hasContractor: PropTypes.bool,
  builderId: PropTypes.number,
  selectedObjectId: PropTypes.number,
  onChangeInfo: PropTypes.func,
  links: PropTypes.array,
  linksIds: PropTypes.array,
  onSetStatus: PropTypes.func,
  hasContractorsToObject: PropTypes.bool,
};

export default Info;
