import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./CreateUser.scss";
import EditableField from "../EditableField";
import EditableTextArea from "../EditableTextArea";
import { createUser } from "../../actions/CreateUserActions";
import { mail, required } from "../../assets/js/validators";
import toaster from "toasted-notes";
import { fetchOrganizations } from "../../actions/SingUpActions";
import Select from "../Select";
import { RoleBasedInputLabels } from "../../assets/js/constants";
import { plusIcon } from "../../assets/img/sprite";

const initialUserInfo = {
  orgName: "",
  orgContacts: "",
  orgAddress: "",
  name: "",
  phone: "",
  email: "",
  info: "",
  role: "",
};

const CreateUser = (props) => {
  const dispatch = useDispatch();
  const singUpStore = useSelector((store) => store.singUp);
  const [changedUserInfo, setChangedUserInfo] = useState({
    ...initialUserInfo,
    role: props.type,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isCreating, setIsCreating] = useState(false);

  const getOrganizations = () => {
    if (
      !singUpStore.isUpdatedOrganizations &&
      !(singUpStore.isFetchingOrg || singUpStore.isErrorOrg)
    ) {
      dispatch(fetchOrganizations());
    }
  };

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) return true;
    }
    return false;
  };

  const handleUserChange = (e) => {
    setChangedUserInfo({
      ...changedUserInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleOrganizationSelect = (item, name) => {
    setChangedUserInfo({
      ...changedUserInfo,
      [name]: item.value,
    });
  };

  const handleUserCreate = async () => {
    if (!isValidationErrors()) {
      const message = await dispatch(createUser(changedUserInfo));
      if (message === "success") {
        props.onCreate();
        setChangedUserInfo({ ...initialUserInfo });
        setIsCreating(false);
        return;
      }

      toaster.notify(
        <div className="error-toast">
          {Object.keys(message).map((errorName) => message[errorName])}
        </div>,
        { position: "top-right", duration: 4000 }
      );
    }
  };

  const handleValidationChange = (name, value) => {
    setValidationErrors({
      ...validationErrors,
      [name]: value,
    });
  };

  return (
    <div className="create-user-block">
      {" "}
      {!isCreating ? (
        <button
          className="create-user-block__add-btn"
          onClick={() => setIsCreating(true)}
        >
          {plusIcon}
          <span>Добавить пользователя</span>
        </button>
      ) : (
        <>
          <h3 className="create-user-block__title">
            Информация о пользователе
          </h3>
          <Select
            label="Название организации"
            items={singUpStore.organizations}
            onChange={handleOrganizationSelect}
            value={changedUserInfo.orgName}
            onOpen={getOrganizations}
            isPaginator={true}
            name="orgName"
            reset
          />
          <EditableField
            value={changedUserInfo.orgContacts}
            label="Контакты организации"
            isEditable={true}
            onChange={handleUserChange}
            name="orgContacts"
          />
          <EditableField
            value={changedUserInfo.orgAddress}
            label="Адрес организации"
            isEditable={true}
            onChange={handleUserChange}
            name="orgAddress"
          />
          <EditableField
            value={changedUserInfo.name}
            label={`ФИО ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={true}
            onChange={handleUserChange}
            name="name"
            validators={[required]}
            onValidationChange={handleValidationChange}
          />
          <EditableField
            value={changedUserInfo.phone}
            label={`Телефон ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={true}
            onChange={handleUserChange}
            name="phone"
            validators={[required]}
            onValidationChange={handleValidationChange}
          />
          <EditableField
            value={changedUserInfo.email}
            label={`Почта ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={true}
            onChange={handleUserChange}
            name="email"
            validators={[required, mail]}
            onValidationChange={handleValidationChange}
          />

          <div className="create-user-block__item _info">
            <EditableTextArea
              value={changedUserInfo.info}
              label="Дополнительная информация"
              isEditable={true}
              onChange={handleUserChange}
              name="info"
            />
          </div>

          <div className="create-user-block__controls">
            <button
              onClick={() => setIsCreating(false)}
              className="create-user-block__cancel-btn _error"
            >
              Отмена
            </button>

            <button
              onClick={handleUserCreate}
              className="create-user-block__create-btn"
            >
              Создать
            </button>
          </div>
        </>
      )}
    </div>
  );
};

CreateUser.propTypes = {
  type: PropTypes.string,
  onCreate: PropTypes.func,
};

export default CreateUser;
