import React, { useState } from "react";
import PropTypes from "prop-types";

import "./NotificationCard.scss";

import { checkIcon } from "../../../../assets/img/sprite";

const NotificationCard = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onTarget = (ev) => {
    ev.stopPropagation();

    props.onTarget(props.item.type_code, props.item.notification_event_ref);
  };

  const onRead = (ev) => {
    ev.stopPropagation();

    props.onRead();
  };

  // useEffect(() => {
  //   if(isOpen && !props.item.notification_status){
  //     props.onRead();
  //   }
  // }, [isOpen])

  return (
    <div
      // style={{backgroundColor: props.item.notification_status ? "#b6b6b5" : "#fff"}}
      onClick={() => setIsOpen(!isOpen)}
      className={`notification-card ${
        props.item.notification_status ? "_read" : ""
      }`}
    >
      <div className="notification-card__content">
        <div className="notification-card__date">
          {props.item.notification_created_at}
        </div>
        <h3 className="notification-card__title">{props.item.notification_title}</h3>

        <div
          className="notification-card__text"
          dangerouslySetInnerHTML={{ __html: props.item.notification_content }}
        />

        <div className="notification-card__controls">
          {!props.item.notification_status && (
            <button onClick={onRead} className="notification-card__read-btn">
              {checkIcon}
              <span>Отметить как прочитанное</span>
            </button>
          )}
          <button className="notification-card__target-btn" onClick={onTarget}>
            Перейти
          </button>
        </div>
      </div>
    </div>
  );
};

NotificationCard.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  onRead: PropTypes.func,
  onOpen: PropTypes.func,
  onTarget: PropTypes.func,
};

export default NotificationCard;
