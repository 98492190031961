import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./ObjectInfo.scss";

import EditableField from "../../../../../../components/EditableField";
import EditableTextArea from "../../../../../../components/EditableTextArea/EditableTextArea";
import Status from "../../../../../../components/Status";
import EditableDateField from "../../../../../../components/EditableDateField";
import EditableSelectField from "../../../../../../components/EditableSelectField";
import { fetchFilters } from "../../../../../../actions/FiltersActions";
import { required } from "../../../../../../assets/js/validators";

const ObjectInfo = (props) => {
  const filtersStore = useSelector((store) => store.objectsFilters);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const dispatch = useDispatch();

  const onOpen = (type) => {
    if (!filtersStore[type].isFetching && !filtersStore[type].isUpdated) {
      dispatch(fetchFilters(type));
    }
  };

  return (
    <div className="object-info">
      <div className="object-info-address">
        <EditableField
          onChange={(ev) => props.onChange(ev.target.value, ev.target.name)}
          label="Адрес"
          value={props.info.address}
          isEditable={props.isEditable}
          name="address"
          onValidationChange={props.onValidationChange}
          validators={[required]}
        />
      </div>

      <div className="object-info-status">
        {isMobileSize && (
          <div className="object-info-status-label">Статус проекта</div>
        )}
        <Status value={props.info.status} color={props.statusColor} />
        {props.info.statusComment && (
          <div className="object-info-status-reason">
            <div className="object-info-status-label">Причина заморозки</div>
            <div className="object-info-status-comment">
              {props.info.statusComment}
            </div>
          </div>
        )}
      </div>

      <div className="object-info-description">
        <EditableTextArea
          value={props.info.description}
          label="Описание"
          isEditable={props.isEditable}
          name="description"
          onChange={(value) => props.onChange(value, "description")}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="object-info-ref">
        <EditableField
          label="Ссылка на камеры наблюдения"
          value={props.info.refVideo}
          isEditable={props.isEditable}
          name="refVideo"
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="object-info-national-project">
        <EditableSelectField
          value={props.info.projectName}
          label="Нац. проект"
          name="n_project"
          onChange={(value) => props.onChange(value, "projectName")}
          isEditable={props.isEditable && props.havePermission}
          isFetching={filtersStore.n_project.isFetching}
          items={filtersStore.n_project.items}
          onSelectOpen={() => onOpen("n_project")}
          onValidationChange={props.onValidationChange}
          validators={[required]}
          reset
        />
      </div>

      <div className="object-info-job-type">
        <EditableSelectField
          value={props.info.jobType}
          label="Тип работ"
          isEditable={props.isEditable && props.havePermission}
          isFetching={filtersStore.work_type.isFetching}
          items={filtersStore.work_type.items}
          onChange={(value) => props.onChange(value, "jobType")}
          onSelectOpen={() => onOpen("work_type")}
          onValidationChange={props.onValidationChange}
          validators={[required]}
          name="work_type"
          reset
        />
      </div>

      <div className="object-info-object-type">
        <EditableSelectField
          value={props.info.objectType}
          label="Тип объекта"
          name="object_type"
          items={filtersStore.object_type.items}
          isFetching={filtersStore.object_type.isFetching}
          isEditable={props.isEditable && props.havePermission}
          onChange={(value) => props.onChange(value, "objectType")}
          onSelectOpen={() => onOpen("object_type")}
          onValidationChange={props.onValidationChange}
          validators={[required]}
          reset
        />
      </div>

      <div className="object-info-plan-beg">
        <EditableDateField
          label="Дата заключения контракта"
          value={props.info.planBeg}
          isEditable={props.isEditable}
          onChange={(value) => props.onChange(value, "planBeg")}
          onValidationChange={props.onValidationChange}
          validators={[required]}
        />
      </div>

      <div className="object-info-plan-end">
        <EditableDateField
          label="Дата окончания контракта"
          value={props.info.planEnd}
          isEditable={props.isEditable}
          onChange={(value) => props.onChange(value, "planEnd")}
          onValidationChange={props.onValidationChange}
          validators={[required]}
        />
      </div>

      {props.isEditable ? (
        <div className="solo-object-controls">
          <button
            className="solo-object-controls-cancel _error"
            onClick={props.onCancel}
          >
            Отмена
          </button>
          <button className="solo-object-controls-save" onClick={props.onSave}>
            Сохранить
          </button>
        </div>
      ) : null}
    </div>
  );
};

ObjectInfo.propTypes = {
  info: PropTypes.shape({
    address: PropTypes.string,
    organization: PropTypes.string,
    organizationContacts: PropTypes.number,
    planBeg: PropTypes.string,
    planEnd: PropTypes.string,
    description: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  onSetStatus: PropTypes.func,
};

export default ObjectInfo;
