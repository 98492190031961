import { WindowSizeNames } from "../constants";

export default ({ isMobile, isTablet, isDesktop }) => {
  switch (true) {
    case isDesktop:
      return WindowSizeNames.DESKTOP;
    case isTablet && !isMobile:
      return WindowSizeNames.TABLET;
    case isMobile:
      return WindowSizeNames.MOBILE;
  }

  return null;
};
