export const CHOOSE_USER_REQUEST = 'CHOOSE_USER_REQUEST'
export const CHOOSE_USER_ERROR = 'CHOOSE_USER_ERROR'
export const CHOOSE_USER_SUCCESS = 'CHOOSE_USER_SUCCESS'

const initialState = {
  isFetching: false,
  isUpdated: false,
  isError: false,
  items: [],
  pagination: {}
}

const chooseUser = (state = initialState, action) => {
  switch (action.type) {
    case CHOOSE_USER_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
      }
    }
    case CHOOSE_USER_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        pagination: action.payload.pagination,
        isUpdated: true,
        isFetching: false,
      }
    }
    case CHOOSE_USER_ERROR: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        isError: true,
      }
    }
    default: {
      return state
    }
  }
}

export default chooseUser;