export const OPEN_INFO_POPUP = "OPEN_INFO_POPUP";
export const CLOSE_INFO_POPUP = "CLOSE_INFO_POPUP";

const initialState = {
  isOpen: false,
  text: "",
};

const infoPopup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_INFO_POPUP: {
      return {
        ...state,
        isOpen: true,
        text: action.payload.text,
      };
    }
    case CLOSE_INFO_POPUP: {
      return { ...state, isOpen: false, text: "" };
    }
    default:
      return state;
  }
};

export default infoPopup;
