import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Stage.scss";

import { inlineArrow } from "./img/inlineSVGicons";
import Paginator from "../../../../../../components/Paginator";

const Stage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const total = props.stages && props.stages.length
  const totalPages = Math.ceil(total / pageSize) || (total ? 1 : 1)

  const pageChange = (page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    if (props.selectAll && props.dirty) {
      let sel = []
      for (let i in props.stages) {
        sel.push(props.stages[i].id)
      }
      setSelected(sel)
    }  else if (!props.selectAll && props.dirty) {
      setSelected([])
    }
  }, [props.dirty, props.selectAll, props.stages, selected.length])

  const getItems = () => {
    let paginationItems = []
    let totalId = currentPage * pageSize > total ? total : currentPage * pageSize
    for (let i = (currentPage - 1) * pageSize; i < totalId; i++) {
      paginationItems.push(props.stages[i])
    }
    return paginationItems
  }

  // const selectAll = (ev) => {
  //   ev.stopPropagation();
  //
  //   if(ev.target.checked){
  //     let arr = []
  //     props.stages.items.forEach((item) => {
  //       arr.push(item.id);
  //     });
  //     setSelected(arr);
  //     props.selectSection(props.section.id, true);
  //   } else {
  //     setSelected([]);
  //     props.selectSection(props.section.id, false);
  //   }
  // }

  const select = (ev, id) => {
    let sel = [...selected];
    if(ev.target.checked){
      sel.push(id);
      props.selectStage(id, true);
    } else {
      sel.splice(sel.indexOf(id), 1);
      props.selectStage(id, false);
    }

    setSelected(sel);
  }

  const click = (ev) => {
    if(ev.target.type !== "checkbox"){
      setIsOpen(!isOpen)
    }
  }

  const isChecked = () => {
    return selected.length === props.stages.length;
  }

  const selectAllInSection = (value) => {
    if (value) {
      let sel = []
      for (let i in props.stages) {
        sel.push(props.stages[i].id)
      }
      setSelected(sel)
      props.selectSection(props.index, sel, true)
    } else {
      setSelected([])
      props.selectSection(props.index, null, false)
    }
  }

  return(
    <div className="object-stage">
      <div
        onClick={click}
        className="object-stage-head"
      >
        <div className="object-stage-select">
          <input checked={isChecked()} onChange={(ev) => selectAllInSection(ev.target.checked)} type="checkbox" />
        </div>

        <div className="object-stage-name">
          {props.section.name}
        </div>

        <div
          className={isOpen ? "object-stage-icon-open" : "object-stage-icon"}
        >
          {inlineArrow}
        </div>
      </div>

      {
        isOpen ? (
          <div className="object-stage-body">
            {
              getItems().map((stage) => {
                return(
                  <label className="object-stage-item" key={stage.id}>
                    <div className="object-stage-item-select">
                      <input
                        checked={selected.includes(stage.id)}
                        onChange={(ev) => select(ev, stage.id)}
                        type="checkbox"
                      />
                    </div>

                    <div className="object-stage-item-name">
                      {stage.name}
                    </div>
                  </label>
                )
              })
            }
            <Paginator
              currentPage={currentPage}
              pageSize={pageSize}
              total={total}
              totalPages={totalPages}
              onPageChange={pageChange}
            />
          </div>
        ) : null
      }
    </div>
  )
}

Stage.propTypes = {
  section: PropTypes.object,
  stages: PropTypes.array,
  selectSection: PropTypes.func,
  selectStage: PropTypes.func,
  selectAll: PropTypes.bool,
}

export default Stage;