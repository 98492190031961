import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../Loader";
import RatingCard from "../RatingCard";
import toaster from "toasted-notes";
import {
  fetchRating,
  saveObjectRating,
  updatePersonRating,
} from "../../actions/RatingAction";
import { closeModal } from "../../actions/ModalAction";
import { RatingRequestTypes } from "../../assets/js/constants";

import "./RatingBlock.scss";

const ObjectRating = ({ objectID }) => {
  const dispatch = useDispatch();
  const ratingStore = useSelector((store) => store.rating);
  const getRating = () =>
    dispatch(fetchRating(RatingRequestTypes.OBJECT, { objectID }));
  const changeRating = (data) => dispatch(updatePersonRating(data));
  const sendRating = () =>
    dispatch(saveObjectRating(objectID)).then((r) => {
      if (r !== "success") {
        toaster.notify(
          <div className="error-toast">
            При сохранении оценок произошла ошибка! Попробуйте ещё раз
          </div>,
          {
            position: "top-right",
            duration: 4000,
          }
        );
        return;
      }
      dispatch(closeModal());
    });
  const handleModalClose = () => dispatch(closeModal());
  const { isFetching, items, isError, errors } = ratingStore;

  useEffect(() => {
    getRating();
  }, []);

  const handleSaveChanges = () => {
    sendRating();
  };

  return (
    <div className="rating-block">
      {isError ? (
        <p className="rating-block__errors">{errors}</p>
      ) : isFetching ? (
        <Loader />
      ) : items.length ? (
        <>
          <div className="rating-block__head">
            <h2 className="rating-block__title">
              Оцените работу участников проекта
            </h2>
          </div>
          <ul className="rating-block__list">
            {items.map((item) => (
              <li key={item.id}>
                <RatingCard item={item} onChange={changeRating} />
              </li>
            ))}
          </ul>
          <div className="rating-block__controls">
            <button
              className="rating-block__btn _gray"
              type="button"
              onClick={handleModalClose}
            >
              Пропустить
            </button>
            <button
              className="rating-block__btn"
              type="button"
              onClick={handleSaveChanges}
            >
              Сохранить
            </button>
          </div>
        </>
      ) : (
        <div>Пользователи не найдены</div>
      )}
    </div>
  );
};

ObjectRating.propTypes = {
  objectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ObjectRating;
