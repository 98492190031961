export const WINDOW_PARAMS_UPDATE = "WINDOW_PARAMS_UPDATE";

export function updateWindowParams(params) {
  return (dispatch) => {
    dispatch({
      type: WINDOW_PARAMS_UPDATE,
      payload: params,
    });
  };
}
