import React from "react";
import "./RegistrationPopup.scss";
import RegistrationForm from "./components/RegistrationForm";
import { useSelector, useDispatch } from "react-redux";
import { closeRegistrationPopup } from "../../actions/RegistrationPopupAction";

const RegistrationPopup = (props) => {
  const popupStore = useSelector((store) => store.registrationPopup);
  const dispatch = useDispatch();
  const closePopup = () => dispatch(closeRegistrationPopup());
  if (popupStore.isOpen) {
    return (
      <div className="registration-popup" onClick={closePopup}>
        <div
          className="registration-popup__content"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="registration-popup__title">Зарегистрироваться</h2>
          <button
            className="registration-popup__close"
            type="button"
            aria-label="Закрыть"
            onClick={closePopup}
          />
          <RegistrationForm />
        </div>
      </div>
    );
  }
  return null;
};

export default RegistrationPopup;
