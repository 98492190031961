import React, { useContext } from "react";
import PropTypes from "prop-types";

import "./StageInfo.scss";

import EditableDateField from "../../../../components/EditableDateField";
import EditableField from "../../../../components/EditableField";
import Percentage from "../../../../components/Percentage";
import { UserContext } from "../../../../App";
import ReactTooltip from "react-tooltip";
import { closeIcon, checkIcon } from "../../../../assets/img/sprite";
import { useSelector } from "react-redux";

const StageInfo = (props) => {
  const change = (ev) => {
    props.onChange(ev.target.name, ev.target.value);
  };
  const user = useContext(UserContext);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  const canEdit = () => {
    if (
      user.permissions.filter(
        (perm) => perm.name === "perm_task_update-extended"
      ).length
    ) {
      return true;
    }
  };

  return (
    <ul className={props.isEditable ? "stage-info-edit" : "stage-info"}>
      <li className="stage-info-beg-head">Начало работ</li>
      <li className="stage-info-end-head">Завершение работ</li>
      <li className="stage-info-amount-head">Объем работ</li>
      <li className="stage-info-unit-head">Единицы измерения</li>

      <li className="stage-info-beg">
        <EditableDateField
          onChange={(value) => props.onChange("planBeg", value)}
          value={props.item.planBeg}
          isEditable={props.isEditable}
        />
      </li>

      <li className="stage-info-end">
        <EditableDateField
          onChange={(value) => props.onChange("planEnd", value)}
          value={props.item.planEnd}
          isEditable={props.isEditable}
        />
      </li>

      <li
        className="stage-info-amount"
        data-tip
        data-for={"stagesItemAmount" + props.item.id}
      >
        <EditableField
          onChange={change}
          value={props.item.requiredAmount}
          isEditable={props.isEditable && canEdit()}
          name="requiredAmount"
        />
        <ReactTooltip
          multiline
          isCapture
          id={"stagesItemAmount" + props.item.id}
          type="dark"
          place="top"
          effect="float"
        >
          <span>{props.item.coefficient}</span>
        </ReactTooltip>
      </li>

      <li className="stage-info-unit">
        <EditableField
          onChange={change}
          value={props.item.unit}
          isEditable={props.isEditable && canEdit()}
          name="unit"
        />
      </li>

      {props.isEditable ? (
        <li className="stage-info-controls">
          <button
            className={`stage-info-save ${!isMobileSize ? "_icon" : ""}`}
            onClick={props.onSave}
          >
            {!isMobileSize ? checkIcon : "Сохранить"}
          </button>
          <button
            className={`stage-info-cancel _error ${
              !isMobileSize ? "_icon" : ""
            }`}
            onClick={props.onCancel}
          >
            {!isMobileSize ? closeIcon : "Отменить"}
          </button>
        </li>
      ) : null}
    </ul>
  );
};

StageInfo.propTypes = {
  isEditable: PropTypes.bool,
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default StageInfo;
