import "./Map.scss";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { FullScreen, defaults as defaultControls } from "ol/control";
import {
  defaults as defaultInteractions,
  PinchZoom,
  DragZoom,
} from "ol/interaction";
import Overlay from "ol/Overlay";
import { toLonLat } from "ol/proj";
import Feature from "ol/Feature";

import useCadasterLayers from "./useCadasterLayers";
import useObjectIcons from "./useObjectIcons";
import useDrawnObjects from "./useDrawnObjects";
import { EXTENT, MIN_ZOOM, MAX_ZOOM } from "./constants";

const Map = ({
  center,
  initialZoom,
  showCadasterLayers,
  objectIcons,
  overlays,
  drawnObjects,
  onClick,
}) => {
  const { layers, layerControls } = useCadasterLayers({ showCadasterLayers });
  const [objectsLayer] = useState(
    () => new VectorLayer({ source: new VectorSource({ feature: [] }) })
  );

  const [map] = useState(
    () =>
      new OlMap({
        target: null,
        layers: [
          new OlLayerTile({ source: new OlSourceOSM() }),
          ...Object.values(layers),
          objectsLayer,
        ],
        view: new OlView({
          center,
          extent: EXTENT,
          zoom: initialZoom,
          constrainOnlyCenter: true,
          constrainResolution: true,
          minZoom: MIN_ZOOM,
          maxZoom: MAX_ZOOM,
        }),
        interactions: defaultInteractions().extend([
          new DragZoom(),
          new PinchZoom(),
        ]),
        controls: defaultControls().extend([new FullScreen()]),
        overlays,
      })
  );

  const location = useLocation();

  useObjectIcons({ map, objectIcons, objectsLayer });
  const { drawControls, isDrawing } = useDrawnObjects({ ...drawnObjects, map });

  useEffect(() => {
    if (!onClick || isDrawing) {
      return;
    }

    const listener = (event) => {
      const coordinates = toLonLat(map.getCoordinateFromPixel(event.pixel));
      onClick({ coordinates });
    };

    map.on("click", listener);
    return () => map.un("click", listener);
  }, [map, onClick, isDrawing]);

  return (
    <div className="map">
      <div className="map-map" ref={(element) => map.setTarget(element)} />
      <footer className="map-footer">
        {location.pathname === "/object/add/info" && drawControls}
        {layerControls}
      </footer>
    </div>
  );
};

Map.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  initialZoom: PropTypes.number.isRequired,
  showCadasterLayers: PropTypes.bool,
  objectIcons: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        mapIcon: PropTypes.string,
      })
    ).isRequired,
    onHover: PropTypes.func,
    onClick: PropTypes.func,
  }),
  overlays: PropTypes.arrayOf(PropTypes.instanceOf(Overlay)),
  drawnObjects: PropTypes.shape({
    canDraw: PropTypes.bool,
    point: PropTypes.shape({
      coordinates: PropTypes.shape({
        longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
      onChange: PropTypes.func,
    }),
    polygons: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.instanceOf(Feature)).isRequired,
      onChange: PropTypes.func,
    }),
  }),
  onClick: PropTypes.func,
};

export default Map;
