import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import "./Contractors.scss";
import { fetchFiles } from "../../../../../../actions/ObjectFilesActions";
import MultiSelect from "../../../../../../components/MultiSelect";
import { setContractorToObject } from "../../../../../../actions/objectContractorsActions";
import toaster from "toasted-notes";
import { closeModal, openModal } from "../../../../../../actions/ModalAction";
import { ModalTypes } from "../../../../../../assets/js/constants";
import ConfirmAction from "../../../../../../components/ConfirmAction";

const Contractors = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { estimates } = useSelector((store) => store.objectFiles);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const getEstimates = () => dispatch(fetchFiles("estimates", id));

  useEffect(() => {
    if (id) {
      getEstimates();
    }
  }, [id]);

  const getContractorEstimates = (profileID) => {
    const contactorEstimates = [];

    estimates.items.forEach((estimate) => {
      if (estimate.construction_object_builder_id === profileID) {
        contactorEstimates.push(estimate.id);
      }
    });

    return contactorEstimates;
  };

  const handleEstimateSelect = (userId, estimatesId) => {
    setContractorToObject(userId, estimatesId, id).then((response) => {
      if (response === true) {
        toaster.notify(<div className="success-toast">Отклик принят!</div>, {
          position: "top-right",
          duration: 2000,
        });
        getEstimates();
        props.onChooseContractor();
      } else {
        toaster.notify(
          <div className="error-toast">{JSON.stringify(response)}</div>,
          { position: "top-right", duration: 2000 }
        );
      }
    });
  };

  const handleSelectEstimateFail = (callback) => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="К смете уже прикреплен подрядчик, изменить подрядчика?"
            onCancel={() => dispatch(closeModal())}
            onConfirm={() => {
              callback();
              dispatch(closeModal());
            }}
          />
        ),
      })
    );
  };

  return (
    <div className="contractors">
      <div className="contractors-card">
        {!isMobileSize && (
          <div className="contractors-card__head">
            <span className="contractors-card__head-item">ФИО</span>
            <span className="contractors-card__head-item">Почта</span>
            <span className="contractors-card__head-item">Телефон</span>
            <span></span>
          </div>
        )}
        {props.contractors.map((item) => {
          return (
            <div className="contractors-card__content" key={item.profile_id}>
              <div className="contractors-card__content-name">
                {isMobileSize && (
                  <span className="contractors-card__label">ФИО</span>
                )}
                <span>{item.profile_fio}</span>
              </div>
              <div className="contractors-card__content-email">
                {isMobileSize && (
                  <span className="contractors-card__label">Почта</span>
                )}
                <a href={`mailto:${item.user_email}`}>{item.user_email}</a>
              </div>
              <div className="contractors-card__content-phone">
                {isMobileSize && (
                  <span className="contractors-card__label">Телефон</span>
                )}
                <a href={`tel:${item.profile_phone_number}`}>
                  {item.profile_phone_number}
                </a>
              </div>
              <div className="contractors-card__content-control">
                <MultiSelect
                  onChange={(estimates) =>
                    handleEstimateSelect(item.profile_id, estimates)
                  }
                  placeholder="Выбрать сметы"
                  options={estimates.items.map((estimate) => {
                    return {
                      key: estimate.id,
                      value: estimate.name,
                    };
                  })}
                  validators={[
                    (estimateID) => {
                      const builderID = estimates.items.find(
                        (estimate) => estimate.id === estimateID
                      ).construction_object_builder_id;
                      return !builderID || builderID === item.profile_id;
                    },
                  ]}
                  onValidationFail={handleSelectEstimateFail}
                  values={getContractorEstimates(item.profile_id)}
                  name="selectedEstimates"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Contractors.propTypes = {
  contractors: PropTypes.array,
  onChooseContractor: PropTypes.func,
};

export default Contractors;
