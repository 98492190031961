import React from "react";
import PropTypes from "prop-types";

import "./EditableTextArea.scss";
import InputTextArea from "../InputTextArea";

const EditableTextArea = (props) => {
  return (
    <div className="editable-text-area">
      {props.isEditable ? (
        <InputTextArea
          value={props.value || ""}
          onChange={props.onChange || (() => {})}
          label={props.label}
          validators={props.validators}
          onValidationChange={props.onValidationChange}
          error={props.error}
          name={props.name}
        />
      ) : (
        <div className="editable-text-area-container">
          <div className="editable-text-area-value">{props.value || "-"}</div>
          <div className="editable-text-area-label">{props.label}</div>
        </div>
      )}
    </div>
  );
};

EditableTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
  error: PropTypes.string,
};

export default EditableTextArea;
