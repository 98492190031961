import React from "react";

import './ObjectLinks.scss'

const ObjectLinks = (props) => {
  return (
    <div className="object-links">
      {!props.links.length ? <div className="no-notes">Нет связанных объектов</div> :
        <>
          <div className="object-links-head">
            <div className="object-links-head-item">
              Название
            </div>
            <div className="object-links-head-item">
              Адрес
            </div>
            <div className="object-links-head-item">
              Дата заморозки
            </div>
            <div className="object-links-head-item">
              Ссылка
            </div>
          </div>
          {props.links.map(item => {
            return (
              <div className="object-links-content" key={item.id}>
                <div className="object-links-content-name">
                  {item.name}
                </div>
                <div className="object-links-content-address">
                  {item.address}
                </div>
                <div className="object-links-content-end">
                  {item.endDate || 'Не указано'}
                </div>
                <a className="object-links-content-link" href={item.link}>
                  Перейти
                </a>
              </div>
            )
          })}
        </>
      }
    </div>
  )
}

export default ObjectLinks
