import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./Stages.scss";

import Loader from "../../../../components/Loader";
import {
  fetchObjectSections,
} from "../../../../actions/ObjectSectionsActions";
import { updateObjectSection } from "../../../../actions/ObjectStagesActions";
import ObjectSectionsBlock from "./components/ObjectSectionsBlock";
import { UserContext } from "../../../../App";
import Paginator from "../../../../components/Paginator";
import toaster from "toasted-notes";
import { OBJECT_IS_AVAILABLE } from "../../../../reducers/objects";
import getSortedSections from "../../../../assets/js/utils/getSortedSections";

const Stages = (props) => {
  const [changedSections, setChangedSections] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const sectionsStore = useSelector((state) => state.objectSections);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const [changedStageBySection, setChangedStageBySection] = useState([]);
  // const user = useContext(UserContext);

  const fetchSections = useCallback(
    (page) => {
      dispatch(fetchObjectSections(props.id, page));
    },
    [props.id, dispatch]
  );

  useEffect(() => {
    if (history.location.search === "?isGrouped=true") {
      dispatch({
        type: OBJECT_IS_AVAILABLE,
        payload: true,
      });
    } else {
      history.push(isAvailable ? "?isGrouped=true" : history.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (
      !sectionsStore.isUpdated &&
      !sectionsStore.isFetching &&
      !sectionsStore.isError
    ) {
      fetchSections();
    }
  }, [sectionsStore, fetchSections]);

  const changeSection = (sectionId, changedSection) => {
    let stages = changedSections;
    let change = changedSections[sectionId] || [];
    change.push(changedSection);
    stages[sectionId] = change;
    setChangedSections(stages);
    let section = changedStageBySection;
    sectionsStore.items.forEach((item) => {
      if (
        item.id === sectionId &&
        !changedStageBySection.find((elem) => elem.id === sectionId)
      ) {
        section.push(item);
      }
    });

    setChangedStageBySection(section);
    setIsChanged(true);
  };

  const sortedSections = getSortedSections(sectionsStore.items);

  const getSections = () => {
    if (sectionsStore.items) {
      return (
        <React.Fragment>
          {sectionsStore.items.length ? (
            Object.keys(sortedSections).map((estimateID) => (
              <ObjectSectionsBlock
                key={estimateID}
                id={props.id}
                estimateID={estimateID}
                name={sortedSections[estimateID].name}
                sections={sortedSections[estimateID].sections}
                changedSections={changedSections}
                changeSection={changeSection}
                fetchSections={fetchSections}
              />
            ))
          ) : (
            <div className="no-notes">Нет этапов</div>
          )}
          {sectionsStore.pagination &&
            sectionsStore.pagination.totalCount >
              sectionsStore.pagination.pageSize && (
              <Paginator
                currentPage={sectionsStore.pagination.currentPage}
                totalPages={sectionsStore.pagination.totalPageCount}
                total={sectionsStore.pagination.totalCount}
                pageSize={sectionsStore.pagination.pageSize}
                onPageChange={fetchSections}
              />
            )}
        </React.Fragment>
      );
    }
  };

  const cancel = () => {
    setChangedSections({});
    setChangedStageBySection([]);
    setIsChanged(false);
    fetchSections();
  };

  const save = () => {
    updateObjectSection(props.id, changedSections, changedStageBySection).then(
      (res) => {
        if (res === "success") {
          toaster.notify(
            <div className="success-toast">Этапы обновлены!</div>,
            {
              position: "top-right",
              duration: 4000,
            }
          );
          fetchSections();
          setIsChanged(false);
          setChangedSections({});
        } else {
          let html = { __html: Object.values(res).join("<br>") };
          toaster.notify(
            <div className="error-toast" dangerouslySetInnerHTML={html} />,
            { position: "top-right", duration: 4000 }
          );
        }
      }
    );
  };

  const isHavePermissions = (permission) => {
    return !!userContext.permissions.find((perm) => perm.name === permission);
  };

  return (
    <div className="container">
      <div className="stages-sections content-card">
        {sectionsStore.isFetching ? <Loader /> : getSections()}
      </div>

      <div className="stages-controls">
        {isChanged &&
        isHavePermissions("perm_construction-object_update-card") ? (
          <React.Fragment>
            <button onClick={cancel} className="stages-controls-cancel _error">
              Отмена
            </button>

            <button onClick={save} className="stages-controls-save">
              Сохранить
            </button>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

Stages.propTypes = {
  id: PropTypes.string,
  builderId: PropTypes.number,
};

export default Stages;
