import {
  USER_SIGNUP_ORGANIZATIONS,
  USER_SINGUP_ERROR,
  USER_SINGUP_REQUEST, USER_SINGUP_ROLES,
  USER_SINGUP_SUCCESS
} from "../actions/SingUpActions";

const initialState = {
  isFetching: false,
  isError: false,
  isFetchingRoles: false,
  isErrorRoles: false,
  isFetchingOrg: false,
  isErrorOrg: false,
  isUpdatedRoles: false,
  isUpdatedOrganizations: false,
  roles: [],
  organizations: [],
  errors: [],
};

const singUp = (state = initialState, action) => {
  switch (action.type) {
    case USER_SINGUP_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case USER_SINGUP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isError: false,
        errors: [],
      };
    }
    case USER_SINGUP_ERROR: {
      let errors = [];
      for (let key in action.payload) {
        errors.push(action.payload[key]);
      }

      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: errors,
      };
    }
    case USER_SINGUP_ROLES: {
      return {
        ...state,
        isFetchingRoles: false,
        isErrorRoles: true,
        roles: action.payload,
        isUpdatedRoles: true,
      }
    }
    case USER_SIGNUP_ORGANIZATIONS: {
      return {
        ...state,
        isFetchingOrg: false,
        isErrorOrg: false,
        isUpdatedOrganizations: true,
        organizations: action.payload
      }
    }
    default:
      return state;
  }
};

export default singUp;
