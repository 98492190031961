import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Input from "../../../components/Input";
import { updatePass } from "../../../actions/UpdatePassActions";
import { required, password } from "../../../assets/js/validators";
import Loader from "../../../components/Loader";
import toaster from "toasted-notes";

const UpdatePassForm = ({token}) => {
  const history = useHistory()
  const [formData, setFormData] = useState({
    pass: "",
    token,
  });
  const updatePassStore = useSelector((store) => store.updatePass);
  const dispatch = useDispatch();
  const updatePassRequest = (pass, token) => dispatch(updatePass(pass, token)).then((r) => {
    if (r?.message?.success) {
      toaster.notify(
        <div className="success-toast">
          {r.message.success}
        </div>,
        { position: "top-right", duration: 4000 }
      );
      history.push("/login");
    } else {
      updatePassStore.errors.map((error, i) => {
        return toaster.notify(
          <div className="error-toast" key={i}>
            {error}
          </div>,
          { position: "top-right", duration: 4000 }
        );
      })
    }
  });

  const change = (ev) => {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  };

  const validationChange = (value) => {};

  const submit = (ev) => {
    ev.preventDefault();
    updatePassRequest(formData.pass, formData.token)
  };

  return (
    <form onSubmit={submit} className="login-form">
      {updatePassStore.isFetching ? <Loader/> : null}
      {updatePassStore.errors.map((item, index) => {
        return (
          <div className="login-submit-error" key={index}>
            {item}
          </div>
        );
      })}
      <div className="login-input-container">
        <Input
          label="Введите новый пароль"
          type="password"
          onChange={(ev) => change(ev)}
          value={formData.pass}
          name="pass"
          validators={[required, password]}
          onValidationChange={validationChange}
        />
      </div>

      <button type="submit" className="login-input-submit">Обновить пароль</button>
    </form>
  );
};

UpdatePassForm.propTypes = {
  token: PropTypes.string,
};

export default UpdatePassForm;
