import React, { useState } from "react";
import "./UserCard.scss";
import PropTypes from "prop-types";
import {
  setUserOrganization,
  setUserPermission,
} from "../../../actions/UserRightsActions";
import { inlineEdit } from "../../Profile/img/inlineSVGicons";
import {
  inlineClose,
  inlineCorrect,
} from "../../SoloObject/components/Stages/components/StageCard/img/inlineSvgicons";
import EditableSelectField from "../../../components/EditableSelectField";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations } from "../../../actions/SingUpActions";
import CheckPermissions from "../../../components/CheckPermissions";
import { inlineDelete } from "../../EditObject/components/EditObjectInfo/components/EditObjectDesignProject/img/inlineSvgIcons";
import { closeModal, openModal } from "../../../actions/ModalAction";
import { deleteUser } from "../../../actions/UserActions";
import { ModalTypes } from "../../../assets/js/constants";
import ConfirmAction from "../../../components/ConfirmAction";
import toaster from "toasted-notes";
import CheckBox from "../../../components/CheckBox";
import {
  editIcon,
  deleteIcon,
  closeIcon,
  checkIcon,
} from "../../../assets/img/sprite";

const UserCard = (props) => {
  const singUpStore = useSelector((store) => store.singUp);
  const { isMobileSize, isTabletSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );
  const dispatch = useDispatch();
  const getOrganizations = () => {
    if (
      !singUpStore.isUpdatedOrganizations &&
      !(singUpStore.isFetchingOrg || singUpStore.isErrorOrg)
    ) {
      dispatch(fetchOrganizations());
    }
  };

  const [changedPermission, setChangePermission] = useState(
    props.user.has_extended_permission
  );
  const [changeOrganization, setChangeOrganization] = useState(
    props.user.organization_name
  );
  const [isEditable, setIsEditable] = useState(false);
  const changePermission = (value) => {
    setChangePermission(value);
    props.user.has_extended_permission = value;
    setPermission(value);
  };

  const setOrganization = () => {
    setUserOrganization(changeOrganization, props.user.profile_id).then((r) => {
      props.onChange();
      setIsEditable(false);
    });
  };

  const setPermission = (value) => {
    setUserPermission(value, props.user.user_id).then((r) => {
      props.onChange();
    });
  };

  const handleUserDelete = () => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="Пользователь будет безвозвратно удален!"
            onCancel={() => dispatch(closeModal())}
            onConfirm={() => {
              deleteUser(props.user.user_id).then((r) => {
                if (r === "success") {
                  props.onChange();
                  dispatch(closeModal());
                  toaster.notify(
                    <div className="success-toast">
                      Пользователь успешно удалён!
                    </div>,
                    {
                      position: "top-right",
                      duration: 4000,
                    }
                  );
                } else {
                  toaster.notify(<div className="error-toast">{r}</div>, {
                    position: "top-right",
                    duration: 4000,
                  });
                }
              });
            }}
          />
        ),
      })
    );
  };

  return (
    <div className="user-rights-card">
      <div className="user-rights-card__name">
        <div className="mobile-label">ФИО</div>
        <span>{props.user.profile_fio}</span>
      </div>
      <div className="user-rights-card__role">
        <div className="mobile-label">Роль</div>
        <span>{props.user.description}</span>
      </div>
      <div className="user-rights-card__phone">
        <div className="mobile-label">Телефон</div>
        <a href={"tel:" + props.user.profile_phone_number}>
          {props.user.profile_phone_number}
        </a>
      </div>
      <div className="user-rights-card__email">
        <div className="mobile-label">Почта</div>
        <a href={"mailto:" + props.user.user_email}>{props.user.user_email}</a>
      </div>
      <div className="user-rights-card__organization">
        <div className="mobile-label">Организация</div>
        <EditableSelectField
          value={props.user.organization_name}
          items={singUpStore.organizations}
          onSelectOpen={getOrganizations}
          name="organization"
          isEditable={isEditable}
          onChange={(value) => setChangeOrganization(value.value)}
          reset
        />
      </div>
      {props.user.item_name === "customer" ? (
        <div className="user-rights-card__rights">
          <CheckBox
            onChange={changePermission}
            value={changedPermission}
            label={isTabletSize ? "расширенные права" : ""}
          />
        </div>
      ) : null}
      <CheckPermissions permissions={["perm_organization_update"]}>
        <div className="controls">
          {isEditable ? (
            <>
              <button
                className={`user-save ${isDesktopSize ? "_icon" : ""}`}
                onClick={setOrganization}
              >
                {isDesktopSize ? checkIcon : <span>Сохранить</span>}
              </button>
              <button
                className={`user-close ${isDesktopSize ? "_icon" : ""} _error`}
                onClick={() => setIsEditable(false)}
              >
                {isDesktopSize ? closeIcon : <span>Отменить</span>}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsEditable(true)}
                className={`user-edit ${isDesktopSize ? "_icon" : ""}`}
              >
                {editIcon}
                {!isDesktopSize && <span>Редактировать</span>}
              </button>

              <CheckPermissions permissions={["perm_user_delete"]}>
                <button
                  className={`user-delete _icon _error`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserDelete();
                  }}
                >
                  {deleteIcon}
                </button>
              </CheckPermissions>
            </>
          )}
        </div>
      </CheckPermissions>
    </div>
  );
};

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default UserCard;
