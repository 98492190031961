import { OTHER_SECTIONS_NAME } from "../constants";

const getSortedSections = (items) => {
  return items.reduce((acc, cur) => {
    const hasID =
      cur.estimateID !== undefined &&
      cur.estimateID !== null &&
      cur.estimateID !== "";

    const key = hasID ? cur.estimateID : OTHER_SECTIONS_NAME;
    const name = hasID ? cur.estimateName : OTHER_SECTIONS_NAME;

    if (acc[key] === undefined) {
      acc[key] = {
        name,
        sections: [],
      };
    }
    acc[key].sections.push(cur);

    return acc;
  }, {});
};

export default getSortedSections;
