import React from "react";

export const inlineClose = (
  <svg
    height="329pt"
    viewBox="0 0 329.26933 329"
    width="329pt"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
  </svg>
);

export const inlineCorrect = (
  <svg
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m450.585 68.552-252.065 252.065-137.105-137.104-61.415 61.415 198.52 198.52 313.48-313.48z" />
    </g>
  </svg>
);

export const inlineEdit = (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.3149 2.66706C21.4433 2.79593 21.5155 2.97048 21.5155 3.15244C21.5155 3.3344 21.4433 3.50894 21.3149 3.63781L19.8807 5.07331L17.1307 2.32331L18.5649 0.887814C18.6938 0.758928 18.8686 0.686523 19.0509 0.686523C19.2332 0.686523 19.4081 0.758928 19.537 0.887814L21.3149 2.66569V2.66706ZM18.9086 6.04406L16.1586 3.29406L6.79073 12.6633C6.71506 12.739 6.65809 12.8313 6.62436 12.9328L5.51748 16.2521C5.49741 16.3126 5.49456 16.3775 5.50925 16.4395C5.52394 16.5016 5.5556 16.5583 5.60068 16.6034C5.64576 16.6484 5.70249 16.6801 5.76453 16.6948C5.82657 16.7095 5.89147 16.7066 5.95198 16.6866L9.27123 15.5797C9.37266 15.5464 9.46493 15.4899 9.54073 15.4147L18.9086 6.04544V6.04406Z" />
    <path d="M1.375 18.5625C1.375 19.1095 1.5923 19.6341 1.97909 20.0209C2.36589 20.4077 2.89049 20.625 3.4375 20.625H18.5625C19.1095 20.625 19.6341 20.4077 20.0209 20.0209C20.4077 19.6341 20.625 19.1095 20.625 18.5625V10.3125C20.625 10.1302 20.5526 9.9553 20.4236 9.82636C20.2947 9.69743 20.1198 9.625 19.9375 9.625C19.7552 9.625 19.5803 9.69743 19.4514 9.82636C19.3224 9.9553 19.25 10.1302 19.25 10.3125V18.5625C19.25 18.7448 19.1776 18.9197 19.0486 19.0486C18.9197 19.1776 18.7448 19.25 18.5625 19.25H3.4375C3.25516 19.25 3.0803 19.1776 2.95136 19.0486C2.82243 18.9197 2.75 18.7448 2.75 18.5625V3.4375C2.75 3.25516 2.82243 3.0803 2.95136 2.95136C3.0803 2.82243 3.25516 2.75 3.4375 2.75H12.375C12.5573 2.75 12.7322 2.67757 12.8611 2.54864C12.9901 2.4197 13.0625 2.24484 13.0625 2.0625C13.0625 1.88016 12.9901 1.7053 12.8611 1.57636C12.7322 1.44743 12.5573 1.375 12.375 1.375H3.4375C2.89049 1.375 2.36589 1.5923 1.97909 1.97909C1.5923 2.36589 1.375 2.89049 1.375 3.4375V18.5625Z" />
  </svg>
);

export const inlineDelete = (
  <svg viewBox="-57 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="m156.371094 30.90625h85.570312v14.398438h30.902344v-16.414063c.003906-15.929687-12.949219-28.890625-28.871094-28.890625h-89.632812c-15.921875 0-28.875 12.960938-28.875 28.890625v16.414063h30.90625zm0 0" />
    <path d="m344.210938 167.75h-290.109376c-7.949218 0-14.207031 6.78125-13.566406 14.707031l24.253906 299.90625c1.351563 16.742188 15.316407 29.636719 32.09375 29.636719h204.542969c16.777344 0 30.742188-12.894531 32.09375-29.640625l24.253907-299.902344c.644531-7.925781-5.613282-14.707031-13.5625-14.707031zm-219.863282 312.261719c-.324218.019531-.648437.03125-.96875.03125-8.101562 0-14.902344-6.308594-15.40625-14.503907l-15.199218-246.207031c-.523438-8.519531 5.957031-15.851562 14.472656-16.375 8.488281-.515625 15.851562 5.949219 16.375 14.472657l15.195312 246.207031c.527344 8.519531-5.953125 15.847656-14.46875 16.375zm90.433594-15.421875c0 8.53125-6.917969 15.449218-15.453125 15.449218s-15.453125-6.917968-15.453125-15.449218v-246.210938c0-8.535156 6.917969-15.453125 15.453125-15.453125 8.53125 0 15.453125 6.917969 15.453125 15.453125zm90.757812-245.300782-14.511718 246.207032c-.480469 8.210937-7.292969 14.542968-15.410156 14.542968-.304688 0-.613282-.007812-.921876-.023437-8.519531-.503906-15.019531-7.816406-14.515624-16.335937l14.507812-246.210938c.5-8.519531 7.789062-15.019531 16.332031-14.515625 8.519531.5 15.019531 7.816406 14.519531 16.335937zm0 0" />
    <path d="m397.648438 120.0625-10.148438-30.421875c-2.675781-8.019531-10.183594-13.429687-18.640625-13.429687h-339.410156c-8.453125 0-15.964844 5.410156-18.636719 13.429687l-10.148438 30.421875c-1.957031 5.867188.589844 11.851562 5.34375 14.835938 1.9375 1.214843 4.230469 1.945312 6.75 1.945312h372.796876c2.519531 0 4.816406-.730469 6.75-1.949219 4.753906-2.984375 7.300781-8.96875 5.34375-14.832031zm0 0" />
  </svg>
);
