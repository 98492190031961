import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom"

import Input from "../../../components/Input";
import { resetPass } from "../../../actions/ResetPassActions";
import { required, mail } from "../../../assets/js/validators";
import Loader from "../../../components/Loader";
import toaster from "toasted-notes";

const ResetPassForm = (props) => {
  const history = useHistory()
  const [formData, setFormData] = useState({
    login: "",
  });
  const resetPassStore = useSelector((store) => store.resetPass);
  const dispatch = useDispatch();
  const resetPassRequest = (login) => dispatch(resetPass(login)).then((r) => {
    if (r?.message?.success) {
      toaster.notify(
        <div className="success-toast">
          {r.message.success}
        </div>,
        { position: "top-right", duration: 4000 }
      );
      history.push("/login");
    } else {
      resetPassStore.errors.map((error, i) => {
        return toaster.notify(
          <div className="error-toast" key={i}>
            {error}
          </div>,
          { position: "top-right", duration: 4000 }
        );
      })
    }
  });

  const change = (ev) => {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  };

  const validationChange = (value) => {};

  const submit = (ev) => {
    ev.preventDefault();
    resetPassRequest(formData.login)
  };

  return (
    <form onSubmit={submit} className="login-form">
      {resetPassStore.isFetching ? <Loader/> : null}
      {resetPassStore.errors.map((item, index) => {
        return (
          <div className="login-submit-error" key={index}>
            {item}
          </div>
        );
      })}
      <div className="login-input-container">
        <Input
          label="Электронная почта"
          type="email"
          onChange={(ev) => change(ev)}
          value={formData.login}
          name="login"
          validators={[required, mail]}
          onValidationChange={validationChange}
        />
      </div>

      <button type="submit" className="login-input-submit">Сбросить</button>
    </form>
  );
};

export default ResetPassForm;
