import React from "react";
import PropTypes from "prop-types";
import { permissions } from "../../../../permissions";

import "./UserCard.scss";
import CheckPermissions from "../../../../components/CheckPermissions";

const UserCard = (props) => {
  const onApply = () => {
    props.onApply(props.item.user_profile_id);
  };
  const onDeny = () => {
    props.onDeny(props.item.user_profile_id);
  };

  return (
    <div className="user-card">
      <div className="user-card-date">{props.item.user_created_at}</div>
      <div className="user-card-content">
        <div className="user-card-content__info">
          <div className="user-card-content__info__main">
            {props.item.user_fio && (
              <div className="user-card-name">
                <span className="user-card-label">ФИО</span>
                <span>{props.item.user_fio}</span>
              </div>
            )}
            {props.item.user_email && (
              <div className="user-card-email">
                <span className="user-card-label">Электронная почта</span>
                <a href={`mailto:${props.item.user_email}`}>{props.item.user_email}</a>
              </div>
            )}
            {props.item.profile_phone_number && (
                <div className="user-card-phone">
                  <span className="user-card-label">Телефон</span>
                  <a href={`tel:${props.item.profile_phone_number}`}>{props.item.profile_phone_number}</a>
                </div>
            )}
          </div>
          <div className="user-card-content__info__additional">
            {props.item.description && (
                <div className="user-card-description">
                  <span className="user-card-label">Описание</span>
                  <span>{props.item.description}</span>
                </div>
            )}
            {props.item.position && (
              <div className="user-card-position">
                <span className="user-card-label">Должность</span>
                <span>{props.item.position}</span>
              </div>
            )}
            {props.item.organization_name && (
              <div className="user-card-org-name">
                <span className="user-card-label">Организация</span>
                <span>{props.item.organization_name}</span>
              </div>
            )}
          </div>
        </div>
        <div className="user-card-buttons">
          <CheckPermissions permissions={[permissions.applyApplication]}>
            <button className="apply" onClick={onApply}>
              Одобрить
            </button>
          </CheckPermissions>
          <CheckPermissions permissions={[permissions.denyApplication]}>
            <button className="deny" onClick={onDeny}>
              Отменить
            </button>
          </CheckPermissions>
        </div>
      </div>
    </div>
  );
};

UserCard.propTypes = {
  item: PropTypes.object,
  onApply: PropTypes.func,
  onDeny: PropTypes.func,
};

export default UserCard;
