import React from "react";
import AccountWidget from "../../components/AccountWiget";
import Logo from "../../components/Logo/logo";
import ResetPassForm from "./components/ResetPassForm";
import RegistrationPopup from "../../containers/RegistrationPopup";

const ResetPassContainer = (props) => {
  return (
    <React.Fragment>
      <AccountWidget />

      <Logo className="login-logo" />

      <div className="login-container">
        <h2 className="login-head">Сброс пароля</h2>

        <ResetPassForm />
      </div>

      <RegistrationPopup />
    </React.Fragment>
  );
};

export default ResetPassContainer;
