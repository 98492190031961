import React from "react";

import "./logo.scss";
import { useHistory } from "react-router-dom";

function Logo(props) {

  const history = useHistory()

  return (
    <div className={"logo " + props.className} onClick={() => history.push("/")}>
      <img alt="Герб томской области" className="logo-img" src={require('../../assets/img/logo.svg')} />
      {/*<div className="logo-text">Государственный заказчик</div>*/}
    </div>
  );
}

export default Logo;
