import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./ObjectsList.scss";

// import ObjectItem from "./ObjectItem";
import { ROUTES } from "../../../../routes";
import { UserContext } from "../../../../App";
import ObjectCard from "../../../../components/ObjectCard";
import { setSubscription } from "../../../../actions/FavoritesActions";
import Switch from "../../../../components/Switch";
import CheckPermissions from "../../../../components/CheckPermissions";
import { useSelector } from "react-redux";

const ObjectsList = ({ objects, onItemSubscribe, onContentReady }) => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { isMobileSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );

  useEffect(() => {
    if (objects.length > 0) {
      onContentReady();
    }
  }, [objects, onContentReady]);

  const isHavePermission = (permission) => {
    return !!userContext.permissions.filter((perm) => {
      return perm.name === permission;
    }).length;
  };

  const click = (id) => {
    history.push(ROUTES.OBJECT.PATH + `/${id}`);
  };

  const subscribe = (id, status) => {
    setSubscription(id, status).then(() => {
      onItemSubscribe();
    });
  };

  return (
    <div className="objects-list">
      {!isMobileSize && (
        <ul className="objects-list-head">
          <li className="objects-list-head-name">Название и адрес</li>
          <li className="objects-list-head-end">Срок по плану</li>
          <li className="objects-list-head-complete">Прогресс</li>
          <li className="objects-list-head-status">Статус</li>
          {isDesktopSize && (
            <CheckPermissions permissions={["perm_subscribe_create"]}>
              <li className="objects-list-head-subscribe">Подписаться</li>
            </CheckPermissions>
          )}
        </ul>
      )}

      <div className="objects-list-content">
        {objects.length ? (
          objects.map((item) => {
            return (
              <div className="objects-list-item" key={item.id}>
                <ObjectCard
                  item={item}
                  onClick={
                    isHavePermission("perm_construction-object_view")
                      ? () => click(item.id)
                      : () => {}
                  }
                  onSubscribe={() => subscribe(item.id, !item.isSubscribed)}
                />
              </div>
            );
          })
        ) : (
          <div className="no-notes">Нет объектов</div>
        )}
      </div>
    </div>
  );
};

ObjectsList.propTypes = {
  objects: PropTypes.array.isRequired,
  onItemSubscribe: PropTypes.func,
};

export default ObjectsList;
