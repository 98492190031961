import {
  TOPICS_REQUEST,
  TOPICS_REQUEST_ERROR,
  TOPICS_REQUEST_SUCCESS,
  TOPIC_REQUEST,
  TOPIC_REQUEST_ERROR,
  TOPIC_REQUEST_SUCCESS,
} from "../reducers/helpTopics";
import api from "./api";

export const fetchTopics = (page) => (dispatch) => {
  const tokenName = localStorage.getItem("token_name");
  const token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    dispatch({
      type: TOPICS_REQUEST_ERROR,
      payload: "Нет прав доступа. Пожалуйста войдите в систему",
    });
    return;
  }

  dispatch({ type: TOPICS_REQUEST });

  fetch(`${api}/v1/topic`, {
    headers: {
      [tokenName]: token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._meta.isSuccessful) {
        dispatch({
          type: TOPICS_REQUEST_ERROR,
          payload: response,
        });
        return;
      }

      const items = response.data.data.map((topic) => ({
        ...topic,
        isFetching: false,
        isError: false,
        isUpdated: false,
      }));

      dispatch({
        type: TOPICS_REQUEST_SUCCESS,
        payload: { items, pagination: response.data.pagination },
      });
    });
};

export const fetchTopic = (id) => (dispatch) => {
  const tokenName = localStorage.getItem("token_name");
  const token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    dispatch({
      type: TOPIC_REQUEST_ERROR,
      payload: "Нет прав доступа. Пожалуйста войдите в систему",
    });
    return;
  }

  dispatch({ type: TOPIC_REQUEST, payload: id });

  fetch(`${api}/v1/topic/${id}`, {
    headers: {
      [tokenName]: token,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response._meta.isSuccessful) {
        dispatch({
          type: TOPIC_REQUEST_ERROR,
          payload: { errors: response.message, id },
        });
        return;
      }

      dispatch({
        type: TOPIC_REQUEST_SUCCESS,
        payload: response.data,
      });
    });
};
