import React, { useCallback, useEffect, useState } from "react";
import "./ProfileContainer.scss";
import EditableField from "../../components/EditableField";
import InputPassword from "../../components/InputPassword";
import CheckPermissions from "../../components/CheckPermissions";
import CheckBox from "../../components/CheckBox";

import { useDispatch, useSelector } from "react-redux";

import { required, mail, phone, password } from "../../assets/js/validators";
import {
  editOrganizationInfo,
  editUserInfo,
  editUserPassword,
  GetUserByTokenAction,
} from "../../actions/UserActions";
import { permissions } from "../../permissions";
import { inlineEdit } from "./img/inlineSVGicons";
import { editIcon } from "../../assets/img/sprite";
import toaster from "toasted-notes";
import EditableSelectField from "../../components/EditableSelectField";
import { fetchOrganizations } from "../../actions/SingUpActions";

const ROLES_WITH_ORGANIZATIONS = [
  "builder",
  "customer",
  "construction_control",
];

const ProfileContainer = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [changedInfo, setChangedInfo] = useState(null);
  const [isEditablePassword, setIsEditablePassword] = useState(false);
  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const userStore = useSelector((store) => store.user);
  const [changedNotify, setChangedNotify] = useState(userStore.have_notify);
  const [changedNotifyEmail, setChangedNotifyEmail] = useState(
    userStore.have_notify_email
  );
  const dispatch = useDispatch();
  const tokenName = localStorage.getItem("token_name");
  const token = localStorage.getItem(tokenName);
  const singUpStore = useSelector((store) => store.singUp);

  const getUserInfo = useCallback(() => {
    dispatch(GetUserByTokenAction(tokenName, token));
  }, [dispatch, token, tokenName]);

  useEffect(() => {
    if (isEditable) {
      setChangedInfo(userStore);
      return;
    }
    setChangedInfo(null);
  }, [isEditable, userStore]);

  const getOrganizations = () => {
    if (
      !singUpStore.isUpdatedOrganizations &&
      !(singUpStore.isFetchingOrg || singUpStore.isErrorOrg)
    ) {
      dispatch(fetchOrganizations());
    }
  };

  const change = (name, value) => {
    setChangedInfo({
      ...changedInfo,
      [name]: value,
    });
  };

  const save = () => {
    editUserInfo(user).then((r) => {
      if (changedInfo?.organizationAddress || changedInfo?.organizationPhone) {
        let orgData = {
          organization_address: user.organizationAddress,
          organization_contacts: user.organizationPhone,
        };
        editOrganizationInfo(orgData, user.organizationId).then((r) => {
          getUserInfo();
          if (r !== "success") {
            toaster.notify(
              <div className="error-toast">{JSON.stringify(r)}</div>,
              {
                position: "top-right",
                duration: 3000,
              }
            );
          } else {
            toaster.notify(
              <div className="success-toast">Информация изменена</div>,
              { position: "top-right", duration: 2000 }
            );
          }
        });
      } else {
        getUserInfo();
        if (r !== "success") {
          toaster.notify(
            <div className="error-toast">{JSON.stringify(r)}</div>,
            {
              position: "top-right",
              duration: 3000,
            }
          );
        } else {
          toaster.notify(
            <div className="success-toast">Информация изменена</div>,
            { position: "top-right", duration: 2000 }
          );
        }
      }
    });
    setIsEditable(false);
  };

  const savePassword = () => {
    editUserPassword(passwordOld, passwordNew).then((r) => {
      getUserInfo();
      if (r !== true) {
        toaster.notify(
          <div className="error-toast">
            {r.user_current_password
              ? r.user_current_password
              : JSON.stringify(r)}
          </div>,
          { position: "top-right", duration: 3000 }
        );
      } else {
        toaster.notify(<div className="success-toast">Пароль изменен</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
    });
    setIsEditablePassword(false);
  };

  const setEditable = () => {
    setIsEditable(true);
  };

  const changeNotify = (value) => {
    setChangedNotify(value);
    user.have_notify = value;
    save();
  };

  const changeNotifyEmail = (value) => {
    setChangedNotifyEmail(value);
    user.have_notify_email = value;
    save();
  };

  const cancelChangePassword = () => {
    setPasswordOld("");
    setPasswordNew("");
    setIsEditablePassword(false);
  };
  let user = changedInfo || userStore;

  return (
    <React.Fragment>
      <div className="container-wrapper">
        <div className="container profile-container">
          <div className="content-card _narrow">
            <div className="profile-header">
              <h1 className="profile-header-text">Личный кабинет</h1>
            </div>

            <div className="profile-container-form">
              <CheckPermissions permissions={[permissions.updateProfile]}>
                <div
                  className={
                    isEditable
                      ? "profile-header-buttons last"
                      : "profile-header-buttons"
                  }
                >
                  {isEditable ? (
                    <button
                      className="profile-header-buttons-cancel _error"
                      onClick={() => setIsEditable(false)}
                    >
                      Отмена
                    </button>
                  ) : null}
                  <button
                    className={
                      isEditable
                        ? "profile-header-buttons-save"
                        : "profile-header-buttons-edit"
                    }
                    onClick={isEditable ? save : setEditable}
                  >
                    {isEditable ? (
                      "Сохранить"
                    ) : (
                      <>
                        {editIcon}
                        <span>Редактировать</span>
                      </>
                    )}
                  </button>
                </div>
              </CheckPermissions>
              <div className="fields">
                <div className="inputs">
                  <EditableField
                    isEditable={isEditable}
                    onChange={(ev) => change("name", ev.target.value)}
                    label={"ФИО"}
                    value={user.name}
                    validators={[required]}
                  />
                  <EditableField
                    isEditable={isEditable}
                    onChange={(ev) => change("contact", ev.target.value)}
                    label={"Телефон"}
                    value={user.contact}
                    validators={[required, phone]}
                  />
                  <EditableField
                    isEditable={isEditable}
                    onChange={(ev) => change("mail", ev.target.value)}
                    label={"Email"}
                    value={user.mail}
                    validators={[required, mail]}
                  />
                  <EditableField
                    isEditable={false}
                    label={"Роль"}
                    value={user.type_name}
                  />
                  {ROLES_WITH_ORGANIZATIONS.includes(user.type) ? (
                    <>
                      <EditableSelectField
                        value={user.organization}
                        label="Организация"
                        name="organization"
                        onChange={(value) =>
                          change("organization", value.value)
                        }
                        isEditable={isEditable}
                        items={singUpStore.organizations}
                        onSelectOpen={getOrganizations}
                        reset
                      />
                      <EditableField
                        value={user.organizationAddress}
                        label="Адрес организации"
                        name="organizationAddress"
                        onChange={(ev) =>
                          change("organizationAddress", ev.target.value)
                        }
                        isEditable={isEditable}
                        reset
                      />
                      <EditableField
                        value={user.organizationPhone}
                        label="Номер организации"
                        name="organizationPhone"
                        onChange={(ev) =>
                          change("organizationPhone", ev.target.value)
                        }
                        isEditable={isEditable}
                        reset
                      />
                    </>
                  ) : null}
                </div>
                {/*{user.type !== "tech_expert" && (*/}
                {/*  <div className="checkbox">*/}
                {/*    <input*/}
                {/*      type="checkbox"*/}
                {/*      id="notify"*/}
                {/*      value={changedNotify}*/}
                {/*      checked={changedNotify}*/}
                {/*      onChange={(ev) => changeNotify(ev.target.checked)}*/}
                {/*    />*/}
                {/*    <label htmlFor="notify">Получать уведомления</label>*/}
                {/*  </div>*/}
                {/*)}*/}
                <CheckBox
                  onChange={changeNotifyEmail}
                  value={changedNotifyEmail}
                  label=" Получать уведомления по почте"
                />
                {user.type === "customer" && user.has_extended_permission && (
                  <div className="extended">Есть расширенные права</div>
                )}
                <div className="change-password">
                  <CheckPermissions permissions={[permissions.updatePassword]}>
                    {isEditablePassword ? (
                      <>
                        <InputPassword
                          label="Старый пароль"
                          value={passwordOld}
                          validators={[required, password]}
                          onChange={(ev) => setPasswordOld(ev.target.value)}
                        />
                        <InputPassword
                          label="Новый пароль"
                          value={passwordNew}
                          validators={[required, password]}
                          onChange={(ev) => setPasswordNew(ev.target.value)}
                        />
                        <div className="buttons">
                          <button
                            onClick={cancelChangePassword}
                            className="cancel _error"
                          >
                            Отмена
                          </button>
                          <button onClick={savePassword}>Сохранить</button>
                        </div>
                      </>
                    ) : (
                      <button onClick={() => setIsEditablePassword(true)}>
                        Изменить пароль
                      </button>
                    )}
                  </CheckPermissions>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileContainer;
