export const OBJECT_CONTRACTORS_REQUEST = "OBJECT_CONTRACTORS_REQUEST"
export const OBJECT_CONTRACTORS_SUCCESS = "OBJECT_CONTRACTORS_SUCCESS"
export const OBJECT_CONTRACTORS_ERROR = "OBJECT_CONTRACTORS_ERROR"

const initialState = {
  contractors: [],
  isFetching: false,
  isUpdated: false,
  isError: false,
  errors: []
}

const objectContractors = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_CONTRACTORS_REQUEST: {
      return {
        ...state,
        contractors: state.contractors,
        isFetching: true,
        isError: false,
      }
    }
    case OBJECT_CONTRACTORS_SUCCESS: {
      return {
        ...state,
        contractors: action.payload,
        isFetching: false,
        isUpdated: true,
        isError: false,
      }
    }
    case OBJECT_CONTRACTORS_ERROR: {
      return {
        ...state,
        contractors: state.contractors,
        isFetching: false,
        isUpdated: true,
        isError: true
      }
    }
    default: {
      return state
    }
  }
}

export default objectContractors;
