import React from "react";
import PropTypes from "prop-types";

import "./CheckBox.scss";

const CheckBox = ({
  value,
  onChange,
  mixClass,
  name,
  label,
  readOnly,
  disabled,
}) => {
  return (
    <label className={`checkbox ${mixClass || ""}`}>
      <input
        type="checkbox"
        value={value}
        name={name}
        checked={value}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(ev) => onChange(ev.target.checked)}
      />
      <span className="checkbox__box" />
      {label && <span>{label}</span>}
    </label>
  );
};

CheckBox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  mixClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CheckBox;
