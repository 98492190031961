import { combineReducers } from "redux";

import singIn from "./singIn.js";
import singUp from "./singUp.js";
import resetPass from "./resetPass.js";
import updatePass from "./updatePass.js";
import user from "./user";
import objectsFilters from "./objectsFilters";
import objectAdd from "./objectAdd";
import objectFiles from "./objectFiles";
import objects from "./objects";
import objectSections from "./objectSections";
import objectStages from "./objectStages";
import rating from "./rating";
import userRating from "./userRating";
import stageSolo from "./stageSolo";
import objectHistories from "./objectHistories";
import notifications from "./notifications";
import favorites from "./favorites";
import userConfirmation from "./userConfirmation";
import archive from "./archive";
import objectsContractors from "./objectsContractors";
import userRights from "./userRights";
import chooseUser from "./chooseUser";
import registrationPopup from "./registrationPopup.js";
import stageStatuses from "./stageStatuses";
import infoPopup from "./infoPopup";
import modal from "./modal";
import createUser from "./createUser";
import helpTopics from "./helpTopics";
import responsive from "./responsive";

export default combineReducers({
  singIn,
  singUp,
  resetPass,
  updatePass,
  user,
  objectsFilters,
  objectAdd,
  objectFiles,
  objects,
  objectSections,
  objectStages,
  rating,
  userRating,
  stageSolo,
  objectHistories,
  notifications,
  favorites,
  userConfirmation,
  archive,
  objectsContractors,
  userRights,
  chooseUser,
  registrationPopup,
  stageStatuses,
  infoPopup,
  modal,
  createUser,
  helpTopics,
  responsive,
});
