import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./HistoryWindow.scss";

import ModalWindow from "../../../../../../components/ModalWindow";
import StageImages from "../../../StageImages";
import StageComments from "../../../StageComments";
import {
  addHistoryComment,
  addHistoryImage,
  deleteHistoryImage,
  fetchHistoryComments,
  fetchHistoryImages,
} from "../../../../../../actions/ObjectHistoriesActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ShowImageModal from "../../../ShowImageModal";
import StageCardInfo from "../StageCardInfo";
import toaster from "toasted-notes";
// import {inlineEdit, inlineDelete} from "./img/inlineSVGicons";

const HistoryWindow = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [imageInFocus, setImageInFocus] = useState(null);
  const historyStore = useSelector((store) => store.objectHistories);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  const getImages = useCallback(
    (page) => {
      dispatch(
        fetchHistoryImages(
          params.objectId,
          params.sectionId,
          params.stageId,
          props.info.works_history_id,
          page
        )
      );
    },
    [
      params.objectId,
      params.sectionId,
      params.stageId,
      props.info.works_history_id,
      dispatch,
    ]
  );

  const getComments = useCallback(() => {
    dispatch(
      fetchHistoryComments(
        params.objectId,
        params.sectionId,
        params.stageId,
        props.info.works_history_id
      )
    );
  }, [
    params.objectId,
    params.sectionId,
    params.stageId,
    props.info.works_history_id,
    dispatch,
  ]);

  useEffect(() => {
    if (
      props.isActive &&
      (historyStore.images.id !== props.info.works_history_id ||
        !historyStore.images.isUpdated)
    ) {
      getImages();
    }
  }, [
    getImages,
    historyStore.images.id,
    historyStore.images.isUpdated,
    props.isActive,
    props.info.works_history_id,
  ]);

  useEffect(() => {
    if (
      props.isActive &&
      (historyStore.comments.id !== props.info.works_history_id ||
        !historyStore.comments.isUpdated)
    ) {
      getComments();
    }
  }, [
    getComments,
    historyStore.comments.id,
    historyStore.comments.isUpdated,
    props.isActive,
    props.info.works_history_id,
  ]);

  const addComment = (content) => {
    dispatch(
      addHistoryComment(
        params.objectId,
        params.sectionId,
        params.stageId,
        props.info.works_history_id,
        content
      )
    ).then(() => {
      getComments();
    });
  };

  const addImages = (ev) => {
    dispatch(
      addHistoryImage(
        params.objectId,
        params.sectionId,
        params.stageId,
        props.info.works_history_id,
        ev.target.files
      )
    ).then(() => {
      getImages();
    });
  };

  const deleteImage = (id) => {
    deleteHistoryImage(
      params.objectId,
      params.sectionId,
      params.stageId,
      props.info.works_history_id,
      id
    ).then((r) => {
      if (r === "success") {
        toaster.notify(
          <div className="success-toast">Фотография удалена!</div>,
          { position: "top-right", duration: 4000 }
        );
        setImageInFocus(null);
        getImages();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  return (
    <ModalWindow
      isActive={props.isActive}
      onClose={props.onClose}
      label="История работ"
      maxWidth={1080}
      maxHeight={1000}
    >
      <div className="stage-histories-table">
        {!isMobileSize && (
          <ul className="stage-histories-header">
            <li className="stage-histories-header-status">Статус</li>
            <li className="stage-histories-header-created">Дата создания</li>
            <li className="stage-histories-header-updated">Дата изменения</li>
            <li className="stage-histories-header-amount">Объем работ</li>
            <li className="stage-histories-header-unit">Единицы измерения</li>
          </ul>
        )}
        <div className="stage-histories-item">
          <StageCardInfo
            info={props.info}
            status={props.status}
            isUnit={true}
          />
        </div>
      </div>

      <div className="stage-histories-info-container">
        <div className="stage-histories-info-status">
          Утверждено заказчиком:
          <span className="stage-histories-info-status-span">
            {props.info.works_history_approve_customer ? "Да" : "Нет"}
          </span>
        </div>

        <div className="stage-histories-info-status">
          Утверждено стройконтролем:
          <span className="stage-histories-info-status-span">
            {props.info.works_history_approve_control ? "Да" : "Нет"}
          </span>
        </div>
      </div>

      <StageImages
        onUpload={addImages}
        images={historyStore.images || []}
        pagination={historyStore.images.pagination}
        onImageClick={(id) => setImageInFocus(id)}
        status={props.status}
        onPageChange={getImages}
      />

      <div className="stage-histories-comments-container">
        <StageComments
          comments={historyStore.comments.items || []}
          pagination={historyStore.comments.pagination}
          onCommentAdd={(content) => addComment(content)}
          status={props.status}
        />
      </div>

      <ShowImageModal
        isShown={!!imageInFocus}
        imageId={imageInFocus}
        onClose={() => setImageInFocus(null)}
        onNavigation={(id) => setImageInFocus(id)}
        store={historyStore}
        onDelete={deleteImage}
        deletePermission={"perm_image_delete"}
      />
    </ModalWindow>
  );
};

HistoryWindow.props = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  info: PropTypes.object,
};

export default HistoryWindow;
