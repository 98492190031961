import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../../../routes";

import "./RegistrationForm.scss";

import Input from "../../../../components/Input";
import InputPassword from "../../../../components/InputPassword";
import {
  downloadUserConfirmation,
  fetchOrganizations,
  fetchRoles,
  singUp,
} from "../../../../actions/SingUpActions";
import {
  mail,
  required,
  password,
  phone,
} from "../../../../assets/js/validators";
import Select from "../../../../components/Select";
import Loader from "../../../../components/Loader";
import toaster from "toasted-notes";
import { closeRegistrationPopup } from "../../../../actions/RegistrationPopupAction";

const RegistrationForm = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    role: null,
    organization: "",
    contacts: "",
    login: "",
    password: "",
  });
  const singUpStore = useSelector((store) => store.singUp);
  const dispatch = useDispatch();
  let history = useHistory();
  const regUserRequest = (data) =>
    dispatch(singUp(data)).then((r) => {
      if (r === true) {
        toaster.notify(
          <div className="success-toast">
            Регистрация прошла успешно. Дождитесь подтверждения администратором
          </div>,
          { position: "top-right", duration: 4000 }
        );
        history.push("/login");
      }
    });
  const getRoles = () => {
    if (
      !singUpStore.isUpdatedRoles &&
      !(singUpStore.isFetchingRoles || singUpStore.isErrorRoles)
    ) {
      dispatch(fetchRoles());
    }
  };

  const closePopup = () => dispatch(closeRegistrationPopup());

  const getOrganizations = () => {
    if (
      !singUpStore.isUpdatedOrganizations &&
      !(singUpStore.isFetchingOrg || singUpStore.isErrorOrg)
    ) {
      dispatch(fetchOrganizations());
    }
  };

  const change = (ev) => {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  };

  const roleChange = (item) => {
    setFormData({
      ...formData,
      role: item.key,
    });
  };

  const organizationChange = (item) => {
    setFormData({
      ...formData,
      organization: item.value,
    });
  };

  const validationChange = (name, value) => {};

  const submit = (ev) => {
    ev.preventDefault();
    regUserRequest(formData);
  };

  const downloadConcern = () => {
    downloadUserConfirmation()
      .then((r) => r.blob())
      .then((blob) => {
        let reader = new FileReader();
        var link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Пользовательское соглашение.docx";
        link.click();
        reader.readAsArrayBuffer(blob);
      });
  };

  return (
    <form onSubmit={submit}>
      {singUpStore.isFetching ? <Loader /> : null}
      {singUpStore.errors.map((error, index) => {
        return (
          <div className="login-submit-error" key={index}>
            {error}
          </div>
        );
      })}

      <div className="registration-input-container">
        {formData.role && (
          <div className="registration-input-title">
            <span>Роль в системе</span>
          </div>
        )}
        <Select
          placeholder="Роль в системе"
          items={singUpStore.roles}
          onChange={roleChange}
          value={formData.role && formData.role.key}
          validators={[required]}
          onValidationChange={validationChange}
          onOpen={getRoles}
          reset
        />
      </div>

      <div className="registration-input-container">
        {formData.name && (
          <div className="registration-input-title">
            <span>ФИО</span>
          </div>
        )}
        <Input
          placeholder="ФИО"
          type="text"
          onChange={change}
          value={formData.name}
          name="name"
          onValidationChange={validationChange}
          validators={[required]}
        />
      </div>

      {!(formData.role === "almost_government_customer") ? (
        <div className="registration-input-container">
          {formData.organization && (
            <div className="registration-input-title">
              <span>Название организации</span>
            </div>
          )}
          <Select
            placeholder="Название организации"
            items={singUpStore.organizations}
            onChange={organizationChange}
            value={formData.organization && formData.organization.key}
            validators={[required]}
            onValidationChange={validationChange}
            onOpen={getOrganizations}
            isPaginator={true}
            reset
          />
        </div>
      ) : null}

      <div className="registration-input-container">
        {formData.contacts && (
          <div className="registration-input-title">
            <span>Номер телефона</span>
          </div>
        )}
        <Input
          placeholder="Номер телефона"
          type="tel"
          onChange={change}
          value={formData.contacts}
          name="contacts"
          onValidationChange={validationChange}
          validators={[required, phone]}
        />
      </div>

      <div className="registration-input-container">
        {formData.login && (
          <div className="registration-input-title">
            <span>Электронная почта</span>
          </div>
        )}
        <Input
          placeholder="Электронная почта"
          type="email"
          onChange={change}
          value={formData.login}
          name="login"
          onValidationChange={validationChange}
          validators={[required, mail]}
        />
      </div>

      <div className="registration-input-container">
        {formData.password && (
          <div className="registration-input-title">
            <span>Пароль</span>
          </div>
        )}
        <InputPassword
          placeholder="Пароль"
          type="password"
          onChange={change}
          value={formData.password}
          name="password"
          validators={[required, password]}
        />
      </div>

      <div className="registration-agree-label">
        <span>Нажимая на кнопку "Зарегистрироваться", вы принимаетее</span>{" "}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={downloadConcern}>Условия использования</a>
      </div>
      <div className="registration-form-buttons">
        <button className="registration-input-submit">
          Зарегистрироваться
        </button>
        <button className="registration-input-cancel" onClick={closePopup}>
          Отмена
        </button>
      </div>
    </form>
  );
};

export default RegistrationForm;
