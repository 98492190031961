import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./ObjectProjectEstimates.scss";

import FileList from "../FileList";
import {
  fetchFiles,
  uploadFiles,
  deleteFiles,
  downloadFiles,
} from "../../actions/ObjectFilesActions";
import ObjectDesignProject from "../ObjectDesignProject";
import { inlinePdf, inlineUnnown } from "./img/inlineSVGicons";
import { UserContext } from "../../App";
import Paginator from "../Paginator";
import Loader from "../Loader";
import toaster from "toasted-notes";

const ObjectProjectEstimates = (props) => {
  const projectEstimatesStore = useSelector(
    (store) => store.objectFiles.projectEstimates
  );
  const dispatch = useDispatch();
  const upload = (files, name) =>
    dispatch(uploadFiles("projectEstimates", props.id, files, name)).then(
      (r) => {
        if (r) {
          toaster.notify(
            <div className="error-toast">{JSON.stringify(r)}</div>,
            {
              position: "top-right",
              duration: 2000,
            }
          );
        }
      }
    );
  const onDelete = (ids) => deleteFiles("projectEstimates", props.id, ids);
  const download = (ids, name, ext) =>
    downloadFiles("projectEstimates", props.id, ids, name, ext);
  const userContext = useContext(UserContext);

  const getFiles = useCallback(
    (page, pageSize) => dispatch(fetchFiles("projectEstimates", props.id)),
    [props.id, dispatch]
  );

  useEffect(() => {
    if (
      !projectEstimatesStore.isUpdated &&
      !projectEstimatesStore.isFetching &&
      !projectEstimatesStore.isError
    ) {
      getFiles();
    }
  }, [projectEstimatesStore, getFiles]);

  const onUpload = (files) => {
    upload(files).then(() => {
      getFiles();
    });
  };

  const onDel = (ids) => {
    onDelete(ids).then((r) => {
      if (r) {
        toaster.notify(<div className="error-toast">{r}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
      getFiles();
    });
  };

  const onDownload = (ids) => {
    let name = "";
    if (ids.length > 1) {
      name = "Проектно-сметная документация";
    } else {
      name = projectEstimatesStore.items.filter((item) => item.id === ids[0])[0]
        .name;
    }
    let ext = ids.length > 1 ? ".zip" : ".pdf";
    download(ids, name, ext);
  };

  const isHavePermission = (permission) => {
    return !!userContext.permissions.filter((perm) => perm.name === permission)
      .length;
  };

  let files = projectEstimatesStore.items.map((item) => {
    return {
      ...item,
      icon: item.extension === ".pdf" ? inlinePdf : inlineUnnown,
    };
  });

  return (
    <div className="solo-object-project-estimates">
      {projectEstimatesStore.isFetching ? <Loader /> : null}
      <FileList
        files={files}
        onUpload={
          isHavePermission("perm_upload_project_estimate") &&
          props.status !== "STOPPED" &&
          props.status !== "WORKS_DONE"
            ? onUpload
            : null
        }
        onDownload={
          isHavePermission("perm_document_download") ? onDownload : null
        }
        onDelete={
          isHavePermission("perm_delete_project_estimate") ? onDel : null
        }
        isFetching={projectEstimatesStore.isFetching}
        isError={projectEstimatesStore.isError}
        label="Добавить файлы (.pdf)"
        format=".pdf"
        status={props.status}
        helpMessage="Максимальный размер загружаемого файла - 200 мб"
      />
      {projectEstimatesStore.pagination.totalCount >
      projectEstimatesStore.pagination.pageSize ? (
        <Paginator
          currentPage={projectEstimatesStore.pagination.currentPage}
          pageSize={projectEstimatesStore.pagination.pageSize}
          total={projectEstimatesStore.pagination.totalCount}
          totalPages={projectEstimatesStore.pagination.totalPageCount}
          onPageChange={getFiles}
        />
      ) : null}
    </div>
  );
};

ObjectDesignProject.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ObjectProjectEstimates;
