import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./ArchiveContainer.scss";

import ObjectCard from "../../components/ObjectCard";
import { fetchArchive } from "../../actions/ArchiveActions";
import Paginator from "../../components/Paginator";
import Loader from "../../components/Loader";
import ObjectsSearch from "../Objects/components/ObjectsSearch";
import { ROUTES } from "../../routes";
import CheckPermissions from "../../components/CheckPermissions";

const ArchiveContainer = () => {
  const archiveStore = useSelector((store) => store.archive);
  const dispatch = useDispatch();
  const getArchive = useCallback(
    (filters, page) => dispatch(fetchArchive(filters, page)),
    [dispatch]
  );
  const history = useHistory();
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  useEffect(() => {
    if (!archiveStore.isUpdated && !archiveStore.isFetching) {
      getArchive();
    }
  }, [archiveStore.isUpdated, archiveStore.isFetching, getArchive]);

  const onClick = (id) => {
    history.push(ROUTES.OBJECT.PATH + `/${id}` + ROUTES.OBJECT.INFO.PATH);
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          {archiveStore.isFetching ? <Loader /> : null}
          <h1 className="archive-header">Архив</h1>
          <ObjectsSearch
            pageName={"archive"}
            onFilter={(filter) => getArchive(filter)}
          />

          <div className="archive-list-container">
            {archiveStore.items.length && !isMobileSize ? (
              <ul className="archive-list-header">
                <li className="archive-list-header-name">Название и адрес</li>
                <li className="archive-list-header-end">Срок по плану</li>
                <li className="archive-list-header-complete">Прогресс</li>
                <li className="archive-list-header-status">Статус</li>
                <CheckPermissions
                  permissions={["perm_construction-object_delete"]}
                >
                  <li className="archive-list-header-delete"></li>
                </CheckPermissions>
              </ul>
            ) : null}

            <ul className="archive-list">
              {archiveStore.items.length ? (
                archiveStore.items.map((item) => {
                  return (
                    <li className="archive-list-item" key={item.id}>
                      <ObjectCard
                        item={item}
                        onClick={() => onClick(item.id)}
                      />
                    </li>
                  );
                })
              ) : (
                <li>
                  <div className="no-notes">Нет объектов</div>
                </li>
              )}
            </ul>
          </div>
          {archiveStore.pagination.totalCount >
          archiveStore.pagination.pageSize ? (
            <Paginator
              currentPage={archiveStore.pagination.currentPage}
              total={archiveStore.pagination.totalCount}
              totalPages={archiveStore.pagination.totalPageCount}
              pageSize={archiveStore.pagination.pageSize}
              onPageChange={(page) => getArchive(undefined, page)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ArchiveContainer;
