export const FAVORITES_REQUEST = "FAVORITES_REQUEST";
export const FAVORITES_SUCCESS = "FAVORITES_SUCCESS";
export const FAVORITES_ERROR = "FAVORITES_ERROR";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  items: [],
  pagination: {},
  isUpdated: false,
};

const favorites = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: []
      };
    }
    case FAVORITES_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        pagination: action.payload.pagination,
        isUpdated: true,
        isFetching: false
      };
    }
    case FAVORITES_ERROR: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        isError: true,
        errors: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default favorites;
