export const ROUTES = {
  START: {
    PATH: '/'
  },
  LOGIN: {
    PATH: "/login",
  },
  REGISTRATION: {
    PATH: "/registration",
  },
  RESET_PASS: {
    PATH: "/resetPass",
  },
  UPDATE_PASS: {
    PATH: "/updatePass",
  },
  OBJECTS: {
    PATH: "/objects",
    ALL: {
      PATH: "/objects/all",
      MAP: {
        PATH: "/objects/all/map",
      },
      LIST: {
        PATH: "/objects/all/list",
      },
    },
    MY: {
      PATH: "/objects/my",
      LIST: {
        PATH: "/objects/my/list",
      },
      MAP: {
        PATH: "/objects/my/map",
      },
    },
  },
  OBJECT: {
    ROUTE: "/object/:id",
    PATH: "/object",
    INFO: {
      ROUTE: "/object/:id/info",
      PATH: "/info",
    },
    STAGES: {
      ROUTE: "/object/:id/stages",
      PATH: "/stages",
    },
    ADD: {
      PATH: "/object/add",
      INFO: {
        PATH: "/object/add/info",
      },
      STAGES: {
        PATH: "/object/add/stages",
      },
    },
    SECTION: {
      PATH: "/section",
      STAGE: {
        ROUTE: "/object/:objectId/section/:sectionId/stage/:stageId",
        PATH: "/stage",
        INFO:{
          ROUTE: "/object/:objectId/section/:sectionId/stage/:stageId/info",
          PATH: "/info"
        },
        HISTORY: {
          ROUTE: "/object/:objectId/section/:sectionId/stage/:stageId/history",
          PATH: "/history"
        }
      },
    },
  },
  FAVORITE: {
    PATH: "/favorite",
  },
  ARCHIVE: {
    PATH: "/archive",
  },
  SETTINGS: {
    PATH: "/settings",
  },
  NOTIFICATION: {
    PATH: "/notifications",
  },
  HELP_CENTER: {
    PATH: "/helpCenter",
    TOPIC: {
      ROUTE: "/helpCenter/topic/:id",
      PATH: "/topic",
    }
  },
  PROFILE: {
    PATH: "/profile"
  },
  USER_CONFIRMATION: {
    PATH: "/user_confirmation"
  },
  RIGHTS: {
    PATH: "/give_rights"
  },
  RATING: {
    PATH: "/rating"
  },
};
