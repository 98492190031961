import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import "./StageHistoriesCard.scss";

import {
  approveHistory,
  deleteWorkHistory,
} from "../../../../actions/ObjectHistoriesActions";
import HistoryWindow from "./components/HistoryWindow";
import StageCardInfo from "./components/StageCardInfo";
import toaster from "toasted-notes";

const StageHistoriesCard = (props) => {
  const params = useParams();

  const onStatusChange = (ev, role, status) => {
    ev.stopPropagation();

    approveHistory(
      params.objectId,
      params.sectionId,
      params.stageId,
      role,
      status
    ).then((r) => {
      if (r === "success") {
        props.onStatusChange();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  const deleteHistory = () => {
    deleteWorkHistory(
      params.objectId,
      params.sectionId,
      params.stageId,
      props.info.works_history_id
    ).then((r) => {
      if (r === "success") {
        props.onStatusChange();
        toaster.notify(
          <div className="success-toast">История работ удалена!</div>,
          { position: "top-right", duration: 4000 }
        );
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  return (
    <div
      className={
        !props.isOpen
          ? "stage-histories-card-closed"
          : "stage-histories-card-open"
      }
    >
      {/*<div onClick={props.isOpen ? () => props.onClick(null) : null}>*/}
        <StageCardInfo
          info={props.info}
          status={props.status}
          onDeleteHistory={() => deleteHistory()}
          onView={
            !props.isOpen ? () => props.onClick(props.info.works_history_id) : null
          }
        />
      {/*</div>*/}

      <HistoryWindow
        isActive={props.isOpen}
        onClose={() => props.onClick(null)}
        info={props.info}
        status={props.status}
      />
    </div>
  );
};

StageHistoriesCard.propTypes = {
  info: PropTypes.object,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onImageClick: PropTypes.func,
  onStatusChange: PropTypes.func,
};

export default StageHistoriesCard;
