export const OBJECT_SECTIONS_REQUEST = "OBJECT_SECTIONS_REQUEST";
export const OBJECT_SECTIONS_SUCCESS = "OBJECT_SECTIONS_SUCCESS";
export const OBJECT_SECTIONS_ERROR = "OBJECT_SECTIONS_ERROR";
export const OBJECT_SUMMARY_SECTIONS_SUCCESS = "OBJECT_SUMMARY_SECTIONS_SUCCESS";
export const OBJECT_SUMMARY_SECTIONS_ERROR = "OBJECT_SUMMARY_SECTIONS_ERROR";
export const OBJECT_SUMMARY_SECTIONS_REQUEST = "OBJECT_SUMMARY_SECTIONS_REQUEST";
export const OBJECT_SECTIONS_SET_IS_UPDATED = "OBJECT_SECTIONS_SET_IS_UPDATED";
export const OBJECT_SECTIONS_SET_IS_ERROR = "OBJECT_SECTIONS_SET_IS_ERROR";

const initialState = {
  isFetching: false,
  items: [],
  summarySections: {
    isUpdated: false,
    isFetching: false,
    isError: false,
    items: []
  },
  isError: false,
  errors: [],
  isUpdated: false,
  pagination: {},
};

const objectSections = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_SECTIONS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case OBJECT_SECTIONS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.payload.value,
        isUpdated: true,
        pagination: action.payload.pagination,
      };
    }
    case OBJECT_SECTIONS_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        isUpdated: true,
        errors: action.payload,
      };
    }
    case OBJECT_SECTIONS_SET_IS_UPDATED: {
      return {
        ...initialState,
        isUpdated: action.payload,
      };
    }
    case OBJECT_SECTIONS_SET_IS_ERROR: {
      return {
        ...initialState,
        isError: action.payload,
      };
    }
    case OBJECT_SUMMARY_SECTIONS_REQUEST: {
      return {
        ...state,
        summarySections: {
          ...state.summarySections,
          isFetching: true
        }
      }
    }
    case OBJECT_SUMMARY_SECTIONS_SUCCESS: {
      return {
        ...state,
        summarySections: {
          ...state.summarySections,
          isFetching: false,
          isUpdated: true,
          items: action.payload
        }
      }
    }
    case OBJECT_SUMMARY_SECTIONS_ERROR: {
      return  {
        ...state,
        summarySections: {
         ...state.summarySections,
          isUpdated: false,
          isFetching: false,
          isError: true
        }
      }
    }
    default: {
      return state;
    }
  }
};

export default objectSections;
