import api from "./api";

export const USER_SINGUP_REQUEST = "USER_SINGUP_REQUEST";
export const USER_SINGUP_SUCCESS = "USER_SINGUP_SUCCESS";
export const USER_SINGUP_ERROR = "USER_SINGUP_ERROR";
export const USER_SINGUP_ROLES = "USER_SINGUP_ROLES"
export const USER_SIGNUP_ORGANIZATIONS = "USER_SIGNUP_ORGANIZATIONS"

export const singUp = (data) => {
  return (dispatch) => {
    dispatch({
      type: USER_SINGUP_REQUEST,
    });

    //TODO:Доделать отправку названия организации
    let userData = {
      user_email: data.login,
      user_password: data.password,
      profile_phone_number: data.contacts,
      profile_fio: data.name,
      item_name: data.role,
      organization_name: data.organization,
    };

    return fetch(api + "/v1/auth/sign-up", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: USER_SINGUP_SUCCESS,
            payload: data,
          });
          return true;
        }
        dispatch({
          type: USER_SINGUP_ERROR,
          payload: data.data.message,
        });
        return data.data.message;
      })
      .catch((error) => {
        dispatch({
          type: USER_SINGUP_ERROR,
          payload: error,
        });
        return false;
      });
  };
};

export function fetchRoles() {
  return (dispatch) => {
    fetch(`${api}/v1/auth/role`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          let items = data.data.map((item) => {
            return{
              key: item.item_name,
              value: item.item_description
            }
          })
          dispatch({
            type: USER_SINGUP_ROLES,
            payload: items
          })
          return;
        }
      })
  }
}

export function fetchOrganizations() {
  return(dispatch) => {
    fetch(`${api}/v1/auth/organization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          let items = data.data.map((item) => {
            return {
              key: item.organization_id,
              value: item.organization_name,
            }
          })
          dispatch({
            type: USER_SIGNUP_ORGANIZATIONS,
            payload: items
          })
          return;
        }
      })
  }
}

export const downloadUserConfirmation = () => {
  return fetch(api + `/v1/document/download/concern`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
}
