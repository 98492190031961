import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./AddObjectArchive.scss";
import ModalWindow from "../../../../../../components/ModalWindow";
import { useDispatch, useSelector } from "react-redux";
import { getArchiveObjectsForCreate } from "../../../../../../actions/AddObjectActions";
import Loader from "../../../../../../components/Loader";
import {
  deleteIcon,
  plusIcon,
  checkIcon,
  closeIcon,
} from "../../../../../../assets/img/sprite";
import Paginator from "../../../../../../components/Paginator";
import ObjectsSearch from "../../../../../Objects/components/ObjectsSearch";

const AddObjectArchive = (props) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const dispatch = useDispatch();
  const addObjectStore = useSelector((store) => store.objectAdd);
  const { isMobileSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );
  const getArchiveObjects = useCallback(
    (filters, page) => dispatch(getArchiveObjectsForCreate(filters, page)),
    [dispatch]
  );
  const [selectedObjectsIds, setSelectedObjectsIds] = useState(
    props.linksIds || []
  );
  const [selectedObjectsInfo, setSelectedObjectsInfo] = useState(
    props.links || []
  );

  useEffect(() => {
    if (
      isActiveModal &&
      !addObjectStore.isUpdated &&
      !addObjectStore.isFetching &&
      !addObjectStore.isError
    ) {
      getArchiveObjects();
    }
  }, [
    getArchiveObjects,
    isActiveModal,
    addObjectStore.isUpdated,
    addObjectStore.isFetching,
    addObjectStore,
  ]);

  const clearStore = () => {
    addObjectStore.isUpdated = false;
    setIsActiveModal(false);
  };

  const select = (ev, id, name, address, end, link) => {
    let sel = props.linksIds ? [...selectedObjectsIds] : selectedObjectsIds;
    let selInfo = props.links ? [...selectedObjectsInfo] : selectedObjectsInfo;

    if (ev.target.checked) {
      sel.push(id);
      selInfo.push({
        id: id,
        name: name,
        address: address,
        endDate: end,
        link: link,
      });
    } else {
      sel.splice(sel.indexOf(id), 1);
      for (let i in selInfo) {
        if (selInfo[i].id === id) {
          selInfo.splice(i, 1);
        }
      }
    }
    setSelectedObjectsIds(sel);
    setSelectedObjectsInfo(selInfo);

    if (!props.links) {
      props.onSelectObjects("archive_ids", selectedObjectsIds);
    }
  };

  const setObjects = () => {
    clearStore();
  };

  const isSelect = (id) => {
    return !!selectedObjectsIds.filter((item) => item === id).length;
  };

  const deleteObject = (id) => {
    let sel = props.linksIds ? [...selectedObjectsIds] : selectedObjectsIds;
    let selInfo = props.links ? [...selectedObjectsInfo] : selectedObjectsInfo;
    sel.splice(sel.indexOf(id), 1);
    for (let i in selInfo) {
      if (selInfo[i].id === id) {
        selInfo.splice(i, 1);
      }
    }
    setSelectedObjectsIds(sel);
    setSelectedObjectsInfo(selInfo);

    if (!props.links) {
      props.onSelectObjects("archive_ids", selectedObjectsIds);
    }
  };

  const saveChanges = () => {
    props.onSelectObjects(selectedObjectsIds);
  };

  return (
    <div className="add-object-archive">
      {props.links ? null : (
        <div className="add-object-archive-head">
          <span>Связать с архивным объектом</span>
          <button onClick={() => setIsActiveModal(true)} type="button">
            {plusIcon}
            <span>Выбрать</span>
          </button>
        </div>
      )}
      {selectedObjectsInfo.length ? (
        <div className="add-object-archive-content">
          {isDesktopSize && (
            <div className="add-object-archive-content-head">
              <div className="add-object-archive-content-head-item">
                Название
              </div>
              <div className="add-object-archive-content-head-item">Адрес</div>
              <div className="add-object-archive-content-head-item">
                Дата заморозки
              </div>
              <div className="add-object-archive-content-head-item">Ссылка</div>
              <div className="add-object-archive-content-head-item" />
            </div>
          )}
          <div className="add-object-archive-content-all">
            {selectedObjectsInfo.map((item) => {
              return (
                <div className="add-object-archive-content-main" key={item.id}>
                  <div className="add-object-archive-content-name">
                    {item.name}
                  </div>
                  <div className="add-object-archive-content-address">
                    {item.address}
                  </div>
                  <div className="add-object-archive-content-end">
                    {item.endDate || "Не указано"}
                  </div>
                  <a
                    className="add-object-archive-content-link"
                    href={item.link + "/info"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Перейти
                  </a>
                  <div
                    className="add-object-archive-content-controls _error _icon"
                    onClick={() => deleteObject(item.id)}
                  >
                    {deleteIcon}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <ModalWindow
        isActive={isActiveModal}
        onClose={() => clearStore()}
        label="Связать с архивным объектом"
        maxWidth={1080}
        maxHeight={1000}
      >
        <ObjectsSearch
          pageName={"archive"}
          onFilter={getArchiveObjects}
          linksToObjects={true}
        />
        <div className="add-object-archive-modal">
          {addObjectStore.isFetching ? <Loader /> : null}
          <div className="add-object-archive-modal-head">
            <div className="add-object-archive-modal-head-item" />
            <div className="add-object-archive-modal-head-item">Название</div>
            <div className="add-object-archive-modal-head-item">Адрес</div>
            <div className="add-object-archive-modal-head-item">
              Дата заморозки
            </div>
            <div className="add-object-archive-modal-head-item">Ссылка</div>
          </div>
          <div className="add-object-archive-modal-content-all">
            {!addObjectStore.archive.items.length ? (
              <div className="no-notes">Нет объектов в архиве</div>
            ) : (
              addObjectStore.archive.items.map((item) => {
                return (
                  <label
                    className="add-object-archive-modal-content"
                    htmlFor={`object_${item.construction_object_id}`}
                    key={item.construction_object_id}
                  >
                    <div className="add-object-archive-modal-content-checkbox">
                      <input
                        type="checkbox"
                        defaultChecked={isSelect(item.construction_object_id)}
                        onChange={(ev) =>
                          select(
                            ev,
                            item.construction_object_id,
                            item.construction_object_name,
                            item.construction_object_address,
                            item.construction_object_date_plan_end,
                            `/object/${item.construction_object_id}`
                          )
                        }
                        id={`object_${item.construction_object_id}`}
                      />
                    </div>
                    <div className="add-object-archive-modal-content-name">
                      {item.construction_object_name}
                    </div>
                    <div className="add-object-archive-modal-content-address">
                      {item.construction_object_address}
                    </div>
                    <div className="add-object-archive-modal-content-end">
                      {item.construction_object_date_plan_end || "Не указано"}
                    </div>
                    <a
                      className="add-object-archive-modal-content-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/object/${item.construction_object_id}/info`}
                    >
                      Перейти
                    </a>
                  </label>
                );
              })
            )}
            {addObjectStore.archive.pagination.totalCount >
            addObjectStore.archive.pagination.pageSize ? (
              <Paginator
                currentPage={addObjectStore.archive.pagination.currentPage}
                total={addObjectStore.archive.pagination.totalCount}
                totalPages={addObjectStore.archive.pagination.totalPageCount}
                pageSize={addObjectStore.archive.pagination.pageSize}
                onPageChange={(page) => getArchiveObjects(undefined, page)}
              />
            ) : null}
          </div>
          <div className="add-object-archive-modal-button">
            <button onClick={setObjects}>
              {plusIcon}
              <span>Выбрать</span>
            </button>
          </div>
        </div>
      </ModalWindow>
      {props.links ? (
        <div className="solo-object-add-links">
          <button onClick={() => setIsActiveModal(true)}>
            {plusIcon}
            <span>Выбрать</span>
          </button>
          <div className="solo-object-add-links-controls">
            <button className="cancel _error" onClick={props.onCancel}>
              {isMobileSize ? closeIcon : <span>Отмена</span>}
            </button>
            <button className="save" onClick={saveChanges}>
              {isMobileSize ? checkIcon : <span>Сохранить</span>}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

AddObjectArchive.propTypes = {
  onSelectObjects: PropTypes.func,
  links: PropTypes.array,
  linksIds: PropTypes.array,
  onCancel: PropTypes.func,
};

export default AddObjectArchive;
