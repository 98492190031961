import {
  USER_UPDATE_PASS_REQUEST,
  USER_UPDATE_PASS_SUCCESS,
  USER_UPDATE_PASS_ERROR,
} from "../actions/UpdatePassActions";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errors: [],
};

const updatePass = (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE_PASS_REQUEST: {
      return { ...state, isFetching: true, isSuccess: false };
    }
    case USER_UPDATE_PASS_SUCCESS: {

      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        isError: false,
        errors: [],
      };
    }
    case USER_UPDATE_PASS_ERROR: {
      let errors = [];
      for (let key in action.payload) {
        errors.push(action.payload[key]);
      }

      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isError: true,
        errors: errors,
      };
    }
    default:
      return state;
  }
};

export default updatePass;
