import React, { useState } from "react";

import "./InputPassword.scss";
import { inlineEyeClosed, inlineEyeOpen } from "./img/inlineSvgIcons.js";

import Input from "../Input";
import PropTypes from "prop-types";

const InputPassword = (props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  return (
    <div className="password-input">
      <Input
        label={props.label}
        type={isPasswordShown ? "text" : "password"}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder}
        error={props.error}
        validators={props.validators}
        onValidationChange={props.onValidationChange}
        validateOnStart={props.validateOnStart}
      >
        <div
          onClick={() => setIsPasswordShown(!isPasswordShown)}
          className="password-input-eye"
        >
          {isPasswordShown ? inlineEyeOpen : inlineEyeClosed}
        </div>
      </Input>
    </div>
  );
};

InputPassword.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
};

export default InputPassword;
