import React from "react";
import PropTypes from "prop-types";

import "./StagesList.scss";
import Stage from "../Stage";

const StagesList = (props) => {
  return(
    <div className="object-stage-list">
      {
        props.sections.map((section, index) => {
          if (section.stages.length) {
            return <Stage
              index={index}
              section={section}
              stages={section.stages}
              selectSection={props.selectSection}
              selectStage={props.selectStage}
              selectAll={props.selectAll}
              dirty={props.dirty}
            />;
          }
          return null;
        })
      }
    </div>
  )
}

StagesList.propTypes = {
  sections: PropTypes.array,
  getStages: PropTypes.func,
  selectSection: PropTypes.func,
  selectStage: PropTypes.func,
  selectAll: PropTypes.bool,
}

export default StagesList;