import {
  USER_SINGIN_ERROR,
  USER_SINGIN_REQUEST,
  USER_SINGIN_SET_TOKEN,
  USER_SINGIN_SUCCESS,
} from "../actions/SingInActions";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  tokenName: null,
  token: null,
};

const singIn = (state = initialState, action) => {
  switch (action.type) {
    case USER_SINGIN_REQUEST: {
      return { ...state, isFetching: true, isSuccess: false };
    }
    case USER_SINGIN_SUCCESS: {
      localStorage.setItem("token_name", action.payload.data.auth_token_name);
      localStorage.setItem(
        action.payload.data.auth_token_name,
        action.payload.data.access_token
      );

      return {
        ...state,
        isFetching: false,
        isError: false,
        errors: [],
        tokenName: action.payload.data.auth_token_name,
        token: action.payload.data.access_token,
      };
    }
    case USER_SINGIN_ERROR: {
      let errors = [];
      for (let key in action.payload) {
        errors.push(action.payload[key]);
      }

      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: errors,
      };
    }
    case USER_SINGIN_SET_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
        tokenName: action.payload.name,
      };
    }
    default:
      return state;
  }
};

export default singIn;
