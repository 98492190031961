import React, { useState } from "react";
import PropTypes from "prop-types";

import "./InputTextArea.scss";

const InputTextArea = (props) => {
  const [validationError, setValidationError] = useState("");

  const onChange = (ev) => {
    if (props.validators) {
      let validators = props.validators;
      for (let i = 0; i < validators.length; i++) {
        let result = validators[i](ev.target.value);

        if (result) {
          setValidationError(result);
          break;
        }
      }
    }

    props.onChange(ev.target.value);
  };

  return (
    <label className="input-text-area">
      <div className="input-text-area-info">
        <div className="input-text-area-label">{props.label}</div>
        {props.error || validationError ? (
          <div className="input-text-area-error">
            {props.error || validationError}
          </div>
        ) : null}
      </div>
      <textarea
        name={props.name}
        className={
          props.error || validationError
            ? "input-text-area-input-error"
            : "input-text-area-input"
        }
        onChange={onChange}
        value={props.value}
      />
    </label>
  );
};

InputTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  name: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
};

export default InputTextArea;
