import {
  OBJECTS_FILTER_SUCCESS,
  OBJECTS_FILTER_REQUEST,
  OBJECTS_FILTER_ERROR,
} from "../reducers/objectsFilters";

import api from "./api";

const routes = {
  n_project: "national-project",
  work_type: "work-type",
  object_type: "object-type",
  status: "statuses",
  role: "roles"
};

export function fetchFilters(type, pageName) {
  return (dispatch) => {
    dispatch({
      type: OBJECTS_FILTER_REQUEST,
      payload: type,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECTS_FILTER_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }
    fetch(api + `/v1/${routes[type]}`, {
      method: type === 'status' ? 'POST' : "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: type === 'status' ? JSON.stringify({pageName: pageName}) : null
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECTS_FILTER_ERROR,
          });
          return;
        }
        let items = data.data.map((item) => {
          return {
            key: item.id,
            value: item.name,
          };
        });
        dispatch({
          type: OBJECTS_FILTER_SUCCESS,
          payload: {
            name: type,
            items: items,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECTS_FILTER_ERROR,
          payload: er,
        });
      });
  };
}
