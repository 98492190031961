import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { plusIcon } from "../../../../assets/img/sprite";

import "./AddHistory.scss";

import Input from "../../../../components/Input";
import CheckPermissions from "../../../../components/CheckPermissions";
import { permissions } from "../../../../permissions";

const AddHistory = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [newHistory, setNewHistory] = useState({
    amount: 0,
  });
  const isAvailable = useSelector((state) => state.objects.isAvailable);

  useEffect(() => {
    if (!isActive) {
      setNewHistory({
        works_history_amount: 0,
      });
    }
  }, [isActive]);

  const onChange = (value) => {
    value = value.replace(",", ".");
    setNewHistory({
      works_history_amount: value,
    });
  };

  const onCreate = () => {
    props.onAdd(newHistory);
    setIsActive(false);
  };

  return (
    <div className="add-history">
      {isActive ? (
        <div className="add-history-head">
          <div className="add-history-info">
            <span className="add-history-info-label">Объем работ</span>

            <Input
              value={newHistory.works_history_amount}
              onChange={(ev) => {
                onChange(ev.target.value);
              }}
            />
            {props.unit && (
              <span className="add-history-info-unit">{props.unit}</span>
            )}
          </div>

          <div className="add-history-info-controls">
            <button onClick={onCreate} className="add-history-save">
              Сохранить
            </button>

            <button
              onClick={() => setIsActive(false)}
              className="add-history-cancel _error"
            >
              Отмена
            </button>
          </div>
        </div>
      ) : (
        <CheckPermissions permissions={[permissions.createWorksHistory]}>
          {props.status === "STOPPED" ||
          props.status === "WORKS_DONE" ? null : (
            <button
              onClick={() => setIsActive(true)}
              className="add-history-create"
              disabled={isAvailable}
            >
              {plusIcon}
              <span>Внести выполненный объем</span>
            </button>
          )}
        </CheckPermissions>
      )}
    </div>
  );
};

AddHistory.propTypes = {
  onAdd: PropTypes.func,
  unit: PropTypes.number,
};

export default AddHistory;
