import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import "./CustomerInfo.scss";
import EditableField from "../../../../../../components/EditableField";
import { editCustomerContractorInfo } from "../../../../../../actions/ObjectsActions";
import { mail, required } from "../../../../../../assets/js/validators";
import ReactTooltip from "react-tooltip";

const CustomerInfo = (props) => {
  const [changedCustomerInfo, setChangedCustomerInfo] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const params = useParams();

  useEffect(() => {
    if (props.isEditable) {
      setChangedCustomerInfo(props.customer);
    } else {
      setChangedCustomerInfo({});
    }
  }, [props.isEditable, props.customer]);

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) return true;
    }
    return false;
  };

  const change = (ev) => {
    setChangedCustomerInfo({
      ...changedCustomerInfo,
      [ev.target.name]: ev.target.value,
    });
  };

  const save = async () => {
    if (!isValidationErrors()) {
      await editCustomerContractorInfo(
        params.id,
        changedCustomerInfo,
        "customer"
      );
      props.onSave();
    }
  };

  const validationChange = (name, value) => {
    setValidationErrors({
      ...validationErrors,
      [name]: value,
    });
  };

  return (
    <div className="customer-info">
      <div className="customer-info-block">
        <div className="customer-info-name">
          <EditableField
            value={
              props.isEditable ? changedCustomerInfo.name : props.customer.name
            }
            label="ФИО"
            isEditable={props.isEditable}
            name="name"
            onChange={change}
            validators={[required]}
            onValidationChange={validationChange}
            isTitle
          />
        </div>

        <div className="customer-info-contact">
          <EditableField
            value={
              props.isEditable
                ? changedCustomerInfo.phone
                : props.customer.phone
            }
            label="Номер"
            isEditable={props.isEditable}
            name="phone"
            onChange={change}
            validators={[required]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="customer-info-email">
          <EditableField
            value={
              props.isEditable
                ? changedCustomerInfo.email
                : props.customer.email
            }
            label="Почта"
            isEditable={props.isEditable}
            name="email"
            onChange={change}
            validators={[required, mail]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="customer-info-additional">
          <EditableField
            value={
              props.isEditable
                ? changedCustomerInfo.contact
                : props.customer.contact
            }
            label="Дополнительная информация"
            isEditable={props.isEditable}
            name="contact"
            onChange={change}
            onValidationChange={validationChange}
          />
        </div>
      </div>

      {props.isEditable ? (
        <div className="customer-info-controls">
          <button
            onClick={props.onCancel}
            className="customer-info-cancel _error"
          >
            Отмена
          </button>

          <button onClick={save} className="customer-info-save">
            Сохранить
          </button>
        </div>
      ) : null}
    </div>
  );
};

CustomerInfo.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    contact: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isEditable: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CustomerInfo;
