import React from "react";
import PropTypes from "prop-types";
import "@pwabuilder/pwainstall";
import "./StartPage.scss"
import AccountWidget from "../../components/AccountWiget";
import RegistrationPopup from "../../containers/RegistrationPopup";

const StartPage = ({onAccountWidgetControlsReady}) => {
  return (
    <div className="main-layout-content">
      <AccountWidget onAccountWidgetControlsReady={onAccountWidgetControlsReady} />

      <div className="container-wrapper">
        <div className="start-page container">
          <div className="info">
            <div className="info-text">
              <div className="info-text__sub-header">платформа</div>
              <div className="info-text__header">Стройконтроль</div>
              <div className="info-text__list">
                <div className="description">
                  Контроль строительных работ Томской области и автоматизация
                  межведомственного взаимодействия
                </div>
                <pwa-install id="installComponent">
                  Установить приложение на рабочий стол
                </pwa-install>
              </div>
            </div>
            <div className="info-image">
              <img src={require("../../assets/img/right_bg.png")} alt=""/>
            </div>
          </div>
          <div className="content">
            <div className="left">
              <div className="name">Задачи</div>
              <ul>
                <li>
                  <span>Контроль хода выполнения строительных процессов</span>
                </li>
                <li>
                <span>
                  Планирование и управление рисками в ходе строительства
                </span>
                </li>
                <li>
                <span>
                  Анализ текущего состояния объектов, применение корректирующих
                  воздействий
                </span>
                </li>
              </ul>
            </div>
            <div className="right">
              Платформа «Стройконтроль» является связующим звеном для основных
              участников процесса строительства в Томской области. Благодаря
              платформе заинтересованные стороны могут отслеживать прогресс
              выполненных работ, оставлять комментарии на том или ином этапе
              работ, загружать фотографии с процесса строительства, а также
              знакомиться с актуальными документами об объектах. Платформа
              разработана в рамках стандарта «Умный город».
            </div>
          </div>
        </div>
      </div>

      <RegistrationPopup />
    </div>
  );
};

StartPage.propTypes = {
  onAccountWidgetControlsReady: PropTypes.func,
};

export default StartPage;
