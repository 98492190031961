import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ERROR,
} from "../reducers/notifications";

import api from "./api";

export function fetchNotifications(page) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: NOTIFICATIONS_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    fetch(api + `/v1/notification`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      method: "POST",
      body: JSON.stringify({page: page})
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: NOTIFICATIONS_ERROR,
          });
          return;
        }

        dispatch({
          type: NOTIFICATIONS_SUCCESS,
          payload: {
            items: data.data.items,
            count: data.data.unread,
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: NOTIFICATIONS_ERROR,
          payload: er,
        });
      });
  };
}

export const setRead = (id) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/notification/${id}/read`, {
    headers: {
      [tokenName]: token,
    },
    method: "POST",
    body: {}
  })
}

export const setReadAll = () => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/notification/read-all`, {
    headers: {
      [tokenName]: token,
    },
    method: "POST",
    body: {}
  })
}
