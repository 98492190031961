import React, { useState } from "react";
import { fromLonLat } from "ol/proj";
import PropTypes from "prop-types";

import "./AddObjectMap.scss";
import Map from "../../../../../../components/Map";

const AddObjectMap = ({ longitude, latitude, onPointSet, polygons, onPolygonsChange }) => {
  const [center] = useState(() =>
    fromLonLat([longitude || 84.95, latitude || 56.49])
  );

  return (
    <div className="add-object-info-map">
      <Map
        center={center}
        initialZoom={17}
        drawnObjects={{
          canDraw: true,
          point: {
            coordinates: longitude && latitude ? { longitude, latitude } : null,
            onChange: onPointSet,
          },
          polygons: {
            items: polygons,
            onChange: onPolygonsChange,
          }
        }}
      />
    </div>
  );
};

AddObjectMap.propTypes = {
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPointSet: PropTypes.func,
};

export default AddObjectMap;
