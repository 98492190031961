import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import "./ObjectsContainer.scss";

import MyObjects from "./components/MyObjects";
import AllObjects from "./components/AllObjects";
import { ROUTES } from "../../routes";
import CheckPermissions from "../../components/CheckPermissions";
import { permissions } from "../../permissions";

const ObjectsContainer = ({ onContentReady }) => {
  const userStore = useSelector((store) => store.user);
  const isConfirmed = userStore.permissions && userStore.permissions.length !== 0;

  return (
    <Switch>
      <Route path={ROUTES.OBJECTS.ALL.PATH}>
        <CheckPermissions permissions={[permissions.viewAllObjects]}>
          <AllObjects onContentReady={onContentReady} />
        </CheckPermissions>
      </Route>

      <Route path={ROUTES.OBJECTS.MY.PATH}>
        {!isConfirmed &&
        <React.Fragment>
            <div className="not-confirmed">Ваш аккаунт не подтвержден</div>
            <div className="not-confirmed-text">Вам придет email сообщение о решении администратора</div>
        </React.Fragment>}
        <CheckPermissions permissions={[permissions.viewMyObjects]}>
          <MyObjects onContentReady={onContentReady} />
        </CheckPermissions>
      </Route>
    </Switch>
  );
};

export default ObjectsContainer;
