import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./ObjectCard.scss";

import Status from "../Status";
import Switch from "../Switch";
import CheckPermissions from "../CheckPermissions";
import ConfirmAction from "../ConfirmAction";
import { deleteIcon, plusIcon } from "../../assets/img/sprite";
import { openModal, closeModal } from "../../actions/ModalAction";
import { fetchArchive } from "../../actions/ArchiveActions";
import { deleteObject } from "../../actions/ObjectsActions";
import { ModalTypes } from "../../assets/js/constants";
import toaster from "toasted-notes";
import CircleProgress from "../CircleProgress";

const ObjectCard = (props) => {
  const dispatch = useDispatch();
  const objectsStore = useSelector((state) => state.objects);
  const { isMobileSize, isTabletSize } = useSelector(
    (store) => store.responsive.window
  );

  const onClick = (ev) => {
    ev.stopPropagation();

    props.onClick();
  };

  const onSubscribe = (ev) => {
    ev.stopPropagation();

    props.onSubscribe(!props.item.isSubscribed);
  };

  const handleObjectDelete = () => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="Объект будет безвозвратно удален!"
            onCancel={() => dispatch(closeModal())}
            onConfirm={() => {
              deleteObject(props.item.id).then((r) => {
                if (r === "success") {
                  dispatch(fetchArchive());
                  dispatch(closeModal());
                  toaster.notify(
                    <div className="success-toast">Объект успешно удалён!</div>,
                    {
                      position: "top-right",
                      duration: 4000,
                    }
                  );
                } else {
                  toaster.notify(<div className="error-toast">{r}</div>, {
                    position: "top-right",
                    duration: 4000,
                  });
                }
              });
            }}
          />
        ),
      })
    );
  };

  return (
    <div className="object-card">
      <div className="object-card-info">
        <div className="object-card-name">
          {isMobileSize && (
            <span className="object-card-label">Название и адрес</span>
          )}
          <div>
            <div onClick={onClick} className="object-card-name-head">
              <div className="object-card-name-head-text">
                {props.item.name}
              </div>
            </div>

            <div className="object-card-name-address">
              {props.item.address || <div>-</div>}
            </div>
          </div>
        </div>

        <div className="object-card-plan-end">
          {isMobileSize && <span className="object-card-label">Срок</span>}
          {<div>{props.item.planEnd}</div> || <div>-</div>}
        </div>

        <div className="object-card-percentage">
          {isMobileSize && (
            <span className="object-card-label">Готовность</span>
          )}
          {isMobileSize ? (
            <span>{Math.round(+props.item.completion) + "%"}</span>
          ) : (
            <CircleProgress
              value={+props.item.completion}
              color={props.item.statusColor}
            />
          )}
        </div>

        <div className="object-card-status">
          {isMobileSize && <span className="object-card-label">Статус</span>}
          <Status value={props.item.status} color={props.item.statusColor} />
        </div>
        <CheckPermissions permissions={["perm_subscribe_create"]}>
          {props.item.statusCode === "STOPPED" ||
          props.item.statusCode === "WORKS_DONE" ? null : (
            <div className="object-card-subscribe">
              {isTabletSize && (
                <span className="object-card-label">Подписаться</span>
              )}
              <Switch
                onActivate={onSubscribe}
                isActive={props.item.isSubscribed}
                disabled={objectsStore.isAvailable}
              />
            </div>
          )}
        </CheckPermissions>

        {!isMobileSize ? (
          <CheckPermissions permissions={["perm_construction-object_delete"]}>
            {props.item.statusCode === "STOPPED" ||
            props.item.statusCode === "WORKS_DONE" ? (
              <div className="object-card-delete">
                <button
                  className="_error _icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleObjectDelete();
                  }}
                >
                  {deleteIcon}
                </button>
              </div>
            ) : null}
          </CheckPermissions>
        ) : (
          <div className="object-card-controls">
            <button onClick={onClick} className="object-card-detailed">
              Подробнее
            </button>
            <CheckPermissions permissions={["perm_construction-object_delete"]}>
              {props.item.statusCode === "STOPPED" ||
              props.item.statusCode === "WORKS_DONE" ? (
                <div className="object-card-delete">
                  <button
                    className="_error"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleObjectDelete();
                    }}
                  >
                    {deleteIcon}
                  </button>
                </div>
              ) : null}
            </CheckPermissions>
          </div>
        )}
      </div>
    </div>
  );
};

ObjectCard.propTypes = {
  item: PropTypes.object.isRequired,
  onSubscribe: PropTypes.func,
  onClick: PropTypes.func,
  withDeleter: PropTypes.bool,
};

export default ObjectCard;
