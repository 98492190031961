import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import "./Summary.scss";

import ModalWindow from "../../../../components/ModalWindow";
import StagesList from "./components/StagesList/StagesList";
import { fetchSummarySections } from "../../../../actions/ObjectSectionsActions";
import { downloadSummary } from "../../../../actions/SummaryActions";
import toaster from "toasted-notes";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("ru", ru);

const Summary = (props) => {
  const dispatch = useDispatch();
  const sectionsStore = useSelector((store) => store.objectSections);
  const [selectedStages, setSelectedStages] = useState([]);
  const [includeImages, setIncludeImages] = useState(false);
  const [includeComments, setIncludeComments] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const getSections = useCallback(
    () => dispatch(fetchSummarySections(props.objectId)),
    [dispatch, props.objectId]
  );

  useEffect(() => {
    if (
      props.isActive &&
      !sectionsStore.summarySections.isUpdated &&
      !sectionsStore.summarySections.isFetching
    ) {
      getSections();
    }
  }, [
    getSections,
    props.isActive,
    sectionsStore.summarySections,
    sectionsStore.summarySections.isFetching,
    sectionsStore.summarySections.isUpdated,
  ]);

  const selectStage = (id, status) => {
    let stages = [...selectedStages];
    if (status) {
      stages.push(id);
    } else {
      let position = stages.indexOf(id);
      stages.splice(position, 1);
    }
    setDirty(false);
    setSelectedStages(stages);
  };

  const selectSection = (id, stagesIds, status) => {
    let stages = [...selectedStages];
    for (let i in sectionsStore.summarySections.items[id].stages) {
      for (let j in stages) {
        if (
          sectionsStore.summarySections.items[id].stages[i].id === stages[j]
        ) {
          stages.splice(stages.indexOf(stages[j]), 1);
        }
      }
    }
    if (status) {
      stages = stages.concat(stagesIds);
    }

    setDirty(false);
    setSelectedStages(stages);
  };

  const download = () => {
    downloadSummary(
      props.objectId,
      selectedStages,
      includeImages,
      includeComments,
      dateRange
    )
      .then((r) => r.blob())
      .then((blob) => {
        if (blob.type === "application/json") {
          toaster.notify(
            <div className="error-toast">Выберите этапы работ</div>,
            {
              position: "top-right",
              duration: 2000,
            }
          );
          return 0;
        }
        let reader = new FileReader();
        var link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${
          includeImages ? "Фотоотчет" : "Отчет"
        } от ${new Date().getDate()}.${
          new Date().getMonth() + 1
        }.${new Date().getFullYear()}.pdf`;
        link.click();
        reader.readAsArrayBuffer(blob);
        reader.onload = (res) => {};
      });
  };

  const selectAll = (value) => {
    setDirty(true);
    if (value) {
      let stages = [];
      for (let i in sectionsStore.summarySections.items) {
        for (let j in sectionsStore.summarySections.items[i].stages) {
          stages.push(sectionsStore.summarySections.items[i].stages[j].id);
        }
      }
      setSelectedStages(stages);
    } else {
      setSelectedStages([]);
    }
    setIncludeAll(value);
  };

  return (
    <ModalWindow
      isActive={props.isActive}
      onClose={props.onClose}
      label="Выгрузка отчета"
      maxWidth={1080}
      maxHeight={1000}
    >
      <div className="summary">
        <div className="summary-list">
          <StagesList
            sections={sectionsStore.summarySections.items}
            selectSection={selectSection}
            selectStage={selectStage}
            selectAll={includeAll}
            dirty={dirty}
          />
        </div>

        <div className="summary-controls">
          <div className="summary-controls-left">
            <label className="summary-controls-checkbox">
              <input
                className="summary-controls-checkbox-input"
                type="checkbox"
                checked={includeAll}
                onChange={(ev) => selectAll(ev.target.checked)}
              />
              Отметить все
            </label>
            <div className="summary-controls-time-block">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                isClearable={true}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                placeholderText="Временной диапазон"
                locale="ru"
              />
            </div>
          </div>
          <div className="summary-controls-right">
            <label className="summary-controls-checkbox">
              <input
                className="summary-controls-checkbox-input"
                type="checkbox"
                checked={includeImages}
                onChange={(ev) => setIncludeImages(ev.target.checked)}
              />
              Фотоотчет
            </label>
            <label className="summary-controls-checkbox">
              <input
                className="summary-controls-checkbox-input"
                type="checkbox"
                checked={includeComments}
                onChange={(ev) => setIncludeComments(ev.target.checked)}
              />
              Комментарии
            </label>

            <button onClick={download} className="summary-controls-button1">
              Выгрузить
            </button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

Summary.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  objectId: PropTypes.number,
};

export default Summary;
