import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Overlay from "ol/Overlay";
import { fromLonLat } from "ol/proj";

import "./ObjectsMap.scss";

import { ROUTES } from "../../../../routes";
import Map from "../../../../components/Map/Map";

const CENTER = fromLonLat([85, 56.5]);

const ObjectsMap = ({ objects }) => {
  const [popup] = useState(
    () =>
      new Overlay({
        element: document.createElement("div"),
        positioning: "bottom-center",
        stopEvent: false,
        offset: [0, -50],
      })
  );

  const onHover = useCallback(
    ({ feature, map }) => {
      map.getTarget().style.cursor = feature ? "pointer" : "";

      let element = popup.getElement();
      if (feature) {
        let coordinates = feature.getGeometry().getCoordinates();
        popup.setPosition(coordinates);
        element.classList.add("object-map-popup-show");
        element.textContent = feature.get("object").name;
      } else {
        element.classList.remove("object-map-popup-show");
        element.textContent = "";
      }
    },
    [popup]
  );

  const history = useHistory();
  const onClick = useCallback(
    ({ feature }) => {
      const path =
        ROUTES.OBJECT.PATH +
        "/" +
        feature.get("object").id +
        ROUTES.OBJECT.INFO.PATH;

      if (history.location.pathname !== path) {
        history.push(path);
      }
    },
    [history]
  );

  return (
    <div className="objects-map-container">
      <Map
        center={CENTER}
        initialZoom={10}
        showCadasterLayers={true}
        objectIcons={{ items: objects, onHover, onClick }}
        overlays={[popup]}
      />
    </div>
  );
};

ObjectsMap.propTypes = {
  objects: PropTypes.array,
};

export default ObjectsMap;
