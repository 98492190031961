import React from "react";
import PropTypes from "prop-types";

import "./AddObjectSectionsList.scss";
import AddObjectSection from "./components/AddObjectSection/AddObjectSection";

const AddObjectSectionsList = (props) => {
  return (
    <ul className="add-object-sections-list">
      {props.sections && props.sections.map((section) => {
        return (
          <li key={section.sectionId} className="add-object-sections-list-item">
            <AddObjectSection
              onStageSave={(value) =>
                props.onStageSave(section.sectionId, value)
              }
              onStageDelete={(value) =>
                props.onStageDelete(section.sectionId, value)
              }
              section={section}
            />
          </li>
        );
      })}
    </ul>
  );
};

AddObjectSectionsList.propTypes = {
  sections: PropTypes.array,
  onStageSave: PropTypes.func,
  onStageDelete: PropTypes.func,
};

export default AddObjectSectionsList;
