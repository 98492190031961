import {
  USER_GET_REQUEST,
  USER_GET_SUCCESS,
  USER_GET_ERROR,
  USER_CLEAR,
  USER_DISABLE_HINTS,
} from "../reducers/user.js";

import api from "./api";

export function GetUserByTokenAction(headerName, token) {
  return (dispatch) => {
    dispatch({
      type: USER_GET_REQUEST,
    });

    return fetch(api + "/v1/user/index", {
      method: "GET",
      headers: {
        [headerName]: token,
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response._meta.isSuccessful) {
          dispatch({
            type: USER_GET_SUCCESS,
            payload: response,
          });
          return response.data;
        }

        dispatch({
          type: USER_GET_ERROR,
          payload: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_GET_ERROR,
          payload: error,
        });
      });
  };
}

export function disableUserHints() {
  return (dispatch) => {
    const tokenName = localStorage.getItem("token_name");
    const token = localStorage.getItem(tokenName);

    return fetch(api + "/v1/user/disable-hints", {
      method: "GET",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: USER_DISABLE_HINTS,
        });

        if (response._meta.isSuccessful) {
          return "success";
        }

        return response.data.message;
      })
      .catch(() => {
        dispatch({
          type: USER_DISABLE_HINTS,
        });
      });
  };
}

export function clearUser() {
  return {
    type: USER_CLEAR,
  };
}

export const editUserInfo = (data) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  let info = {
    user_email: data.mail,
    profile_phone_number: data.contact,
    profile_fio: data.name,
    have_notify: data.have_notify,
    have_notify_email: data.have_notify_email,
    organization_name: data.organization,
  };

  return fetch(api + `/v1/user/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify(info),
  })
    .then((response) => response.json())
    .then((r) => {
      if (r._meta.isSuccessful) {
        return "success";
      } else {
        return r.data.message;
      }
    });
};

export const editOrganizationInfo = (info, id) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  return fetch(api + `/v1/organization/${id}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify(info),
  })
    .then((response) => response.json())
    .then((r) => {
      if (r._meta.isSuccessful) {
        return "success";
      } else {
        return r.data.message;
      }
    });
};

export const editUserPassword = (passwordOld, passwordNew) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  let data = {
    user_current_password: passwordOld,
    user_password: passwordNew,
  };
  return fetch(api + `/v1/user/update-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((r) => {
      if (r._meta.isSuccessful) {
        return true;
      } else {
        return r.data.message;
      }
    });
};

export const getUserManual = () => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  return fetch(api + `/v1/document/download/manual`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
  });
};

export function deleteUser(userId) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/user/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify({user_id: userId}),
  })
      .then((r) => r.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          return "success";
        } else {
          return data.data.message[0];
        }
      }).catch((er) => er);
}
