import React, { useCallback, useEffect } from "react";
import PropTypes from 'prop-types';

import "./ContractorList.scss"

import ModalWindow from "../../../../components/ModalWindow";
import Contractors from "./components/Contractors";
import { useDispatch, useSelector } from "react-redux";
import { fetchObjectContractors } from "../../../../actions/objectContractorsActions";

const ContractorList = (props) => {

  const dispatch = useDispatch();
  const contractorsStore = useSelector(store => store.objectsContractors)

  const getContractors = useCallback(
    () => dispatch(fetchObjectContractors(props.objectId)),
    [dispatch, props.objectId]
  )

  useEffect(() => {
    if (props.isActive && !contractorsStore.isUpdated && !contractorsStore.isFetching) {
      getContractors()
    }
  }, [getContractors, props.isActive, contractorsStore.isFetching, contractorsStore.isUpdated])

  return(
    <ModalWindow
      isActive={props.isActive}
      onClose={props.onClose}
      label="Выбор подрядчика"
      maxWidth={1080}
      maxHeight={1000}
    >
      <div className="contractors">
        <Contractors
          contractors={contractorsStore.contractors}
          onChooseContractor={props.onSetContractor}
        />
      </div>
    </ModalWindow>
  )
}

ContractorList.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  objectId: PropTypes.number,
  onSetContractor: PropTypes.func,
}

export default ContractorList;