import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import "./AddObjectInfo.scss";

import ExpandablePanel from "../../../../components/ExpandablePanel";
import FileSolo from "../../../../components/FileSolo";
import FileList from "../../../../components/FileList";
import AddObjectForm from "./components/AddObjectForm";
import AddObjectUser from "./components/AddObjectUser";
import AddObjectMap from "./components/AddObjectMap";
import {
  uploadFiles,
  updateFile,
  removeUserFromFile,
} from "../../../../actions/AddObjectActions";
import Paginator from "../../../../components/Paginator";
import toaster from "toasted-notes";
import {
  inlineUnnown,
  inlineXls,
  inlineXlsx,
} from "../../../../components/ObjectEstimates/img/inlineSVGicons";
import { inlinePdf } from "../../../../components/ObjectDesignProject/img/inlineSVGicons";
import CheckPermissions from "../../../../components/CheckPermissions";
import AddObjectArchive from "./components/AddObjectArchive";
import { ADD_OBJECT_DELETE_FILE } from "../../../../reducers/objectAdd";
import { UserInputsData, OrgInputsData } from "../../../../assets/js/constants";
import { required, mail } from "../../../../assets/js/validators";

const AddObjectInfo = (props) => {
  const dispatch = useDispatch();

  const postFiles = (type, files) =>
    dispatch(uploadFiles(type, files)).then((r) => {
      if (r) {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
    });

  const updFile = (type, file) =>
    dispatch(updateFile(type, file)).then((r) => {
      if (r) {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
    });

  const removeFile = (type, estimateID, userRole) =>
    dispatch(removeUserFromFile(type, { id: estimateID, role: userRole })).then(
      (r) => {
        if (r) {
          toaster.notify(
            <div className="error-toast">{JSON.stringify(r)}</div>,
            {
              position: "top-right",
              duration: 2000,
            }
          );
        }
      }
    );

  const [currentPage, setCurrentPage] = useState({
    estimates: 1,
    projectEstimates: 1,
  });
  let [pageSize] = useState(8);
  const total = {
    estimates: props.info.estimates.items.length,
    projectEstimates: props.info.projectEstimates.items.length,
  };

  const totalPages = {
    estimates:
      Math.ceil(total.estimates / pageSize) || (total.estimates ? 1 : 1),
    projectEstimates:
      Math.ceil(total.projectEstimates / pageSize) ||
      (total.projectEstimates ? 1 : 1),
  };

  const getIcon = (ext) => {
    switch (ext) {
      case ".xls": {
        return inlineXls;
      }
      case ".xlsx": {
        return inlineXlsx;
      }
      case ".pdf": {
        return inlinePdf;
      }
      default: {
        return inlineUnnown;
      }
    }
  };

  const getFiles = (type) => {
    let paginationFiles = {
      estimates: [],
      projectEstimates: [],
    };
    let totalId =
      currentPage[type] * pageSize > total[type]
        ? total[type]
        : currentPage[type] * pageSize;
    for (let i = (currentPage[type] - 1) * pageSize; i < totalId; i++) {
      paginationFiles[type].push(props.info[type].items[i]);
      paginationFiles[type][i].icon = getIcon(
        paginationFiles[type][i].extension
      );
    }
    return paginationFiles[type];
  };

  const pageChange = useCallback(
    (type, page) => {
      setCurrentPage({
        ...currentPage,
        [type]: page,
      });
    },
    [currentPage]
  );

  const onInfoChange = props.onChange;
  const onPointSet = useCallback(
    ({ longitude, latitude }) => {
      onInfoChange("longitude", longitude);
      onInfoChange("latitude", latitude);
    },
    [onInfoChange]
  );
  const onPolygonsChange = useCallback(
    (polygons) => onInfoChange("polygons", polygons),
    [onInfoChange]
  );

  const deleteFile = useCallback(
    (type, selected) => {
      dispatch({
        type: ADD_OBJECT_DELETE_FILE,
        payload: {
          type: type,
          ids: selected,
        },
      });
    },
    [dispatch]
  );

  return (
    <div className="add-object-info-container">
      <AddObjectMap
        longitude={props.info.info.longitude}
        latitude={props.info.info.latitude}
        onPointSet={onPointSet}
        polygons={props.info.info.polygons ?? []}
        onPolygonsChange={onPolygonsChange}
      />

      <div className="content-card">
        <AddObjectUser
          info={props.info.info}
          type="customer"
          title="Заказчик"
          btnLabel="Назначить заказчика"
          onChange={props.onChange}
          onValidationChange={props.onValidationChange}
          userInputs={[
            { ...UserInputsData.NAME, validators: [required] },
            { ...UserInputsData.PHONE, validators: [required] },
            { ...UserInputsData.EMAIL, validators: [required, mail] },
            { ...UserInputsData.ADDITIONAL_INFO, withoutAdditionalLabel: true },
          ]}
        />
      </div>

      <div className="content-card">
        <AddObjectUser
          info={props.info.info}
          type="architect"
          title="Разработчик дизайн-проекта"
          btnLabel="Назначить архитектора"
          withUserCreator
          onChange={props.onChange}
          onValidationChange={props.onValidationChange}
          userInputs={[
            { ...UserInputsData.NAME, validators: [required] },
            { ...UserInputsData.PHONE, validators: [required] },
            { ...UserInputsData.EMAIL, validators: [required, mail] },
            { ...UserInputsData.ADDITIONAL_INFO, withoutAdditionalLabel: true },
          ]}
          orgInputs={[
            { ...OrgInputsData.NAME, withoutAdditionalLabel: true },
            { ...OrgInputsData.CONTACTS, withoutAdditionalLabel: true },
            { ...OrgInputsData.ADDRESS, withoutAdditionalLabel: true },
          ]}
        />
      </div>

      <div className="content-card">
        <AddObjectUser
          info={props.info.info}
          type="designer"
          title="Разработчик проектно-сметной документации"
          btnLabel="Назначить проектировщика"
          withUserCreator
          onChange={props.onChange}
          onValidationChange={props.onValidationChange}
          userInputs={[
            { ...UserInputsData.NAME, validators: [required] },
            { ...UserInputsData.PHONE, validators: [required] },
            { ...UserInputsData.EMAIL, validators: [required, mail] },
            { ...UserInputsData.ADDITIONAL_INFO, withoutAdditionalLabel: true },
          ]}
          orgInputs={[
            { ...OrgInputsData.NAME, withoutAdditionalLabel: true },
            { ...OrgInputsData.CONTACTS, withoutAdditionalLabel: true },
            { ...OrgInputsData.ADDRESS, withoutAdditionalLabel: true },
          ]}
        />
      </div>

      <div className="content-card">
        {/*<ExpandablePanel label="Сметный расчет">*/}
        <div className="content-card__head">
          <h3 className="content-card__title">Сметный расчет</h3>
        </div>
        <FileList
          files={getFiles("estimates")}
          onUpload={(files) => postFiles("estimates", files)}
          label="Добавить файлы (.xls, .xlsx)"
          onDelete={(selected) => deleteFile("estimates", selected)}
          onUpdate={(estimate) => updFile("estimates", estimate)}
          onRemove={(id, role) => removeFile("estimates", id, role)}
          format=".xls, .xlsx"
          isEstimate={true}
        />
        {total.estimates > pageSize ? (
          <Paginator
            currentPage={currentPage.estimates}
            pageSize={pageSize}
            total={total.estimates}
            totalPages={totalPages.estimates}
            onPageChange={(page) => pageChange("estimates", page)}
          />
        ) : null}
        {/*</ExpandablePanel>*/}
      </div>

      <div className="content-card">
        {props.info.info.jobType.value === "Благоустройство" ? (
          <div className="content-card__item">
            <h3 className="content-card__title">
              Дизайн-проект / Дефектная ведомость
            </h3>
            <FileSolo
              file={
                props.info.designProject.items.length > 0
                  ? props.info.designProject.items[0]
                  : null
              }
              label="Добавить файл (.pdf)"
              onUpload={(files) => postFiles("designProject", files)}
              onDelete={(selected) => deleteFile("designProject", selected)}
              format=".pdf"
              icon={getIcon(".pdf")}
              helpMessage="Максимальный размер загружаемого файла - 200 мб"
            />
          </div>
        ) : null}

        <div className="content-card__item">
          <h3 className="content-card__title">Связь с архивными объектами</h3>
          <AddObjectArchive onSelectObjects={props.onChange} />
        </div>

        <div className="content-card__item">
          <h3 className="content-card__title">Проектная документация</h3>
          <FileList
            files={getFiles("projectEstimates")}
            onUpload={(files) => postFiles("projectEstimates", files)}
            onDelete={(selected) => deleteFile("projectEstimates", selected)}
            label="Добавить файлы (.pdf)"
            format=".pdf"
            helpMessage="Максимальный размер загружаемого файла - 200 мб"
          />
          {total.projectEstimates > pageSize ? (
            <Paginator
              currentPage={currentPage.projectEstimates}
              pageSize={pageSize}
              total={total.projectEstimates}
              totalPages={totalPages.projectEstimates}
              onPageChange={(page) => pageChange("projectEstimates", page)}
            />
          ) : null}
        </div>

        <div className="add-object-info-bottom-controls">
          <button onClick={props.onAddObject} className="add-object-info-save">
            Добавить объект
          </button>
        </div>
      </div>
    </div>
  );
};

AddObjectInfo.propTypes = {
  info: PropTypes.object,
  onChange: PropTypes.func,
  onAddObject: PropTypes.func,
  onValidationChange: PropTypes.func,
};

export default AddObjectInfo;
