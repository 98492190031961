import React from "react";
import "./InfoPopup.scss";
import { useSelector, useDispatch } from "react-redux";
import { closeInfoPopup } from "../../actions/InfoPopupAction";

const InfoPopup = (props) => {
  const popupStore = useSelector((store) => store.infoPopup);
  const dispatch = useDispatch();
  const closePopup = () => dispatch(closeInfoPopup());
  if (popupStore.isOpen) {
    return (
      <div className="info-popup" onClick={closePopup}>
        <div
          className="info-popup__content"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="info-popup__close"
            type="button"
            aria-label="Закрыть"
            onClick={closePopup}
          />
          <p className="info-popup__text">{popupStore.text}</p>
        </div>
      </div>
    );
  }
  return null;
};

export default InfoPopup;
