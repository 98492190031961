import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import "./FileCards.scss";
import {
  plusIcon,
  downloadIcon,
  deleteIcon,
} from "../../../../assets/img/sprite";
import FileCard from "../FileCard";
import EstimateCard from "../FileCard/EstimateCard";
import CheckPermissions from "../../../CheckPermissions";
import { useSelector } from "react-redux";
import { closeModal, openModal } from "../../../../actions/ModalAction";
import { ModalTypes } from "../../../../assets/js/constants";
import ConfirmAction from "../../../ConfirmAction";

const FileCards = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  const fileClick = (id) => {
    let newSelected = [...selected];
    if (newSelected.includes(id)) {
      newSelected.splice(newSelected.indexOf(id), 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const download = () => {
    props.onDownload(selected);
    setSelected([]);
  };

  const del = () => {
    props.onDelete(selected);
    setSelected([]);
    dispatch(closeModal());
  };

  const upload = (ev) => {
    let files = [];
    let fileList = ev.target.files;
    if (!fileList.length) return;

    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    props.onUpload(files);
  };

  const handleFilesDelete = () => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="Файл будет безвозвратно удален!"
            onCancel={() => dispatch(closeModal())}
            onConfirm={del}
          />
        ),
      })
    );
  };

  return (
    <div className="file-list-container">
      <div className="file-list-controls">
        <CheckPermissions permissions={["perm_document_upload"]}>
          {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
            <label onChange={upload} className="file-list-upload">
              {plusIcon}
              <span>Добавить</span>
              <input
                multiple
                className="file-list-upload-input"
                type="file"
                accept={props.format}
              />
            </label>
          ) : null}
        </CheckPermissions>
        {props.onDownload ? (
          <button
            disabled={!selected.length}
            onClick={download}
            className="file-list-download"
          >
            {downloadIcon}
            {!isMobileSize && <span>Загрузить</span>}
          </button>
        ) : null}
        {props.onDelete &&
        props.status !== "WORKS_DONE" &&
        props.status !== "STOPPED" ? (
          <button
            disabled={!selected.length}
            onClick={handleFilesDelete}
            className="file-list-delete _error"
          >
            {deleteIcon}
            {!isMobileSize && <span>Удалить</span>}
          </button>
        ) : null}
      </div>
      <ul className={`file-list ${props.isEstimate ? "_estimate" : ""}`}>
        {!props.isEstimate || isMobileSize ? null : (
          <li className="estimate-card-head">
            <span>Смета</span>
            <span>Подрядчик</span>
            <span>Стройконтроль</span>
          </li>
        )}
        {props.files.map((file) => {
          return (
            <li key={file.id} className="file-list-file-card">
              {props.isEstimate ? (
                <EstimateCard
                  id={file.id}
                  name={file.name}
                  size={file.size}
                  icon={file.icon}
                  builder={{
                    id: file.construction_object_builder_id || "",
                    name: file.construction_object_builder_name || "",
                    email: file.construction_object_builder_email || "",
                    phone: file.construction_object_builder_phone_number || "",
                    additionalInfo:
                      file.construction_object_builder_contact_info || "",
                    orgName:
                      file.construction_object_builder_organization_name || "",
                    orgContacts:
                      file.construction_object_builder_organization_contacts ||
                      "",
                    orgAddress:
                      file.construction_object_builder_organization_address ||
                      "",
                  }}
                  control={{
                    id: file.construction_object_construction_control_id || "",
                    name:
                      file.construction_object_construction_control_name || "",
                    email:
                      file.construction_object_construction_control_email || "",
                    phone:
                      file.construction_object_construction_control_phone_number ||
                      "",
                    additionalInfo:
                      file.construction_object_construction_control_contact_info ||
                      "",
                    orgName:
                      file.construction_object_construction_control_organization_name ||
                      "",
                    orgContacts:
                      file.construction_object_construction_control_organization_contacts ||
                      "",
                    orgAddress:
                      file.construction_object_construction_control_organization_address ||
                      "",
                  }}
                  onClick={() => fileClick(file.id)}
                  selected={selected.includes(file.id)}
                  onUpdate={props.onUpdate}
                  onRemove={props.onRemove}
                />
              ) : (
                <FileCard
                  name={file.name}
                  size={file.size}
                  icon={file.icon}
                  onClick={() => fileClick(file.id)}
                  selected={selected.includes(file.id)}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FileCards.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      icon: PropTypes.any,
      size: PropTypes.string,
    })
  ),
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  isEstimate: PropTypes.bool,
};

export default FileCards;
