import React, { useCallback, useEffect } from "react";

import "./UserConfirmationContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  applyUser,
  denyUser,
} from "../../actions/UserConfirmationActions";
import UserCard from "./components/UserCard";
import Loader from "../../components/Loader";
import Paginator from "../../components/Paginator";

const UserConfirmationContainer = () => {
  const confirmStore = useSelector((store) => store.userConfirmation);
  const dispatch = useDispatch();

  const getUsers = useCallback(
    (page) => {
      dispatch(fetchUsers(page));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !confirmStore.isUpdated &&
      !(confirmStore.isFetching || confirmStore.isError)
    ) {
      getUsers();
    }
  }, [
    getUsers,
    confirmStore.isError,
    confirmStore.isFetching,
    confirmStore.isUpdated,
  ]);

  const setApplyUser = (uid) => {
    applyUser(uid).then(() => {
      getUsers();
    });
  };
  const setDenyUser = (uid) => {
    denyUser(uid).then(() => {
      getUsers();
    });
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          {confirmStore.isFetching ? <Loader /> : null}
          <h1 className="confirm-header">Заявки</h1>

          {confirmStore.items.length ? (
            confirmStore.items.map((item) => {
              return (
                <div className="confirm-card" key={item.user_profile_id}>
                  <UserCard
                    item={item}
                    onApply={() => setApplyUser(item.user_profile_id)}
                    onDeny={() => setDenyUser(item.user_profile_id)}
                  />
                </div>
              );
            })
          ) : (
            <div className="no-notes">Нет заявок</div>
          )}
          {confirmStore.pagination.totalCount >
          confirmStore.pagination.pageSize ? (
            <Paginator
              currentPage={confirmStore.pagination.currentPage}
              totalPages={confirmStore.pagination.totalPageCount}
              total={confirmStore.pagination.totalCount}
              pageSize={confirmStore.pagination.pageSize}
              onPageChange={getUsers}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserConfirmationContainer;
