import React, { useState, useEffect, useCallback, useMemo } from "react";

import Draw from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeometryType from "ol/geom/GeometryType";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import { fromLonLat, toLonLat } from "ol/proj";
import Feature from "ol/Feature";

const POLYGON_FILL = new Fill({ color: "rgba(255, 255, 255, 0.4)" });
const POLYGON_STROKE = new Stroke({ color: "#3399CC", width: 1.25 });
const POLYGON_STYLE = new Style({ fill: POLYGON_FILL, stroke: POLYGON_STROKE });

const DELETING_POLYGON_STROKE = new Stroke({ color: "#FF3535", width: 1.25 });
const DELETING_POLYGON_STYLE = new Style({
  fill: POLYGON_FILL,
  stroke: DELETING_POLYGON_STROKE,
});

const useLayer = (map) => {
  const [source] = useState(() => new VectorSource());

  const [layer] = useState(() => new VectorLayer({ source }));
  useEffect(() => {
    map.addLayer(layer);
    return () => map.removeLayer(layer);
  }, [map, layer]);

  return source;
};

const useDrawInteraction = ({ map, type, source, handleDrawEnd }) => {
  const [drawInteraction, setDrawInteraction] = useState(null);

  useEffect(() => {
    if (!drawInteraction) {
      return;
    }

    map.addInteraction(drawInteraction);
    return () => map.removeInteraction(drawInteraction);
  }, [map, drawInteraction]);

  const start = useCallback(
    () =>
      setDrawInteraction((interaction) => {
        // eslint-disable-next-line no-unused-expressions
        interaction?.abortDrawing();
        return new Draw({ type, source, stopClick: true });
      }),
    [type, source]
  );
  const abort = useCallback(() => {
    setDrawInteraction((interaction) => {
      // eslint-disable-next-line no-unused-expressions
      interaction?.abortDrawing();
      return null;
    });
  }, []);

  useEffect(() => {
    if (!drawInteraction) {
      return;
    }

    const listener = (event) => {
      // eslint-disable-next-line no-unused-expressions
      handleDrawEnd?.(event);
      abort();
    };
    drawInteraction.on("drawend", listener);
    return () => drawInteraction.un("drawend", listener);
  }, [abort, drawInteraction, handleDrawEnd]);

  return { start, abort, isActive: drawInteraction !== null };
};

const useDrawPoint = ({ map, point, onChange }) => {
  const source = useLayer(map);

  const handleDrawEnd = useCallback(
    (event) => {
      const coordinates = event.feature.getGeometry().getCoordinates();
      const [longitude, latitude] = toLonLat(coordinates);
      onChange({ longitude, latitude });
    },
    [onChange]
  );

  const { start, abort, isActive } = useDrawInteraction({
    map,
    type: GeometryType.POINT,
    source,
    handleDrawEnd,
  });

  useEffect(() => {
    if (!point || isActive) {
      return;
    }

    const geometry = new Point(fromLonLat([point.longitude, point.latitude]));
    source.addFeature(new Feature({ geometry }));
    return () => source.clear(true);
  }, [source, isActive, point]);

  return { startPoint: start, abortPoint: abort };
};

const useDrawPolygons = ({ map, polygons, onChange }) => {
  const source = useLayer(map);
  useEffect(() => {
    source.clear(true);
    source.addFeatures(polygons ?? []);

    const handleChange = () => onChange(source.getFeatures());

    source.on("addfeature", handleChange);
    source.on("removefeature", handleChange);
    return () => {
      source.un("addfeature", handleChange);
      source.un("removefeature", handleChange);
    };
  }, [onChange, polygons, source]);

  const { start, abort } = useDrawInteraction({
    map,
    type: GeometryType.POLYGON,
    source,
  });

  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    if (!isRemoving) {
      return;
    }

    let previousHoveredPolygon = null;

    const handlePointerMove = (event) => {
      const coordinate = map.getCoordinateFromPixel(event.pixel);

      const hoveredPolygon =
        source
          .getFeaturesAtCoordinate(coordinate)
          .filter((feature) => feature.getGeometry() instanceof Polygon)
          .pop() ?? null;

      if (hoveredPolygon !== previousHoveredPolygon) {
        // eslint-disable-next-line no-unused-expressions
        previousHoveredPolygon?.setStyle(POLYGON_STYLE);
        // eslint-disable-next-line no-unused-expressions
        hoveredPolygon?.setStyle(DELETING_POLYGON_STYLE);
        previousHoveredPolygon = hoveredPolygon;
      }
    };

    const handleRemoval = () => {
      if (previousHoveredPolygon) {
        source.removeFeature(previousHoveredPolygon);
      }

      setIsRemoving(false);
    };

    map.on("click", handleRemoval);
    map.on("pointermove", handlePointerMove);

    return () => {
      map.un("click", handleRemoval);
      map.un("pointermove", handlePointerMove);

      // eslint-disable-next-line no-unused-expressions
      previousHoveredPolygon?.setStyle(POLYGON_STYLE);
    };
  }, [isRemoving, source, map, onChange]);

  const removePolygon = useCallback(() => setIsRemoving(true), []);
  const abortRemovingPolygon = useCallback(() => setIsRemoving(false), []);

  const clearPolygons = useCallback(() => source.clear(true), [source]);

  const drawnArea = useMemo(() => {
    if (!polygons) {
      return 0;
    }

    return polygons.reduce(
      (sum, polygon) => sum + polygon.getGeometry().getArea(),
      0
    );
  }, [polygons]);

  return {
    startPolygon: start,
    abortPolygon: abort,
    removePolygon,
    abortRemovingPolygon,
    clearPolygons,
    polygonArea: drawnArea,
  };
};

const useDrawnObjects = ({ map, point, polygons, canDraw }) => {
  const [currentAction, setCurrentAction] = useState(null);

  const { onChange: onPolygonsChange } = polygons ?? {};
  const {
    startPolygon,
    abortPolygon,
    removePolygon,
    abortRemovingPolygon,
    clearPolygons,
    polygonArea,
  } = useDrawPolygons({
    map,
    polygons: polygons?.items,
    onChange: useCallback(
      (features) => {
        setCurrentAction(null);
        // eslint-disable-next-line no-unused-expressions
        onPolygonsChange?.(features);
      },
      [onPolygonsChange]
    ),
  });

  const { onChange: onPointChange } = point ?? {};
  const { startPoint, abortPoint } = useDrawPoint({
    map,
    point: point?.coordinates,
    onChange: useCallback(
      (coordinates) => {
        setCurrentAction(null);
        // eslint-disable-next-line no-unused-expressions
        onPointChange?.(coordinates);
      },
      [onPointChange]
    ),
  });

  const abort = useCallback(() => {
    if (currentAction === "addPoint") {
      abortPoint();
    } else if (currentAction === "addPolygon") {
      abortPolygon();
    } else if (currentAction === "removePolygon") {
      abortRemovingPolygon();
    }
  }, [abortPoint, abortPolygon, abortRemovingPolygon, currentAction]);

  const handleAddPoint = useCallback(() => {
    abort();
    setCurrentAction((currentAction) => {
      if (currentAction === "addPoint") {
        return null;
      }

      startPoint();
      return "addPoint";
    });
  }, [abort, startPoint]);

  const handleAddPolygon = useCallback(() => {
    abort();
    setCurrentAction((currentAction) => {
      if (currentAction === "addPolygon") {
        return null;
      }

      startPolygon();
      return "addPolygon";
    });
  }, [abort, startPolygon]);

  const handleRemovePolygon = useCallback(() => {
    abort();
    setCurrentAction((currentAction) => {
      if (currentAction === "removePolygon") {
        return null;
      }

      removePolygon();
      return "removePolygon";
    });
  }, [abort, removePolygon]);

  const handleClearPolygons = useCallback(() => {
    abort();
    clearPolygons();
    // eslint-disable-next-line no-unused-expressions
    onPolygonsChange?.([]);
    setCurrentAction(null);
  }, [abort, clearPolygons, onPolygonsChange]);

  const areaInSquaredKilometers = polygonArea / 1e6;
  const roundedAreaValue = Math.round(areaInSquaredKilometers * 1e3) / 1e3;
  const areaElement = (
    <span className="map-draw-area">
      Общая площадь: {roundedAreaValue} км<sup>2</sup>
    </span>
  );

  const drawControls = (
    <div className="map-draw">
      {canDraw ? (
        <>
          <button
            type="button"
            className={
              currentAction === "addPoint"
                ? "map-draw-button-selected _add-mark"
                : "map-draw-button _add-mark"
            }
            onClick={handleAddPoint}
          >
            {point.coordinates ? "Изменить метку" : "Добавить метку"}
          </button>
          {areaElement}
          <button
            type="button"
            className={
              currentAction === "addPolygon"
                ? "map-draw-button-selected _add-polygon"
                : "map-draw-button _add-polygon"
            }
            onClick={handleAddPolygon}
          >
            Добавить полигон
          </button>
          <button
            type="button"
            className={
              (currentAction === "removePolygon"
                ? "map-draw-button-selected _remove-polygon"
                : "map-draw-button _remove-polygon") + " -destructive"
            }
            onClick={handleRemovePolygon}
            disabled={(polygons?.items?.length ?? 0) === 0}
          >
            Удалить полигон
          </button>
          <button
            type="button"
            className="map-draw-button _remove-polygons -destructive"
            onClick={handleClearPolygons}
            disabled={(polygons?.items?.length ?? 0) === 0}
          >
            Удалить все полигоны
          </button>
        </>
      ) : (
        areaElement
      )}
    </div>
  );

  return { drawControls, isDrawing: currentAction !== null };
};

export default useDrawnObjects;
