import api from './api'
import { CHOOSE_USER_ERROR, CHOOSE_USER_REQUEST, CHOOSE_USER_SUCCESS } from "../reducers/chooseUser";
import { RIGHTS_ERROR } from "../reducers/userRights";

export function fetchCustomersOrBuilders(filters, page, type, objectId) {
  return (dispatch) => {
    dispatch({
      type: CHOOSE_USER_REQUEST
    })

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: RIGHTS_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    if (filters) {
      if (
        filters.filter((item) => item.value === "").length === filters.length
      ) {
        filters = undefined;
      }
    }

    fetch(api + `/v1/user/${type}`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      method: 'POST',
      body: JSON.stringify({page: page, filter: filters, construction_object_id: objectId})
    })
      .then((response) => response.json())
      .then((data) => {
        if(!data._meta.isSuccessful) {
          dispatch({
            type: CHOOSE_USER_ERROR
          })
          return
        }

        dispatch({
          type: CHOOSE_USER_SUCCESS,
          payload: {
            items: data.data.items,
            pagination: data.data.pagination
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: RIGHTS_ERROR,
          payload: error,
        });
      });

  }
}

export function setUserToObject(type, objectId, uid) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(api + `/v1/object/${objectId}/assign/${type}`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
    body: JSON.stringify({profile_id: uid})
  })
    .then(response => response.json())
    .then(data => {
      if (!data._meta.isSuccessful) {
        return data.data.message;
      }
      return 'success'
    })
}