import React from "react";

import "./Loader.scss";

function Loader(props) {
  return <div className="loader-container" style={{width: props.width || 40, height: props.width || 40}}>
    <svg viewBox="0 0 80 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74 40C77.3137 40 80.0461 37.2989 79.5508 34.0224C78.7992 29.0494 77.1136 24.2443 74.5641 19.8673C71.0039 13.7551 65.8866 8.69631 59.7338 5.20665C53.5811 1.71699 46.613 -0.0787084 39.5399 0.00264568C32.4669 0.0839997 25.542 2.03949 19.4711 5.66975C13.4003 9.30001 8.40066 14.4752 4.98199 20.6677C1.56332 26.8602 -0.152115 33.8484 0.0105824 40.92C0.17328 47.9916 2.20829 54.8936 5.90813 60.9223C8.5576 65.2395 11.993 68.9983 16.0183 72.0137C18.6704 74.0004 22.3429 72.8716 23.9109 69.9523C25.479 67.0331 24.3268 63.4379 21.8078 61.2849C19.5908 59.39 17.674 57.1523 16.1357 54.6456C13.5458 50.4255 12.1213 45.5942 12.0074 40.644C11.8935 35.6939 13.0943 30.8021 15.4874 26.4674C17.8805 22.1326 21.3802 18.51 25.6298 15.9688C29.8794 13.4276 34.7269 12.0588 39.678 12.0019C44.6291 11.9449 49.5067 13.2019 53.8137 15.6447C58.1206 18.0874 61.7027 21.6286 64.1949 25.9071C65.6751 28.4485 66.7394 31.196 67.3596 34.0457C68.0643 37.2837 70.6863 40 74 40Z" fill="#2EAADC"/>
    </svg>
  </div>;
}

export default Loader;
