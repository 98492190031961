import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import "./StageCard.scss";
import EditableField from "../../../../../../components/EditableField";
import EditableDateField from "../../../../../../components/EditableDateField";
import CircleProgress from "../../../../../../components/CircleProgress";
import Status from "../../../../../../components/Status";
import {
  editIcon,
  deleteIcon,
  checkIcon,
  closeIcon,
} from "../../../../../../assets/img/sprite";
import { UserContext } from "../../../../../../App";

const StageCard = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [changedStage, setChangedStage] = useState(null);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const { isMobileSize, isDesktopSize } = useSelector((store) => store.responsive.window);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (isEditable) {
      setChangedStage(props.item);
      return;
    }
    setChangedStage(null);
  }, [isEditable, props.item]);

  const change = (name, value) => {
    if (name === "requiredAmount") {
      value = value.toString().replace("-", "");
      value = value.replace(",", ".");
      if (
        item.requiredAmount.toString()[0] === "-" &&
        value[0].toString() !== "-"
      ) {
        props.onChangeAmount();
      }
    }
    if (name === "unit") {
      if (item.unit === "" && value.toString() !== "") {
        props.onChangeUnit();
      }
    }
    setChangedStage({
      ...changedStage,
      [name]: value,
    });
  };

  const isHavePermission = (permission) => {
    return !!userContext.permissions.filter((perm) => {
      return perm.name === permission;
    }).length;
  };

  const save = () => {
    props.onSave(changedStage);
    setIsEditable(false);
  };

  let item = changedStage || props.item;
  return (
    <div className="stages-item">
      <div
        onClick={
          !isEditable && +item.isExist === 1
            ? () => props.onClick(props.item.id)
            : null
        }
        className={
          +item.isExist === 1 ? "stages-name-active" : "stages-name-disabled"
        }
        data-tip
        data-for={"stagesItemName" + props.item.id}
      >
        <EditableField
          value={item.name}
          onChange={(ev) => change("name", ev.target.value)}
          isEditable={
            isEditable && isHavePermission("perm_task_update-extended")
          }
        />
      </div>
      <ReactTooltip
        multiline
        isCapture
        id={"stagesItemName" + props.item.id}
        type="dark"
        place="top"
        effect="float"
      >
        <span>{item.name}</span>
      </ReactTooltip>

      <div className="stages-plan-start">
        <div className="mobile-label">Начало работ</div>
        <EditableDateField
          isEditable={isEditable}
          onChange={(value) => change("planBeg", value)}
          value={item.planBeg}
        />
      </div>

      <div className="stages-plan-end">
        <div className="mobile-label">Конец работ</div>
        <EditableDateField
          isEditable={isEditable}
          onChange={(value) => change("planEnd", value)}
          value={item.planEnd}
        />
      </div>

      <div className="stages-status">
        <div className="mobile-label">Статус</div>
        <Status value={item.status} color={item.statusColor} />
      </div>

      <div className="stages-required-amount-block">
        <div className="mobile-label">Объем работ</div>
        <div className="stages-required-amount-block-items">
          <div
            className={
              item.requiredAmount <= 0
                ? "red-stages-text"
                : "stages-required-amount"
            }
            data-tip
            data-for={"stagesItemAmount" + props.item.id}
          >
            <EditableField
              isEditable={
                isEditable && isHavePermission("perm_task_update-extended")
              }
              onChange={(ev) => change("requiredAmount", ev.target.value)}
              value={item.requiredAmount}
            />
          </div>
          <div
            className={item.unit === "" ? "red-stages-text" : "stages-unit"}
            data-tip
            data-for={"stagesItemUnit" + props.item.id}
          >
            {/*<div className="mobile-label">Единицы измерения</div>*/}
            <EditableField
              isEditable={
                isEditable && isHavePermission("perm_task_update-extended")
              }
              onChange={(ev) => change("unit", ev.target.value)}
              value={item.unit}
            />
          </div>
        </div>
      </div>

      <ReactTooltip
        multiline
        isCapture
        id={"stagesItemAmount" + props.item.id}
        type="dark"
        place="top"
        effect="float"
      >
        <span>{item.coefficient}</span>
      </ReactTooltip>

      {/*<div*/}
      {/*  className={item.unit === "" ? "red-stages-text" : "stages-unit"}*/}
      {/*  data-tip*/}
      {/*  data-for={"stagesItemUnit" + props.item.id}*/}
      {/*>*/}
      {/*  <div className="mobile-label">Единицы измерения</div>*/}
      {/*  <EditableField*/}
      {/*    isEditable={*/}
      {/*      isEditable && isHavePermission("perm_task_update-extended")*/}
      {/*    }*/}
      {/*    onChange={(ev) => change("unit", ev.target.value)}*/}
      {/*    value={item.unit}*/}
      {/*  />*/}
      {/*</div>*/}

      {item.unit && item.unit.length > 10 ? (
        <ReactTooltip
          multiline
          isCapture
          id={"stagesItemUnit" + props.item.id}
          type="dark"
          place="top"
          effect="float"
        >
          <span>{item.unit}</span>
        </ReactTooltip>
      ) : null}

      <div className="stages-percentage">
        <div className="mobile-label">Прогресс</div>
        {isDesktopSize ? (
          <CircleProgress value={+item.completion} color={item.statusColor} />
        ) : (
          <div>{+item.completion + "%"}</div>
        )}
      </div>

      <div className="stages-control">
        {!isEditable &&
        isHavePermission("perm_construction-object_update-card") &&
        props.status !== "STOPPED" &&
        props.status !== "WORKS_DONE" ? (
          <button
            onClick={() => setIsEditable(true)}
            className="stages-control-edit"
            disabled={isAvailable}
          >
            {editIcon}
              {isMobileSize && <span>Редактировать</span>}
          </button>
        ) : null}

        {(!props.item.isExist ||
          props.item.isExist === "0" ||
          isHavePermission("perm_task_delete") ||
          isHavePermission("extended_permission")) &&
        props.status !== "STOPPED" &&
        props.status !== "WORKS_DONE" &&
        !isEditable ? (
          <button
            className="stages-control-delete _error"
            onClick={() =>
              props.onDelete(item.id, item.requiredAmount, item.unit)
            }
            disabled={isAvailable}
          >
            {deleteIcon}
              {isMobileSize && <span>Удалить</span>}
          </button>
        ) : null}

        {isEditable &&
        isHavePermission("perm_construction-object_update-card") ? (
          <button
            onClick={save}
            className="stages-control-save"
            disabled={
              changedStage &&
              !(
                (!changedStage.planEnd && !changedStage.planBeg) ||
                (changedStage.planEnd && changedStage.planBeg)
              )
            }
          >
            {checkIcon}
              {isMobileSize && <span>Сохранить</span>}
          </button>
        ) : null}

        {isEditable &&
        isHavePermission("perm_construction-object_update-card") ? (
          <button
            onClick={() => setIsEditable(false)}
            className="stages-control-cancel _error"
          >
            {closeIcon}
              {isMobileSize && <span>Отменить</span>}
          </button>
        ) : null}
      </div>
    </div>
  );
};

StageCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    sectionName: PropTypes.string,
    sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    completion: PropTypes.string,
    status: PropTypes.string,
    statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statusColor: PropTypes.string,
    factBeg: PropTypes.string,
    factEnd: PropTypes.string,
    planBeg: PropTypes.string,
    planEnd: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

export default StageCard;
