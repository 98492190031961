import moment from "moment";

export const ADD_OBJECT_UPLOAD_FILE_REQUEST = "ADD_OBJECT_UPLOAD_FILE_REQUEST";
export const ADD_OBJECT_UPLOAD_FILE_SUCCESS = "ADD_OBJECT_UPLOAD_FILE_SUCCESS";
export const ADD_OBJECT_UPLOAD_FILE_ERROR = "ADD_OBJECT_UPLOAD_FILE_ERROR";
export const ADD_OBJECT_UPDATE_FILE_REQUEST = "ADD_OBJECT_UPDATE_FILE_REQUEST";
export const ADD_OBJECT_UPDATE_FILE_SUCCESS = "ADD_OBJECT_UPDATE_FILE_SUCCESS";
export const ADD_OBJECT_UPDATE_FILE_ERROR = "ADD_OBJECT_UPDATE_FILE_ERROR";
export const ADD_OBJECT_DELETE_FILE = "ADD_OBJECT_DELETE_FILE";
export const ADD_OBJECT_REQUEST = "ADD_OBJECT_REQUEST";
export const ADD_OBJECT_SUCCESS = "ADD_OBJECT_SUCCESS";
export const ADD_OBJECT_LINK_SUCCESS = "ADD_OBJECT_LINK_SUCCESS";
export const ADD_OBJECT_ERROR = "ADD_OBJECT_ERROR";
export const ADD_OBJECT_CHANGE_INFO = "ADD_OBJECT_CHANGE_INFO";
export const ADD_OBJECT_CHANGE_STAGE = "ADD_OBJECT_CHANGE_STAGE";
export const ADD_OBJECT_DELETE_STAGE = "ADD_OBJECT_DELETE_STAGE";
export const ADD_OBJECT_SET_INITIAL = "ADD_OBJECT_SET_INITIAL";

const initialState = {
  isFetching: false,
  isError: false,
  isUpdated: false,
  errors: null,
  archive: {
    items: [],
    pagination: {},
  },
  sections: {
    isFetching: false,
    isError: false,
    errors: [],
    items: [],
  },
  info: {
    //Информация
    name: "",
    address: "",
    description: "",
    longitude: "",
    latitude: "",
    planBeg: moment().format("DD.MM.YYYY"),
    planEnd: moment().format("DD.MM.YYYY"),
    refVideo: "",
    jobType: {
      key: null,
      value: "",
    },
    objectType: {
      key: null,
      value: "",
    },
    nationalObjectType: {
      key: null,
      value: "",
    },
    //Заказчик
    customerId: "",
    customerName: "",
    customerPhone: "",
    customerAdditionalInfo: "",
    customerEmail: "",
    //Исполнитель
    contractorId: "",
    contractorName: "",
    contractorPhone: "",
    contractorAdditionalInfo: "",
    contractorEmail: "",
    contractorOrgAddress: "",
    contractorOrgContacts: "",
    contractorOrgName: "",
    //Проектировщик
    designerId: "",
    designerName: "",
    designerPhone: "",
    designerAdditionalInfo: "",
    designerEmail: "",
    designerOrgAddress: "",
    designerOrgContacts: "",
    designerOrgName: "",
    //Архитектор
    architectId: "",
    architectName: "",
    architectPhone: "",
    architectAdditionalInfo: "",
    architectEmail: "",
    architectOrgAddress: "",
    architectOrgContacts: "",
    architectOrgName: "",
  },
  estimates: {
    isFetching: false,
    isError: false,
    errors: [],
    itemsId: [],
    items: [],
  },
  projectEstimates: {
    isFetching: false,
    isError: false,
    errors: [],
    itemsId: [],
    items: [],
  },
  designProject: {
    isFetching: false,
    isError: false,
    errors: [],
    itemsId: [],
    items: [],
  },
};

const objectAdd = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OBJECT_CHANGE_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case ADD_OBJECT_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: null,
      };
    }
    case ADD_OBJECT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case ADD_OBJECT_LINK_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        archive: {
          ...state.archive,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };
    }
    case ADD_OBJECT_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        isUpdated: false,
        errors: action.payload,
      };
    }
    case ADD_OBJECT_UPLOAD_FILE_REQUEST: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isError: false,
          errors: [],
          isFetching: true,
        },
      };
    }
    case ADD_OBJECT_UPLOAD_FILE_SUCCESS: {
      let files = state[action.payload.name];
      let newItems = null;
      let newItemsId = null;

      if (action.payload.items) {
        newItems = [...files.items];
        action.payload.items && newItems.push(...action.payload.items);
      }
      if (action.payload.itemsId) {
        newItemsId = [...files.itemsId];
        action.payload.itemsId && newItemsId.push(...action.payload.itemsId);
      }

      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          isFetching: false,
          items: newItems ? newItems : state[action.payload.name].items,
          itemsId: newItemsId ? newItemsId : state[action.payload.name].itemsId,
        },
        sections: {
          ...state.sections,
          items: [...state.sections.items, ...action.payload.sections],
        },
      };
    }
    case ADD_OBJECT_UPLOAD_FILE_ERROR: {
      return {
        ...state,
        [action.payload.name]: {
          isFetching: false,
          isError: true,
          items: [],
          errors: action.payload.value,
        },
      };
    }
    case ADD_OBJECT_UPDATE_FILE_REQUEST: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isError: false,
          errors: [],
          isFetching: true,
        },
      };
    }
    case ADD_OBJECT_UPDATE_FILE_SUCCESS: {
      const updatedItem = action.payload.item;
      const updatedItems = [...state[action.payload.name].items];

      if (updatedItem) {
        let oldItem = null;
        const updatedItemIndex = updatedItems.findIndex((item) => {
          if (item.id === updatedItem.id) {
            oldItem = item;
            return true;
          }
        });
        if (updatedItemIndex >= 0) {
          updatedItems.splice(updatedItemIndex, 1, {
            ...oldItem,
            ...updatedItem,
          });
        }
      }

      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          isFetching: false,
          items: updatedItems,
        },
      };
    }
    case ADD_OBJECT_UPDATE_FILE_ERROR: {
      return {
        ...state,
        [action.payload.name]: {
          isFetching: false,
          isError: true,
          items: [],
          errors: action.payload.value,
        },
      };
    }
    case ADD_OBJECT_DELETE_FILE: {
      if (action.payload.type !== "designProject") {
        let files = [];
        let filesIds = [];
        for (let i in state[action.payload.type].items) {
          for (let j in action.payload.ids) {
            if (
              state[action.payload.type].items[i].id !== action.payload.ids[j]
            ) {
              files.push(state[action.payload.type].items[i]);
              filesIds.push(state[action.payload.type].items[i].id);
            }
          }
        }
        return {
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            items: files,
            itemsId: filesIds,
          },
        };
      } else {
        return {
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            items: [],
            itemsId: [],
          },
        };
      }
    }
    case ADD_OBJECT_CHANGE_STAGE: {
      let newSections = state.sections.items.map((section) => {
        if (section.sectionId === action.payload.sectionId) {
          section.tasks = section.tasks.map((task) => {
            if (task.id === action.payload.changedStage.id) {
              return action.payload.changedStage;
            }
            return task;
          });
        }
        return section;
      });

      return {
        ...state,
        sections: {
          ...state.sections,
          items: newSections,
        },
      };
    }
    case ADD_OBJECT_DELETE_STAGE: {
      let newSections = state.sections.items.map((section) => {
        if (section.sectionId === action.payload.sectionId) {
          section.tasks = section.tasks.filter((task) => {
            return task.id !== action.payload.stageId;
          });
        }
        return section;
      });

      return {
        ...state,
        sections: {
          ...state.sections,
          items: newSections,
        },
      };
    }
    case ADD_OBJECT_SET_INITIAL: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default objectAdd;
