import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
} from "../actions/CreateUserActions";

const initialState = {
  isSuccess: false,
  isFetching: false,
  isError: false,
  errors: [],
};

const createUser = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
        isFetching: false,
      };
    }
    case CREATE_USER_ERROR: {
      const errors = Object.keys(action.payload.errorData).map(
        (errorName) => action.payload.errorData[errorName]
      );
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        isError: true,
        errors,
      };
    }
    default: {
      return state;
    }
  }
};

export default createUser;
