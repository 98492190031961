import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";

import "./SoloObjectHead.scss";
import Tabs from "../../../../components/Tabs";
import {
  editIcon,
  freezeIcon,
  deleteIcon,
  downloadIcon,
  ratingIcon,
  respondIcon,
  closeIcon,
} from "../../../../assets/img/sprite";
import { ROUTES } from "../../../../routes";
import EditableField from "../../../../components/EditableField";
import { UserContext } from "../../../../App";
import CheckPermissions from "../../../../components/CheckPermissions";
import { closeModal, openModal } from "../../../../actions/ModalAction";
import { ModalTypes } from "../../../../assets/js/constants";
import ConfirmAction from "../../../../components/ConfirmAction";
import { deleteObject } from "../../../../actions/ObjectsActions";
import { fetchArchive } from "../../../../actions/ArchiveActions";
import toaster from "toasted-notes";

const SoloObjectHead = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const isInfoPath =
    history.location.pathname === url + ROUTES.OBJECT.INFO.PATH;
  const user = useContext(UserContext);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const { isMobileSize } = useSelector((state) => state.responsive.window);

  const tabItems = [
    {
      key: url + ROUTES.OBJECT.INFO.PATH,
      value: "Информация",
    },
    {
      key: url + ROUTES.OBJECT.STAGES.PATH,
      value: "Этапы",
    },
  ];

  const onTabClick = (item) => {
    history.push(item.key);
  };

  const handleModalOpen = () =>
    dispatch(
      openModal({
        type: ModalTypes.OBJECT_RATING,
        withoutCloseBtn: true,
        data: {
          objectID: props.id,
        },
      })
    );

  const getOnClick = () => {
    if (
      !user.permissions.filter(
        (perm) => perm.name === "perm_construction-object_update"
      ).length
    ) {
      return null;
    }
    if (props.isEditable) {
      return props.onCancel;
    }
    if (isInfoPath) {
      return props.onEditClick;
    }
    return null;
  };

  const getEditClass = () => {
    if (props.isEditable) {
      return "solo-object-head-edit-cancel";
    }
    if (isInfoPath) {
      return "solo-object-head-edit";
    }
    return false;
  };

  const handleObjectDelete = () => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="Объект будет безвозвратно удален!"
            onCancel={() => dispatch(closeModal())}
            onConfirm={() => {
              deleteObject(props.id).then((r) => {
                if (r === "success") {
                  dispatch(fetchArchive());
                  dispatch(closeModal());
                  history.push(ROUTES.ARCHIVE.PATH);
                  toaster.notify(
                    <div className="success-toast">Объект успешно удалён!</div>,
                    {
                      position: "top-right",
                      duration: 4000,
                    }
                  );
                } else {
                  toaster.notify(<div className="error-toast">{r}</div>, {
                    position: "top-right",
                    duration: 4000,
                  });
                }
              });
            }}
          />
        ),
      })
    );
  };

  return (
    <div className="solo-object-head">
      <div className="solo-object-head-name">
        <EditableField
          value={props.name}
          isEditable={props.isEditable && props.havePermission}
          name="name"
          onChange={(ev) => props.onChange(ev.target.value, ev.target.name)}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="solo-object-head-switch">
        <Tabs
          items={tabItems}
          active={history.location.pathname}
          onClick={onTabClick}
          isNav
          isSelect={isMobileSize}
        />
      </div>

      <div className="solo-object-head-controls">
        <CheckPermissions
          permissions={["perm_construction-object_update-status-to-stopped"]}
        >
          {props.status === "STOPPED" ||
          props.status === "WORKS_DONE" ? null : (
            <button
              className="solo-object-head-freeze"
              onClick={props.onFreezeClick}
              disabled={isAvailable}
            >
              {freezeIcon}
              <span>Заморозка</span>
            </button>
          )}
        </CheckPermissions>

        <CheckPermissions permissions={["perm_contractor_create"]}>
          {!props.isRequest ? (
            <button
              className="solo-object-head-respond"
              onClick={props.onRespond}
              disabled={isAvailable}
            >
              {respondIcon}
              <span>Откликнуться</span>
            </button>
          ) : null}
        </CheckPermissions>

        <CheckPermissions permissions={["perm_score_evaluete"]}>
          {props.status === "STOPPED" || props.status === "WORKS_DONE" ? (
            <button
              className="solo-object-head-rating"
              onClick={handleModalOpen}
              disabled={isAvailable}
              style={isAvailable ? { opacity: "0.5" } : {}}
            >
              {ratingIcon}
              <span>Оценить</span>
            </button>
          ) : null}
        </CheckPermissions>

        <CheckPermissions permissions={["perm_report_unload-report"]}>
          <button
            className="solo-object-head-summary"
            onClick={props.onSummaryClick}
            disabled={isAvailable}
            style={isAvailable ? { opacity: "0.5" } : {}}
          >
            {downloadIcon}
            {!isMobileSize && <span>Выгрузка отчёта</span>}
          </button>
        </CheckPermissions>

        <CheckPermissions permissions={["perm_construction-object_update"]}>
          {!getEditClass() ||
          props.status === "STOPPED" ||
          props.status === "WORKS_DONE" ? null : (
            <button
              className={`solo-object-head-edit ${
                props.isEditable ? "_error" : ""
              }`}
              onClick={getOnClick()}
              disabled={isAvailable}
              style={isAvailable ? { opacity: "0.5" } : {}}
            >
              {props.isEditable ? (
                <>
                  {isMobileSize && closeIcon}
                  {!isMobileSize && <span>Отменить</span>}
                </>
              ) : (
                <>
                  {editIcon}
                  {!isMobileSize && <span>Редактировать</span>}
                </>
              )}
            </button>
          )}
        </CheckPermissions>

        <CheckPermissions permissions={["perm_construction-object_delete"]}>
          {props.statusCode === "STOPPED" ||
          props.statusCode === "WORKS_DONE" ? (
            <button
              className="solo-object-head-delete _error"
              onClick={(e) => {
                e.stopPropagation();
                handleObjectDelete();
              }}
            >
              {deleteIcon}
              {!isMobileSize && <span>Удалить объект</span>}
            </button>
          ) : null}
        </CheckPermissions>

        {props.statusCode === "PLANNED" && props.isHavePermToSetStatus ? (
          <button
            className="solo-object-head-set-status"
            onClick={props.onSetStatus}
          >
            Поставить статус "В работе"
          </button>
        ) : null}
      </div>
    </div>
  );
};

SoloObjectHead.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onEditClick: PropTypes.func,
  onSummaryClick: PropTypes.func,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool,
  onCancel: PropTypes.func,
  onValidationChange: PropTypes.func,
  onFreezeClick: PropTypes.func,
  onRespond: PropTypes.func,
  isRequest: PropTypes.bool,
  statusCode: PropTypes.string,
  isHavePermToSetStatus: PropTypes.bool,
  onSetStatus: PropTypes.func,
};

export default SoloObjectHead;
