import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { useDispatch } from "react-redux";
import { openRegistrationPopup } from "../../../actions/RegistrationPopupAction";

function Unlogged({onAccountWidgetControlsReady}) {
  const dispatch = useDispatch();
  useEffect(() => {
      onAccountWidgetControlsReady !== undefined && onAccountWidgetControlsReady();
  }, [onAccountWidgetControlsReady]);
  const openPopup = () => dispatch(openRegistrationPopup());
  return (
    <div className="account-widget">
      <NavLink
          activeClassName="account-widget-active"
          to={ROUTES.LOGIN.PATH}
          className="account-widget-button _log-in"
      >
        Войти
      </NavLink>
      <button
          to={ROUTES.REGISTRATION.PATH}
          className="account-widget-button _registration button"
          onClick={openPopup}
      >
          Регистрация
      </button>
    </div>
  );
}

Unlogged.propTypes = {
    onAccountWidgetControlsReady: PropTypes.func,
};

export default Unlogged;
