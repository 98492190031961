import PropTypes from "prop-types";

const ratingCardItem = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rating: PropTypes.number,
  review: PropTypes.string,
});

const inputData = PropTypes.shape({
  label: PropTypes.string,
  prefixName: PropTypes.string,
  postfixName: PropTypes.string,
  mixClass: PropTypes.string,
  withoutAdditionalLabel: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
});

const userData = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  additionalInfo: PropTypes.string,
  orgName: PropTypes.string,
  orgContacts: PropTypes.string,
  orgAddress: PropTypes.string,
});

export { ratingCardItem, inputData, userData };
