import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./OverallRatingContainer.scss";

import Paginator from "../../components/Paginator";
import Loader from "../../components/Loader";
import Tabs from "../../components/Tabs";
import RatingStars from "../../components/RatingStars";
import { fetchRating } from "../../actions/RatingAction";
import {
  RatingRequestTypes,
  RatingRequestRoles,
  ModalTypes,
  SortTypes,
  MAX_RATING_VALUE,
} from "../../assets/js/constants";
import { openModal } from "../../actions/ModalAction";
import resetSort from "../../assets/js/utils/resetSort";
import {
  sortAscIcon,
  sortDescIcon,
  sortDroppedIcon,
} from "../../assets/img/sprite";

const tabsItems = [
  {
    key: RatingRequestRoles.ARCHITECT,
    value: "Архитекторы",
  },
  {
    key: RatingRequestRoles.BUILDER,
    value: "Подрядчики",
  },
  {
    key: RatingRequestRoles.DESIGNER,
    value: "Проектировщики",
  },
];

const SortParams = {
  NAME: {
    param: "name",
    defaultValue: SortTypes.DROPPED.value,
  },
  RATING: {
    param: "rating",
    defaultValue: SortTypes.DESC.value,
  },
};

const initialSort = Object.keys(SortParams).reduce((acc, cur) => {
  acc[SortParams[cur].param] = SortParams[cur].defaultValue;
  return acc;
}, {});

const initialResetSortWatcher = {
  count: 1,
  currentParam: SortParams.RATING.param,
};

const OverallRatingContainer = () => {
  const dispatch = useDispatch();
  const getRating = (data) =>
    dispatch(fetchRating(RatingRequestTypes.OVERALL, data));
  const handleModalOpen = (id) =>
    dispatch(
      openModal({
        type: ModalTypes.PERSONAL_RATING,
        withoutCloseBtn: true,
        data: {
          userID: id,
        },
      })
    );
  const ratingStore = useSelector((store) => store.rating);
  const { isMobileSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );
  const { isFetching, items, pagination, isError, errors } = ratingStore;
  const [activeType, setActiveType] = useState(tabsItems[0]);
  const [sort, setSort] = useState({ ...initialSort });
  const [page, setPage] = useState(pagination.currentPage);
  const [resetSortWatcher, setResetSortWatcher] = useState({
    ...initialResetSortWatcher,
  });

  useEffect(() => {
    getRating({
      role: activeType.key,
      sort,
      page,
    });
  }, [activeType, sort, page]);

  const handleTypeChange = (item) => {
    setActiveType(item);
  };

  const handleSortChange = (param) => {
    if (resetSortWatcher.currentParam === param) {
      if (resetSortWatcher.count >= 2) {
        setSort({
          ...resetSort(sort),
        });
        setResetSortWatcher({ count: 0, currentParam: "" });
        return;
      }

      setResetSortWatcher({
        ...resetSortWatcher,
        count: resetSortWatcher.count + 1,
      });
    } else {
      setResetSortWatcher({ count: 1, currentParam: param });
    }
    let sortParam = sort[param];
    let type = SortTypes.DROPPED.value;
    switch (sortParam) {
      case SortTypes.ASC.value:
        type = SortTypes.DESC.value;
        break;
      default:
        type = SortTypes.ASC.value;
        break;
    }

    setSort({
      ...resetSort(sort),
      [param]: type,
    });
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const getSortIcon = (sortType) => {
    if (!sortType) return null;

    switch (sortType) {
      case SortTypes.ASC.value:
        return sortAscIcon;
      case SortTypes.DESC.value:
        return sortDescIcon;
      case SortTypes.DROPPED.value:
        return sortDroppedIcon;
      default:
        return null;
    }
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          <div className="container__head">
            <h1 className="container__title">Рейтинг</h1>
          </div>

          <div className="container__content">
            <div className="overall-rating">
              <Tabs
                items={tabsItems}
                active={activeType.key}
                onClick={handleTypeChange}
                isSelect={isMobileSize}
              />

              <div className="rating-table">
                <div className="rating-table__head">
                  {isMobileSize && (
                    <span className="rating-table__sort-label">
                      Сортировать по:
                    </span>
                  )}
                  <div
                    className={`sort ${SortTypes[sort.name]?.mixClass || ""}`}
                    onClick={() => handleSortChange(SortParams.NAME.param)}
                  >
                    <span>ФИО</span>
                    {getSortIcon(sort.name)}
                  </div>
                  {!isMobileSize && (
                    <>
                      <span>Электронная почта</span>
                      <span>Номер телефона</span>
                    </>
                  )}
                  <div
                    className={`sort ${SortTypes[sort.rating]?.mixClass || ""}`}
                    onClick={() => handleSortChange(SortParams.RATING.param)}
                  >
                    <span>Оценка</span>
                    {getSortIcon(sort.rating)}
                  </div>
                </div>
                {isError ? (
                  <p className="rating-table__errors">{errors}</p>
                ) : isFetching ? (
                  <Loader />
                ) : items.length > 0 ? (
                  <>
                    {items.map(({ id, title, email, phone, rating }) => (
                      <div key={id} className="rating-table__row">
                        <div className="rating-table__cell">
                          {isMobileSize && <span>ФИО</span>}
                          <span>{title}</span>
                        </div>
                        <div className="rating-table__cell">
                          {isMobileSize && <span>Почта</span>}
                          <a href={"mailto:" + email}>{email}</a>
                        </div>
                        <div className="rating-table__cell">
                          {isMobileSize && <span>Телефон</span>}
                          <a href={"tel:" + phone}>{phone}</a>
                        </div>
                        <div className="rating-table__cell">
                          {isMobileSize && <span>Оценка</span>}
                          {typeof rating === "string" &&
                          typeof +rating === "number" ? (
                            isDesktopSize ? (
                              <RatingStars
                                rating={+rating}
                                maxRating={MAX_RATING_VALUE}
                                onClick={() => handleModalOpen(id)}
                              />
                            ) : (
                              <div>
                                <span
                                  onClick={() => handleModalOpen(id)}
                                  className="rating-value"
                                >
                                  {+rating}
                                </span>
                                {" / " + MAX_RATING_VALUE}
                              </div>
                            )
                          ) : (
                            <span onClick={() => handleModalOpen(id)}>
                              {rating}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}

                    {pagination.totalCount > pagination.pageSize ? (
                      <Paginator
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        total={pagination.totalCount}
                        totalPages={pagination.totalPageCount}
                        onPageChange={handlePageChange}
                      />
                    ) : null}
                  </>
                ) : (
                  <div className="no-users">Пользователи не найдены</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallRatingContainer;
