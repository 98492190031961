import React from "react";
import PropTypes from "prop-types";

import "./EditableSelectField.scss";
import Select from "../Select";

const EditableSelectField = (props) => {
  return (
    <div className="editable-select-field">
      {props.isEditable ? (
        <Select
          items={props.items}
          onChange={props.onChange}
          label={props.label}
          value={props.value}
          validators={props.validators}
          onValidationChange={props.onValidationChange}
          isFetching={props.isFetching}
          reset={props.reset}
          name={props.name}
          onOpen={props.onSelectOpen}
        />
      ) : (
        <div className="editable-select-field-container">
          <div className="editable-select-field-value">{props.value}</div>
          <div className="editable-select-field-label">{props.label}</div>
        </div>
      )}
    </div>
  );
};

EditableSelectField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  loadItems: PropTypes.func,
  isFetching: PropTypes.bool,
  reset: PropTypes.bool,
  name: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
  onSelectOpen: PropTypes.func,
};

export default EditableSelectField;
