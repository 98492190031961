export const OBJECTS_REQUEST = "OBJECTS_REQUEST";
export const OBJECTS_REQUEST_SUCCESS = "OBJECTS_REQUEST_SUCCESS";
export const OBJECTS_REQUEST_ERROR = "OBJECTS_REQUEST_ERROR";
export const OBJECT_REQUEST = "OBJECT_REQUEST";
export const OBJECT_REQUEST_SUCCESS = "OBJECT_REQUEST_SUCCESS";
export const OBJECT_REQUEST_ERROR = "OBJECT_REQUEST_ERROR";
export const OBJECT_IS_AVAILABLE = "OBJECT_IS_AVAILABLE";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  items: [],
  updated: false,
  lastType: null,
  pagination: {},
  isAvailable: false,
};

const getItemIndexById = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (+id === +items[i].id) {
      return i;
    }
  }
  return -1;
};

const objects = (state = initialState, action) => {
  switch (action.type) {
    case OBJECTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
        lastType: action.payload,
      };
    }
    case OBJECT_REQUEST: {
      let newItems = state.items;
      let itemIndex = getItemIndexById(newItems, action.payload);

      if (itemIndex >= 0) {
        newItems[itemIndex].isFetching = true;
        newItems[itemIndex].isError = false;
        newItems[itemIndex].errors = false;
      } else {
        newItems.push({
          id: action.payload,
          isFetching: true,
          isError: false,
          errors: [],
        });
      }

      return {
        ...state,
        items: newItems,
      };
    }
    case OBJECTS_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        pagination: action.payload.pagination,
        updated: true,
      };
    }
    case OBJECT_REQUEST_SUCCESS: {
      let newItems = state.items;
      let itemIndex = getItemIndexById(newItems, action.payload.id);
      newItems[itemIndex] = action.payload;
      newItems[itemIndex].isFetching = false;
      newItems[itemIndex].isError = false;
      newItems[itemIndex].errors = [];

      return {
        ...state,
        items: newItems,
      };
    }
    case OBJECTS_REQUEST_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: action.payload,
      };
    }
    case OBJECT_REQUEST_ERROR: {
      let newItems = state.items;
      let itemIndex = getItemIndexById(newItems, action.payload.id);
      newItems[itemIndex].isFetching = false;
      newItems[itemIndex].isError = true;
      newItems[itemIndex].errors = action.payload.errors;

      return {
        ...state,
        items: newItems,
      };
    }
    case OBJECT_IS_AVAILABLE: {
      return {
        ...state,
        isAvailable: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default objects;
