import {
  ARCHIVE_REQUEST,
  ARCHIVE_SUCCESS,
  ARCHIVE_ERROR,
} from "../reducers/archive";

import api from "./api";

export const fetchArchive = (filters, page) => {
  return (dispatch) => {
    dispatch({
      type: ARCHIVE_REQUEST
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: ARCHIVE_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    if (filters) {
      if (filters.filter(item => item.value === "").length === filters.length) {
        filters = undefined
      }
    }

    let body = {
      filter: filters,
      page: page,
    };
    fetch(`${api}/v1/object/archive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: JSON.stringify(body),
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data._meta.isSuccessful) {
        let items = [];
        data.data.items.forEach((item) => {
          items.push(normalizeData(item))
        })
        dispatch({
          type: ARCHIVE_SUCCESS,
          payload: { items: items, pagination: data.data.pagination },
        });
        return;
      }

      dispatch({
        type: ARCHIVE_ERROR,
        payload: data,
      });
    })
      .catch((er) => {
        dispatch({
          type: ARCHIVE_ERROR,
          payload: er,
        });
      });
  }
}

export const freezeTask = (object_id, comment) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/object/${object_id}/update/status-to-stopped`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
    body: JSON.stringify(comment),
  })
      .then(r => r.json())
    .then((r) => {
      if (r._meta.isSuccessful) {
        return 'success'
      }
    })
      .catch(er => {})
}

const normalizeData = (raw) => {
  return {
    id: raw.construction_object_id,
    name: raw.construction_object_name,
    address: raw.construction_object_address,
    planEnd: raw.construction_object_date_plan_end,
    status: raw.construction_object_status,
    builder: raw.construction_object_builder_name,
    isSubscribed: raw.is_subscribed,
    statusColor: raw.construction_object_status_color_code,
    statusCode: raw.construction_object_status_code,
    completion:
      raw.percent_completion || raw.construction_object_percent_completion,
    description: raw.construction_object_description,
    customer: {
      name: raw.construction_object_customer_name,
      contact: raw.construction_object_customer_phone_number,
      email: raw.construction_object_customer_email,
    },
    contractor: {
      builderId: raw.construction_object_builder_id,
      builderName: raw.construction_object_builder_name,
      builderContact: raw.construction_object_builder_phone_number,
      builderEmail: raw.construction_object_builder_email,
      organizationName: raw.organization_name,
      organizationContact: raw.organization_contacts,
      organizationAddress: raw.organization_address,
    },
  };
};