import React, { useState } from "react";
import PropTypes from "prop-types";

import "./AddObjectSection.scss";
import { inlineArrow } from "../../img/inlineSVGicons";
import AddObjectStage from "../AddObjectStage";
import Paginator from "../../../../../../../../../../components/Paginator";
import { useSelector } from "react-redux";

const AddObjectSection = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowAll, setIsShowAll] = useState(false);
  const { isMobileSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );
  let [pageSize] = useState(5);
  let total = props.section.tasks.length;
  let totalPages = Math.ceil(total / pageSize) || (total ? 1 : 1);

  const getStages = () => {
    let paginationTasks = [];
    if (isShowAll) {
      paginationTasks = props.section.tasks;
    } else {
      let totalI =
        currentPage * pageSize > total ? total : currentPage * pageSize;
      for (let i = (currentPage - 1) * pageSize; i < totalI; i++) {
        paginationTasks.push(props.section.tasks[i]);
      }
    }

    return paginationTasks.map((task, index) => {
      return (
        <AddObjectStage
          onSave={props.onStageSave}
          onChangeAmount={changeAmount}
          onChangeUnit={changeUnit}
          onDelete={deleteStage}
          key={index}
          stage={task}
        />
      );
    });
  };

  const deleteStage = (value, amount, unit) => {
    if (amount[0] === "-" || amount === "0" || unit === "") {
      props.section.error = Number(props.section.error) - 1;
    }
    props.onStageDelete(value);
  };
  const changeAmount = () => {
    props.section.error = Number(props.section.error) - 1;
  };
  const changeUnit = () => {
    props.section.error = Number(props.section.error) - 1;
  };
  const pageChange = (page) => {
    setCurrentPage(page);
    getStages();
  };

  const showAll = () => {
    setIsShowAll(true);
    getStages(1, total);
  };
  const hideAll = () => {
    setIsShowAll(false);
    getStages(1, 5);
  };

  return (
    <div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="add-object-sections-list-item-header"
      >
        <div
          className={`add-object-sections-list-icon ${isOpen ? "_open" : ""}`}
        >
          {inlineArrow}
        </div>
        <div className="add-object-sections-list-name">
          {props.section.name}
          {props.section.error ? <div className="indicator" /> : null}
        </div>
      </div>
      {isOpen ? (
        <div className="add-object-section-content">
          <div className="add-object-section-list">
            {isDesktopSize && (
              <ul className="add-object-section-head">
                <li className="add-object-section-head-name">Название</li>
                <li className="add-object-section-head-begin">Начало работ</li>
                <li className="add-object-section-head-end">Конец работ</li>
                <li className="add-object-section-head-amount">Объем работ</li>
                <li className="add-object-section-head-unit">
                  Единицы измерения
                </li>
              </ul>
            )}
            {getStages()}
          </div>
          {total > pageSize && !isShowAll ? (
            <div className="add-stages-paginator">
              <div className="add-stages-paginator-total">{total + " " + "Разделов"}</div>
              <Paginator
                currentPage={currentPage}
                pageSize={pageSize}
                total={total}
                totalPages={totalPages}
                onPageChange={pageChange}
              />

              <div className="show-all" onClick={showAll}>
                Показать все
              </div>
            </div>
          ) : isShowAll ? (
            <div className="hide-all _error" onClick={hideAll}>
              Скрыть
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

AddObjectSection.propTypes = {
  section: PropTypes.object,
  onStageSave: PropTypes.func,
  onStageDelete: PropTypes.func,
};

export default AddObjectSection;
