export const RIGHTS_REQUEST = "RIGHTS_REQUEST"
export const RIGHTS_SUCCESS = "RIGHTS_SUCCESS"
export const RIGHTS_ERROR = "RIGHTS_ERROR"

const initialState = {
  isSuccess: false,
  isFetching: false,
  isError: false,
  isUpdated: false,
  items: [],
  pagination: {},
}

const userRights = (state = initialState, action) => {
  switch (action.type) {
    case RIGHTS_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
        isError: false
      }
    }
    case RIGHTS_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
        isFetching: false,
        isUpdated: true,
        items: action.payload.items,
        pagination: action.payload.pagination
      }
    }
    case RIGHTS_ERROR: {
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        isError: true,
      }
    }
    default: {
      return state
    }
  }
}

export default userRights
