import React, { useState } from "react";
import PropTypes from "prop-types";

import './AddContractor.scss'
import Input from "../../../../../../../components/Input";
import {
  inlineClose,
  inlineCorrect
} from "../../../../../../AddObject/components/AddObjectSections/components/AddObjectSectionsBlock/components/AddObjectSectionsList/components/AddObjectStage/img/inlineSVGicons";
import { mail, required } from "../../../../../../../assets/js/validators";

const AddContractor = (props) => {

  const [addContractorInfo, setAddContractorInfo] = useState({
    name: '',
    email: '',
    phone: '',
    contacts: ''
  })

  const [validationErrors, setValidationErrors] = useState({})

  const change = (value, name) => {
    setAddContractorInfo({
      ...addContractorInfo,
      [name]: value
    })
  }

  const validationChange = (name, value) => {
    setValidationErrors({
      ...validationErrors,
      [name]: value,
    });
  };

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) return true;
    }
    return false;
  };

  return(
    <div className="add-contractor">
      <div className="add-contractor-name">
        <Input value={addContractorInfo.name} onValidationChange={validationChange} validators={[required]} label='ФИО субподрядчика' onChange={ev => change(ev.target.value, 'name')} />
      </div>
      <div className="add-contractor-phone">
        <Input value={addContractorInfo.phone} onValidationChange={validationChange} validators={[required]} label='Номер субподрядчика' onChange={ev => change(ev.target.value, 'phone')} />
      </div>
      <div className="add-contractor-email">
        <Input value={addContractorInfo.email} onValidationChange={validationChange} label='Почта субподрядчика' validators={[required, mail]} onChange={ev => change(ev.target.value, 'email')} />
      </div>
      <div className="add-contractor-contacts">
        <Input value={addContractorInfo.contacts} label='Дополнительная информация' onChange={ev => change(ev.target.value, 'contacts')} />
      </div>
      <button className="add-contractor-save" disabled={isValidationErrors() || addContractorInfo.phone === '' || addContractorInfo.email === '' || addContractorInfo.name === ''} onClick={() => props.onSave(addContractorInfo)}>
        {inlineCorrect}
      </button>
      <button className="add-contractor-cancel" onClick={props.onCancel}>
        {inlineClose}
      </button>
    </div>
  )
}

AddContractor.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

export default AddContractor;
