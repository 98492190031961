import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./InfoPanelWithEdit.scss";
import { editIcon } from "../../../../../../assets/img/sprite";
import { UserContext } from "../../../../../../App";
import CheckPermissions from "../../../../../../components/CheckPermissions";
import ChooseUser from "../../../../../../components/ChooseUser";
import ReactTooltip from "react-tooltip";

const InfoPanelWithEdit = (props) => {
  const userContext = useContext(UserContext);
  const [usersActive, setUsersActive] = useState(false);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const { isTabletSize } = useSelector((state) => state.responsive.window);
  const isDisabled = () => {
    let success = 0;
    props.permissionsOnEdit.forEach((permission) => {
      if (!!userContext.permissions.find((perm) => perm.name === permission)) {
        success++;
      }
    });

    return success !== props.permissionsOnEdit.length || isAvailable;
  };

  const setUser = () => {
    setUsersActive(false);
    props.onChangeInfo();
  };

  return (
    <React.Fragment>
      <div className="info-panel-with-edit-label">
        {props.label && (
          <div
            className="info-panel-with-edit-label-text"
            data-tip
            data-for={props.type + "Info" + (props.builderId || props.label)}
          >
            {props.label}
          </div>
        )}

        <ReactTooltip
          multiline
          isCapture
          id={props.type + "Info" + (props.builderId || props.label)}
          type="dark"
          place="top"
          effect="float"
        >
          <span>Поля данного блока редактируются только в рамках объекта</span>
        </ReactTooltip>
        {!isTabletSize && (
          <div className="buttons">
            <CheckPermissions permissions={[props.viewSetUserPerm]}>
              {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
                <button
                  className="buttons-fill"
                  onClick={() => setUsersActive(true)}
                  disabled={isAvailable}
                >
                  {props.modalLabel}
                </button>
              ) : null}
            </CheckPermissions>
            <CheckPermissions permissions={["perm_contractor_index"]}>
              {props.buttonLabel && props.hasContractorToObject ? (
                <button
                  className="info-panel-with-edit-add_contractor"
                  onClick={props.onSetContractor}
                  disabled={isAvailable}
                >
                  {props.buttonLabel}
                </button>
              ) : null}
            </CheckPermissions>
            {isDisabled() ||
            props.withoutEdit ||
            props.status === "STOPPED" ||
            props.status === "WORKS_DONE" ? null : (props.label ===
                "Исполнитель" &&
                props.builderId) ||
              props.label !== "Исполнитель" ? (
              <button
                onClick={props.onEditClick}
                className={`info-panel-with-edit ${
                  props.isEditable ? "_error" : ""
                }`}
                disabled={isDisabled()}
              >
                {!props.isEditable ? (
                  <>
                    {editIcon}
                    <span>Редактировать</span>
                  </>
                ) : (
                  "Отменить"
                )}
              </button>
            ) : null}
          </div>
        )}
      </div>

      <ChooseUser
        type={props.type}
        isActive={usersActive}
        withUserCreator={props.withUserCreator}
        label={props.modalLabel}
        onClose={() => setUser()}
        setUserPerm={props.setUserPerm}
        objectId={props.objectId}
      />

      {props.children}
      {isTabletSize && (
        <div className="buttons">
          <CheckPermissions permissions={[props.viewSetUserPerm]}>
            {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
              <button
                className="buttons-fill"
                onClick={() => setUsersActive(true)}
                disabled={isAvailable}
              >
                {props.modalLabel}
              </button>
            ) : null}
          </CheckPermissions>
          <CheckPermissions permissions={["perm_contractor_index"]}>
            {props.buttonLabel && props.hasContractorToObject ? (
              <button
                className="info-panel-with-edit-add_contractor"
                onClick={props.onSetContractor}
                disabled={isAvailable}
              >
                {props.buttonLabel}
              </button>
            ) : null}
          </CheckPermissions>
          {isDisabled() ||
          props.withoutEdit ||
          props.status === "STOPPED" ||
          props.status === "WORKS_DONE" ||
          (props.isEditable && isTabletSize) ? null : (props.label ===
              "Исполнитель" &&
              props.builderId) ||
            props.label !== "Исполнитель" ? (
            <button
              onClick={props.onEditClick}
              className={`info-panel-with-edit ${
                props.isEditable ? "_error" : ""
              }`}
              disabled={isDisabled()}
            >
              {!props.isEditable ? (
                <>
                  {editIcon}
                  <span>Редактировать</span>
                </>
              ) : (
                "Отменить"
              )}
            </button>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

InfoPanelWithEdit.propTypes = {
  label: PropTypes.string,
  permissionsOnEdit: PropTypes.array,
  onEditClick: PropTypes.func,
  isEditable: PropTypes.bool,
  withoutEdit: PropTypes.bool,
  withUserCreator: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSetContractor: PropTypes.func,
  hasContractorToObject: PropTypes.bool,
  builderId: PropTypes.number,
  objectId: PropTypes.number,
  onChangeInfo: PropTypes.func,
};

export default InfoPanelWithEdit;
