import React, { useCallback, useEffect, useState } from "react";
import "./UserSearch.scss";
import Input from "../Input";
import Select from "../Select";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations } from "../../actions/SingUpActions";
import PropTypes from "prop-types";
import { fetchFilters } from "../../actions/FiltersActions";

const UserSearch = (props) => {
  const [filterValues, setFilterValues] = useState({
    name: "",
    email: "",
    organization: "",
    role: "",
    phone: "",
  });
  const [isFiltered, setIsFiltered] = useState(true);

  const singUpStore = useSelector((store) => store.singUp);
  const filterItemsStore = useSelector((store) => store.objectsFilters);
  const dispatch = useDispatch();
  const getOrganizations = () => {
    if (
      !singUpStore.isUpdatedOrganizations &&
      !(singUpStore.isFetchingOrg || singUpStore.isErrorOrg)
    ) {
      dispatch(fetchOrganizations());
    }
  };
  const getRoles = () => {
    if (!filterItemsStore.role.isUpdated && !filterItemsStore.role.isFetching) {
      dispatch(fetchFilters("role"));
    }
  };

  const filters = [
    {
      filterName: "name",
      operator: "ilike",
      value: filterValues.name || "",
    },
    {
      filterName: "email",
      operator: "ilike",
      value: filterValues.email || "",
    },
    {
      filterName: "organization",
      operator: "ilike",
      value: filterValues.organization || "",
    },
    {
      filterName: "role",
      operator: "ilike",
      value: filterValues.role || "",
    },
    {
      filterName: "phone",
      operator: "ilike",
      value: filterValues.phone || "",
    },
  ];

  const change = (value, name) => {
    setIsFiltered(false);
    if (name) {
      setFilterValues({
        ...filterValues,
        [name]: value ? value.value : null,
      });
      return;
    }

    setFilterValues({
      ...filterValues,
      [value.target.name]: value.target.value,
    });
  };

  const onFilter = useCallback(() => {
    props.onFilter(filters);
  }, [props, filters]);

  useEffect(() => {
    if (!isFiltered) {
      onFilter();
      setIsFiltered(true);
    }
  }, [isFiltered, onFilter]);

  return (
    <div
      className={props.chooseUser ? "user-search-for-choose" : "user-search"}
    >
      <div className="user-search__item">
        <Input
          type="text"
          placeholder="ФИО"
          name="name"
          value={filterValues.name}
          onChange={change}
        />
      </div>
      <div className="user-search__item">
        <Input
          type="text"
          placeholder="Почта"
          name="email"
          value={filterValues.email}
          onChange={change}
        />
      </div>
      <div className="user-search__item">
        <Input
          type="text"
          placeholder="Номер телефона"
          name="phone"
          value={filterValues.phone}
          onChange={change}
        />
      </div>
      {props.chooseUser ? null : (
        <>
          <div className="user-search__item">
            <Select
              placeholder="Организация"
              name="organization"
              value={filterValues.organization}
              onChange={(val, name) => {
                // if(val.key === null && val.value !== ""){
                //   return
                // }
                change(val, name);
              }}
              items={singUpStore.organizations}
              onOpen={getOrganizations}
              reset
              isRounded
            />
          </div>
          <div className="user-search__item">
            <Select
              placeholder="Роль"
              name="role"
              value={filterValues.role}
              onChange={(val, name) => {
                // if(val.key === null && val.value !== ""){
                //   return
                // }
                change(val, name);
              }}
              items={filterItemsStore.role.items}
              onOpen={getRoles}
              reset
              isRounded
            />
          </div>
        </>
      )}
    </div>
  );
};

UserSearch.propTypes = {
  onFilter: PropTypes.func.isRequired,
};

export default UserSearch;
