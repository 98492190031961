import React from "react";
import PropTypes from "prop-types";

import "./ShowImageModal.scss";

import { useSelector } from "react-redux";
import CheckPermissions from "../../../../components/CheckPermissions";

const ShowImageModal = (props) => {
  const stageStoreDef = useSelector(store => store.stageSolo);
  let stageStore = props.store
  if(!stageStore){
    stageStore = stageStoreDef;
  }
  let imageIndex = 0;
  const currentImage = stageStore.images.items && stageStore.images.items.find((item, index) => {
    if(item.id === props.imageId){
      imageIndex = index;
      return true;
    }
    return false;
  })

  let api;
  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_PRODUCTION_API_URL;
  } else {
    api = process.env.REACT_APP_DEVELOP_API_URL;
  }

  return(
    <React.Fragment>
      { props.isShown ? (
        <div className="show-image-modal-container">
          <div className="show-image-modal-head">
            <button className="show-image-modal-head-close" onClick={props.onClose}>X</button>
          </div>

          <div className="show-image-modal-main">
            <button
              disabled={imageIndex === 0}
              onClick={() => props.onNavigation(stageStore.images.items[imageIndex - 1].id)}
              className="show-image-modal-back">
              {"<"}
            </button>

            <div className="show-image-modal-image">
              <img src={ api + "/" + currentImage.full } alt={currentImage.name} />
            </div>

            <button
              disabled={imageIndex === (stageStore.images.items.length - 1)}
              onClick={() => props.onNavigation(stageStore.images.items[imageIndex + 1].id)}
              className="show-image-modal-forward">
              {">"}
            </button>
          </div>

          <div className="show-image-modal-controls">
            <div className="show-image-modal-controls-name">{currentImage.name}</div>

            <div className="show-image-modal-controls-buttons">
              <a href={ api + "/" +currentImage.full } rel="noopener noreferrer" download target="_blank" className="show-image-modal-controls-download">Скачать</a>
              {props.deletePermission ?
                <CheckPermissions permissions={[props.deletePermission]}>
                  <button onClick={() => props.onDelete(props.imageId)} className="show-image-modal-controls-delete">Удалить</button>
                </CheckPermissions>
               :
                <button className="show-image-modal-controls-delete">Удалить</button>
              }
            </div>
          </div>

          <div onClick={props.onClose} className="show-image-modal-background" />
        </div>
        ) : null
      }
    </React.Fragment>
  )
}

ShowImageModal.propTypes = {
  isShown: PropTypes.bool,
  imageId: PropTypes.string,
  onClose: PropTypes.func,
  onNavigation: PropTypes.func,
  onDelete: PropTypes.func,
  store: PropTypes.any
}

export default ShowImageModal;