import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Switch,
  Route,
  useParams,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import "./StageContainer.scss";

import StageHeader from "./components/StageHeader";
import StageInfo from "./components/StageInfo";
import StageComments from "./components/StageComments";
import StageHistoriesCard from "./components/StageHistoriesCard";
import {
  checkIcon,
  closeIcon,
  deleteIcon,
  viewIcon,
} from "../../assets/img/sprite";
import {
  fetchStage,
  fetchStageComments,
  uploadStageComment,
  updateStage,
  fetchStageSoloImages,
  fetchStageHistories,
  deleteStageSoloImage,
} from "../../actions/StageSoloActions";
import {
  addHistory,
  approveHistory,
} from "../../actions/ObjectHistoriesActions";
import { openModal } from "../../actions/ModalAction";
import ShowImageModal from "./components/ShowImageModal";
import { ROUTES } from "../../routes";
import AddHistory from "./components/AddHistory";
import Loader from "../../components/Loader";
import toaster from "toasted-notes";
import { UserContext } from "../../App";
import { OBJECT_IS_AVAILABLE } from "../../reducers/objects";
import Percentage from "../../components/Percentage";
import CheckPermissions from "../../components/CheckPermissions";
import { ModalTypes } from "../../assets/js/constants";

const StageContainer = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [imageInFocus, setImageInFocus] = useState(null);
  const [changedItem, setChangedItem] = useState(null);
  const [openHistory, setOpenHistory] = useState(null);
  // const [commentsPage, setCommentsPage] = useState(1);
  const [isAvailableForApproval, setIsAvailableForApproval] = useState(false);
  const stageStore = useSelector((store) => store.stageSolo);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const userStore = useSelector((store) => store.user);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const userType = userStore.type;
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (history.location.search === "?isGrouped=true") {
      dispatch({
        type: OBJECT_IS_AVAILABLE,
        payload: true,
      });
    } else {
      history.push(isAvailable ? "?isGrouped=true" : history.location.pathname);
    }
  }, []);

  useEffect(() => {
    const totalWorkAmount =
      stageStore.histories.items && stageStore.histories.items.length > 0
        ? stageStore.histories.items.reduce(
            (acc, cur) => (acc += +cur.works_history_amount),
            0
          )
        : 0;
    const workInfo = changedItem || stageStore.item;
    setIsAvailableForApproval(totalWorkAmount >= +workInfo.requiredAmount);
  }, [stageStore.histories.items]);

  const commentAdd = (message) =>
    uploadStageComment(
      params.objectId,
      params.sectionId,
      params.stageId,
      message
    );
  const updStage = (changes) =>
    updateStage(params.objectId, params.sectionId, params.stageId, changes);
  const { url } = useRouteMatch();

  const user = useContext(UserContext);
  const isHavePermission = (permission) => {
    return user.permissions.filter((perm) => perm.name === permission).length;
  };

  const getStage = useCallback(() => {
    dispatch(fetchStage(params.objectId, params.sectionId, params.stageId));
  }, [params.objectId, params.sectionId, params.stageId, dispatch]);

  const getHistories = useCallback(() => {
    dispatch(
      fetchStageHistories(params.objectId, params.sectionId, params.stageId)
    );
  }, [params.objectId, params.sectionId, params.stageId, dispatch]);

  const getComments = useCallback(
    (page) => {
      dispatch(
        fetchStageComments(
          params.objectId,
          params.sectionId,
          params.stageId,
          page
        )
      );
    },
    [params.objectId, params.sectionId, params.stageId, dispatch]
  );

  const getImages = useCallback(
    (page) => {
      dispatch(
        fetchStageSoloImages(
          params.objectId,
          params.sectionId,
          params.stageId,
          page
        )
      );
    },
    [dispatch, params.objectId, params.sectionId, params.stageId]
  );

  //Историии
  useEffect(() => {
    if (
      (stageStore.item.id !== +params.stageId ||
        !stageStore.histories.isUpdated) &&
      !stageStore.histories.isFetching &&
      !stageStore.histories.isError
    ) {
      getHistories();
    }
  }, [
    stageStore.item.id,
    params.stageId,
    getHistories,
    stageStore.histories.isUpdated,
    stageStore.histories.isFetching,
    stageStore.histories.isError,
  ]);

  //Информация
  useEffect(() => {
    if (
      (stageStore.item.id !== +params.stageId || !stageStore.isUpdated) &&
      !stageStore.isFetching &&
      !stageStore.isError
    ) {
      getStage();
    }
  }, [
    stageStore.isFetching,
    stageStore.isUpdated,
    params.stageId,
    stageStore.item.id,
    getStage,
    stageStore.isError,
  ]);

  //Фото
  useEffect(() => {
    if (
      (stageStore.item.id !== +params.stageId || !stageStore.images.updated) &&
      !stageStore.images.isFetching &&
      !stageStore.images.isError
    ) {
      getImages();
    }
  }, [
    stageStore.images.isFetching,
    stageStore.images.updated,
    params.stageId,
    stageStore.item.id,
    getImages,
    stageStore.images.isError,
  ]);

  //Комментарии
  useEffect(() => {
    if (
      (stageStore.item.id !== +params.stageId ||
        !stageStore.comments.updated) &&
      !stageStore.comments.isFetching &&
      !stageStore.comments.isError
    ) {
      getComments();
    }
  }, [
    stageStore.comments.isFetching,
    stageStore.comments.updated,
    getComments,
    params.stageId,
    stageStore.item.id,
    stageStore.comments.isError,
  ]);

  useEffect(() => {
    if (isEditable) {
      setChangedItem(stageStore.item);
    } else {
      setChangedItem(null);
    }
  }, [isEditable, stageStore.item]);

  const change = (name, value) => {
    setChangedItem({
      ...changedItem,
      [name]: value,
    });
  };

  const save = () => {
    updStage(changedItem).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Этап обновлен!</div>, {
          position: "top-right",
          duration: 2000,
        });
      } else {
        let html = { __html: Object.values(r).join("<br>") };
        toaster.notify(
          <div className="error-toast" dangerouslySetInnerHTML={html} />,
          { position: "top-right", duration: 2000 }
        );
      }
      getStage();
    });
    setIsEditable(false);
  };

  const items = [
    {
      key: url + ROUTES.OBJECT.SECTION.STAGE.INFO.PATH,
      value: "Информация",
    },
    {
      key: url + ROUTES.OBJECT.SECTION.STAGE.HISTORY.PATH,
      value: "История",
    },
  ];

  const addComment = (message) => {
    commentAdd(message).then(() => {
      getComments();
    });
  };

  const historyAdd = (value) => {
    dispatch(
      addHistory(params.objectId, params.sectionId, params.stageId, value)
    ).then((r) => {
      if (r !== true) {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 2000,
        });
      } else {
        toaster.notify(<div className="success-toast">История создана!</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
      getHistories();
      getStage();
    });
  };

  // const uploadImage = (ev) => {
  //   let images = [];
  //   let files = ev.target.files;
  //   for(let i = 0; i < files.length; i++){
  //     images.push(files[i]);
  //   }
  //
  //   uploadStageImage(params.objectId, params.sectionId, params.stageId, images)
  //     .then((r) => {
  //       if (r !== 'success') {
  //         toaster.notify(
  //           <div className="error-toast">
  //             {JSON.stringify(r.image_file ? r.image_file : r)}
  //           </div>, {position: 'top-right', duration: 4000}
  //         );
  //       }
  //       getImages();
  //     });
  // }

  const deleteImage = (id) => {
    deleteStageSoloImage(
      params.objectId,
      params.sectionId,
      params.stageId,
      id
    ).then((r) => {
      if (r === "success") {
        toaster.notify(
          <div className="success-toast">Фотография удалена!</div>,
          { position: "top-right", duration: 4000 }
        );
        setImageInFocus(null);
        getImages();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  const statusChange = () => {
    getStage();
    getHistories();
  };

  const handleStatusChange = (ev, role, status) => {
    ev.stopPropagation();

    approveHistory(
      params.objectId,
      params.sectionId,
      params.stageId,
      role,
      status
    ).then((r) => {
      if (r.message === "success") {
        getStage();
        getHistories();

        if (r.objectCompleted && userType === "customer") {
          dispatch(
            openModal({
              type: ModalTypes.OBJECT_RATING,
              withoutCloseBtn: true,
              data: { objectID: params.objectId },
            })
          );
        }
      } else {
        toaster.notify(
          <div className="error-toast">
            {Object.values(r.message).map((item) => (
              <p>{item}</p>
            ))}
          </div>,
          {
            position: "top-right",
            duration: 4000,
          }
        );
      }
    });
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          <div className="stage-card-header">
            <StageHeader
              item={changedItem ? changedItem : stageStore.item}
              tabItems={items}
              isEditable={isEditable}
              onEditClick={() => setIsEditable(!isEditable)}
              onChange={change}
              status={stageStore.item.statusCode}
              havePermission={isHavePermission("perm_task_update-extended")}
            />
          </div>

          <div className="stage-card-info">
            <StageInfo
              item={changedItem || stageStore.item}
              isEditable={isEditable}
              onCancel={() => setIsEditable(false)}
              onChange={change}
              onSave={save}
              havePermission={isHavePermission("perm_task_update-extended")}
            />
          </div>
        </div>

        <Switch>
          <Route exact path={ROUTES.OBJECT.SECTION.STAGE.INFO.ROUTE}>
            <React.Fragment>
              <div className="content-card">
                <div className="stage-histories-head">История работ</div>
                {stageStore.item.statusCode === "WORKS_DONE" ||
                stageStore.item.statusCode === "STOPPED" ? null : (
                  <div className="stage-histories-add">
                    <AddHistory
                      onAdd={historyAdd}
                      status={stageStore.item.statusCode}
                    />
                  </div>
                )}

                <div className="stage-histories-container">
                  <div className="stage-histories-table">
                    {stageStore.histories.items &&
                    stageStore.histories.items.length > 0 &&
                    !isMobileSize ? (
                      <ul className="stage-histories-header">
                        <li className="stage-histories-header-status">
                          Статус
                        </li>
                        <li className="stage-histories-header-created">
                          Дата создания
                        </li>
                        <li className="stage-histories-header-updated">
                          Дата изменения
                        </li>
                        <li className="stage-histories-header-amount">
                          Объем работ
                        </li>
                        <li></li>
                      </ul>
                    ) : null}
                    {stageStore.histories.items &&
                      stageStore.histories.items.map((el) => {
                        if (!el) return null;

                        return (
                          <div
                            className="stage-histories-item"
                            key={el.works_history_id}
                          >
                            <StageHistoriesCard
                              onClick={(id) => {
                                setOpenHistory(id);
                              }}
                              isOpen={el.works_history_id === openHistory}
                              info={el}
                              onImageClick={(id) => setImageInFocus(id)}
                              onStatusChange={statusChange}
                              status={stageStore.item.statusCode}
                            />
                          </div>
                        );
                      })}
                  </div>

                  {stageStore.item.statusCode === "WORKS_DONE" ||
                  stageStore.item.statusCode === "STOPPED" ||
                  !isAvailableForApproval ? null : (
                    <div className="stage-histories-controls">
                      {/*Заказчик*/}
                      <CheckPermissions
                        permissions={["perm_works-history_approve-customer"]}
                      >
                        <div className="stage-histories-controls-section">
                          <button
                            onClick={(ev) =>
                              handleStatusChange(ev, "customer", true)
                            }
                            className="stage-histories-controls-approve _success"
                            disabled={
                              stageStore.item.approveCustomer || isAvailable
                            }
                          >
                            {userType === "government_customer"
                              ? "Утвердить (заказчик)"
                              : "Утвердить"}
                          </button>

                          <button
                            onClick={(ev) =>
                              handleStatusChange(ev, "customer", false)
                            }
                            className="stage-histories-controls-decline _error"
                            disabled={
                              (stageStore.item.approveCustomer &&
                                userType !== "government_customer") ||
                              stageStore.item.approveCustomer === false ||
                              isAvailable
                            }
                          >
                            {userType === "government_customer"
                              ? "Отклонить (заказчик)"
                              : "Отклонить"}
                          </button>
                        </div>
                      </CheckPermissions>

                      {/*Стройконтроль*/}
                      <CheckPermissions
                        permissions={["perm_works-history_approve-control"]}
                      >
                        <div className="stage-histories-controls-section">
                          <button
                            onClick={(ev) =>
                              handleStatusChange(ev, "control", true)
                            }
                            className="stage-histories-controls-approve _success"
                            disabled={
                              stageStore.item.approveControl || isAvailable
                            }
                          >
                            {userType === "government_customer"
                              ? "Утвердить (стройконтроль)"
                              : "Утвердить"}
                          </button>

                          <button
                            onClick={(ev) =>
                              handleStatusChange(ev, "control", false)
                            }
                            className="stage-histories-controls-decline _error"
                            disabled={
                              (stageStore.item.approveControl &&
                                userType !== "government_customer") ||
                              stageStore.item.approveControl === false ||
                              isAvailable
                            }
                          >
                            {userType === "government_customer"
                              ? "Отклонить (стройконтроль)"
                              : "Отклонить"}
                          </button>
                        </div>
                      </CheckPermissions>

                      {/*<button className="stage-histories-controls-edit">{inlineEdit}</button>*/}

                      {/*<button className="stage-histories-controls-delete">{inlineDelete}</button>*/}
                    </div>
                  )}
                </div>
                {/*<div>*/}
                {/*  <StageImages*/}
                {/*    onUpload={uploadImage}*/}
                {/*    onImageClick={(id) => setImageInFocus(id)}*/}
                {/*    pagination={stageStore.images.pagination}*/}
                {/*    onPageChange={getImages}*/}
                {/*    images={stageStore.images}*/}
                {/*    status={stageStore.item.statusCode}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>

              <div className="content-card">
                {stageStore.isFetching ? <Loader /> : null}
                <StageComments
                  comments={stageStore.comments.items}
                  pagination={stageStore.comments.pagination}
                  onCommentAdd={(content) => addComment(content)}
                  onPageChange={getComments}
                  status={stageStore.item.statusCode}
                />
              </div>
            </React.Fragment>
          </Route>

          {/*{width > 800 && <Route exact path={ROUTES.OBJECT.SECTION.STAGE.HISTORY.ROUTE}>*/}
          {/*  <React.Fragment>*/}
          {/*    {stageStore.item.statusCode === "WORKS_DONE" || stageStore.item.statusCode === "STOPPED" ? null :*/}
          {/*      <div className="stage-histories-add">*/}
          {/*        <AddHistory onAdd={historyAdd} status={stageStore.item.statusCode} unit={(changedItem && changedItem.unit) || stageStore.item.unit} />*/}
          {/*      </div>*/}
          {/*    }*/}

          {/*    {*/}
          {/*      stageStore.histories.items && stageStore.histories.items.length > 0 ? (*/}
          {/*        <ul className="stage-histories-header">*/}
          {/*          <li className="stage-histories-header-status">Статус</li>*/}
          {/*          <li className="stage-histories-header-created">Дата создания</li>*/}
          {/*          <li className="stage-histories-header-updated">Дата изменения</li>*/}
          {/*          <li className="stage-histories-header-amount">Объем работ</li>*/}
          {/*        </ul>*/}
          {/*      ) : null*/}
          {/*    }*/}

          {/*    <ul className="stage-histories-container">*/}
          {/*      {*/}
          {/*        stageStore.histories.items && stageStore.histories.items.map(el => {*/}
          {/*          if (!el) return null;*/}

          {/*          return (*/}
          {/*            <li key={el.works_history_id}>*/}
          {/*              <StageHistoriesCard*/}
          {/*                onClick={(id) => {*/}
          {/*                  setOpenHistory(id)*/}
          {/*                }}*/}
          {/*                isOpen={el.works_history_id === openHistory}*/}
          {/*                info={el}*/}
          {/*                onImageClick={(id) => setImageInFocus(id)}*/}
          {/*                onStatusChange={statusChange}*/}
          {/*                status={stageStore.item.statusCode}*/}
          {/*              />*/}
          {/*            </li>*/}
          {/*          )*/}
          {/*        })*/}
          {/*      }*/}
          {/*    </ul>*/}
          {/*  </React.Fragment>*/}
          {/*</Route>*/}
          {/*}*/}

          <Route path="*">
            <Redirect to={url + ROUTES.OBJECT.SECTION.STAGE.INFO.PATH} />
          </Route>
        </Switch>
      </div>

      <ShowImageModal
        isShown={!!imageInFocus}
        imageId={imageInFocus}
        onClose={() => setImageInFocus(null)}
        onDelete={deleteImage}
        onNavigation={(id) => setImageInFocus(id)}
        deletePermission={"perm_image_delete"}
      />
    </div>
  );
};

export default StageContainer;
