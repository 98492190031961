import {
  isMobile,
  isMobileOnly,
  isTablet,
  isWindows,
  isMacOs,
  isAndroid,
  isWinPhone,
  isIOS,
  mobileVendor,
  mobileModel,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isYandex,
  isChromium,
  isMobileSafari,
  browserVersion,
  browserName,
} from 'mobile-device-detect';
import { TABLET_WIDTH, MOBILE_WIDTH } from '../assets/js/constants';
import hasWebGLSupport from "../assets/js/utils/hasWebGLSupport";
import { WINDOW_PARAMS_UPDATE } from "../actions/ResponsiveActions";

const initialState = {
  window: {
    width: 0,
    height: 0,
    centerX: 0,
    centerY: 0,
    ratio: 0,
    isDesktopSize: false,
    isTabletSize: false,
    isMobileSize: false,
    positionHorizontal: false,
  },
  device: {
    isDesktop: !isMobile,
    isWindows,
    isMacOs,
    isAdaptive: isMobile,
    isTablet,
    isMobile: isMobileOnly,
    isAndroid,
    isWinPhone,
    isIOS,
    mobileVendor,
    mobileModel,
    pixelRatio: window.devicePixelRatio,
    hasTouchscreen: (() => 'ontouchstart' in document.documentElement)(),
  },
  browser: {
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isYandex,
    isChromium,
    isMobileSafari,
    browserVersion,
    browserName,
    hasWebpSupport: false,
    hasWebGLSupport: hasWebGLSupport(),
  },
};

const responsive = (state = initialState, action) => {
  switch (action.type) {
    case WINDOW_PARAMS_UPDATE: {
      const ratio = action.payload.width / action.payload.height;
      return {
        ...state,
        window: {
          ...state.window,
          width: action.payload.width,
          height: action.payload.height,
          centerX: action.payload.width / 2,
          centerY: action.payload.height / 2,
          ratio,
          isDesktopSize: action.payload.width > TABLET_WIDTH,
          isTabletSize: action.payload.width <= TABLET_WIDTH,
          isMobileSize: action.payload.width <= MOBILE_WIDTH,
          positionHorizontal: state.device.isAdaptive && ratio > 1,
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default responsive;

