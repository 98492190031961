import React from "react";
import AccountWidget from "../../components/AccountWiget";
import Logo from "../../components/Logo/logo";
import "./SingInContainer.scss";
import SingInForm from "./components/SingInForm";
import RegistrationPopup from "../../containers/RegistrationPopup";

const SingInContainer = (props) => {
  return (
    <React.Fragment>
      <AccountWidget />

      <div className="login-container">
        <h2 className="login-head">Авторизация</h2>

        <SingInForm />
      </div>
      <RegistrationPopup />
    </React.Fragment>
  );
};

export default SingInContainer;
