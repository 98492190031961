import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../Loader";
import RatingCard from "../RatingCard";
import RatingStars from "../RatingStars";
import { fetchRating } from "../../actions/UserRatingAction";
import { closeModal } from "../../actions/ModalAction";

import "../ObjectRating/RatingBlock.scss";
import { MAX_RATING_VALUE } from "../../assets/js/constants";

const UserRating = ({ userID }) => {
  const dispatch = useDispatch();
  const ratingStore = useSelector((store) => store.userRating);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const getRating = () => dispatch(fetchRating(userID));
  const handleModalClose = () => dispatch(closeModal());
  const {
    isFetching,
    userData: { title, score, rating },
    isError,
    errors,
  } = ratingStore;

  useEffect(() => {
    getRating();
  }, []);

  return (
    <div className="rating-block">
      {isError ? (
        <p className="rating-block__errors">{errors}</p>
      ) : isFetching ? (
        <Loader />
      ) : (
        <>
          <div className="rating-block__head">
            <h2 className="rating-block__title">{title}</h2>

            {typeof rating === "string" && typeof +rating === "number" ? (
              !isMobileSize ? (
                <RatingStars rating={+rating} maxRating={MAX_RATING_VALUE} />
              ) : (
                <div className="rating-block__rating">
                  <span className="rating-value">{+rating}</span>
                  {" / " + MAX_RATING_VALUE}
                </div>
              )
            ) : (
              <span>{rating}</span>
            )}
          </div>
          <ul className="rating-block__list">
            {score.map((item) => (
              <li key={item.id}>
                <RatingCard item={item} />
              </li>
            ))}
          </ul>
          <div className="rating-block__controls">
            <button
              className="rating-block__btn"
              type="button"
              onClick={handleModalClose}
            >
              Закрыть
            </button>
          </div>
        </>
      )}
    </div>
  );
};

UserRating.propTypes = {
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UserRating;
