import api from "./api";
import formatDate from '../assets/js/utils/formatDate';

export const downloadSummary = (objectId, stagesId, includeImages = 0, includeComments = 0, dateRange) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }
  let [date_start, date_stop] = dateRange;
  let queryParamsOfDates = '';
  if (date_start && date_stop) {
    date_start = formatDate(new Date(date_start));
    date_stop = formatDate(new Date(date_stop));
    queryParamsOfDates = `?date_start=${date_start}&date_stop=${date_stop}`;
  }

  let body = {
    'tasks_id': stagesId,
    is_image_unload: includeImages,
    is_comment_unload: includeComments,
  }

  return fetch( `${api}/v1/object/${objectId}/report/unload${queryParamsOfDates}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token
    },
    body: JSON.stringify(body)
  })
}

