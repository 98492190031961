import { useEffect, useRef } from "react";

import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import ellipseSvg from "./img/Ellipse.svg";

const useObjectIcons = ({ map, objectIcons, objectsLayer }) => {
  const { onHover, onClick } = objectIcons ?? {};

  useEffect(() => {
    if (!objectIcons) {
      return;
    }

    const features = objectIcons.items.map((object) => {
      const iconStyle = new Style({
        image: new Icon({
          anchor: [14, 20],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src:
            typeof object.mapIcon === "string" && object.mapIcon !== "50.png"
              ? require(`../../assets/img/iconsForMap/${object.mapIcon}`)
              : ellipseSvg,
        }),
      });

      const iconFeature = new Feature({
        geometry: new Point(fromLonLat([object.longitude, object.latitude])),
        object,
      });
      iconFeature.setStyle(iconStyle);
      return iconFeature;
    });

    const source = new VectorSource({ features });
    objectsLayer.setSource(source);
  }, [objectIcons, objectsLayer]);

  const previousHoveredFeature = useRef(null);
  useEffect(() => {
    if (!onHover) {
      return;
    }

    const listener = (ev) => {
      const feature =
        map.forEachFeatureAtPixel(
          ev.pixel,
          (feature, layer) => layer === objectsLayer && feature
        ) ?? null;
      if (previousHoveredFeature.current !== feature) {
        onHover({ feature, map });
        previousHoveredFeature.current = feature;
      }
    };

    map.on("pointermove", listener);
    return () => map.un("pointermove", listener);
  }, [map, onHover, objectsLayer]);

  useEffect(() => {
    if (!onClick) {
      return;
    }

    const listener = (ev) => {
      const feature = map.forEachFeatureAtPixel(
        ev.pixel,
        (feature, layer) => layer === objectsLayer && feature
      );
      if (feature) {
        onClick({ feature, map });
      }
    };

    map.on("click", listener);
    return () => map.un("click", listener);
  }, [map, onClick, objectsLayer]);
};

export default useObjectIcons;
