import {
  OBJECT_SECTIONS_REQUEST,
  OBJECT_SECTIONS_SUCCESS,
  OBJECT_SECTIONS_ERROR,
  OBJECT_SUMMARY_SECTIONS_REQUEST,
  OBJECT_SUMMARY_SECTIONS_ERROR,
  OBJECT_SUMMARY_SECTIONS_SUCCESS,
} from "../reducers/objectSections";
import { OTHER_SECTIONS_NAME } from "../assets/js/constants";

import api from "./api";
import { OBJECT_STAGES_SET_IS_UPDATED } from "../reducers/objectStages";

export const fetchObjectSections = (id, page) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_SECTIONS_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_SECTIONS_ERROR,
        payload: ["Нет прав доступа. Пожалуйста войдите в систему"],
      });
      return;
    }

    fetch(api + `/v1/object/${id}/section`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify({ page: page }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_SECTIONS_ERROR,
            payload: data.message,
          });
          return;
        }

        dispatch({
          type: OBJECT_SECTIONS_SUCCESS,
          payload: {
            value: mapSections(data.data.items),
            pagination: data.data.pagination,
          },
        });

        dispatch({
          type: OBJECT_STAGES_SET_IS_UPDATED,
          payload: data.data.items.map(
            (item) => item.construction_object_section_id
          ),
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_SECTIONS_ERROR,
          payload: er,
        });
      });
  };
};

export const fetchSummarySections = (objectId) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_SUMMARY_SECTIONS_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_SUMMARY_SECTIONS_ERROR,
        payload: ["Нет прав доступа. Пожалуйста войдите в систему"],
      });
      return;
    }
    fetch(api + `/v1/object/${objectId}/report/index`, {
      method: "POST",
      headers: {
        [tokenName]: token,
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_SUMMARY_SECTIONS_ERROR,
            payload: data.message,
          });
          return;
        }

        dispatch({
          type: OBJECT_SUMMARY_SECTIONS_SUCCESS,
          payload: mapSummarySections(data.data),
        });
      });
  };
};

export function addObjectSection(objectId, estimateId, name) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/section/create`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
    body: JSON.stringify({
      construction_object_section_name: name,
      estimate_id: estimateId !== OTHER_SECTIONS_NAME ? estimateId : null,
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return "success";
      } else {
        return data.data.message.construction_object_section_name;
      }
    });
}

export function addObjectSectionTask(objectId, sectionId, data) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(
    api + `/v1/object/${objectId}/section/${sectionId}/task/create`,
    {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      method: "POST",
      body: JSON.stringify({
        construction_object_task_name: data.name,
        construction_object_task_date_plan_beg: data.planBeg,
        construction_object_task_date_plan_end: data.planEnd,
        construction_object_task_required_amount_work: data.amount,
        construction_object_task_unit: data.unit,
        construction_object_task_unit_coefficient: 1,
      }),
    }
  )
    .then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return "success";
      } else {
        return data.data.message;
      }
    });
}

export function deleteObjectSection(objectId, sectionId) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/delete`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
  })
    .then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return "success";
      } else {
        return data.data.message;
      }
    });
}

export function editObjectSection(objectId, sectionId, name) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/update`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
    body: JSON.stringify({
      construction_object_section_name: name,
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return "success";
      } else {
        return data.data.message;
      }
    });
}

const mapSections = (raw) => {
  return raw.map((item) => {
    return {
      id: item.construction_object_section_id,
      object_id: item.construction_object_id,
      name: item.construction_object_section_name,
      isDirty: item.is_dirty,
      isExist: item.is_exist,
      estimateID: item.estimate_id,
      estimateName: item.estimate_name,
      error: Number(item.error),
    };
  });
};

const mapSummarySections = (raw) => {
  return raw.map((item) => {
    return {
      id: item.construction_object_section_id,
      name: item.construction_object_section_name,
      stages: JSON.parse(item.tasks).map((elem) => {
        return {
          id: elem.construction_object_task_id,
          name: elem.construction_object_task_name,
        };
      }),
    };
  });
};
