import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Modal.scss";

const Modal = (props) => {
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    setIsShowModal(props.isShow);
  }, [props]);

  return (
    <div
      className={isShowModal ? "modal-container" : "modal-container__hidden"}
    >
      <div className="modal__background" />
      <div className="modal__content-container">
        <div className="modal">
          <button
            className="modal__cancel"
            onClick={() => {
              props.cancelButtonProps.onClick();
              setIsShowModal(false);
            }}
          >
            X
          </button>
          <div className="modal__content">
            <div className="modal__content__props">{props.content}</div>
            <div className="modal__content__button-container">
              <button
                className="button-ok"
                onClick={props.okButtonProps.onClick}
                disabled={props.okButtonProps.disabled}
              >
                {props.okButtonProps.content}
              </button>
              <button
                className="button-cancel"
                onClick={() => {
                  props.cancelButtonProps.onClick();
                  setIsShowModal(false);
                }}
              >
                {props.cancelButtonProps.content}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isShow: PropTypes.bool,
  content: PropTypes.node,
  okContent: PropTypes.string,
  cancelContent: PropTypes.string,
  okButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    content: PropTypes.string,
  }),
  cancelButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    content: PropTypes.string,
  }),
};

export default Modal;
