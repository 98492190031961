import React from "react";
import PropTypes from "prop-types";
import {
  inlineStarEmpty,
  inlineStarFilled,
  inlineStarHalfFilled,
} from "./img/inlineSVGicons";
import changeFloatNumberSeparator from "../../assets/js/utils/changeFloatNumberSeparator";

import "./RatingStars.scss";

const getRatingStars = (rating, maxRating) => {
  const stars = [];
  let star = null;
  const [integerRating, floatRating] = String(rating)
    .split(".")
    .map((number) => +number);
  for (let i = 1; i <= maxRating; i++) {
    star = inlineStarEmpty;
    if (i <= integerRating) {
      star = inlineStarFilled;
    }
    if (floatRating && i > integerRating && i === integerRating + 1) {
      star = inlineStarHalfFilled;
    }
    stars.push(<li key={`${rating}-${i}`}>{star}</li>);
  }

  return stars;
};

const RatingStars = ({ rating, maxRating, onClick }) => {
  const isClickable = typeof onClick === "function";

  return (
    <div
      className={`rating-stars${isClickable ? " _clickable" : ""}`}
      onClick={onClick}
    >
      <ul className="rating-stars__list">
        {getRatingStars(rating, maxRating)}
      </ul>
      <span className="rating-stars__value">
        {changeFloatNumberSeparator(rating)}
      </span>
    </div>
  );
};

RatingStars.propTypes = {
  rating: PropTypes.number,
  maxRating: PropTypes.number,
  onClick: PropTypes.func,
};

export default RatingStars;
