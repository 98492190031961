import React, { useState } from "react";
import { editIcon } from "../../assets/img/sprite";
import ContractorInfo from "../../containers/SoloObject/components/Info/components/ContractorInfo";

import "./UserInfoEditable.scss";
import PropTypes from "prop-types";
import { userData as userDataTypes } from "../../assets/js/propTypes";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

const UserInfoEditable = ({ title, type, user, onSave }) => {
  const [isUserDataEditing, setIsUserDataEditing] = useState(false);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  return (
    <div className="user-info-editable">
      <div className="user-info-editable__header">
        <h3
          className="user-info-editable__title"
          data-tip
          data-for={"userInfo" + user.id}
        >
          {title || "Пользователь"}
        </h3>

        <ReactTooltip
          multiline
          isCapture
          id={"userInfo" + user.id}
          type="dark"
          place="top"
          effect="float"
        >
          <span>Поля данного блока редактируются только в рамках объекта</span>
        </ReactTooltip>
        <button
          className={`user-info-editable__edit-btn ${
            isUserDataEditing ? "_error" : ""
          }`}
          type="button"
          onClick={() => setIsUserDataEditing((oldValue) => !oldValue)}
        >
          {isUserDataEditing ? (
            "Отменить"
          ) : (
            <>
              {editIcon}
              {!isMobileSize && <span>Редактировать</span>}
            </>
          )}
        </button>
      </div>
      <ContractorInfo
        isEditable={isUserDataEditing}
        isPossibleToAdd={false}
        withoutExecutors
        type={type}
        contractor={{
          builderName: user.name,
          builderEmail: user.email,
          builderPhone: user.phone,
          builderContact: user.additionalInfo,
          organizationName: user.orgName,
          organizationContacts: user.orgContacts,
          organizationAddress: user.orgAddress,
        }}
        onCancel={() => setIsUserDataEditing(false)}
        onSave={onSave}
        onlyOutsideSaveHandler
      />
    </div>
  );
};

UserInfoEditable.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  user: userDataTypes.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UserInfoEditable;
