export const TOPICS_REQUEST = "TOPICS_REQUEST";
export const TOPICS_REQUEST_SUCCESS = "TOPICS_REQUEST_SUCCESS";
export const TOPICS_REQUEST_ERROR = "TOPICS_REQUEST_ERROR";
export const TOPIC_REQUEST = "TOPIC_REQUEST";
export const TOPIC_REQUEST_SUCCESS = "TOPIC_REQUEST_SUCCESS";
export const TOPIC_REQUEST_ERROR = "TOPIC_REQUEST_ERROR";

const initialState = {
  isFetching: false,
  isError: false,
  isUpdated: false,
  errors: [],
  items: [],
  pagination: {},
};

const getTopicById = (topics, id) => {
  const existingTopic = topics.find((topic) => Number(topic.id) === id);
  if (existingTopic) {
    return existingTopic;
  }

  const topic = {
    id,
    isFetching: false,
    isError: false,
    isUpdated: false,
    errors: [],
  };
  topics.push(topic);
  return topic;
};

const helpTopics = (state = initialState, action) => {
  switch (action.type) {
    case TOPICS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case TOPICS_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        items: action.payload.items,
        pagination: action.payload.pagination,
      };
    }
    case TOPICS_REQUEST_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: action.payload,
      };
    }
    case TOPIC_REQUEST: {
      const items = [...state.items];
      const topic = getTopicById(items, action.payload);
      Object.assign(topic, {
        isFetching: true,
        isError: false,
        isUpdated: false,
      });

      return { ...state, items };
    }
    case TOPIC_REQUEST_SUCCESS: {
      const items = [...state.items];
      const topic = getTopicById(items, action.payload.id);
      Object.assign(topic, action.payload, {
        isFetching: false,
        isError: false,
        isUpdated: true,
      });

      return { ...state, items };
    }
    case TOPIC_REQUEST_ERROR: {
      const items = [...state.items];
      const topic = getTopicById(items, action.payload.id);
      Object.assign(topic, {
        isFetching: false,
        isError: true,
        isUpdated: false,
        errors: action.payload.errors,
      });

      return { ...state, items };
    }
    default: {
      return state;
    }
  }
};

export default helpTopics;
