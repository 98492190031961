import React, { useCallback, useEffect } from "react";
import "./GiveRightsContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../actions/UserRightsActions";
import UserCard from "./components";
import Loader from "../../components/Loader";
import Paginator from "../../components/Paginator";
import UserSearch from "../../components/UserSearch";

const GiveRightsContainer = () => {
  const dispatch = useDispatch();
  const rightsStore = useSelector((store) => store.userRights);
  const { isDesktopSize } = useSelector((store) => store.responsive.window);
  const getUserList = useCallback(
    (filters, page) => {
      dispatch(fetchUserList(filters, page));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!rightsStore.isUpdated && !rightsStore.isFetching) {
      getUserList();
    }
  });

  return (
    <div className="container-wrapper">
      <div className="rights container">
        <div className="content-card">
          {rightsStore.isFetching ? <Loader /> : null}
          <div className="rights-header">Управление пользователями</div>
          <UserSearch onFilter={(filter) => getUserList(filter)} />
          <div className="rights-content">
            <div className="rights-content-table">
              {isDesktopSize && (
                <div className="rights-content-head">
                  <span>ФИО</span>
                  <span>Роль</span>
                  <span>Телефон</span>
                  <span>Почта</span>
                  <span>Организация</span>
                  <span className="rights-content-head-rights">Права</span>
                  <span />
                </div>
              )}
              {rightsStore.items.length ? (
                <>
                  {rightsStore.items.map((item) => {
                    return (
                      <div className="user-rights-cards" key={item.user_id}>
                        <UserCard user={item} onChange={getUserList} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="no-notes">Нет пользователей</div>
              )}
            </div>
            {rightsStore.pagination.totalCount >
            rightsStore.pagination.pageSize ? (
              <Paginator
                currentPage={rightsStore.pagination.currentPage}
                totalPages={rightsStore.pagination.totalPageCount}
                total={rightsStore.pagination.totalCount}
                pageSize={rightsStore.pagination.pageSize}
                onPageChange={(page) => getUserList(undefined, page)}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiveRightsContainer;
