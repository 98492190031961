import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import "./accountWidget.scss";

import Unlogged from "./components/Unlogged";
import Logged from "./components/Logged";
import Logo from "../Logo/logo";
import { menuIcon, arrowBackIcon } from "../../assets/img/sprite";
import { ROUTES } from "../../routes";

const HEADER_WITHOUT_BACKGROUND_ROUTES = [ROUTES.LOGIN.PATH, ROUTES.START.PATH];

const AccountWidget = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { isMobileSize, isTabletSize, isDesktopSize } = useSelector((store) => store.responsive.window);

  useEffect(() => {
    props.toggleMenu && props.toggleMenu(isDesktopSize);
  }, [isDesktopSize, isTabletSize, isMobileSize]);

  let content = props.isLogged ? (
    <Logged
      name={props.name ? props.name : "Имя"}
      role={props.role ? props.role : "Роль"}
      isMenuOpen={props.isMenuOpen}
      toggleMenu={props.toggleSidebar}
      arrowBack={props.arrowBack}
    />
  ) : (
    <Unlogged
      onAccountWidgetControlsReady={props.onAccountWidgetControlsReady}
    />
  );

  const arrowBack = (
    <svg
      width="35"
      height="25"
      viewBox="0 0 43 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1233 0.970767C14.2833 1.13042 14.4103 1.32009 14.497 1.5289C14.5836 1.73771 14.6282 1.96157 14.6282 2.18764C14.6282 2.41372 14.5836 2.63757 14.497 2.84638C14.4103 3.05519 14.2833 3.24486 14.1233 3.40452L5.0242 12.5001L14.1233 21.5958C14.2831 21.7556 14.4098 21.9453 14.4963 22.1541C14.5828 22.3629 14.6273 22.5866 14.6273 22.8126C14.6273 23.0386 14.5828 23.2624 14.4963 23.4712C14.4098 23.68 14.2831 23.8697 14.1233 24.0295C13.9635 24.1893 13.7738 24.3161 13.565 24.4026C13.3562 24.4891 13.1324 24.5336 12.9064 24.5336C12.6804 24.5336 12.4566 24.4891 12.2478 24.4026C12.039 24.3161 11.8493 24.1893 11.6895 24.0295L1.37702 13.717C1.21696 13.5574 1.08997 13.3677 1.00332 13.1589C0.916671 12.9501 0.87207 12.7262 0.87207 12.5001C0.87207 12.2741 0.916671 12.0502 1.00332 11.8414C1.08997 11.6326 1.21696 11.4429 1.37702 11.2833L11.6895 0.970767C11.8492 0.810706 12.0388 0.683714 12.2477 0.597067C12.4565 0.51042 12.6803 0.46582 12.9064 0.46582C13.1325 0.46582 13.3563 0.51042 13.5651 0.597067C13.7739 0.683714 13.9636 0.810706 14.1233 0.970767Z"
        fill="#37352F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59375 12.5C2.59375 12.0442 2.77483 11.607 3.09716 11.2847C3.41949 10.9623 3.85666 10.7812 4.3125 10.7812H40.4062C40.8621 10.7812 41.2993 10.9623 41.6216 11.2847C41.9439 11.607 42.125 12.0442 42.125 12.5C42.125 12.9558 41.9439 13.393 41.6216 13.7153C41.2993 14.0377 40.8621 14.2188 40.4062 14.2188H4.3125C3.85666 14.2188 3.41949 14.0377 3.09716 13.7153C2.77483 13.393 2.59375 12.9558 2.59375 12.5Z"
        fill="#37352F"
      />
    </svg>
  );

  return props.isMenuOpen && isDesktopSize ? null : (
    <div
      className={`container-wrapper account-widget-wrapper ${
        HEADER_WITHOUT_BACKGROUND_ROUTES.includes(location.pathname)
          ? "_without-background"
          : ""
      }`}
    >
      <div className="account-widget-container">
        <Logo className="login-logo" />
        {props.arrowBack && isMobileSize ? (
            <button
                className="account-widget-arrow-back"
                onClick={() => {
                  history.goBack();
                }}
            >
              {arrowBackIcon}
              <span>Назад</span>
            </button>
        ) : null}
        {content}
        {!props.isLogged || props.isMenuOpen ? null : (
          <button
            className="account-widget-menu-toggle _icon"
            onClick={props.toggleMenu}
          >
            {menuIcon}
          </button>
        )}
      </div>
    </div>
  );
};

AccountWidget.propTypes = {
  onAccountWidgetControlsReady: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

export default AccountWidget;
