import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, useHistory, Link, useParams } from "react-router-dom";

import "./ObjectsHeader.scss";
import Tabs from "../../../../components/Tabs";
import { ROUTES } from "../../../../routes";
import CheckPermissions from "../../../../components/CheckPermissions";
import { plusIcon } from "../../../../assets/img/sprite";

const ObjectsHeader = (props) => {
  const location = useLocation();
  const history = useHistory();
  const objectsStore = useSelector((store) => store.objects);
  const { isMobileSize} = useSelector(
      (store) => store.responsive.window
  );

  const withNavTabs = props.navTabItems.length > 1;

  const tabClick = (item) => {
    history.replace(item.key);
  };

  const handleNavTabsChange = ({ key }) => {
    props.onClickAvailable(key !== "org");
  };

  return (
    <div className="objects-header">
      {withNavTabs && <h1 className="objects-header-title">{props.title}</h1>}
      <div
        className={`objects-header-controls ${
          !withNavTabs ? "_without-divider" : ""
        }`}
      >
        {withNavTabs ? (
          <Tabs
            items={props.navTabItems}
            onClick={handleNavTabsChange}
            active={
              new URLSearchParams(location.search).get("isGrouped") === "true"
                ? "org"
                : "self"
            }
            isNav={!isMobileSize}
            isSelect={isMobileSize}
          />
        ) : (
          <h1 className="objects-header-title">{props.navTabItems[0].value}</h1>
        )}
        {/*<div className="objects-header-controls-switch">*/}
        <Tabs
          items={props.tabItems}
          onClick={tabClick}
          active={location.pathname}
        />
        {/*</div>*/}
      </div>
      <CheckPermissions permissions={["perm_construction-object_create"]}>
        <Link
          className={
            objectsStore.isAvailable
              ? "objects-header-controls-button__disabled"
              : "objects-header-controls-button"
          }
          disabled={objectsStore.isAvailable}
          to={ROUTES.OBJECT.ADD.PATH}
        >
          {plusIcon}
          <span>Добавить объект</span>
        </Link>
      </CheckPermissions>
    </div>
  );
};

ObjectsHeader.propTypes = {
  tabItems: PropTypes.array.isRequired,
  navTabItems: PropTypes.array.isRequired,
  title: PropTypes.string,
  onClickAvailable: PropTypes.func,
};

export default ObjectsHeader;
