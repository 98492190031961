import React from "react";
import PropTypes from "prop-types";

import "./FileCard.scss";

const FileCard = (props) => {
  return (
      <div
          onClick={props.onClick}
          className={props.selected ? "file-card-selected" : "file-card"}
      >
        <div className="file-card-icon">{props.icon}</div>
        <div className="file-card-info">
          <div className="file-card-name">{props.name}</div>
          <div className="file-card-size">{props.size}</div>
        </div>
      </div>
  );
};

FileCard.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.any,
  size: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default FileCard;
