import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./MultiSelect.scss";

import { inlineArrow } from "./img/inlineSVGicons";

const MultiSelect = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options] = useState(props.options || []);
  const [hasOptions, setHasOptions] = useState(false);

  const hasValidation =
    Array.isArray(props.validators) && props.validators.length > 0;

  const multiselectEl = useRef(null);
  const toggleEl = useRef(null);
  const optionsListEl = useRef(null);

  useEffect(() => {
    setHasOptions(options.length > 0);
  }, [options]);

  const handleOptionSelect = ({ key }) => {
    const updatedOptions = [...props.values];
    const optionIndex = updatedOptions.indexOf(key);
    if (optionIndex >= 0) {
      updatedOptions.splice(optionIndex, 1);
    } else {
      updatedOptions.push(key);
      if (!validate(key)) {
        props.onValidationFail &&
          props.onValidationFail(() => {
            props.onChange(updatedOptions);
          });
        return;
      }
    }

    props.onChange(updatedOptions);
  };

  const useActiveElement = () => {
    const [active, setActive] = useState(document.activeElement);

    const handleFocusIn = () => {
      setActive(document.activeElement);
    };

    useEffect(() => {
      document.addEventListener("focusin", handleFocusIn);
      return () => {
        document.removeEventListener("focusin", handleFocusIn);
      };
    }, []);

    return active;
  };

  const focusedElement = useActiveElement();

  useEffect(() => {
    optionsListEl.current &&
      setIsOpen(optionsListEl.current.contains(focusedElement));
  }, [focusedElement]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.closest(".multiselect__toggle") === toggleEl.current) {
        setIsOpen((prevValue) => !prevValue);
        return;
      }
      setIsOpen(multiselectEl.current.contains(e.target));
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const validate = (value) => {
    if (!hasValidation) return true;

    let isValid = false;
    props.validators.forEach((validator) => {
      isValid = isValid || validator(value);
    });
    return isValid;
  };

  return (
    <div ref={multiselectEl} className="multiselect">
      <button
        ref={toggleEl}
        type="button"
        className="multiselect__toggle"
        disabled={!hasOptions}
      >
        <span>{props.placeholder}</span>
        {inlineArrow}
      </button>

      {hasOptions && isOpen && (
        <ul ref={optionsListEl} className="multiselect__list">
          {options.map((option, i) => (
            <li key={option.key}>
              <label className="multiselect__option">
                <input
                  type="checkbox"
                  name={`option-${i}`}
                  onChange={(e) => {
                    e.currentTarget.focus();
                    handleOptionSelect(option);
                  }}
                  checked={props.values.includes(option.key)}
                />
                <span>{option.value}</span>
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationFail: PropTypes.func,
};

export default MultiSelect;
