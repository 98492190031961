import {
  ADD_OBJECT_REQUEST,
  ADD_OBJECT_SUCCESS,
  ADD_OBJECT_ERROR,
  ADD_OBJECT_UPLOAD_FILE_REQUEST,
  ADD_OBJECT_UPLOAD_FILE_SUCCESS,
  ADD_OBJECT_UPLOAD_FILE_ERROR,
  ADD_OBJECT_UPDATE_FILE_REQUEST,
  ADD_OBJECT_UPDATE_FILE_SUCCESS,
  ADD_OBJECT_UPDATE_FILE_ERROR,
  ADD_OBJECT_LINK_SUCCESS,
} from "../reducers/objectAdd";
import { OPEN_INFO_POPUP } from "../reducers/infoPopup";
import GeoJSON from "ol/format/GeoJSON";
import api from "./api";

const geoJson = new GeoJSON();

const getMethods = (type) => {
  let methods = null;
  switch (type) {
    case "designProject": {
      methods = {
        get: "view-design-project",
        post: "upload-design-project",
        delete: "delete-design-project",
        update: "update-design-project",
        download: "download-design-project",
      };
      break;
    }
    case "estimates": {
      methods = {
        get: "view-estimates",
        post: "upload-estimates",
        delete: "delete-estimates",
        update: "update-estimate",
        download: "download-estimates",
      };
      break;
    }
    case "projectEstimates": {
      methods = {
        get: "view-project-estimates",
        post: "upload-project-estimates",
        delete: "delete-project-estimates",
        update: "update-project-estimates",
        download: "download-project-estimates",
      };
      break;
    }
    default: {
    }
  }
  return methods;
};

export function addObject(
  info,
  sections,
  estimates,
  projectEstimates,
  designProject
) {
  return (dispatch) => {
    dispatch({
      type: ADD_OBJECT_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: ADD_OBJECT_ERROR,
        payload: "Ошибка прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    let body = mapAddObjectRequestBody(
      info,
      sections,
      estimates,
      projectEstimates,
      designProject
    );
    return fetch(api + "/v1/object/create/card", {
      method: "POST",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: ADD_OBJECT_ERROR,
            payload: data.data.message[0],
          });
          return data.data.message[0];
        }

        dispatch({
          type: ADD_OBJECT_SUCCESS,
          payload: data,
        });
        return data.data;
      })
      .catch((er) => {
        dispatch({
          type: ADD_OBJECT_ERROR,
          payload: er,
        });
      });
  };
}

export function uploadFiles(type, files) {
  return (dispatch) => {
    dispatch({
      type: ADD_OBJECT_UPLOAD_FILE_REQUEST,
      payload: type,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: ADD_OBJECT_UPLOAD_FILE_ERROR,
        payload: {
          name: type,
          value: "Ошибка прав доступа. Пожалуйста войдите в систему",
        },
      });
      return;
    }

    let body = new FormData();
    files.forEach((file) => {
      body.append("files[]", file);
    });
    return fetch(api + "/v1/document/" + getUrlByFilesType(type), {
      method: "POST",
      headers: {
        [tokenName]: token,
      },
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: ADD_OBJECT_UPLOAD_FILE_ERROR,
            payload: {
              name: type,
              value: data,
            },
          });

          dispatch({
            type: OPEN_INFO_POPUP,
            payload: {
              text: data.data.message.files,
            },
          });
          // return data.data.message.files;
        } else {
          dispatch({
            type: OPEN_INFO_POPUP,
            payload: {
              text: "Файлы успешно добавлены.",
            },
          });
        }

        dispatch({
          type: ADD_OBJECT_UPLOAD_FILE_SUCCESS,
          payload: {
            name: type,
            itemsId: data.data.files_id,
            items: data.data.files_info,
            sections: data.data.items
              ? data.data.items.map((item) => {
                  return {
                    objectId: item.construction_object_id,
                    sectionId: item.construction_object_section_id,
                    error: Number(item.error),
                    name: item.construction_object_section_name,
                    estimateID: item.estimate_id,
                    estimateName: item.estimate_name,
                    isDirty: !!item.is_dirty,
                    isExist: !!item.is_exist,
                    tasks: item.task.map((t) => {
                      return {
                        id: t.construction_object_task_id,
                        name: t.construction_object_task_name,
                        completion:
                          t.construction_object_task_percent_completion,
                        requiredAmount:
                          t.construction_object_task_required_amount_work,
                        unit: t.construction_object_task_unit,
                        coefficient:
                          t.construction_object_task_unit_coefficient,
                        updated: t.construction_object_task_last_updated_at,
                        sectionId: t.construction_object_section_id,
                        createdAt: t.construction_object_task_created_at,
                        factBeg: t.construction_object_task_date_fact_beg,
                        factEnd: t.construction_object_task_date_fact_end,
                        planBeg: t.construction_object_task_date_plan_beg,
                        planEnd: t.construction_object_task_date_plan_end,
                      };
                    }),
                  };
                })
              : [],
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: ADD_OBJECT_UPLOAD_FILE_ERROR,
          payload: {
            name: type,
            value: er.message,
          },
        });
      });
  };
}

export function updateFile(type, { id, name, control, builder }) {
  return (dispatch) => {
    const methods = getMethods(type);
    if (!methods) {
      return "Для экшена updateFile указан неверный параметр type";
    }

    dispatch({
      type: ADD_OBJECT_UPDATE_FILE_REQUEST,
      payload: type,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: ADD_OBJECT_UPLOAD_FILE_ERROR,
        payload: {
          name: type,
          value: "Ошибка прав доступа. Пожалуйста войдите в систему",
        },
      });
      return;
    }

    //TODO Реализовать адаптер для всех типов, данная реализация только для смет
    const fileData = {
      estimate_id: id,
      estimate_name: name,
      construction_object_construction_control_id: control.id,
      construction_object_construction_control_name: control.name,
      construction_object_construction_control_email: control.email,
      construction_object_construction_control_phone_number: control.phone,
      construction_object_construction_control_contact_info:
        control.additionalInfo,
      construction_object_construction_control_organization_name:
        control.orgName,
      construction_object_construction_control_organization_contacts:
        control.orgContacts,
      construction_object_construction_control_organization_address:
        control.orgAddress,
      construction_object_builder_id: builder.id,
      construction_object_builder_name: builder.name,
      construction_object_builder_email: builder.email,
      construction_object_builder_phone_number: builder.phone,
      construction_object_builder_contact_info: builder.additionalInfo,
      construction_object_builder_organization_name: builder.orgName,
      construction_object_builder_organization_contacts: builder.orgContacts,
      construction_object_builder_organization_address: builder.orgAddress,
    };
    const body = JSON.stringify(fileData);

    return fetch(api + `/v1/document/${methods.update}/`, {
      method: "POST",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: ADD_OBJECT_UPDATE_FILE_ERROR,
            payload: {
              name: type,
              value: data,
            },
          });
          return data.data.message.files;
        }

        dispatch({
          type: ADD_OBJECT_UPDATE_FILE_SUCCESS,
          payload: {
            name: type,
            item: {
              id: id,
              name: name,
              construction_object_construction_control_id: control.id,
              construction_object_construction_control_name: control.name,
              construction_object_construction_control_email: control.email,
              construction_object_construction_control_phone_number:
                control.phone,
              construction_object_construction_control_contact_info:
                control.additionalInfo,
              construction_object_construction_control_organization_name:
                control.orgName,
              construction_object_construction_control_organization_contacts:
                control.orgContacts,
              construction_object_construction_control_organization_address:
                control.orgAddress,
              construction_object_builder_id: builder.id,
              construction_object_builder_name: builder.name,
              construction_object_builder_email: builder.email,
              construction_object_builder_phone_number: builder.phone,
              construction_object_builder_contact_info: builder.additionalInfo,
              construction_object_builder_organization_name: builder.orgName,
              construction_object_builder_organization_contacts:
                builder.orgContacts,
              construction_object_builder_organization_address:
                builder.orgAddress,
            },
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: ADD_OBJECT_UPDATE_FILE_ERROR,
          payload: {
            name: type,
            value: er.message,
          },
        });
      });
  };
}

export const removeUserFromFile = (type, { id, role }) => {
  return (dispatch) => {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return "Нет прав доступа. Пожалуйста войдите в систему";
    }

    //TODO Реализовать адаптер для всех типов файлов, данная реализация только для смет
    const fileData = {
      estimate_id: id,
    };

    const body = JSON.stringify(fileData);

    return fetch(
      api +
        `/v1/document/unassign-${
          role === "construction-control" ? "control" : role
        }/`,
      {
        method: "POST",
        headers: {
          [tokenName]: token,
          "Content-Type": "application/json",
        },
        body: body,
      }
    )
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          return "Ошибка в процессе открепления пользователя";
        }

        let roleName = "";

        switch (role) {
          case "construction-control":
            roleName = "construction_control";
            break;
          case "builder":
            roleName = "builder";
            break;
          default:
            break;
        }

        dispatch({
          type: ADD_OBJECT_UPDATE_FILE_SUCCESS,
          payload: {
            name: type,
            item: {
              id: id,
              [`construction_object_${roleName}_id`]: "",
              [`construction_object_${roleName}_name`]: "",
              [`construction_object_${roleName}_email`]: "",
              [`construction_object_${roleName}_phone_number`]: "",
              [`construction_object_${roleName}_contact_info`]: "",
              [`construction_object_${roleName}_organization_name`]: "",
              [`construction_object_${roleName}_organization_contacts`]: "",
              [`construction_object_${roleName}_organization_address`]: "",
            },
          },
        });
      })
      .catch((er) => {
        return er.message;
      });
  };
};

const getUrlByFilesType = (type) => {
  const urls = {
    estimates: "upload-estimates",
    projectEstimates: "upload-project-estimates",
    designProject: "upload-design-project",
  };

  return urls[type];
};

export function getArchiveObjectsForCreate(filters, page) {
  return (dispatch) => {
    dispatch({
      type: ADD_OBJECT_REQUEST,
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: ADD_OBJECT_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }
    if (filters) {
      if (
        filters.filter((item) => item.value === "").length === filters.length
      ) {
        filters = undefined;
      }
    }

    let body = {
      filter: filters,
      page: page,
    };
    fetch(`${api}/v1/archive-object`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: JSON.stringify(body),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: ADD_OBJECT_LINK_SUCCESS,
            payload: {
              items: data.data.items,
              pagination: data.data.pagination,
            },
          });
          return;
        } else {
          dispatch({
            type: ADD_OBJECT_ERROR,
            payload: data,
          });
          return;
        }
      });
  };
}

const mapAddObjectRequestBody = (
  info,
  sections,
  estimates,
  projectEstimates,
  designProject
) => {
  return {
    key: "construction_object",
    params: {
      //Информация
      construction_object_name: info.name,
      construction_object_description: info.description,
      construction_object_address: info.address,
      construction_object_date_plan_beg: info.planBeg,
      construction_object_date_plan_end: info.planEnd,
      construction_object_work_type_id: info.jobType.key,
      construction_object_type_id: info.objectType.key,
      construction_object_type_name: info.objectType.value,
      national_project_id: info.nationalObjectType.key,
      longitude: info.longitude,
      latitude: info.latitude,
      polygons: geoJson.writeFeaturesObject(info.polygons ?? []),
      construction_object_archive_ids: info.archive_ids,
      //Заказчик
      construction_object_customer_name: info.customerName,
      construction_object_customer_phone_number: info.customerPhone,
      construction_object_customer_contact_info: info.customerAdditionalInfo,
      construction_object_customer_email: info.customerEmail,
      construction_object_customer_id: info.customerId,
      //Подрядчик
      construction_object_builder_id: info.contractorId,
      construction_object_builder_name: info.contractorName,
      construction_object_builder_phone_number: info.contractorPhone,
      construction_object_builder_contact_info: info.contractorAdditionalInfo,
      construction_object_builder_email: info.contractorEmail,
      construction_object_builder_organization_address:
        info.contractorOrgAddress,
      construction_object_builder_organization_contacts:
        info.contractorOrgContacts,
      construction_object_builder_organization_name: info.contractorOrgName,
      //Проектировщик
      construction_object_designer_id: info.designerId,
      construction_object_designer_name: info.designerName,
      construction_object_designer_phone_number: info.designerPhone,
      construction_object_designer_contact_info: info.designerAdditionalInfo,
      construction_object_designer_email: info.designerEmail,
      construction_object_designer_organization_address:
        info.designerOrgAddress,
      construction_object_designer_organization_contacts:
        info.designerOrgContacts,
      construction_object_designer_organization_name: info.designerOrgName,
      //Архитектор
      construction_object_architect_id: info.architectId,
      construction_object_architect_name: info.architectName,
      construction_object_architect_phone_number: info.architectPhone,
      construction_object_architect_contact_info: info.architectAdditionalInfo,
      construction_object_architect_email: info.architectEmail,
      construction_object_architect_organization_address:
        info.architectOrgAddress,
      construction_object_architect_organization_contacts:
        info.architectOrgContacts,
      construction_object_architect_organization_name: info.architectOrgName,
      //Файлы
      estimate_id: estimates && estimates.itemsId,
      project_estimate_id: projectEstimates && projectEstimates.itemsId,
      design_project_id: designProject && designProject.itemsId,
      construction_object_ref_video: info.refVideo,
    },
    children:
      sections.items &&
      sections.items.map((section) => {
        return {
          key: "section",
          params: {
            construction_object_section_name: section.name,
          },
          children: section.tasks.map((item) => {
            return {
              key: "task",
              params: {
                construction_object_task_name: item.name,
                construction_object_task_date_plan_beg: item.planBeg,
                construction_object_task_date_plan_end: item.planEnd,
                construction_object_task_required_amount_work:
                  item.requiredAmount,
                construction_object_task_unit: item.unit,
                construction_object_task_unit_coefficient: item.coefficient,
              },
            };
          }),
        };
      }),
  };
};
