import {
  RIGHTS_ERROR,
  RIGHTS_REQUEST,
  RIGHTS_SUCCESS,
} from "../reducers/userRights";
import api from "./api";

export function fetchUserList(filters, page) {
  return (dispatch) => {
    dispatch({
      type: RIGHTS_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: RIGHTS_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    if (filters) {
      if (
        filters.filter((item) => item.value === "").length === filters.length
      ) {
        filters = undefined;
      }
    }

    fetch(api + `/v1/user/control`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      method: "POST",
      body: JSON.stringify({ page: page, filter: filters }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: RIGHTS_ERROR,
          });
          return;
        }

        dispatch({
          type: RIGHTS_SUCCESS,
          payload: {
            items: data.data.items,
            pagination: data.data.pagination,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: RIGHTS_ERROR,
          payload: error,
        });
      });
  };
}

export function setUserPermission(value, uid) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(api + `/v1/user/${value ? 'add' : 'remove'}-extended-permission`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
    body: JSON.stringify({user_id: uid})
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data._meta.isSuccessful) {
        return data.data.message[0];
      }
      return true
    })
}


export function setUserOrganization(value, uid) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(api + `/v1/user/update-organization`, {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    method: "POST",
    body: JSON.stringify({organization_name: value, profile_id: uid})
  })
    .then((response) => response.json())
    .then((data) => {
      if  (!data._meta.isSuccessful) {
        return data.data.message[0]
      }
      return true
    })
}