import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./ObjectDesignProject.scss";

import FileSolo from "../FileSolo";
import {
  fetchFiles,
  uploadFiles,
  deleteFiles,
  downloadFiles,
} from "../../actions/ObjectFilesActions";
import { inlineUnnown, inlinePdf } from "./img/inlineSVGicons";
import { UserContext } from "../../App";
import Loader from "../Loader";
import toaster from "toasted-notes";

const ObjectDesignProject = (props) => {
  const designProjectStore = useSelector(
    (store) => store.objectFiles.designProject
  );
  const dispatch = useDispatch();
  const upload = (files) =>
    dispatch(uploadFiles("designProject", props.id, files)).then((r) => {
      if (r) {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
    });
  const onDelete = (ids) => deleteFiles("designProject", props.id, ids);
  const download = (ids) =>
    downloadFiles(
      "designProject",
      props.id,
      ids,
      designProjectStore.items[0].name,
      ".pdf"
    );
  const userContext = useContext(UserContext);

  const getFiles = useCallback(
    () => dispatch(fetchFiles("designProject", props.id)),
    [dispatch, props.id]
  );

  useEffect(() => {
    if (
      !designProjectStore.isUpdated &&
      !designProjectStore.isFetching &&
      !designProjectStore.isError
    ) {
      getFiles();
    }
  }, [designProjectStore, getFiles]);

  let file = null;
  if (designProjectStore.items && designProjectStore.items.length) {
    file = {
      ...designProjectStore.items[0],
      icon:
        designProjectStore.items[0].extension === ".pdf"
          ? inlinePdf
          : inlineUnnown,
    };
  }

  const onUpload = (files) => {
    upload(files).then(() => getFiles());
  };

  const onDel = (ids) => {
    onDelete(ids).then(() => getFiles());
  };

  const isHavePermission = (permission) => {
    return !!userContext.permissions.filter((perm) => perm.name === permission)
      .length;
  };

  return (
    <div className="solo-object-design-project">
      {designProjectStore.isFetching ? <Loader /> : null}
      <FileSolo
        status={props.status}
        file={file}
        onUpload={
          isHavePermission("perm_document_upload") &&
          props.status !== "STOPPED" &&
          props.status !== "WORKS_DONE"
            ? onUpload
            : null
        }
        onDownload={
          isHavePermission("perm_document_download") ? download : null
        }
        onDelete={isHavePermission("perm_document_delete") ? onDel : null}
        isFetching={designProjectStore.isFetching}
        isError={designProjectStore.isError}
        label="Добавить файл (.pdf)"
        format=".pdf"
        helpMessage="Максимальный размер загружаемого файла - 200 мб"
      />
    </div>
  );
};

ObjectDesignProject.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ObjectDesignProject;
