export const mail = (value) => {
  // eslint-disable-next-line
  if (!/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(value)) {
    return "Некорректно";
  }
  return "";
};

export const required = (value) => {
  if (value.length === 0) {
    return "Обязательное поле";
  }

  return "";
};

export const phone = (value) => {
  if (
    // eslint-disable-next-line
    !/^[\+]?[(]?[0-9]{4}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)
  ) {
    return "Некорректный номер";
  }
  return "";
};

export const password = (value) => {
  if (value && value.length < 6) {
    return "Слишком короткий пароль";
  }

  return "";
};
