import {
  STAGE_STATUSES_REQUEST,
  STAGE_STATUSES_SUCCESS,
  STAGE_STATUSES_ERROR,
} from "../reducers/stageStatuses";

import api from "./api";

export function fetchStatuses() {
  return (dispatch) => {
    dispatch({
      type: STAGE_STATUSES_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: STAGE_STATUSES_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }
    fetch(api + "/v1/task/statuses/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: STAGE_STATUSES_ERROR,
          });
          return;
        }
        let items = data.data.map((item) => {
          return {
            key: item.status_id,
            value: item.status_name,
          };
        });
        dispatch({
          type: STAGE_STATUSES_SUCCESS,
          payload: {
            items: items,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: STAGE_STATUSES_ERROR,
          payload: er,
        });
      });
  };
}
