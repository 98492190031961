export const OBJECT_STAGES_REQUEST = "OBJECT_STAGES_REQUEST";
export const OBJECT_STAGES_SUCCESS = "OBJECT_STAGES_SUCCESS";
export const OBJECT_STAGES_ERROR = "OBJECT_STAGES_ERROR";
export const SECTIONS_GO_TO_NEW_OBJECT = "GO_TO_NEW_OBJECT";
export const OBJECT_STAGES_SET_IS_UPDATED = "OBJECT_STAGES_SET_IS_UPDATED";

const initialState = {
  objectId: null,
  isFetching: false,
  items: {},
  isError: false,
  errors: [],
};

const objectSections = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_STAGES_REQUEST: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload]: {
            isFetching: true,
            isError: false,
            errors: [],
          },
        },
      };
    }
    case OBJECT_STAGES_SUCCESS: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.sectionId]: {
            isFetching: false,
            isUpdated: true,
            items: action.payload.value,
            pagination: action.payload.pagination,
          },
        },
      };
    }
    case OBJECT_STAGES_ERROR: {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.sectionId]: {
            isFetching: false,
            isError: true,
            isUpdated: true,
            errors: action.payload.value,
          },
        },
      };
    }
    case OBJECT_STAGES_SET_IS_UPDATED: {
      let newItems = [];
      for(let key in state.items){
        let item = state.items[key];

        if (action.payload.includes(key)) {
          item.isUpdated = false
        }
        newItems.push(item);
      }

      return {
        ...state,
        items: newItems,
      };
    }
    case SECTIONS_GO_TO_NEW_OBJECT: {
      return {
        ...initialState,
        objectId: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default objectSections;
