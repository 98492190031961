import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./StageCardInfo.scss";

import EditableDateField from "../../../../../../components/EditableDateField";
import EditableField from "../../../../../../components/EditableField";
import CheckPermissions from "../../../../../../components/CheckPermissions";
import { deleteIcon, viewIcon } from "../../../../../../assets/img/sprite";
import Status from "../../../../../../components/Status";

const StageCardInfo = (props) => {
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const deleteHistory = (e) => {
    e.stopPropagation();
    props.onDeleteHistory();
  };
  const isApprove =
    props.info.works_history_approve_customer &&
    props.info.works_history_approve_control;
  return (
    <React.Fragment>
      <div className="stage-histories-info">
        <div className="stage-histories-status">
          {isMobileSize && <span className="stage-histories-label">Статус</span>}
          <Status value={isApprove ? "Утверждено" : "Не утверждено"} color={isApprove ? "#0DA865" : "#999999"} />
        </div>

        <div className="stage-histories-created">
          {isMobileSize && <span className="stage-histories-label">Дата создания</span>}
          <EditableDateField value={props.info.works_history_created_at} />
        </div>

        <div className="stage-histories-updated">
          {isMobileSize && <span className="stage-histories-label">Дата изменения</span>}
          <EditableDateField value={props.info.works_history_updated_at} />
        </div>

        <div className="stage-histories-amount">
          {isMobileSize && <span className="stage-histories-label">Объем работ</span>}
          <EditableField value={props.info.works_history_amount} />
        </div>

        {props.isUnit ? (
          <div className="stage-histories-unit">
            {isMobileSize && <span className="stage-histories-label">Единицы измерения</span>}
            <EditableField value={props.info.construction_object_task_unit} />
          </div>
        ) : (
          <div className="stage-histories-table-controls">
            <button
              className={`stage-histories-view ${isMobileSize ? "" : "_icon"}`}
              type="button"
              onClick={(e) => props.onView && props.onView()}
              disabled={isAvailable}
            >
              {viewIcon}
              {isMobileSize && <span>Подробнее</span>}
            </button>
            {props.status === "WORKS_DONE" ||
            props.status === "STOPPED" ? null : (
              <CheckPermissions permissions={["perm_works-history_delete"]}>
                <button
                  className={`stage-histories-delete _error ${isMobileSize ? "" : "_icon"}`}
                  type="button"
                  onClick={(e) => deleteHistory(e)}
                  disabled={isAvailable}
                >
                  {deleteIcon}
                </button>
              </CheckPermissions>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

StageCardInfo.props = {
  info: PropTypes.object,
  onView: PropTypes.func,
};

export default StageCardInfo;
