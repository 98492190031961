import { CONFIRM_ERROR, CONFIRM_REQUEST, CONFIRM_SUCCESS } from "../reducers/userConfirmation";

import api from "./api";

export function fetchUsers(page) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: CONFIRM_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    fetch(api + `/v1/application`, {
      method: "POST",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({page: page})
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: CONFIRM_ERROR
          });
          return;
        }

        dispatch({
          type: CONFIRM_SUCCESS,
          payload: {
            items: data.data.items,
            pagination: data.data.pagination
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: CONFIRM_ERROR,
          payload: error,
        });
      });
  };
}

export const applyUser = (uid) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/application/apply`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify({
      user_profile_id: uid
    })
  })
}

export const denyUser = (uid) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(`${api}/v1/application/deny`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify({
      user_profile_id: uid
    })
  })
}