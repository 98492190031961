export const OBJECTS_FILTER_REQUEST = "OBJECTS_FILTER_REQUEST";
export const OBJECTS_FILTER_SUCCESS = "OBJECTS_FILTER_SUCCESS";
export const OBJECTS_FILTER_ERROR = "OBJECTS_FILTER_ERROR";

const initialState = {
  n_project: {
    isFetching: false,
    items: [],
    isUpdated: false,
  },
  work_type: {
    isFetching: false,
    items: [],
    isUpdated: false,
  },
  object_type: {
    isFetching: false,
    items: [],
    isUpdated: false,
  },
  status: {
    isFetching: false,
    items: [],
    isUpdated: false,
  },
  role: {
    isFetching: false,
    items: [],
    isUpdated: false
  }
};

const objectsFilters = (state = initialState, action) => {
  switch (action.type) {
    case OBJECTS_FILTER_REQUEST: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isFetching: true,
        },
      };
    }
    case OBJECTS_FILTER_SUCCESS: {
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          items: action.payload.items,
          isFetching: false,
          isUpdated: true,
        },
      };
    }
    case OBJECTS_FILTER_ERROR: {
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          isFetching: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default objectsFilters;
