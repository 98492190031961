import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import "./ContractorInfo.scss";
import EditableField from "../../../../../../components/EditableField";
import { editCustomerContractorInfo } from "../../../../../../actions/ObjectsActions";
import { mail, required } from "../../../../../../assets/js/validators";
import AddContractor from "./AddContractor";
import Executors from "./Executors";
import {
  RoleBasedInputLabels,
  RoleNames,
} from "../../../../../../assets/js/constants";

const ContractorInfo = (props) => {
  const [changedContractorInfo, setChangedContractorInfo] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isAddMore, setIsAddMore] = useState(false);
  const [executors, setExecutors] = useState(
    (props.contractor.executors && !props.withoutExecutors) || []
  );
  const params = useParams();

  useEffect(() => {
    if (props.isEditable) {
      setChangedContractorInfo(props.contractor);
    } else {
      setChangedContractorInfo({});
    }
  }, [props.isEditable, props.contractor]);

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) return true;
    }
    return false;
  };

  const change = (ev) => {
    setChangedContractorInfo({
      ...changedContractorInfo,
      [ev.target.name]: ev.target.value,
    });
  };

  const save = props.onlyOutsideSaveHandler
    ? () => props.onSave(props.type, changedContractorInfo)
    : async () => {
        if (!isValidationErrors()) {
          await editCustomerContractorInfo(
            params.id,
            changedContractorInfo,
            props.type,
            executors
          );
          props.onSave();
        }
      };

  const validationChange = (name, value) => {
    setValidationErrors({
      ...validationErrors,
      [name]: value,
    });
  };

  const addContractor = (info) => {
    let ex = executors;
    ex.push(info);
    setExecutors(ex);
    setIsAddMore(false);
  };

  const deleteExecutor = (index) => {
    let ex = [...executors];
    ex.splice(index, 1);
    setExecutors(ex);
  };

  const editExecutors = (info, index) => {
    let ex = [...executors];
    ex[index] = info;
    setExecutors(ex);
  };

  return (
    <div className="contractor-info">
      <div className="contractor-info-block _org">
        <h4 className="contractor-info-block-title">Организация</h4>
        <div className="contractor-info-org-name">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.organizationName
                : props.contractor.organizationName
            }
            label="Название компании"
            isEditable={props.isEditable}
            onChange={change}
            name="organizationName"
            validators={[required]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="contractor-info-org-contacts">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.organizationContacts
                : props.contractor.organizationContacts
            }
            label="Контактный номер компании"
            isEditable={props.isEditable}
            onChange={change}
            name="organizationContacts"
          />
        </div>

        <div className="contractor-info-org-address">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.organizationAddress
                : props.contractor.organizationAddress
            }
            label="Адрес компании"
            isEditable={props.isEditable}
            onChange={change}
            name="organizationAddress"
          />
        </div>
      </div>

      <div className="contractor-info-block">
        <h4 className="contractor-info-block-title">{RoleNames[props.type]}</h4>
        <div className="contractor-info-builder-name">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.builderName
                : props.contractor.builderName
            }
            label={`ФИО ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={props.isEditable}
            onChange={change}
            name="builderName"
            validators={[required]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="contractor-info-builder-contacts">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.builderPhone
                : props.contractor.builderPhone
            }
            label={`Номер ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={props.isEditable}
            onChange={change}
            name="builderPhone"
            validators={[required]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="contractor-info-builder-email">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.builderEmail
                : props.contractor.builderEmail
            }
            label={`Почта ${RoleBasedInputLabels[props.type] || ""}`}
            isEditable={props.isEditable}
            onChange={change}
            name="builderEmail"
            validators={[required, mail]}
            onValidationChange={validationChange}
          />
        </div>

        <div className="contractor-info-builder-additional">
          <EditableField
            value={
              props.isEditable
                ? changedContractorInfo.builderContact
                : props.contractor.builderContact
            }
            label="Дополнительная информация"
            isEditable={props.isEditable}
            onChange={change}
            name="builderContact"
            onValidationChange={validationChange}
          />
        </div>
      </div>

      {executors.length > 0 && (
        <div className="contractor-info-block">
          {executors.map((item, index) => {
            return (
              <Executors
                isEditalbeBlock={props.isEditable}
                item={item}
                index={index}
                onDelete={deleteExecutor}
                onSave={editExecutors}
              />
            );
          })}
        </div>
      )}

      {props.isEditable ? (
        <React.Fragment>
          {!props.isPossibleToAdd ? null : !isAddMore ? (
            <button
              className="contractor-info-builder-add"
              onClick={() => setIsAddMore(true)}
            >
              Добавить субподрядчика
            </button>
          ) : (
            <AddContractor
              onCancel={() => setIsAddMore(false)}
              onSave={addContractor}
            />
          )}
          <div className="contractor-info-builder-controls">
            <button
              onClick={props.onCancel}
              className="contractor-info-builder-cancel _error"
            >
              Отмена
            </button>

            <button onClick={save} className="contractor-info-builder-save">
              Сохранить
            </button>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

ContractorInfo.propTypes = {
  contractor: PropTypes.shape({
    builderName: PropTypes.string,
    builderContact: PropTypes.string,
    builderEmail: PropTypes.string,
    organizationName: PropTypes.string,
    organizationContact: PropTypes.string,
    organizationAddress: PropTypes.string,
    executors: PropTypes.any,
  }).isRequired,
  isPossibleToAdd: PropTypes.bool,
  isEditable: PropTypes.bool,
  isFetching: PropTypes.bool,
  withoutExecutors: PropTypes.bool,
  type: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onlyOutsideSaveHandler: PropTypes.bool,
};

export default ContractorInfo;
