import React, { useState } from "react";
import PropTypes from "prop-types";

import "./InputDate.scss";
import { inlineCalendar } from "./img/inlineSVGicons";
import DatePicker from "../DatePicker";
import moment from "moment";

const InputDate = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [validationError, setValidationError] = useState("");

  const calendarClick = () => {
    setIsOpen(!isOpen);
  };

  const onChange = (value) => {
    if (props.validators && props.validators.length) {
      let error = "";
      let validators = props.validators;

      for (let i = 0; i < validators.length; i++) {
        let result = validators[i](value);
        if (result) {
          error = result;
          break;
        }
      }

      if (error !== validationError) {
        setValidationError(error);
        props.onValidationChange(error);
      }
    }

    props.onChange(value);
    setIsOpen(false);
  };

  return (
    <div className="input-date-container">
      <label className="input-date">
        {(props.label || props.error || validationError) && (
          <div className="input-date-info">
            {props.label && (
              <div className="input-date-label">{props.label}</div>
            )}
            {props.error ||
              (validationError && (
                <div className="input-date-error">
                  {props.error || validationError}
                </div>
              ))}
          </div>
        )}
        <input
          onChange={(ev) => onChange(ev.target.value)}
          className={
            props.error || validationError
              ? "input-date-input-error"
              : "input-date-input"
          }
          value={props.value || ""}
        />
        <div
          onClick={calendarClick}
          className={isOpen ? "input-date-icon-open" : "input-date-icon"}
        >
          {inlineCalendar}
        </div>
      </label>

      {isOpen ? (
        <div className="input-date-picker-container">
          <DatePicker
            value={props.value || moment().format("DD.MM.YYYY")}
            onSelect={onChange}
          />
        </div>
      ) : null}
    </div>
  );
};

InputDate.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
};

export default InputDate;
