import {SortTypes} from "./constants";

const adaptGroupRatingToClient = (items) => {
    return items.map((item) => {
        const adaptedItem = {
            ...item,
            id: item.profile_id,
            title: item.profile_fio,
            subtitle: item.role,
            email: item.user_email,
            phone: item.profile_phone_number,
        };

        delete adaptedItem.profile_id;
        delete adaptedItem.profile_fio;
        delete adaptedItem.role;

        return adaptedItem;
    });
}

const adaptGroupRatingToServer = (items) => {
    return items.map((item) => {
        const adaptedItem = {
            ...item,
            evaluated_user_id: item.id,
        };

        delete adaptedItem.id;
        delete adaptedItem.title;
        delete adaptedItem.subtitle;
        delete adaptedItem.phone;
        delete adaptedItem.email;

        return adaptedItem;
    });
};

const adaptPersonRatingToClient = (item) => {
    const adaptedItem = {
        ...item,
        id: item.profile_id || item.user_id,
        title: item.profile_fio,
        email: item.user_email,
        phone: item.profile_phone_number,
        score: adaptUserScoreToClient(item.score),
    };

    delete adaptedItem.profile_id;
    delete adaptedItem.user_id;
    delete adaptedItem.profile_fio;
    delete adaptedItem.user_email;
    delete adaptedItem.profile_phone_number;

    return adaptedItem;
}

const adaptUserScoreToClient = (score) => {
    return score.map((item) => {
        const adaptedItem = {
            ...item,
            id: item.construction_object_name,
            title: item.construction_object_name,
        };

        delete adaptedItem.construction_object_name;

        return adaptedItem;
    });
};

const adaptSortToServer = (sort) => {
    const adaptedSort = [];
    Object.keys(sort).forEach((param) => {
        if (sort[param] === SortTypes.DROPPED.value) return;
        adaptedSort.push({
            column: param,
            order: sort[param],
        });
    });
    return adaptedSort;
};

const adaptCreateUserDataToServer = (data) => {
    const adaptedData = {
        ...data,
        user_email: data.email,
        profile_phone_number: data.phone,
        profile_fio: data.name,
        role_name: data.role,
        organization_name: data.orgName || null,
        organization_contacts: data.orgContacts || null,
        organization_address: data.orgAddress || null,
        additional_information: data.info || null,
    };

    delete adaptedData.email;
    delete adaptedData.phone;
    delete adaptedData.name;
    delete adaptedData.role;
    delete adaptedData.orgName;
    delete adaptedData.orgContacts;
    delete adaptedData.orgAddress;
    delete adaptedData.info;

    return adaptedData;
};

export {
    adaptGroupRatingToClient,
    adaptGroupRatingToServer,
    adaptPersonRatingToClient,
    adaptSortToServer,
    adaptCreateUserDataToServer,
}