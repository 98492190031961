import React from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { required } from "../../../../assets/js/validators";

import "./AddObjectHeader.scss";

import Tabs from "../../../../components/Tabs";
import { ROUTES } from "../../../../routes";
import Input from "../../../../components/Input";
import AddObjectForm from "../AddObjectInfo/components/AddObjectForm";

const AddObjectHeader = (props) => {
  const history = useHistory();
  const location = useLocation();

  const tabsItems = [
    {
      key: ROUTES.OBJECT.ADD.INFO.PATH,
      value: "Информация",
    },
    {
      key: ROUTES.OBJECT.ADD.STAGES.PATH,
      value: "Этапы",
    },
  ];

  const tabClick = (value) => {
    history.replace(value.key);
  };

  const nameChange = (ev) => {
    props.onChange(ev.target.name, ev.target.value);
  };

  return (
    <div className="add-object-header">
      <div className="add-object-header-name">
        <Input
          value={props.info.name}
          onChange={nameChange}
          name="name"
          placeholder="Название объекта"
          validators={[required]}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="add-object-header-tabs">
        <Tabs items={tabsItems} onClick={tabClick} active={location.pathname} />
      </div>

      <AddObjectForm
          info={props.info}
          onChange={props.onChange}
          onValidationChange={props.onValidationChange}
      />
    </div>
  );
};

AddObjectHeader.propTypes = {
  info: PropTypes.object,
  onChange: PropTypes.func,
  onValidationChange: PropTypes.func,
};

export default AddObjectHeader;
