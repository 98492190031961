import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom"
import { ROUTES } from "../../../../routes";

import "./SingInForm.scss";

import Input from "../../../../components/Input";
import InputPassword from "../../../../components/InputPassword";
import { singIn } from "../../../../actions/SingInActions";
import { required, mail, password } from "../../../../assets/js/validators";
import Loader from "../../../../components/Loader";
import { GetUserByTokenAction } from "../../../../actions/UserActions";

const SingInForm = (props) => {
  const history = useHistory()
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const singInStore = useSelector((store) => store.singIn);
  const dispatch = useDispatch();
  const singInRequest = (login, password) => dispatch(singIn(login, password)).then((r) => {
    if (r) {
      let tokenName = localStorage.getItem("token_name");
      let token = localStorage.getItem(tokenName);
      if (!localStorage.getItem("completed_registration_tutorial")) {
        localStorage.setItem("completed_registration_tutorial", "true");
      }
      dispatch(GetUserByTokenAction(tokenName, token)).then((response) => {
        if (response.item_name === "tech_expert") {
          history.push("/user_confirmation")
        } else if (response.item_name === "government_customer") {
          history.push("/objects/all/list")
        } else {
          history.push("/objects/my/list")
        }
      })
    }
  });

  const change = (ev) => {
    setFormData({
      ...formData,
      [ev.target.name]: ev.target.value,
    });
  };

  const validationChange = (value) => {};

  const submit = (ev) => {
    ev.preventDefault();
    singInRequest(formData.login, formData.password)
  };

  return (
    <form onSubmit={submit} className="login-form">
      {singInStore.isFetching ? <Loader/> : null}
      {singInStore.errors.map((item, index) => {
        return (
          <div className="login-submit-error" key={index}>
            {item}
          </div>
        );
      })}
      <div className="login-input-container">
        <Input
          label="Электронная почта"
          type="email"
          onChange={(ev) => change(ev)}
          value={formData.login}
          name="login"
          validators={[required, mail]}
          onValidationChange={validationChange}
        />
      </div>

      <div className="login-input-container">
        <InputPassword
          label="Пароль"
          onChange={(ev) => change(ev)}
          value={formData.password}
          name="password"
          validators={[required, password]}
        />
      </div>

      <Link
        type="button"
        className="login-form-reset"
        to={ROUTES.RESET_PASS.PATH}>
          Сбросить пароль
      </Link>

      <button type="submit" className="login-input-submit">Авторизация</button>
    </form>
  );
};

export default SingInForm;
