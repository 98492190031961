import React from "react";
import PropTypes from "prop-types";

import "./EditableDateField.scss";

import InputDate from "../InputDate";

const EditableDateField = (props) => {
  return (
    <div className="editable-date-field">
      {props.isEditable ? (
        <div className="editable-date-field-input">
          <InputDate
            label={props.label}
            value={props.value || ""}
            onChange={props.onChange}
            error={props.error}
            validators={props.validators}
            onValidationChange={props.onValidationChange}
          />
        </div>
      ) : (
        <div className="editable-date-field-container">
          <div className="editable-date-field-value">{props.value}</div>
          <div className="editable-date-field-label">{props.label}</div>
        </div>
      )}
    </div>
  );
};

EditableDateField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.func,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
};

export default EditableDateField;
