import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { fetchFilters } from "../../../../actions/FiltersActions";
import "./ObjectSearch.scss";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { inlineArrow } from "./img/inlineSVGicons";
import { searchIcon } from "../../../../assets/img/sprite";

const ObjectsSearch = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filtersValues, setFiltersValues] = useState({
    address: "",
    name: "",
    nationalProject: "",
    jobType: "",
    objectType: "",
    objectStatus: "",
  });
  const [isFiltered, setIsFiltered] = useState(true);
  const [inputFilters, setInputFilters] = useState({});
  const filterItemsStore = useSelector((store) => store.objectsFilters);
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.filters) {
      let filters = {};
      props.filters.forEach((filter) => {
        filters[filter.filterName] = filter.value;
      });
      setInputFilters(filters);
    }
  }, [props.filters]);

  const filters = [
    {
      filterName: "name",
      operator: "ilike",
      value: filtersValues.name || "",
    },
    {
      filterName: "address",
      operator: "ilike",
      value: filtersValues.address || "",
    },
    {
      filterName: "national_project",
      operator: "ilike",
      value: filtersValues.nationalProject || "",
    },
    {
      filterName: "work_type",
      operator: "ilike",
      value: filtersValues.jobType || "",
    },
    {
      filterName: "object_type",
      operator: "ilike",
      value: filtersValues.objectType || "",
    },
    {
      filterName: "status",
      operator: "ilike",
      value: filtersValues.objectStatus || "",
    },
  ];

  const onFilter = useCallback(() => {
    props.onFilter(filters);
  }, [props, filters]);

  useEffect(() => {
    if (!isFiltered) {
      onFilter();
      setIsFiltered(true);
    }
  }, [isFiltered, onFilter]);

  const onSelectOpen = (type) => {
    if (
      !filterItemsStore[type].isFetching &&
      !filterItemsStore[type].isUpdated
    ) {
      dispatch(fetchFilters(type, props.pageName));
    }
  };

  const change = (value, name) => {
    setIsFiltered(false);
    if (name) {
      setFiltersValues({
        ...filtersValues,
        [name]: value ? value.value : null,
      });
      return;
    }

    setFiltersValues({
      ...filtersValues,
      [value.target.name]: value.target.value,
    });
  };

  return (
    <div className="objects-search">
      <div className="objects-search-name">
        <Input
          type="text"
          placeholder="Поиск по названию"
          isValid={true}
          name="name"
          value={filtersValues.name}
          onChange={change}
          icon={searchIcon}
        />
      </div>

      <div className="objects-search-address">
        <Input
          type="text"
          placeholder="Поиск по адресу"
          isValid={true}
          value={inputFilters.address || filtersValues.address}
          name="address"
          onChange={change}
          icon={searchIcon}
        />
      </div>

      {props.linksToObjects ? null : (
        <>
          {isMobileSize ? (
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="objects-search-expand"
            >
              <div className="objects-search-expand-text">
                Расширенный поиск
              </div>
              <div
                className={
                  isOpen
                    ? "objects-search-expand-arrow-open"
                    : "objects-search-expand-arrow"
                }
              >
                {inlineArrow}
              </div>
            </button>
          ) : null}

          {isOpen || !isMobileSize ? (
            <React.Fragment>
              <div className="objects-search-national-project">
                <Select
                  placeholder="Национальный проект"
                  items={filterItemsStore.n_project.items}
                  value={filtersValues.nationalProject}
                  isFetching={filterItemsStore.n_project.isFetching}
                  name="nationalProject"
                  onChange={(val, name) => {
                    // if (val.key === null && val.value !== "") {
                    //   return;
                    // }
                    change(val, name);
                  }}
                  onOpen={() => onSelectOpen("n_project")}
                  reset
                  isRounded
                />
              </div>

              <div className="objects-search-job-type">
                <Select
                  placeholder="Тип работ"
                  items={filterItemsStore.work_type.items}
                  value={filtersValues.jobType}
                  isFetching={filterItemsStore.work_type.isFetching}
                  name="jobType"
                  onChange={(val, name) => {
                    // if (val.key === null && val.value !== "") {
                    //   return;
                    // }
                    change(val, name);
                  }}
                  onOpen={() => onSelectOpen("work_type")}
                  reset
                  isRounded
                />
              </div>

              <div className="objects-search-object-type">
                <Select
                  placeholder="Тип объекта"
                  items={filterItemsStore.object_type.items}
                  value={filtersValues.objectType}
                  isFetching={filterItemsStore.object_type.isFetching}
                  name="objectType"
                  onChange={(val, name) => {
                    // if (val.key === null && val.value !== "") {
                    //   return;
                    // }
                    change(val, name);
                  }}
                  onOpen={() => onSelectOpen("object_type")}
                  reset
                  isRounded
                />
              </div>

              <div className="objects-search-object-status">
                <Select
                  placeholder="Статус объекта"
                  items={filterItemsStore.status.items}
                  value={filtersValues.objectStatus}
                  isFetching={filterItemsStore.status.isFetching}
                  name="objectStatus"
                  onChange={(val, name) => {
                    // if (val.key === null && val.value !== "") {
                    //   return;
                    // }
                    change(val, name);
                  }}
                  onOpen={() => onSelectOpen("status")}
                  reset
                  isRounded
                />
              </div>
            </React.Fragment>
          ) : null}
        </>
      )}
    </div>
  );
};

ObjectsSearch.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  linksToObjects: PropTypes.bool,
  pageName: PropTypes.string,
};

export default ObjectsSearch;
