import {
  OPEN_INFO_POPUP,
  CLOSE_INFO_POPUP,
} from "../reducers/infoPopup";

export function openInfoPopup(text) {
  return (dispatch) => {
    dispatch({
      type: OPEN_INFO_POPUP,
      payload: { text },
    });
  };
}

export function closeInfoPopup() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_INFO_POPUP,
    });
  };
}
