import React from "react";
import PropTypes from "prop-types";

import "./AddObjectSections.scss";

import AddObjectSectionsBlock from "./components/AddObjectSectionsBlock";
import getSortedSections from "../../../../assets/js/utils/getSortedSections";

const AddObjectSections = (props) => {
  const sortedSections = getSortedSections(props.sections.items);

  return Object.keys(sortedSections).length > 0 ? (
    <div className="container">
      <div className="content-card">
        {Object.keys(sortedSections).map((estimateID) => (
          <AddObjectSectionsBlock
            key={estimateID}
            name={sortedSections[estimateID].name}
            sections={sortedSections[estimateID].sections}
            onStageSave={props.onStageSave}
            onStageDelete={props.onStageDelete}
          />
        ))}
      </div>
    </div>
  ) : null;
};

AddObjectSections.propTypes = {
  sections: PropTypes.object,
  onStageSave: PropTypes.func,
  onStageDelete: PropTypes.func,
};

export default AddObjectSections;
