import {
  RATING_REQUEST,
  RATING_ERROR,
  RATING_SUCCESS,
  OBJECT_USER_RATING_UPDATE,
} from "../actions/RatingAction";

const initialState = {
  isFetching: false,
  items: [],
  pagination: {},
  isError: false,
  errors: [],
};

const rating = (state = initialState, action) => {
  switch (action.type) {
    case RATING_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case RATING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        pagination: action.payload.pagination,
      };
    }
    case RATING_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: action.payload.errors,
      };
    }
    case OBJECT_USER_RATING_UPDATE: {
      const items = [...state.items];
      let errors = null;
      let updatingItem = null;
      const updatingItemIndex = items.findIndex((item) => {
        if (item.id === action.payload.id) {
          updatingItem = {
            ...item,
            rating: action.payload.value || item.rating,
            review: action.payload.review || item.review,
          };
          return true;
        }
        return false;
      });
      const hasUpdatingItem = updatingItemIndex >= 0;
      if (hasUpdatingItem) {
        items.splice(updatingItemIndex, 1, updatingItem)
      } else {
        errors = ['Произошла ошибка при обновлении оценки пользователя, пользователь не найден.'];
      }
      return {
        ...state,
        items: hasUpdatingItem ? items : state.items,
        isError: !hasUpdatingItem,
        errors: !hasUpdatingItem ? errors : state.errors,
      };
    }
    default: {
      return state;
    }
  }
};

export default rating;
