const TABLET_WIDTH = 1023;
const MOBILE_WIDTH = 767;

const ModalTypes = {
  OBJECT_RATING: "rating",
  PERSONAL_RATING: "personal-rating",
  RENDER: "render",
};

const RatingRequestTypes = {
  OVERALL: "overall",
  OBJECT: "object",
};

const RatingRequestRoles = {
  ARCHITECT: "architect-index",
  BUILDER: "builder-index",
  DESIGNER: "designer-index",
};

const SortTypes = {
  DESC: {
    value: "DESC",
    mixClass: "_desc",
  },
  ASC: {
    value: "ASC",
    mixClass: "_asc",
  },
  DROPPED: {
    value: "DROPPED",
    mixClass: "",
  },
};

const MAX_RATING_VALUE = 10;

const RoleNames = {
  builder: "Подрядчик",
  architect: "Архитектор",
  designer: "Проектировщик",
};

const RoleBasedInputLabels = {
  builder: "подрядчика",
  architect: "архитектора",
  designer: "проектировщика",
};

const UserInputsData = {
  NAME: {
    label: "Имя",
    prefixName: "name",
    postfixName: "Name",
    mixClass: "name",
  },
  PHONE: {
    label: "Телефон",
    prefixName: "phone",
    postfixName: "Phone",
    mixClass: "number",
  },
  EMAIL: {
    label: "Почта",
    prefixName: "email",
    postfixName: "Email",
    mixClass: "mail",
  },
  ADDITIONAL_INFO: {
    label: "Дополнительная информация",
    prefixName: "additionalInfo",
    postfixName: "AdditionalInfo",
    mixClass: "additional-info",
  },
};

const OrgInputsData = {
  NAME: {
    label: "Название компании",
    prefixName: "orgName",
    postfixName: "OrgName",
    mixClass: "org-name",
  },
  CONTACTS: {
    label: "Контактный номер компании",
    prefixName: "orgContacts",
    postfixName: "OrgContacts",
    mixClass: "org-contacts",
  },
  ADDRESS: {
    label: "Адрес компании",
    prefixName: "orgAddress",
    postfixName: "OrgAddress",
    mixClass: "org-address",
  },
};

const OTHER_SECTIONS_NAME = "Прочие разделы";

const WindowSizeNames = {
  MOBILE: "MOBILE",
  TABLET: "TABLET",
  DESKTOP: "DESKTOP",
};

export {
  TABLET_WIDTH,
  MOBILE_WIDTH,
  ModalTypes,
  RatingRequestTypes,
  RatingRequestRoles,
  SortTypes,
  MAX_RATING_VALUE,
  RoleBasedInputLabels,
  RoleNames,
  UserInputsData,
  OrgInputsData,
  OTHER_SECTIONS_NAME,
  WindowSizeNames,
};
