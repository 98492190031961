import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  addObjectSection,
  deleteObjectSection,
  editObjectSection,
  fetchObjectSections,
} from "../../../../../../actions/ObjectSectionsActions";

import "./ObjectSectionsBlock.scss";
import ObjectSection from "../ObjectSection";
import toaster from "toasted-notes";
import { ROUTES } from "../../../../../../routes";
import CheckPermissions from "../../../../../../components/CheckPermissions";
import Input from "../../../../../../components/Input";
import { plusIcon } from "../../../../../../assets/img/sprite";

const ObjectSectionsBlock = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [newSection, setNewSection] = useState("");
  const [isAddSection, setIsAddSection] = useState(false);
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const history = useHistory();

  const deleteSection = (objectId, sectionId) => {
    deleteObjectSection(objectId, sectionId).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Раздел удалён!</div>, {
          position: "top-right",
          duration: 4000,
        });
        props.fetchSections();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  const saveSection = (objectId, sectionId, name) => {
    editObjectSection(objectId, sectionId, name).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Раздел обновлён!</div>, {
          position: "top-right",
          duration: 4000,
        });
        props.fetchSections();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
    });
  };

  const stageClick = (sectionId, stageId) => {
    // if (user.type !== 'builder' || props.builderId) {
    history.push(
      `${ROUTES.OBJECT.PATH}/${props.id}${ROUTES.OBJECT.SECTION.PATH}/${sectionId}${ROUTES.OBJECT.SECTION.STAGE.PATH}/${stageId}`
    );
    // }
  };

  const fetchSections = useCallback(
    (page) => {
      dispatch(fetchObjectSections(props.id, page));
    },
    [props.id, dispatch]
  );

  const addSection = () => {
    addObjectSection(props.id, props.estimateID, newSection).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Раздел добавлен!</div>, {
          position: "top-right",
          duration: 4000,
        });
        setNewSection("");
        fetchSections();
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 4000,
        });
      }
      setIsAddSection(false);
    });
  };

  return (
    <div className={`object-sections-block ${isOpen ? "_open" : ""}`}>
      <div
        className="object-sections-block-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.name || "Разделы сметы"}
      </div>
      {isOpen ? (
        <>
          {props.sections.map((item) => (
            <ObjectSection
              key={item.id}
              status={props.status}
              changedStages={props.changedSections}
              item={item}
              onSectionChange={props.changeSection}
              onStageClick={(id) => stageClick(item.id, id)}
              objectId={props.id}
              sectionId={item.id}
              onDeleteSection={deleteSection}
              onSaveSection={saveSection}
            />
          ))}
          <CheckPermissions permissions={["perm_section_create"]}>
            {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
              <div className="section-create">
                {!isAddSection && (
                  <button
                    onClick={() => setIsAddSection(true)}
                    disabled={isAvailable}
                  >
                    {plusIcon}
                    <span>Добавить раздел</span>
                  </button>
                )}
                {isAddSection && (
                  <>
                    <Input
                      value={newSection}
                      onChange={(ev) => setNewSection(ev.target.value)}
                      label="Добавить новый раздел"
                      placeholder="Название раздела"
                    />
                    <div className="section-create-controls">
                      <button onClick={addSection} type="button">
                        Сохранить
                      </button>
                      <button
                          className="cancel _error"
                          onClick={() => setIsAddSection(false)}
                          type="button"
                      >
                        Отмена
                      </button></div>
                  </>
                )}
              </div>
            ) : null}
          </CheckPermissions>
        </>
      ) : null}
    </div>
  );
};

ObjectSectionsBlock.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  estimateID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sections: PropTypes.array,
  changedSections: PropTypes.object,
  changeSection: PropTypes.func,
  fetchSections: PropTypes.func,
};

export default ObjectSectionsBlock;
