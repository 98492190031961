export const STAGE_STATUSES_REQUEST = "STAGE_STATUSES_REQUEST";
export const STAGE_STATUSES_SUCCESS = "STAGE_STATUSES_SUCCESS";
export const STAGE_STATUSES_ERROR = "STAGE_STATUSES_ERROR";

const initialState = {
  isFetching: false,
  items: [],
  isUpdated: false,
};

const stageStatuses = (state = initialState, action) => {
  switch (action.type) {
    case STAGE_STATUSES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case STAGE_STATUSES_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        isFetching: false,
        isUpdated: true,
      };
    }
    case STAGE_STATUSES_ERROR: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default stageStatuses;
