import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Paginator.scss";

//import MultiSelect from "../MultiSelect";

const Paginator = (props) => {
  /*const items = [
    {
      key: 10,
      value: "10",
    },
  ];*/
  const [value, setValue] = useState(props.currentPage || 1);

  const handlePageChange = () => {
    props.onPageChange(value);
  };

  useEffect(() => {
    setValue(props.currentPage);
  }, [props.currentPage]);

  return (
    <div className="paginator">
      <button
        onClick={() => props.onPageChange(1)}
        className="paginator-back-all"
        disabled={props.currentPage === 1}
      >
        {"<<"}
      </button>

      <button
        className="paginator-back"
        disabled={props.currentPage === 1}
        onClick={() => props.onPageChange(props.currentPage - 1)}
      >
        {"<"}
      </button>

      <form className="paginator-pages" onSubmit={(e) => {
          e.preventDefault();
          props.onPageChange(value);
      }}>
        <input
          className="paginator-pages-current"
          type="number"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          // onBlur={handlePageChange}
          min={1}
          max={props.totalPages}
        />
        <div className="paginator-pages-total">из {props.totalPages}</div>
      </form>

      <button
        className="paginator-forward"
        disabled={props.currentPage === props.totalPages}
        onClick={() => props.onPageChange(props.currentPage + 1)}
      >
        {">"}
      </button>

      <button
        className="paginator-forward-all"
        disabled={props.currentPage === props.totalPages}
        onClick={() => props.onPageChange(props.totalPages)}
      >
        {">>"}
      </button>

      {/*<div className="paginator-sizes">
          <MultiSelect
            onChange={(item) => props.onPageChange(item.value)}
            items={props.pageSizes || items}
            value={props.pageSize || 0}
          />
        </div>*/}
    </div>
  );
};

Paginator.propTypes = {
  total: PropTypes.number,
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  pageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.string,
    })
  ),
};

export default Paginator;
