import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./StageComments.scss";

import InputTextArea from "../../../../components/InputTextArea";
import Paginator from "../../../../components/Paginator";

const StageComments = (props) => {
  const [addCommentText, setAddCommentText] = useState("");
  const isAvailable = useSelector((state) => state.objects.isAvailable);

  const addCommentChange = (value) => {
    setAddCommentText(value);
  };

  const addComment = () => {
    props.onCommentAdd(addCommentText);
    setAddCommentText("");
  };

  return (
    <div className="stage-comments-container">
      <h3 className="stage-comments-head">Комментарии</h3>

      <ul className="stage-comments">
        {props.comments.length ? (
          props.comments.map((item) => {
            return (
              <li key={item.comment_id} className="stage-comment">
                <div className="stage-comment-head">
                  <div className="stage-comment-name">
                    <span>{item.profile_fio}</span>
                    <span className="stage-comment-date">
                      {item.comment_created_at}
                    </span>
                  </div>
                  <div className="stage-comment-position">
                    {item.description}
                  </div>
                </div>

                <div className="stage-comment-text">{item.comment_content}</div>
              </li>
            );
          })
        ) : (
          <div className="no-notes">Нет комментариев</div>
        )}
      </ul>

      {props.pagination.totalCount > props.pagination.pageSize ? (
        <div className="stage-comments-paginator">
          <Paginator
            pageSize={props.pagination.pageSize}
            currentPage={props.pagination.currentPage}
            totalPages={props.pagination.totalPageCount}
            total={props.pagination.totalCount}
            onPageChange={props.onPageChange}
          />
        </div>
      ) : null}

      {props.status === "WORKS_DONE" || props.status === "STOPPED" ? null : (
        <div className="stage-comments-add">
          <div className="stage-comments-add-input">
            <InputTextArea
              label="Ваш комментарий"
              value={addCommentText}
              onChange={addCommentChange}
            />
          </div>

          <button
            onClick={addComment}
            className="stage-comments-add-button"
            disabled={isAvailable || !addCommentText}
            type="button"
          >
            Отправить
          </button>
        </div>
      )}
    </div>
  );
};

StageComments.propTypes = {
  comments: PropTypes.array,
  pagination: PropTypes.object,
  onCommentAdd: PropTypes.func,
  onPageChange: PropTypes.func,
};

export default StageComments;
