import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import "./AllObjects.scss";

import { ROUTES } from "../../../../routes";
import ObjectsSearch from "../ObjectsSearch";
import ObjectsList from "../ObjectsList";
import { fetchObjects } from "../../../../actions/ObjectsActions";
import { fetchFavorites } from "../../../../actions/FavoritesActions";
import ObjectsHeader from "../ObjectsHeader";
import ObjectsMap from "../ObjectsMap/ObjectsMap";
import Loader from "../../../../components/Loader";
import Paginator from "../../../../components/Paginator";
import { OBJECT_IS_AVAILABLE } from "../../../../reducers/objects";

const AllObjects = ({ onContentReady }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestType = "index";
  // const [requestType, setRequestType] = useState("index");
  const [objectFilter, setObjectFilter] = useState(undefined);
  const objectsStore = useSelector((store) => store.objects);
  const { type: userType } = useSelector((store) => store.user);

  useEffect(() => {
    if (history.location.search === "?isGrouped=true") {
      dispatch({
        type: OBJECT_IS_AVAILABLE,
        payload: true,
      });
    }
  }, []);

  const getAllObjects = (filters) => {
    setObjectFilter(filters);
    dispatch(
      fetchObjects(requestType, filters, undefined, objectsStore.isAvailable)
    );
  };
  const getFavorites = () => dispatch(fetchFavorites());

  const getAllObjectPaginator = useCallback(
    (page) =>
      dispatch(fetchObjects(requestType, [], page, objectsStore.isAvailable)),
    [dispatch]
  );

  const getMyObjects = useCallback(
    (filters) => {
      setObjectFilter(filters);
      dispatch(
        fetchObjects(requestType, filters, undefined, objectsStore.isAvailable)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getAllObjects(undefined);
  }, [objectsStore.isAvailable]);

  useEffect(() => {
    if (
      objectsStore.lastType !== requestType &&
      !objectsStore.isUpdated &&
      !objectsStore.isFetching &&
      !objectsStore.isError
    ) {
      getMyObjects();
    }
  }, [objectsStore, getMyObjects]);

  const tabItems = [
    {
      key: ROUTES.OBJECTS.ALL.LIST.PATH,
      value: "Объекты",
    },
    {
      key: ROUTES.OBJECTS.ALL.MAP.PATH,
      value: "Карта",
    },
  ];

  const navTabItems = [
    {
      key: "self",
      value:
        userType === "builder" ? "Доступные для работы объекты" : "Все объекты",
    },
  ];

  if (userType !== "government_customer" && userType !== "builder") {
    navTabItems.push({
      key: "org",
      value: "Доступные объекты",
    });
  }

  const onSubscribe = () => {
    getAllObjects(objectFilter);
    getFavorites();
  };

  const onClickAvailable = (isAvailable) => {
    history.replace(
      isAvailable ? history.location.pathname : "?isGrouped=true"
    );
    dispatch({
      type: OBJECT_IS_AVAILABLE,
      payload: !isAvailable,
    });
  };

  return (
    <div className="container-wrapper">
      <div className="objects-container container">
        <div className="content-card">
          <ObjectsHeader
              tabItems={tabItems}
              navTabItems={navTabItems}
              title="Объекты"
              onClickAvailable={onClickAvailable}
          />

          <ObjectsSearch
              pageName={requestType}
              onFilter={(filter) => getAllObjects(filter)}
          />

          <Switch>
            <Route exact path={ROUTES.OBJECTS.ALL.LIST.PATH}>
              <div className="objects-list-container">
                <ObjectsList
                    onItemSubscribe={onSubscribe}
                    objects={objectsStore.items}
                    onContentReady={onContentReady}
                />
                {objectsStore.pagination.totalCount >
                objectsStore.pagination.pageSize ? (
                    <Paginator
                        currentPage={objectsStore.pagination.currentPage}
                        totalPages={objectsStore.pagination.totalPageCount}
                        total={objectsStore.pagination.totalCount}
                        pageSize={objectsStore.pagination.pageSize}
                        onPageChange={getAllObjectPaginator}
                    />
                ) : null}
              </div>
            </Route>

            <Route exact path={ROUTES.OBJECTS.ALL.MAP.PATH}>
              <>
                <ObjectsMap objects={objectsStore.items} />
              </>
            </Route>

            <Route path={ROUTES.OBJECTS.ALL.PATH}>
              <Redirect to={ROUTES.OBJECTS.ALL.LIST.PATH} />
            </Route>
          </Switch>

          {objectsStore.isFetching ? <Loader /> : null}</div>
      </div>
    </div>
  );
};

export default AllObjects;
