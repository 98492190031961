import React, { useEffect, useState } from "react";

import "./Executors.scss";
import EditableField from "../../../../../../../components/EditableField";
import { inlineDelete } from "../../../../../../../components/FileList/components/FileCards/img/inlineSvgIcons";
import {
  inlineCorrect,
  inlineEdit,
} from "../../../../../../AddObject/components/AddObjectSections/components/AddObjectSectionsBlock/components/AddObjectSectionsList/components/AddObjectStage/img/inlineSVGicons";
import { inlineClose } from "../../../../Stages/components/StageCard/img/inlineSvgicons";
import { mail, required } from "../../../../../../../assets/js/validators";

const Executors = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [changedExecutor, setChangedExecutor] = useState(null)
  const [validationErrors, setValidationErrors] = useState({})

  const change = (value, name) => [
    setChangedExecutor({
      ...changedExecutor,
      [name]: value
    })
  ]

  useEffect(() => {
    if (isEditable) {
      setChangedExecutor(props.item);
      return;
    }
    setChangedExecutor(null);
  }, [isEditable, props.item]);

  const validationChange = (name, value) => {
    setValidationErrors({
      ...validationErrors,
      [name]: value,
    });
  };

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) return true;
    }
    return false;
  };

  let item = changedExecutor || props.item
  return (
    <div className="contractor-info-executors-content">
      <div className="contractor-info-executors-content-name">
      <EditableField
        isEditable={isEditable && props.isEditalbeBlock}
        value={item.name}
        validators={[required]}
        onValidationChange={validationChange}
        onChange={(ev) => change(ev.target.value, 'name')}
        label="ФИО субподрядчика"
      />
    </div>
      <div className="contractor-info-executors-content-phone">
      <EditableField
        isEditable={isEditable && props.isEditalbeBlock}
        value={item.phone}
        validators={[required]}
        onValidationChange={validationChange}
        onChange={(ev) => change(ev.target.value, 'phone')}
        label="Номер субподрядчика"
      />
    </div>
      <div className="contractor-info-executors-content-email">
      <EditableField
        isEditable={isEditable && props.isEditalbeBlock}
        value={item.email}
        validators={[required, mail]}
        onValidationChange={validationChange}
        onChange={(ev) => change(ev.target.value, 'email')}
        label="Почта субподрядчика"
      />
    </div>
      <div className="contractor-info-executors-content-contacts">
      <EditableField
        isEditable={isEditable && props.isEditalbeBlock}
        value={item.contacts}
        onChange={(ev) => change(ev.target.value, 'contacts')}
        label="Дополнительная информация"
      />
      </div>
      {props.isEditalbeBlock ? (
        <>
          {!isEditable ? (
            <>
              <button className="contractor-info-executors-content-delete" onClick={() => props.onDelete(props.index)}>
                {inlineDelete}
              </button>
              <button
                className="contractor-info-executors-content-edit"
                onClick={() => setIsEditable(true)}
              >
                {inlineEdit}
              </button>
            </>
          ) : (
            <>
              <button className="contractor-info-executors-content-save" disabled={isValidationErrors()} onClick={() => { props.onSave(changedExecutor, props.index); setIsEditable(false)}}>
                {inlineCorrect}
              </button>
              <button
                className="contractor-info-executors-content-cancel"
                onClick={() => setIsEditable(false)}
              >
                {inlineClose}
              </button>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Executors;
