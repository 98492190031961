import api from "./api";
import {
  adaptPersonRatingToClient,
} from "../assets/js/adapters";

export const USER_RATING_REQUEST = "USER_RATING_REQUEST";
export const USER_RATING_ERROR = "USER_RATING_ERROR";
export const USER_RATING_SUCCESS = "USER_RATING_SUCCESS";

export function fetchRating(userID) {
  return (dispatch) => {
    dispatch({
      type: USER_RATING_REQUEST,
    });

    const tokenName = localStorage.getItem("token_name");
    const token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: USER_RATING_ERROR,
        payload: {
          errors: ["Нет прав доступа. Пожалуйста войдите в систему"],
        },
      });
      return;
    }

    fetch(api + `/v1/score/user-score?profile_id=${userID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: USER_RATING_ERROR,
            payload: {
              errors: data.message,
            },
          });
        }

        dispatch({
          type: USER_RATING_SUCCESS,
          payload: {
            userData: adaptPersonRatingToClient(data.data),
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: USER_RATING_ERROR,
          payload: {
            errors: er.message,
          },
        });
      });
  };
}
