import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./InputFileDrop.scss";

const InputFileDrop = (props) => {
  const [isFilesOnArea, setIsFilesOnArea] = useState(false);
  const [isFilesOnInput, setIsFilesOnInput] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState(null);

  useEffect(() => {
    if (droppedFiles !== null) {
      props.onFileDrop(droppedFiles);
      setDroppedFiles(null);
    }
  }, [droppedFiles]);

  const handleAreaDragEnter = (ev) => {
    if (ev.currentTarget.contains(ev.relatedTarget)) return;
    setIsFilesOnArea(true);
  };

  const handleAreaDragLeave = (ev) => {
    if (ev.currentTarget.contains(ev.relatedTarget)) return;
    setIsFilesOnArea(false);
  };

  const handleInputDragEnter = (ev) => {
    if (ev.currentTarget.contains(ev.relatedTarget)) return;
    setIsFilesOnInput(true);
  };

  const handleInputDragLeave = (ev) => {
    if (ev.currentTarget.contains(ev.relatedTarget)) return;
    setIsFilesOnInput(false);
  };

  const handleFilesDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = [];
    const fileList = e.dataTransfer.files;
    const filesCnt = props.isMultiple ? fileList.length : 1;
    for (let i = 0; i < filesCnt; i++) {
      files.push(fileList[i]);
    }

    setIsFilesOnArea(false);
    setIsFilesOnInput(false);
    setDroppedFiles(files);
  };

  const handleFilesDropOnArea = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFilesOnArea(false);
  };

  const handleFilesInput = (e) => {
    e.preventDefault();
    const files = [];
    const fileList = e.target.files;

    if (fileList.length) {
      for (let i = 0; i < fileList.length; i++) {
        files.push(fileList[i]);
      }

      e.target.value = "";
      setIsFilesOnArea(false);
      setIsFilesOnInput(false);
      setDroppedFiles(files);
    }
  };

  const dragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  return (
    <div
      className="input-file-drop"
      onDragEnter={handleAreaDragEnter}
      onDragLeave={handleAreaDragLeave}
      onDragOver={dragOver}
      onDrop={handleFilesDropOnArea}
    >
      {props.children}
      {props.helpMessage && (
          <span className="input-file-drop__message">{props.helpMessage}</span>
      )}
      {(isFilesOnArea || !props.children) && (
        <label
          onChange={handleFilesInput}
          onDragEnter={handleInputDragEnter}
          onDragLeave={handleInputDragLeave}
          onDrop={handleFilesDrop}
          className={
            isFilesOnInput ? "file-list-filler-drag" : "file-list-filler"
          }
        >
          {props.label}
          <input
            multiple={props.isMultiple}
            type="file"
            className="file-list-filler-input"
            accept={props.format}
          />
        </label>
      )}
    </div>
  );
};

InputFileDrop.propTypes = {
  onFileDrop: PropTypes.func,
  isMultiple: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  helpMessage: PropTypes.string,
};

export default InputFileDrop;
