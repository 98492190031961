import React from "react";

import AccountWidget from "../../components/AccountWiget";
import Logo from "../../components/Logo/logo";
import SingUpForm from "./components/SingUpForm";

import "./singUpContainer.scss";

const SingUpContainer = (props) => {
  return (
    <React.Fragment>
      <AccountWidget />

      <div className="login-container">
        <h2 className="login-head">Регистрация</h2>

        <SingUpForm />
      </div>
    </React.Fragment>
  );
};

export default SingUpContainer;
