import React from "react";
import PropTypes from "prop-types";

import "./Status.scss";
import { useSelector } from "react-redux";

const Status = (props) => {
  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const bgc =
    props.color === "#EF3E4A" ? props.color + "16" : props.color + "33";
  return (
    <div
      className={"state-container"}
      style={{ color: props.color, backgroundColor: isMobileSize ? "" : bgc }}
    >
      {props.value}
    </div>
  );
};

Status.propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
};

export default Status;
