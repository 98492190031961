import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { UserContext } from "../../../../App";

import "./StageHeader.scss";

import EditableField from "../../../../components/EditableField";
import { editIcon } from "../../../../assets/img/sprite";
import { ROUTES } from "../../../../routes";
import CircleProgress from "../../../../components/CircleProgress";

const StageHeader = (props) => {
  const history = useHistory();
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  const { url } = useRouteMatch();
  const isInfoPath =
    history.location.pathname === url + ROUTES.OBJECT.INFO.PATH;

  const user = useContext(UserContext);

  const getEditClass = () => {
    if (
      !user.permissions.filter((perm) => perm.name === "perm_task_update")
        .length ||
      isAvailable
    ) {
      return "stage-header-edit-disabled";
    }
    if (props.isEditable) {
      return "stage-header-edit-active _error";
    }
    if (isInfoPath) {
      return "stage-header-edit";
    }
    return "stage-header-edit-disabled";
  };

  const getOnClick = () => {
    if (
      !user.permissions.filter((perm) => perm.name === "perm_task_update")
        .length
    ) {
      return null;
    }
    // if (props.isEditable) {
    //   return props.onCancel;
    // }
    if (isInfoPath) {
      return props.onEditClick;
    }
    return null;
  };

  // const canEdit = () => {
  //   if (user.permissions.filter(
  //     (perm) => perm.name === 'perm_task_update-extended'
  //   ).length) {
  //     return true
  //   }
  // }

  return (
    <div className="stage-header">
      <div className="stage-header-name">
        <EditableField
          value={props.item.name}
          isEditable={props.isEditable && props.havePermission}
          onChange={(ev) => props.onChange("name", ev.target.value)}
        />
      </div>

      <CircleProgress value={props.item.completion || 0} color={props.item.statusColor} />

      {getEditClass() === "stage-header-edit-disabled" ||
      props.status === "WORKS_DONE" ||
      props.status === "STOPPED" ? null : (
        <button
          onClick={getOnClick()}
          className={getEditClass()}
          type="button"
          disabled={getEditClass() === "stage-header-edit-disabled"}
        >
          {props.isEditable ? (
            <span>Отменить</span>
          ) : (
            <>
              {editIcon}
              {!isMobileSize && <span>Редактировать</span>}
            </>
          )}
        </button>
      )}
    </div>
  );
};

StageHeader.propTypes = {
  tabItems: PropTypes.arrayOf(
    PropTypes.objectOf({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  item: PropTypes.object,
  isEditable: PropTypes.bool,
  onEditClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default StageHeader;
