export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export function openModal({
  type,
  withoutCloseBtn = false,
  isContainer = false,
  data = {},
  render,
}) {
  return (dispatch) => {
    dispatch({
      type: OPEN_MODAL,
      payload: { type, withoutCloseBtn, isContainer, data, render },
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };
}
