import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import "./AddObjectForm.scss";

import Input from "../../../../../../components/Input";
import InputDate from "../../../../../../components/InputDate";
import InputTextArea from "../../../../../../components/InputTextArea";
import Select from "../../../../../../components/Select";
import { fetchFilters } from "../../../../../../actions/FiltersActions";
import {required} from "../../../../../../assets/js/validators";

const AddObjectForm = (props) => {
  const changeInfo = (ev) => {
    props.onChange(ev.target.name, ev.target.value);
  };
  const filtersStore = useSelector((store) => store.objectsFilters);
  const dispatch = useDispatch();
  const getFilters = (type) => {
    if (!filtersStore[type].isUpdated && !filtersStore[type].isFetching) {
      dispatch(fetchFilters(type));
    }
  };

  return (
    <div className="add-object-info-form">
      <div className="add-object-info-form-address">
        <Input
          label="Адрес"
          value={props.info.address}
          name="address"
          onChange={changeInfo}
          validators={[required]}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="add-object-info-form-plan-beg">
        <InputDate
          label="Дата заключения контракта"
          value={props.info.planBeg}
          onChange={(value) => props.onChange("planBeg", value)}
          validators={[required]}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="add-object-info-form-plan-end">
        <InputDate
          label="Дата окончания контракта"
          value={props.info.planEnd}
          onChange={(value) => props.onChange("planEnd", value)}
          validators={[required]}
          onValidationChange={props.onValidationChange}
        />
      </div>

      <div className="add-object-info-form-description">
        <InputTextArea
          label="Описание"
          value={props.info.description}
          onChange={(value) => props.onChange("description", value)}
        />
      </div>

      <div className="add-object-info-form-ref">
        <Input
          label="Ссылка на камеры наблюдения"
          value={props.info.refVideo}
          name="refVideo"
          onChange={changeInfo}
        />
      </div>

      <div className="add-object-info-form-national-project">
        <Select
          value={props.info.nationalObjectType.value}
          items={filtersStore.n_project.items}
          label="Нац. проект"
          onChange={(value) => props.onChange("nationalObjectType", value)}
          onOpen={() => getFilters("n_project")}
          validators={[required]}
          onValidationChange={props.onValidationChange}
          reset
        />
      </div>

      <div className="add-object-info-form-job-type">
        <Select
          value={props.info.jobType.value}
          items={filtersStore.work_type.items}
          label="Тип работ"
          onChange={(value) => props.onChange("jobType", value)}
          onOpen={() => getFilters("work_type")}
          validators={[required]}
          onValidationChange={props.onValidationChange}
          reset
        />
      </div>

      <div className="add-object-info-form-object-type">
        <Select
          value={props.info.objectType.value}
          items={filtersStore.object_type.items}
          label="Тип объекта"
          onChange={(value) => props.onChange("objectType", value)}
          onOpen={() => getFilters("object_type")}
          validators={[required]}
          onValidationChange={props.onValidationChange}
          reset
          readOnly
        />
      </div>
    </div>
  );
};

AddObjectForm.propTypes = {
  info: PropTypes.object,
  onChange: PropTypes.func,
  onValidationChange: PropTypes.func,
};

export default AddObjectForm;
