export const CONFIRM_REQUEST = "CONFIRM_REQUEST";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_ERROR = "CONFIRM_ERROR";
export const CONFIRM_CLEAR = "CONFIRM_CLEAR";

const initialState = {
  isSuccess: false,
  isFetching: false,
  isError: false,
  isUpdated: false,
  errors: [],
  items: [],
  pagination: {},
};

const userConfirmation = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case CONFIRM_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
        isFetching: false,
        isUpdated: true,
        items: action.payload.items,
        pagination: action.payload.pagination,
      };
    }
    case CONFIRM_ERROR: {
      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        isError: true,
        errors: action.payload,
      };
    }
    case CONFIRM_CLEAR: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default userConfirmation;