import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import "./FavoritesContainer.scss";

import ObjectCard from "../../components/ObjectCard";
import {
  fetchFavorites,
  setSubscription,
} from "../../actions/FavoritesActions";
import { fetchObjects } from "../../actions/ObjectsActions";
import { ROUTES } from "../../routes";
import Paginator from "../../components/Paginator";
import Loader from "../../components/Loader";
import ObjectsSearch from "../Objects/components/ObjectsSearch";
import CheckPermissions from "../../components/CheckPermissions";

const FavoritesContainer = (props) => {
  const favoritesStore = useSelector((store) => store.favorites);
  const { isMobileSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const getFavorites = useCallback(
    (filters, page) => dispatch(fetchFavorites(filters, page)),
    [dispatch]
  );
  const getAllobjects = () => dispatch(fetchObjects("private"));

  useEffect(() => {
    if (!favoritesStore.isUpdated && !favoritesStore.isFetching) {
      getFavorites();
    }
  }, [getFavorites, favoritesStore.isFetching, favoritesStore.isUpdated]);

  const onSubscribe = (id) => {
    setSubscription(id, false).then(() => {
      getFavorites();
      getAllobjects();
    });
  };

  const onClick = (id) => {
    history.push(ROUTES.OBJECT.PATH + `/${id}` + ROUTES.OBJECT.INFO.PATH);
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          {favoritesStore.isFetching ? <Loader /> : null}
          <h1 className="favorites-header">Избранное</h1>
          <ObjectsSearch
            pageName={"subscribe"}
            onFilter={(filter) => getFavorites(filter)}
          />
          <div className="favorites-list">
            {isMobileSize && (
              <ul className="favorites-head">
                <li className="favorites-head-name">Название и адрес</li>
                <li className="favorites-head-plan-end">Срок по плану</li>
                <li className="favorites-head-percentage">Прогресс</li>
                <li className="favorites-head-status">Статус</li>
                {isDesktopSize && (
                  <CheckPermissions permissions={["perm_subscribe_create"]}>
                    <li className="favorites-head-subscribe">Подписаться</li>
                  </CheckPermissions>
                )}
              </ul>
            )}
            <ul className="fav-objects">
              {favoritesStore.items.length ? (
                favoritesStore.items.map((item) => {
                  return (
                    <li key={item.id}>
                      <ObjectCard
                        item={item}
                        onSubscribe={() => onSubscribe(item.id)}
                        onClick={() => onClick(item.id)}
                      />
                    </li>
                  );
                })
              ) : (
                <li>
                  <div className="no-notes">Нет объектов</div>
                </li>
              )}
            </ul>
          </div>
          {favoritesStore.pagination.totalCount >
          favoritesStore.pagination.pageSize ? (
            <Paginator
              currentPage={favoritesStore.pagination.currentPage}
              total={favoritesStore.pagination.totalCount}
              totalPages={favoritesStore.pagination.totalPageCount}
              pageSize={favoritesStore.pagination.pageSize}
              onPageChange={(page) => getFavorites(undefined, page)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FavoritesContainer;
