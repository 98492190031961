import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { fromLonLat } from "ol/proj";
import Map from "../../../../../../components/Map/Map";

import "./SoloObjectMap.scss";

const SoloObjectMap = ({
  longitude,
  latitude,
  onPointSet,
  isEditable,
  polygons,
  onPolygonsChange,
}) => {
  const [center] = useState(() => fromLonLat([longitude, latitude]));

  return (
    <div className="solo-object-map">
      <Map
        center={center}
        initialZoom={17}
        showCadasterLayers={true}
        drawnObjects={{
          canDraw: isEditable,
          point: { coordinates: { longitude, latitude }, onChange: onPointSet },
          polygons: { items: polygons ?? [], onChange: onPolygonsChange },
        }}
      />
    </div>
  );
};

SoloObjectMap.propTypes = {
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditable: PropTypes.bool,
  onPointSet: PropTypes.func,
  icon: PropTypes.string,
};

export default SoloObjectMap;
