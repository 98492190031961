import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Select.scss";

import { inlineReset, inlineArrow } from "./img/inlineSVGicons";
import { triangularArrowIcon } from "../../assets/img/sprite";
import Loader from "../Loader";
import Paginator from "../Paginator";

const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.items || []);
  const [inputValue, setInputValue] = useState(props.value || []);
  const [validationError, setValidationError] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const total = filteredItems.length;
  const totalPages = Math.ceil(total / pageSize) || (total ? 1 : 1);

  const onChange = (item) => {
    props.onChange(item, props.name);
  };

  const { onOpen } = props;
  useEffect(() => {
    if (onOpen && isOpen) {
      onOpen();
    }
  }, [onOpen, isOpen]);

  useEffect(() => {
    let filtered = props.items.filter((item) => {
      return item.value.toLowerCase().trim().includes(inputValue);
    });
    setFilteredItems(filtered);
  }, [inputValue, props.items]);

  const onLabelClick = (ev) => {
    let input = ev.currentTarget.parentElement.querySelector("input");
    if (isOpen) {
      setIsOpen(false);
      input.blur();
      return;
    }
    setIsOpen(true);
    input.focus();
  };

  const change = (ev) => {
    props.validators && validate(ev.target.value);
    setInputValue(ev.target.value);
    onChange({
      key: null,
      value: ev.target.value,
    });
  };

  const reset = (ev) => {
    setInputValue("");
    props.validators && validate("");
    onLabelClick(ev);
    onChange({
      key: null,
      value: "",
    });
  };

  const select = (item) => {
    props.validators && validate(item.value);
    setInputValue(item.value);

    onChange(item);
  };

  const onBlur = (ev) => {
    if (!isTouched) {
      props.validators && validate(inputValue);
      setIsTouched(true);
    }
    for (let i = 0; i < filteredItems.length; i++) {
      if (filteredItems[i].value.trim() === inputValue) {
        onChange(filteredItems[i]);
        props.validators && validate(filteredItems[i].value);
        return;
      }
    }
  };

  const validate = (value) => {
    let result = "";
    props.validators.forEach((validator) => {
      let message = validator(value);
      if (!result || !message) result = message;
    });

    setValidationError(result);
    props.onValidationChange(props.name, result);
  };

  const getItems = () => {
    let paginationItems = [];
    let totalId =
      currentPage * pageSize > total ? total : currentPage * pageSize;
    for (let i = (currentPage - 1) * pageSize; i < totalId; i++) {
      paginationItems.push(filteredItems[i]);
    }
    return paginationItems;
  };

  const pageChange = (page) => {
    setCurrentPage(page);
  };

  const content = props.isFetching ? (
    <li>
      <Loader width={10} />
    </li>
  ) : props.isPaginator ? (
    <>
      {getItems().map((item) => (
        <li
          key={item.key}
          className={
            props.value === item.key ? "select-item selected" : "select-item"
          }
          onClick={() => select(item)}
        >
          {item.value}
        </li>
      ))}
      <div className="select-paginator">
        {total > pageSize ? (
          <Paginator
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            totalPages={totalPages}
            onPageChange={pageChange}
          />
        ) : null}
      </div>
    </>
  ) : (
    filteredItems.map((item) => (
      <li
        key={item.key}
        className={
          props.value === item.key ? "select-item selected" : "select-item"
        }
        onClick={() => select(item)}
      >
        {item.value}
      </li>
    ))
  );

  return (
    <div className={`select ${props.isRounded ? "_rounded" : ""}`}>
      {props.label ? (
        <div className="select-label-text" onClick={onLabelClick}>
          {props.label}
        </div>
      ) : null}

      <label onFocus={(ev) => {}} className="select-label">
        <div className="select-label-container">
          <input
            formNoValidate
            className={
              props.error || validationError
                ? "select-input-error"
                : "select-input"
            }
            placeholder={props.placeholder}
            value={inputValue}
            onChange={change}
            onClick={() => setIsOpen(true)}
            style={props.reset ? { paddingRight: 54 + "px" } : null}
            onBlur={onBlur}
            readOnly={props.readOnly}
          />

          {filteredItems.length > 0 ? (
            <div className="select-menu">{content}</div>
          ) : null}

          {inputValue.length > 0 && props.reset ? (
            <div onClick={reset} className="select-reset">
              {inlineReset}
            </div>
          ) : null}

          <div onClick={onLabelClick} className="select-arrow">
            {triangularArrowIcon}
          </div>
        </div>
      </label>
      {props.error || validationError ? (
        <div className="select-label-error" onClick={onLabelClick}>
          {props.error || validationError}
        </div>
      ) : null}
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  name: PropTypes.string,
  onOpen: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  isFetching: PropTypes.bool,
  reset: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
  isPaginator: PropTypes.bool,
  isRounded: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default Select;
