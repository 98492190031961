import { ROUTES } from "../../routes";
import { permissions } from "../../permissions";

import {
  allObjectsIcon,
  myObjectsIcon,
  notificationIcon,
  favoritesIcon,
  archiveIcon,
  helpCenterIcon,
  ratingIcon,
  bidIcon,
  userManagementIcon,
} from "../../assets/img/sprite";

export const config = [
  {
    text: "Все объекты",
    img: allObjectsIcon,
    path: ROUTES.OBJECTS.ALL.PATH,
    permission: permissions.viewAllObjects,
  },
  {
    text: "Мои объекты",
    img: myObjectsIcon,
    path: ROUTES.OBJECTS.MY.PATH,
    permission: permissions.viewMyObjects,
  },
  {
    text: "Избранное",
    img: favoritesIcon,
    path: ROUTES.FAVORITE.PATH,
    permission: permissions.viewFavorite,
  },
  {
    text: "Архив",
    img: archiveIcon,
    path: ROUTES.ARCHIVE.PATH,
    permission: permissions.viewArchive,
  },
  {
    text: "Управление пользователями",
    img: userManagementIcon,
    path: ROUTES.RIGHTS.PATH,
    permission: permissions.updateRights,
  },
  {
    text: "Уведомления",
    img: notificationIcon,
    path: ROUTES.NOTIFICATION.PATH,
    permission: permissions.viewNotifications,
  },
  {
    text: "Заявки",
    img: bidIcon,
    path: ROUTES.USER_CONFIRMATION.PATH,
    permission: permissions.viewApplication,
  },
  {
    text: "Справочный центр",
    img: helpCenterIcon,
    path: ROUTES.HELP_CENTER.PATH,
  },
  {
    text: "Рейтинг",
    img: ratingIcon,
    path: ROUTES.RATING.PATH,
    permission: permissions.viewRating,
  },
];
