import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./FileSolo.scss";
import { inlineDelete, inlineDownload } from "./img/inlineSvgIcons";
import { downloadIcon, deleteIcon } from "../../assets/img/sprite";
import InputFileDrop from "../InputFileDrop";
import { closeModal, openModal } from "../../actions/ModalAction";
import { ModalTypes } from "../../assets/js/constants";
import ConfirmAction from "../ConfirmAction";

const FileSolo = (props) => {
  const dispatch = useDispatch();
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  if (props.isError) {
    return (
      <div className="file-solo-empty-filler">Ошибка при загрузке файлов</div>
    );
  }

  const handleFileDelete = () => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        withoutCloseBtn: true,
        render: () => (
          <ConfirmAction
            text="Файлы будут безвозвратно удалены!"
            onCancel={() => dispatch(closeModal())}
            onConfirm={() => {
              props.onDelete(props.file.id);
              dispatch(closeModal());
            }}
          />
        ),
      })
    );
  };

  if (props.file) {
    return (
      <div className="file-solo-container">
        <div className="file-solo">
          <div
            className="file-solo-icon"
            style={!props.onDownload ? { cursor: "default" } : null}
            onClick={props.onDownload ? () => {} : null}
          >
            {props.file.icon || props.icon}
          </div>

          <div className="file-solo-info">
            <div className="file-solo-name">{props.file.name}</div>
            <div className="file-solo-size">{props.file.size}</div>
          </div>
        </div>

        <div className="file-solo-controls">
          {props.onDownload ? (
            <button
              onClick={() => props.onDownload(props.file.id)}
              className="file-solo-download"
            >
              {downloadIcon}
              {!isMobileSize && <span>Загрузить</span>}
            </button>
          ) : null}

          {props.onDelete &&
          props.status !== "WORKS_DONE" &&
          props.status !== "STOPPED" ? (
            <button
              onClick={handleFileDelete}
              className="file-solo-delete _error"
            >
              {deleteIcon}
              {!isMobileSize && <span>Удалить</span>}
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  if (
    props.onUpload &&
    props.status !== "WORKS_DONE" &&
    props.status !== "STOPPED"
  ) {
    return (
      <InputFileDrop
        onFileDrop={props.onUpload}
        format={props.format}
        label={props.label}
        helpMessage={props.helpMessage}
      />
    );
  }

  return <div className="file-solo-empty-filler">Нет файлов</div>;
};

FileSolo.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.any,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.string,
  }),
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  icon: PropTypes.any,
  label: PropTypes.string,
  format: PropTypes.string,
  helpMessage: PropTypes.string,
};

export default FileSolo;
