import api from "./api";

export const USER_UPDATE_PASS_REQUEST = "USER_UPDATE_PASS_REQUEST";
export const USER_UPDATE_PASS_SUCCESS = "USER_UPDATE_PASS_SUCCESS";
export const USER_UPDATE_PASS_ERROR = "USER_UPDATE_PASS_ERROR";

export function updatePass(password, token) {
  return (dispatch) => {
    dispatch({
      type: USER_UPDATE_PASS_REQUEST,
    });

    let userData = {
      password,
      token,
    };

    return fetch(api + "/v1/auth/reset-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: USER_UPDATE_PASS_SUCCESS,
            payload: data,
          });
          return data.data;
        }

        dispatch({
          type: USER_UPDATE_PASS_ERROR,
          payload: data.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_UPDATE_PASS_ERROR,
          payload: error,
        });
      });
  };
}
