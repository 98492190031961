export const OPEN_REGISTRATION_POPUP = "OPEN_REGISTRATION_POPUP";
export const CLOSE_REGISTRATION_POPUP = "CLOSE_REGISTRATION_POPUP";

export function openRegistrationPopup() {
  return (dispatch) => {
    dispatch({
      type: OPEN_REGISTRATION_POPUP,
    });
  };
}

export function closeRegistrationPopup() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_REGISTRATION_POPUP,
    });
  };
}
