import React from "react";
import PropTypes from "prop-types";

function CircleProgress({ value, color }) {
  const roundedValue = Math.round(value);
  return (
    <svg
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 112 112"
    >
      <circle
        cx="50%"
        cy="50%"
        r="50"
        fill="none"
        stroke="#EAEAEA"
        strokeWidth="12"
      />
      <circle
        cx="50%"
        cy="50%"
        r="50"
        transform="rotate(-90 56 56)"
        fill="none"
        strokeDashoffset={(1 - roundedValue / 100) * 314}
        strokeDasharray="314"
        stroke={color || "#2EAADC"}
        strokeWidth="12"
      ></circle>
      <text
        id="count"
        x="50%"
        y="50%"
        fill="#333333"
        textAnchor="middle"
        dy="11"
        fontSize="34"
      >
        {roundedValue + "%"}
      </text>
    </svg>
  );
}

CircleProgress.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  unconfirmedValue: PropTypes.number,
};

export default CircleProgress;
