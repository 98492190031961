import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./NotificationsContainer.scss";

import NotificationCard from "./components/NotificationCard";
import {
  fetchNotifications,
  setRead,
  setReadAll,
} from "../../actions/NotificationsActions";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../routes";
import Loader from "../../components/Loader";
import Paginator from "../../components/Paginator";
import { checkAllIcon } from "../../assets/img/sprite";

const NotificationsContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationsStore = useSelector((store) => store.notifications);

  const getNotifications = useCallback(
    (page) => {
      dispatch(fetchNotifications(page));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      !notificationsStore.isUpdated &&
      !(notificationsStore.isFetching || notificationsStore.isError)
    ) {
      getNotifications();
    }
  }, [
    getNotifications,
    notificationsStore.isError,
    notificationsStore.isUpdated,
    notificationsStore.isFetching,
  ]);

  const onRead = (id) => {
    setRead(id).then((r) => {
      getNotifications(notificationsStore.pagination.currentPage);
    });
  };

  const readAll = () => {
    setReadAll().then(() => {
      getNotifications(notificationsStore.pagination.currentPage);
    });
  };

  const onTarget = (type, ref, id) => {
    onRead(id);
    let info = JSON.parse(ref);
    switch (type) {
      case "CONTRACT_OBJECT_BEGIN":
      case "CONTRACT_TASK_BEGIN":
      case "OBJECT_TO_WORK":
      case "COMPLETED_OBJECT":
      case "UPDATED_OBJECT":
      case "UPLOADED_DOCUMENT":
      case "REQUEST_CONTRACTOR":
      case "CREATED_OBJECT": {
        history.push(
          ROUTES.OBJECT.PATH +
            `/${info.construction_object_id}` +
            ROUTES.OBJECT.INFO.PATH
        );
        break;
      }

      case "DELETED_SECTION":
      case "CREATED_SECTION":
      case "CREATED_TASK":
      case "UPDATED_TASK":
      case "UPDATED_SECTION":
      case "DELETED_TASK": {
        history.push(
          ROUTES.OBJECT.PATH +
            `/${info.construction_object_id}` +
            ROUTES.OBJECT.STAGES.PATH
        );
        break;
      }

      case "COMPLETED_TASK":
      case "CREATE_COMMENT_TASK":
      case "DELETED_WORKS-HISTORY": {
        history.push(
          ROUTES.OBJECT.PATH +
            `/${info.construction_object_id}` +
            ROUTES.OBJECT.SECTION.PATH +
            `/${info.construction_object_section_id}` +
            ROUTES.OBJECT.SECTION.STAGE.PATH +
            `/${info.construction_object_task_id}`
        );
        break;
      }

      case "CREATE_COMMENT_WORKS-HISTORY":
      case "CREATED_WORKS-HISTORY":
      case "UPDATED_WORKS-HISTORY":
      case "APPROVED_WORKS-HISTORY":
      case "DISAPPROVED_WORKS-HISTORY": {
        history.push(
          ROUTES.OBJECT.PATH +
            `/${info.construction_object_id}` +
            ROUTES.OBJECT.SECTION.PATH +
            `/${info.construction_object_section_id}` +
            ROUTES.OBJECT.SECTION.STAGE.PATH +
            `/${info.construction_object_task_id}` +
            ROUTES.OBJECT.SECTION.STAGE.HISTORY.PATH
        );
        break;
      }
      default: {
        history.push(
          ROUTES.OBJECT.PATH +
            `/${info.construction_object_id}` +
            ROUTES.OBJECT.INFO.PATH
        );
        break;
      }
    }

    let mainContent = document.getElementsByClassName("main-layout-content")[0];
    mainContent.scrollTo({ top: 0 });
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card _narrow">
          {notificationsStore.isFetching ? <Loader /> : null}

          <div className="container__head">
            <h1 className="container__title">Уведомления</h1>

            <button className="notifications-cards-read-all" onClick={readAll}>
              {checkAllIcon}
              <span>Отметить все как прочитанные</span>
            </button>
          </div>

          {/*<ul className="notifications-cards-head">*/}
          {/*  <li className="notifications-cards-header-task">Объект</li>*/}
          {/*  <li className="notifications-cards-header-text">Текст</li>*/}
          {/*  <li className="notifications-cards-header-time">Время</li>*/}
          {/*  <li className="notifications-cards-header-read">*/}
          {/*    <button onClick={readAll}>Отметить все как "Прочитано"</button>*/}
          {/*  </li>*/}
          {/*</ul>*/}

          {notificationsStore.items.length ? (
            <ul className="notifications-cards-list">
              {notificationsStore.items.map((item) => {
                return (
                  <li key={item.notification_id}>
                    <NotificationCard
                      item={item}
                      onRead={() => onRead(item.notification_id)}
                      onTarget={(type, ref) =>
                        onTarget(type, ref, item.notification_id)
                      }
                    />
                  </li>
                );
              })}
            </ul>
          ) : (
            <div className="no-notes">Нет уведомлений</div>
          )}
          {notificationsStore.pagination.totalCount >
          notificationsStore.pagination.pageSize ? (
            <Paginator
              currentPage={notificationsStore.pagination.currentPage}
              totalPages={notificationsStore.pagination.totalPageCount}
              total={notificationsStore.pagination.totalCount}
              pageSize={notificationsStore.pagination.pageSize}
              onPageChange={getNotifications}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NotificationsContainer;
