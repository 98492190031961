import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Input from "../../../../../../../../components/Input";
import InputDate from "../../../../../../../../components/InputDate";

import "./AddTask.scss";
import moment from "moment";
import { addObjectSectionTask } from "../../../../../../../../actions/ObjectSectionsActions";
import toaster from "toasted-notes";

const AddTask = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [newTask, setNewTask] = useState({
    name: "",
    planBeg: moment().format("DD.MM.YYYY"),
    planEnd: moment().format("DD.MM.YYYY"),
    amount: "",
    unit: "",
  });
  const isAvailable = useSelector((state) => state.objects.isAvailable);

  const changeNewTask = (name, value) => {
    if (name === "amount") {
      value = value.replace(",", ".");
    }
    setNewTask({
      ...newTask,
      [name]: value,
    });
  };

  const onSave = () => {
    addObjectSectionTask(props.objectId, props.sectionId, newTask).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Этап добавлен!</div>, {
          position: "top-right",
          duration: 4000,
        });
        setNewTask({
          name: "",
          planBeg: moment().format("DD.MM.YYYY"),
          planEnd: moment().format("DD.MM.YYYY"),
          amount: "",
          unit: "",
        });
        props.onTaskAdd();
      } else {
        let html = { __html: Object.values(r).join("<br>") };
        toaster.notify(
          <div className="error-toast" dangerouslySetInnerHTML={html} />,
          { position: "top-right", duration: 4000 }
        );
      }
    });
  };

  const onCancel = () => {
    setIsEditable(false);
    setNewTask({
      name: "",
      planBeg: moment().format("DD.MM.YYYY"),
      planEnd: moment().format("DD.MM.YYYY"),
      amount: "",
      unit: "",
    });
  };

  return (
    <div className="add-tasks">
      {isEditable ? (
        <React.Fragment>
          <div className="add-tasks__name">
            <Input
              name="name"
              value={newTask.name}
              onChange={(ev) => changeNewTask("name", ev.target.value)}
              label="Название"
            />
          </div>
          <div className="add-tasks__plan-beg">
            <InputDate
              name="planBeg"
              value={newTask.planBeg}
              onChange={(value) => changeNewTask("planBeg", value)}
              label="Начало работ"
            />
          </div>
          <div className="add-tasks__plan-end">
            <InputDate
              name="planEnd"
              value={newTask.planEnd}
              onChange={(value) => changeNewTask("planEnd", value)}
              label="Конец работ"
            />
          </div>
          <div className="add-tasks__amount">
            <Input
              name="amount"
              value={newTask.amount}
              onChange={(ev) => changeNewTask("amount", ev.target.value)}
              label="Объем работ"
            />
          </div>
          <div className="add-tasks__unit">
            <Input
              name="unit"
              value={newTask.unit}
              onChange={(ev) => changeNewTask("unit", ev.target.value)}
              label="Единицы изм."
            />
          </div>
        </React.Fragment>
      ) : null}

      <div className={`add-tasks__controls ${!isEditable ? "_not-editing" : ""}`}>
        {isEditable ? (
          <React.Fragment>
            <button className="add-tasks__controls__cancel _error" onClick={onCancel}>
              Отмена
            </button>

            <button
              className="add-tasks__controls__save"
              disabled={
                !(
                  (!newTask.planEnd && !newTask.planBeg) ||
                  (newTask.planEnd && newTask.planBeg)
                )
              }
              onClick={onSave}
            >
              Сохранить
            </button>
          </React.Fragment>
        ) : (
          <button
            className="add-tasks__controls__add"
            onClick={() => setIsEditable(true)}
            disabled={isAvailable}
          >
            Добавить этап
          </button>
        )}
      </div>
    </div>
  );
};

AddTask.propTypes = {
  objectId: PropTypes.string,
  sectionId: PropTypes.number,
  onTaskAdd: PropTypes.func,
};

export default AddTask;
