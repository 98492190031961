import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginContainer from "./containers/SingIn";
import { connect } from "react-redux";
import { setTokenInfo } from "./actions/SingInActions";
import { GetUserByTokenAction, disableUserHints } from "./actions/UserActions";
import { updateWindowParams } from "./actions/ResponsiveActions";

import { ROUTES } from "./routes.js";
import ObjectsContainer from "./containers/Objects";
import SideMenuLayout from "./layouts/SidemenuLayout";
import SingUpContainer from "./containers/SingUp";
import SingInContainer from "./containers/SingIn/SingInContainer";
import ResetPassContainer from "./containers/ResetPass/ResetPassContainer";
import UpdatePassContainer from "./containers/UpdatePass/UpdatePassContainer";

import "./assets/fonts/fonts.css";
import AddObjectContainer from "./containers/AddObject";
import SoloObjectContainer from "./containers/SoloObject";
import CheckPermissions from "./components/CheckPermissions";
import { permissions } from "./permissions";
import StageContainer from "./containers/Stage";
import NotificationsContainer from "./containers/Notifications";
import ProfileContainer from "./containers/Profile";
import FavoritesContainer from "./containers/Favorites";
import UserConfirmationContainer from "./containers/UserConfirmation";
import ArchiveContainer from "./containers/Archive";
import GiveRightsContainer from "./containers/GiveRights";
import toaster from "toasted-notes";
import Loader from "./components/Loader";
import StartPage from "./containers/StartPage";
import HelpTopics from "./containers/HelpTopics";
import HelpTopic from "./containers/HelpTopic";
import Tutorial from "./components/Tutorial";
import {
  getRegistrationSteps,
  getInstructionalSteps,
} from "./components/Tutorial/steps";
import GlobalModal from "./containers/GlobalModal";
import OverallRatingContainer from "./containers/OverallRating";
import getWindowSizeName from "./assets/js/utils/getWindowSizeName";

export const UserContext = React.createContext(null);

class App extends React.Component {
  state = {
    showRegistrationTutorial:
      localStorage.getItem("completed_registration_tutorial") !== "true",
    isPendingContent: true,
    isPendingAccountWidgetControls: true,
    windowSize: {
      isMobile: this.props.isMobileSize,
      isTablet: this.props.isTabletSize,
      isDesktop: this.props.isDesktopSize,
    },
    windowSizeName: getWindowSizeName({
      isMobile: this.props.isMobileSize,
      isTablet: this.props.isTabletSize,
      isDesktop: this.props.isDesktopSize,
    }),
  };

  handleTutorialRedirection = () => this.setState({ isPendingContent: true });

  handleContentReady = () => {
    this.setState({ isPendingContent: false });
  };

  handleTutorialFinish = () => {
    this.props.disableHints();
  };

  handleRegistrationTutorialRedirection = () =>
    this.setState({ isPendingAccountWidgetControls: true });

  handleAccountWidgetControlsReady = () => {
    this.setState({ isPendingAccountWidgetControls: false });
  };

  handleRegistrationTutorialFinish = () => {
    localStorage.setItem("completed_registration_tutorial", "true");
    this.setState({ showRegistrationTutorial: false });
  };

  handleWindowResize = () => {
    const width = document.body.clientWidth;
    const height = document.body.clientHeight;

    this.props.updateResponsiveData({ width, height });
  };

  componentDidMount() {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (tokenName && token) {
      this.props.setTokenInfo(tokenName, token);
      this.props.getUserByToken(tokenName, token);
    }
    this.handleWindowResize();
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !this.props.user.isSuccess &&
      !this.props.user.isFetching &&
      !this.props.user.isError
    ) {
      let tokenName = localStorage.getItem("token_name");
      let token = localStorage.getItem(tokenName);
      if (tokenName && token) {
        this.props.setTokenInfo(tokenName, token);
        this.props.getUserByToken(tokenName, token);
      }
    }

    if (
      this.props.isMobileSize !== this.state.windowSize.isMobile ||
      this.props.isTabletSize !== this.state.windowSize.isTablet ||
      this.props.isDesktopSize !== this.state.windowSize.isDesktop
    ) {
      this.setState({
        windowSize: {
          isMobile: this.props.isMobileSize,
          isTablet: this.props.isTabletSize,
          isDesktop: this.props.isDesktopSize,
        },
        windowSizeName: getWindowSizeName({
          isMobile: this.props.isMobileSize,
          isTablet: this.props.isTabletSize,
          isDesktop: this.props.isDesktopSize,
        }),
      });
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.props.user}>
        <Switch>
          {/*StartPage*/}
          <Route exact path={ROUTES.START.PATH}>
            <StartPage
              onAccountWidgetControlsReady={
                this.handleAccountWidgetControlsReady
              }
            />
          </Route>

          <Route path={ROUTES.LOGIN.PATH}>
            <LoginContainer />
          </Route>

          <Route path={ROUTES.RESET_PASS.PATH}>
            <ResetPassContainer />
          </Route>

          <Route
            exact
            path={`${ROUTES.UPDATE_PASS.PATH}/:token`}
            render={(offerProps) => <UpdatePassContainer {...offerProps} />}
          />

          <Route path={ROUTES.REGISTRATION.PATH}>
            <SingUpContainer />
          </Route>

          {this.props.user.isFetching ? (
            <Route path="*">
              <Loader />
            </Route>
          ) : null}
          {!this.props.user.isSuccess ? (
            <Route path="*">
              <SingInContainer />
            </Route>
          ) : null}

          {/*Objects*/}
          <Route path={ROUTES.OBJECTS.PATH}>
            <SideMenuLayout>
              <ObjectsContainer onContentReady={this.handleContentReady} />
            </SideMenuLayout>
          </Route>

          {/*Add Object*/}
          <Route path={ROUTES.OBJECT.ADD.PATH}>
            <SideMenuLayout arrowBack>
              <AddObjectContainer />
            </SideMenuLayout>
          </Route>

          {/*Stage*/}
          <Route path={ROUTES.OBJECT.SECTION.STAGE.ROUTE}>
            <SideMenuLayout arrowBack>
              <StageContainer />
            </SideMenuLayout>
          </Route>

          {/*Object*/}
          <Route path={ROUTES.OBJECT.ROUTE}>
            <SideMenuLayout arrowBack>
              <SoloObjectContainer />
            </SideMenuLayout>
          </Route>

          {/*Favorites*/}
          <Route exact path={ROUTES.FAVORITE.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewFavorite]}>
                <FavoritesContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          {/*Archive*/}
          <Route exact path={ROUTES.ARCHIVE.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewArchive]}>
                <ArchiveContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.SETTINGS.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewUserProfile]}>
                <ObjectsContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.NOTIFICATION.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewNotifications]}>
                <NotificationsContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.HELP_CENTER.PATH}>
            <SideMenuLayout>
              <HelpTopics />
            </SideMenuLayout>
          </Route>

          <Route path={ROUTES.HELP_CENTER.TOPIC.ROUTE}>
            <SideMenuLayout arrowBack>
              <HelpTopic />
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.PROFILE.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewProfile]}>
                <ProfileContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.USER_CONFIRMATION.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewApplication]}>
                <UserConfirmationContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.RIGHTS.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.updateRights]}>
                <GiveRightsContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route exact path={ROUTES.RATING.PATH}>
            <SideMenuLayout>
              <CheckPermissions permissions={[permissions.viewRating]}>
                <OverallRatingContainer />
              </CheckPermissions>
            </SideMenuLayout>
          </Route>

          <Route path="*">
            <SideMenuLayout />
          </Route>
        </Switch>
        <GlobalModal />

        {!this.props.user.isSuccess && this.state.showRegistrationTutorial && (
          <Tutorial
            isPendingContent={this.state.isPendingAccountWidgetControls}
            onRedirection={this.handleRegistrationTutorialRedirection}
            onFinish={this.handleRegistrationTutorialFinish}
            steps={getRegistrationSteps(this.state.windowSizeName)}
          />
        )}

        {this.props.user.isSuccess && this.props.user.showHints && (
          <Tutorial
            isPendingContent={this.state.isPendingContent}
            onRedirection={this.handleTutorialRedirection}
            onFinish={this.handleTutorialFinish}
            steps={getInstructionalSteps(this.state.windowSizeName)}
          />
        )}
      </UserContext.Provider>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    info: store.singIn,
    user: store.user,
    navigation: store.navigation,
    isMobileSize: store.responsive.window.isMobileSize,
    isTabletSize: store.responsive.window.isTabletSize,
    isDesktopSize: store.responsive.window.isDesktopSize,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTokenInfo: (name, token) => dispatch(setTokenInfo(name, token)),
    getUserByToken: (header, token) =>
      dispatch(GetUserByTokenAction(header, token)).then((r) => {
        if (r && r.item_name.indexOf("almost") !== -1) {
          toaster.notify(
            <div className="success-toast">
              Ваш аккаунт ожидает подтверждения
            </div>,
            { position: "top-right", duration: 4000 }
          );
        }
      }),
    disableHints: () =>
      dispatch(disableUserHints()).then((r) => {
        if (r !== "success") {
          toaster.notify(
            <div className="error-toast">
              При завершении просмотра подсказок произошла ошибка.
            </div>,
            { position: "top-right", duration: 4000 }
          );
        }
      }),
    updateResponsiveData: (data) => dispatch(updateWindowParams(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
