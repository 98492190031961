import React from "react";
import PropTypes from "prop-types";

import "./Switch.scss";

const Switch = (props) => {
  return (
    <label className={props.isActive ? "switch-active" : "switch"}>
      <input
        onChange={props.onActivate}
        className="switch-input"
        type="checkbox"
        disabled={props.disabled}
      />
      <span className="switch-slider" />
    </label>
  );
};

Switch.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Switch;
