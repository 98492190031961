import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./AddObjectStage.scss";

import EditableField from "../../../../../../../../../../components/EditableField";
import EditableDateField from "../../../../../../../../../../components/EditableDateField";

import {
  editIcon,
  deleteIcon,
  checkIcon,
  closeIcon,
} from "../../../../../../../../../../assets/img/sprite";
import ReactTooltip from "react-tooltip";
import {useSelector} from "react-redux";

const AddObjectStage = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [changedStage, setChangedStage] = useState(null);
  const { isMobileSize } = useSelector((store) => store.responsive.window);

  useEffect(() => {
    if (isEditable) {
      setChangedStage(props.stage);
      return;
    }
    setChangedStage(null);
  }, [isEditable, props.stage]);

  const change = (name, value) => {
    if (name === "requiredAmount") {
      value = value.replace("-", "");
      if (stage.requiredAmount[0] === "-" && value[0] !== "-") {
        props.onChangeAmount();
      }
    }
    if (name === "unit") {
      if (stage.unit === "" && value !== "") {
        props.onChangeUnit();
      }
    }
    setChangedStage({
      ...changedStage,
      [name]: value,
    });
  };

  const save = () => {
    props.onSave(changedStage);
    setIsEditable(false);
  };

  const onDelete = () => {
    props.onDelete(
      props.stage.id,
      props.stage.requiredAmount,
      props.stage.unit
    );
  };

  let stage = changedStage || props.stage;
  return (
    <div className="add-object-stage-container">
      <div className="add-object-stage">
        <div
          className="add-object-stage-name"
          data-tip
          data-for={"stagesItemName" + stage.id}
        >
          <EditableField
            onChange={(ev) => change("name", ev.target.value)}
            value={stage.name}
            isEditable={isEditable}
          />
        </div>
        <ReactTooltip
          multiline
          isCapture
          id={"stagesItemName" + stage.id}
          type="dark"
          place="top"
          effect="float"
        >
          <span>{stage.name}</span>
        </ReactTooltip>

        <div className="add-object-stage-plan-beg">
          <div className="mobile-label">Начало работ</div>
          <EditableDateField
            onChange={(value) => change("planBeg", value)}
            value={stage.planBeg}
            isEditable={isEditable}
          />
        </div>

        <div className="add-object-stage-plan-end">
          <div className="mobile-label">Конец работ</div>
          <EditableDateField
            onChange={(value) => change("planEnd", value)}
            value={stage.planEnd}
            isEditable={isEditable}
          />
        </div>

        <div
          className={
            stage.requiredAmount[0] === "-" || stage.requiredAmount === "0"
              ? "red-text"
              : "add-object-stage-amount"
          }
        >
          <div className="mobile-label">Объем</div>
          <EditableField
            onChange={(ev) => change("requiredAmount", ev.target.value)}
            value={stage.requiredAmount}
            isEditable={isEditable}
          />
        </div>

        <div
          className={stage.unit === "" ? "red-text" : "add-object-stage-unit"}
          data-tip
          data-for={"stagesItemUnit" + stage.id}
        >
          <div className="mobile-label">Единицы изм.</div>
          <EditableField
            onChange={(ev) => change("unit", ev.target.value)}
            value={stage.unit}
            isEditable={isEditable}
          />
        </div>
        {stage.unit.length > 10 ? (
          <ReactTooltip
            multiline
            isCapture
            id={"stagesItemUnit" + stage.id}
            type="dark"
            place="top"
            effect="float"
          >
            <span>{stage.unit}</span>
          </ReactTooltip>
        ) : null}

        <div className="add-object-stage-controls">
          <button
            onClick={isEditable ? save : onDelete}
            className={
              isEditable
                ? "add-object-stage-control-1-save"
                : "add-object-stage-control-1-delete _error"
            }
          >
            {isEditable ? (
                <>
                  {checkIcon}
                  {isMobileSize && <span>Сохранить</span>}
                </>
            ) : (
                <>
                  {deleteIcon}
                  {isMobileSize && <span>Удалить</span>}
                </>
            )}
          </button>

          <button
            onClick={() => setIsEditable(!isEditable)}
            className={
              isEditable
                ? "add-object-stage-control-2-cancel _error"
                : "add-object-stage-control-2-edit"
            }
          >
            {isEditable ? (
              <>
                {closeIcon}
                {isMobileSize && <span>Отменить</span>}
              </>
            ) : (
              <>
                {editIcon}
                {isMobileSize && <span>Редактировать</span>}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

AddObjectStage.propTypes = {
  stage: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    completion: PropTypes.string,
    requiredAmount: PropTypes.string,
    unit: PropTypes.string,
    updated: PropTypes.string,
    sectionId: PropTypes.string,
    createdAt: PropTypes.string,
    factBeg: PropTypes.string,
    factEnd: PropTypes.string,
    planBeg: PropTypes.string,
    planEnd: PropTypes.string,
  }),
  onSave: PropTypes.func,
};

export default AddObjectStage;
