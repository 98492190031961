import {
  USER_RATING_REQUEST,
  USER_RATING_ERROR,
  USER_RATING_SUCCESS,
} from "../actions/UserRatingAction";

const initialState = {
  isFetching: false,
  userData: {
    id: "",
    title: "",
    rating: null,
    score: [],
  },
  isError: false,
  errors: [],
};

const userRating = (state = initialState, action) => {
  switch (action.type) {
    case USER_RATING_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case USER_RATING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        userData: action.payload.userData,
      };
    }
    case USER_RATING_ERROR: {
      return {
        ...state,
        isFetching: false,
        isError: true,
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

export default userRating;
