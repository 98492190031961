import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./FileCard.scss";
import "../../../UserInfoEditable/UserInfoEditable.scss";
import { editIcon, checkIcon, closeIcon } from "../../../../assets/img/sprite";
import ChooseUser from "../../../ChooseUser";
import UserInfoEditable from "../../../UserInfoEditable/UserInfoEditable";
import { userData as userDataTypes } from "../../../../assets/js/propTypes";
import { ModalTypes } from "../../../../assets/js/constants";
import { openModal, closeModal } from "../../../../actions/ModalAction";
import CheckBox from "../../../CheckBox";

const PersonTypes = {
  BUILDER: "builder",
  CONTROL: "construction-control",
};

const EstimateCard = (props) => {
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(props.name);
  const [isNameChanged, setIsNameChanged] = useState(false);
  const [selectingPersonType, setSelectingPersonType] = useState(null);
  const [builderPerson, setBuilderPerson] = useState({
    ...props.builder,
  });
  const [controlPerson, setControlPerson] = useState({
    ...props.control,
  });
  const [usersActive, setUsersActive] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const { isMobileSize } = useSelector((store) => store.responsive.window);

  const hasUpdateHandler = !!props.onUpdate;
  const hasRemoveHandler = !!props.onRemove;

  useEffect(() => {
    setIsNameChanged(fileName !== props.name);
  });

  useEffect(() => {
    if (
      (builderPerson.id && builderPerson.id !== props.builder.id) ||
      (controlPerson.id && controlPerson.id !== props.control.id) ||
      shouldUpdate
    ) {
      updateFileData();
      setShouldUpdate(false);
    }
  }, [builderPerson, controlPerson, shouldUpdate]);

  useEffect(() => {
    setBuilderPerson({ ...props.builder });
    setControlPerson({ ...props.control });
  }, [props.control.id, props.builder.id]);

  const getModalLabel = () => {
    switch (selectingPersonType) {
      case PersonTypes.BUILDER:
        return "Назначить подрядчика";
      case PersonTypes.CONTROL:
        return "Назначить стройконтроль";
      default:
        return "";
    }
  };

  const getUserInfoTitle = (type) => {
    switch (type) {
      case PersonTypes.BUILDER:
        return "Подрядчик";
      case PersonTypes.CONTROL:
        return "Стройконтроль";
      default:
        return "";
    }
  };

  const updateFileData = () => {
    hasUpdateHandler &&
      props.onUpdate({
        id: props.id,
        name: fileName,
        builder: builderPerson,
        control: controlPerson,
      });
  };

  const onFileNameChange = (e) => {
    setIsNameChanged(e.target.value !== props.name);
    setFileName(e.target.value);
  };

  const onFileNameUpdate = (e) => {
    e.preventDefault();
    if (!isNameChanged) return;
    updateFileData();
  };

  const onPersonTypeSelect = (type) => {
    setSelectingPersonType(type);
    setUsersActive(true);
  };

  const onPersonSelect = ({
    id,
    name,
    email,
    phone,
    additionalInfo,
    orgName,
    orgContacts,
    orgAddress,
  }) => {
    const preparedPersonData = {
      id: id || "",
      name: name || "",
      email: email || "",
      phone: phone || "",
      additionalInfo: additionalInfo || "",
      orgName: orgName || "",
      orgContacts: orgContacts || "",
      orgAddress: orgAddress || "",
    };

    switch (selectingPersonType) {
      case PersonTypes.BUILDER:
        setBuilderPerson(preparedPersonData);
        break;
      case PersonTypes.CONTROL:
        setControlPerson(preparedPersonData);
        break;
      default:
        break;
    }
  };

  const onPersonRemove = (type) => {
    hasRemoveHandler && props.onRemove(props.id, type);
  };

  const handlePersonDataChange = (
    type,
    {
      builderName,
      builderEmail,
      builderPhone,
      builderContact,
      organizationName,
      organizationContacts,
      organizationAddress,
    }
  ) => {
    const adaptedData = {
      name: builderName || "",
      email: builderEmail || "",
      phone: builderPhone || "",
      additionalInfo: builderContact || "",
      orgName: organizationName || "",
      orgContacts: organizationContacts || "",
      orgAddress: organizationAddress || "",
    };

    switch (type) {
      case PersonTypes.BUILDER:
        setBuilderPerson({ ...builderPerson, ...adaptedData });
        break;
      case PersonTypes.CONTROL:
        setControlPerson({ ...controlPerson, ...adaptedData });
        break;
      default:
        break;
    }

    setShouldUpdate(true);
    dispatch(closeModal());
  };

  const handlePersonInfoShow = (personType) => {
    dispatch(
      openModal({
        type: ModalTypes.RENDER,
        isContainer: true,
        render: () => {
          let userInfo = {};
          switch (personType) {
            case PersonTypes.BUILDER:
              userInfo = builderPerson;
              break;
            case PersonTypes.CONTROL:
              userInfo = controlPerson;
              break;
            default:
              break;
          }

          return (
            <UserInfoEditable
              title={getUserInfoTitle(personType)}
              type={personType}
              user={userInfo}
              onSave={handlePersonDataChange}
            />
          );
        },
      })
    );
  };

  return (
    <div className="estimate-card-wrapper">
      <div className="estimate-card-block">
        {isMobileSize && <span className="estimate-card-label">Смета</span>}
        <div
          onClick={props.onClick}
          className={props.selected ? "file-card-selected" : "file-card"}
        >
          <div className="file-card-icon">{props.icon}</div>
          <div className="file-card-info">
            <form className="file-card-name-form" onSubmit={onFileNameUpdate}>
              <div className="file-card-name-block">
                <input
                  id={`file-name-${props.id}`}
                  type="text"
                  className="file-card-name"
                  onChange={onFileNameChange}
                  value={fileName}
                  readOnly={!hasUpdateHandler}
                />
                {!isNameChanged && hasUpdateHandler ? (
                  <label
                    htmlFor={`file-name-${props.id}`}
                    className="file-card-change _icon"
                  >
                    {editIcon}
                  </label>
                ) : null}
              </div>
              {isNameChanged && hasUpdateHandler ? (
                <button
                  type="submit"
                  className="file-card-name-update _icon"
                  onClick={(e) => e.stopPropagation()}
                >
                  {checkIcon}
                </button>
              ) : null}
            </form>
            <div className="file-card-size">{props.size}</div>
          </div>
        </div>
      </div>
      <div className="estimate-card-block">
        {isMobileSize && <span className="estimate-card-label">Подрядчик</span>}
        <div
          className="file-card-person-block"
        >
          {/*<span className="file-card-person-title">Подрядчик</span>*/}
          {hasRemoveHandler && props.builder.id ? (
            <span
              className="file-card-remove _error"
              onClick={(e) => {
                e.stopPropagation();
                onPersonRemove(PersonTypes.BUILDER);
              }}
            >
              {closeIcon}
            </span>
          ) : null}
          {hasUpdateHandler ? (
            <span
              className="file-card-change"
              onClick={(e) => {
                e.stopPropagation();
                onPersonTypeSelect(PersonTypes.BUILDER);
              }}
            >
              {editIcon}
            </span>
          ) : null}
          <a
            href="#"
            className={`file-card-person-name ${!builderPerson.id ? "_empty" : ""}`}
            onClick={
              !builderPerson.id
                ? null
                : (e) => {
                    e.preventDefault();
                    handlePersonInfoShow(PersonTypes.BUILDER);
                  }
            }
          >
            {builderPerson.name || "-"}
          </a>
        </div>
      </div>
      <div className="estimate-card-block">
        {isMobileSize && (
          <span className="estimate-card-label">Стройконтроль</span>
        )}
        <div
          className="file-card-person-block"
        >
          {/*<span className="file-card-person-title">Стройконтроль</span>*/}
          {hasRemoveHandler && props.control.id ? (
            <span
              className="file-card-remove _error"
              onClick={(e) => {
                e.stopPropagation();
                onPersonRemove(PersonTypes.CONTROL);
              }}
            >
              {closeIcon}
            </span>
          ) : null}
          {hasUpdateHandler ? (
            <span
              className="file-card-change"
              onClick={(e) => {
                e.stopPropagation();
                onPersonTypeSelect(PersonTypes.CONTROL);
              }}
            >
              {editIcon}
            </span>
          ) : null}
          <a
            href="#"
            className={`file-card-person-name ${!controlPerson.id ? "_empty" : ""}`}
            onClick={
              !controlPerson.id
                ? null
                : (e) => {
                    e.preventDefault();
                    handlePersonInfoShow(PersonTypes.CONTROL);
                  }
            }
          >
            {controlPerson.name || "-"}
          </a>
        </div>
      </div>

      {/*<CheckBox onChange={props.onClick} value={props.selected} mixClass="file-card-select"/>*/}

      <ChooseUser
        type={selectingPersonType}
        isActive={usersActive}
        label={getModalLabel()}
        onClose={() => {
          setUsersActive(false);
        }}
        setUserPerm="perm_construction-object_assign-builder"
        onSelect={onPersonSelect}
      />
    </div>
  );
};

EstimateCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  builder: userDataTypes,
  control: userDataTypes,
  icon: PropTypes.any,
  size: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
};

export default EstimateCard;
