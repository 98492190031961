import React from "react";
import PropTypes from "prop-types";

import "./FileList.scss";
import InputFileDrop from "../InputFileDrop";
import FileCards from "./components/FileCards";
import Loader from "../Loader";

const FileList = (props) => {
  const isFiles = props.files.length > 0;

  if (props.isError) {
    return (
      <div className="file-list-filler-inactive">
        Ошибка при загрузке файлов
      </div>
    );
  }

  if (props.isFetching) {
    return <Loader />;
  }

  if (!isFiles && !props.onUpload) {
    return <div className="file-list-filler-inactive">Нет файлов</div>;
  }

  return props.onUpload ? (
    <InputFileDrop
      onFileDrop={props.onUpload}
      isMultiple
      format={props.format}
      label={props.label}
      helpMessage={props.helpMessage}
    >
      {!isFiles ? null : (
        <div className="file-list-cards">
          <FileCards
            files={props.files}
            onUpload={props.onUpload}
            onDownload={props.onDownload}
            onDelete={(selected) => props.onDelete(selected)}
            onUpdate={props.onUpdate}
            onRemove={props.onRemove}
            format={props.format}
            status={props.status}
            isEstimate={props.isEstimate}
          />
        </div>
      )}
    </InputFileDrop>
  ) : (
    <div className="file-list-cards">
      <FileCards
        files={props.files}
        onUpload={props.onUpload}
        onDownload={props.onDownload}
        onDelete={
          props.onDelete ? (selected) => props.onDelete(selected) : null
        }
        onUpdate={props.onUpdate}
        onRemove={props.onRemove}
        format={props.format}
        status={props.status}
        isEstimate={props.isEstimate}
      />
    </div>
  );
};

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.any,
      size: PropTypes.string,
    })
  ).isRequired,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  isFetching: PropTypes.bool,
  label: PropTypes.string,
  format: PropTypes.string,
  isEstimate: PropTypes.bool,
  helpMessage: PropTypes.string,
};

export default FileList;
