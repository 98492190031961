import {
  OBJECT_STAGES_SUCCESS,
  OBJECT_STAGES_REQUEST,
  OBJECT_STAGES_ERROR,
} from "../reducers/objectStages";

import api from "./api";


export const fetchStagesFromSection = (
  object_id,
  section_id,
  searchName,
  statusID,
  sortBy,
  sortOrder,
  page,
  pageSize
) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_STAGES_REQUEST,
      payload: section_id,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_STAGES_ERROR,
        payload: {
          id: section_id,
          value: ["Нет прав доступа. Пожалуйста войдите в систему"],
        },
      });
      return;
    }

    let bodyParams = {
      page: page,
      pageSize: pageSize,
    }

    if (searchName) {
      bodyParams.search_name = searchName
    }
    if (sortBy && sortOrder) {
      bodyParams["order_"+sortBy] = "SORT_"+sortOrder.toUpperCase();
    }
    if (statusID) {
      bodyParams.status_id = statusID;
    }

    return fetch(api + `/v1/object/${object_id}/section/${section_id}/task`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify(bodyParams),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_STAGES_ERROR,
            payload: {
              id: section_id,
              value: data.message,
            },
          });
          return;
        }

        dispatch({
          type: OBJECT_STAGES_SUCCESS,
          payload: {
            sectionId: section_id,
            value: mapStages(data.data.items),
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_STAGES_ERROR,
          payload: {
            sectionId: section_id,
            value: er,
          },
        });
      });
  };
};

export const updateObjectSection = (objectId, changedSections, sections) => {
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return;
    }

    return fetch(api + `/v1/object/${objectId}/update/card`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      // body: JSON.stringify(stagesToUpdate(changedStages, sections)),
      body: JSON.stringify(stagesToUpdate(changedSections, sections)),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          return data.data.message
        } else {
          return 'success'
        }
      })
};

const mapStages = (raw) => {
  return raw.map((item) => {
    return {
      id: item.construction_object_task_id,
      name: item.construction_object_task_name,
      sectionId: item.construction_object_section_id,
      factBeg: item.construction_object_task_date_fact_beg,
      factEnd: item.construction_object_task_date_fact_end,
      planBeg: item.construction_object_task_date_plan_beg,
      planEnd: item.construction_object_task_date_plan_end,
      statusCode: item.construction_object_task_status_code,
      status: item.construction_object_task_status,
      statusColor: item.construction_object_task_status_color_code,
      createdAt: item.construction_object_task_created_at,
      updatedAt: item.construction_object_task_last_updated_at,
      requiredAmount: item.construction_object_task_required_amount_work,
      unit: item.construction_object_task_unit,
      coefficient: item.construction_object_task_unit_coefficient,
      completion: item.construction_object_task_percent_completion,
      unconfirmedCompletion: item.construction_object_task_unconfirmed_percent_completion,
      isDirty: item.is_dirty,
      isExist: item.is_exist,
      is_delete: false
    };
  });
};

const stagesToUpdate = (changedSections, sections) => {
  let result = [];
  sections.forEach((section) => {
    if (changedSections[section.id]) {
      result.push({
        key: "section",
        params: {
          construction_object_section_id: section.id,
          construction_object_section_name: section.name,
          is_dirty: section.isDirty,
          is_exist: section.isExist,
          is_delete: false,
        },
        children: changedSections[section.id].map((item) => {
          return {
            key: "task",
            params: {
              construction_object_task_id: item.id,
              construction_object_task_name: item.name,
              construction_object_section_id: item.sectionId,
              construction_object_task_date_fact_beg: item.factBeg,
              construction_object_task_date_fact_end: item.factEnd,
              construction_object_task_date_plan_beg: item.planBeg,
              construction_object_task_date_plan_end: item.planEnd,
              construction_object_task_created_at: item.createdAt,
              construction_object_task_last_updated_at: item.updatedAt,
              construction_object_task_required_amount_work:
                item.requiredAmount,
              construction_object_task_unit: item.unit,
              construction_object_task_unit_coefficient: item.coefficient,
              is_dirty: item.isDirty,
              is_exist: item.isExist,
              is_delete: item.is_delete,
            },
          };
        }),
      });
    }
  });

  return result;
};
