import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Tabs.scss";

const Tabs = ({ items, onClick, active, isNav, isSelect }) => {
  const [activeItem, setActiveItem] = useState(
    items.find((item) => item.key === active) || items[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setActiveItem(items.find((item) => item.key === active));
  }, [active]);

  if (isSelect)
    return (
      <nav className="tabs-select">
          <button
            className={`tabs-select__item _toggle ${isOpen ? "" : "_active"}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            {activeItem?.value || "-"}
          </button>
        {isOpen && (
          <ul className="tabs-select__options">
            {items.map((item) => {
              if (item.key === active) return null;

              return (
                <li key={item.key}>
                  <button
                    className="tabs-select__item"
                    onClick={() => {
                      onClick(item);
                      setIsOpen(false);
                    }}
                  >
                    {item.value}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    );

  return (
    <nav className={`tabs ${isNav ? "_nav-tab" : ""}`}>
      {items.map((item) => {
        return (
          <button
            key={item.key}
            className={
              active === item.key ? "tabs-item tabs-item-active" : "tabs-item"
            }
            onClick={() => onClick(item)}
          >
            {item.value}
          </button>
        );
      })}
    </nav>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isNav: PropTypes.bool,
  isSelect: PropTypes.bool,
};

export default Tabs;
