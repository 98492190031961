export const permissions = {
  viewAllObjects: "perm_construction-object_index",
  viewMyObjects: "perm_construction-object_private",
  viewFavorite: "perm_construction-object_subscribe",
  viewArchive: "perm_construction-object_archive",
  viewUserProfile: "perm_user_index",
  viewNotifications: "perm_notification_view",
  viewProfile: "perm_user_index",
  updateProfile: "perm_user_update",
  updatePassword: "perm_user_update-password",
  viewApplication: "perm_application_index",
  applyApplication: "perm_application_apply",
  denyApplication: "perm_application_deny",
  createWorksHistory: "perm_works-history_create",
  loadImage: "perm_image_load",
  updateRights: "perm_user_add-extended-permission",
  updateUserOrganization: "perm_organization_update",
  viewRating: "perm_view_rating",
};
