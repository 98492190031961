import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/ru';

import "./DatePicker.scss";
import { inlineNext } from "./img/inlineSVGicons";

const DatePicker = (props) => {
  moment.locale('ru');
  const date = moment(props.value, "DD.MM.YYYY");
  const [currentMonth, setCurrentMonth] = useState(date.month());
  const [currentYear, setCurrentYear] = useState(date.year());

  const daysInMonth = moment(
    `${currentMonth + 1}.${currentYear}`,
    "MM.YYYY"
  ).daysInMonth();

  const beginMonth = moment(
    `01.${currentMonth + 1}.${currentYear}`,
    "DD.MM.YYYY"
  ).day();

  const changeDate = (day) => {
    const newDate = moment(`${day}.${currentMonth + 1}.${currentYear}`, "DD.MM.YYYY").format("DD.MM.YYYY");
    props.onSelect(newDate);
  };

  let days = [];
  for (let i = 0; i < beginMonth - 1; i++) {
    days.push(<div />);
  }
  if (beginMonth === 0) {
    for (let i = 0; i < 6; i++) {
      days.push(<div />);
    }
  }
  for (let i = 1; i <= daysInMonth; i++) {
    let dayOfWeek = moment(
      `${i}.${currentMonth + 1}.${currentYear}`,
      "DD.MM.YYYY"
    ).day();

    days.push(
      <div
        style={dayOfWeek === 6 || dayOfWeek === 0 ? { color: "red" } : null}
        className="date-picker-day-container"
        onClick={() => changeDate(i)}
      >
        <div
          className={
            date.month() === currentMonth &&
            date.year() === currentYear &&
            i === date.date()
              ? "date-picker-day-selected"
              : "date-picker-day"
          }
        >
          {i}
        </div>
      </div>
    );
  }

  const changeYear = (ev) => {
    setCurrentYear(+ev.target.value);
  };

  const changeMonth = (month) => {
    let monthNumber = moment().month(month).month();
    if (month > 11) {
      setCurrentYear(currentYear + 1);
    }
    if (month < 0) {
      setCurrentYear(currentYear - 1);
    }
    setCurrentMonth(monthNumber);
  };

  let years = [];
  for (let i = 2010; i <= moment().year() + 10; i++) {
    years.push(i);
  }

  let months = [];
  for (let i = 0; i <= 11; i++) {
    months.push(moment().month(i).format("MMMM"));
  }

  return (
    <div className="date-picker">
      <div className="date-picker-header">
        <button
          onClick={() => changeMonth(currentMonth - 1)}
          className="date-picker-header-button"
        >
          {inlineNext}
        </button>

        <select
          onChange={(ev) => changeMonth(ev.target.value)}
          className="date-picker-header-month"
        >
          {months.map((month, index) => {
            return (
              <option key={index} selected={index === currentMonth}>
                {month}
              </option>
            );
          })}
        </select>

        <select onChange={changeYear} className="date-picker-header-year">
          {years.map((year) => {
            return <option selected={year === currentYear}>{year}</option>;
          })}
        </select>

        <button
          onClick={() => changeMonth(currentMonth + 1)}
          className="date-picker-header-button"
        >
          {inlineNext}
        </button>
      </div>
      <div className="date-picker-calendar">
        <div className="date-picker-calendar-day-name">Пн</div>
        <div className="date-picker-calendar-day-name">Вт</div>
        <div className="date-picker-calendar-day-name">Ср</div>
        <div className="date-picker-calendar-day-name">Чт</div>
        <div className="date-picker-calendar-day-name">Пт</div>
        <div className="date-picker-calendar-day-name">Сб</div>
        <div className="date-picker-calendar-day-name">Вс</div>
        {days}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default DatePicker;
