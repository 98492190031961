export const ARCHIVE_REQUEST = "ARCHIVE_REQUEST";
export const ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS";
export const ARCHIVE_ERROR = "ARCHIVE_ERROR";

const initialState = {
  isFetching: false,
  isError: false,
  errors: [],
  items: [],
  pagination: {},
  isUpdated: false,
};

const archive = (state = initialState, action) => {
  switch (action.type) {
    case ARCHIVE_REQUEST: {
      return {
        ...state,
        isFetching: true,
        isError: false,
        errors: []
      };
    }
    case ARCHIVE_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        pagination: action.payload.pagination,
        isUpdated: true,
        isFetching: false
      };
    }
    case ARCHIVE_ERROR: {
      return {
        ...state,
        isFetching: false,
        isUpdated: true,
        isError: true,
        errors: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default archive;
