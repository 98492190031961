import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./SoloObjectContainer.scss";

import Stages from "./components/Stages";
import Info from "./components/Info";
import Modal from "../../components/Modal";
import { ROUTES } from "../../routes";
import {
  fetchSoloObject,
  editObjectInfo,
  sendRespond,
  setStatusToInProgress,
} from "../../actions/ObjectsActions";
import SoloObjectHead from "./components/SoloObjectHead/SoloObjectHead";
import { GO_TO_NEW_OBJECT } from "../../reducers/objectFiles";
import Summary from "./components/Summary";
import Loader from "../../components/Loader";
import toaster from "toasted-notes";
import ContractorList from "./components/ContractorList";
import { freezeTask } from "../../actions/ArchiveActions";
import { UserContext } from "../../App";
import {
  OBJECT_SECTIONS_SET_IS_ERROR,
  OBJECT_SECTIONS_SET_IS_UPDATED,
} from "../../reducers/objectSections";
import { OBJECT_IS_AVAILABLE } from "../../reducers/objects";
import InputTextArea from "../../components/InputTextArea";
import ObjectInfo from "./components/Info/components/ObjectInfo";

const SoloObjectContainer = () => {
  const [isObjectEdit, setIsObjectEdit] = useState(false);
  const [changedObjectInfo, setChangedObjectInfo] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isSummaryActive, setIsSummaryActive] = useState(false);
  const [isContractorListActive, setIsContractorListActive] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [commentModal, setCommentModal] = useState("");
  const objectsStore = useSelector((store) => store.objects);
  const filesStore = useSelector((store) => store.objectFiles);
  const { contractors } = useSelector((store) => store.objectsContractors);
  const params = useParams();
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const isObjectInfoRoute = location.pathname === url + ROUTES.OBJECT.INFO.PATH;

  useEffect(() => {
    if (history.location.search === "?isGrouped=true") {
      dispatch({
        type: OBJECT_IS_AVAILABLE,
        payload: true,
      });
    } else {
      history.push(
        objectsStore.isAvailable ? "?isGrouped=true" : history.location.pathname
      );
    }
  }, []);

  const user = useContext(UserContext);
  const isHavePermission = (permission) => {
    return user.permissions.filter((perm) => perm.name === permission).length;
  };

  const editObject = () => {
    dispatch(editObjectInfo(params.id, changedObjectInfo)).then((r) => {
      if (r !== "success") {
        let html = { __html: Object.values(r).join("<br>") };
        toaster.notify(
          <div className="error-toast" dangerouslySetInnerHTML={html} />,
          { position: "top-right", duration: 4000 }
        );
      } else {
        toaster.notify(
          <div className="success-toast">Объект отредактирован!</div>,
          { position: "top-right", duration: 2000 }
        );
      }
    });
  };

  const selectedObject = objectsStore.items.filter(
    (item) => item.id === +params.id
  )[0];

  const fetchObject = useCallback(() => dispatch(fetchSoloObject(params.id)), [
    params.id,
    dispatch,
  ]);

  useEffect(() => {
    if (!selectedObject || !selectedObject.updated) {
      fetchObject();

      dispatch({
        type: OBJECT_SECTIONS_SET_IS_UPDATED,
        payload: false,
      });
      dispatch({
        type: OBJECT_SECTIONS_SET_IS_ERROR,
        payload: false,
      });
    }
  }, [selectedObject, fetchObject, dispatch]);

  useEffect(() => {
    if (filesStore.objectId !== params.id) {
      dispatch({
        type: GO_TO_NEW_OBJECT,
        payload: params.id,
      });
    }
  }, [filesStore, params.id, dispatch]);

  useEffect(() => {
    if (selectedObject?.hasAppContractor === false) {
      setIsContractorListActive(false);
    }
  }, [selectedObject?.hasAppContractor]);

  const onEditClick = () => {
    setChangedObjectInfo(selectedObject);
    setIsObjectEdit(true);
  };

  const onCancel = () => {
    setIsObjectEdit(false);
  };

  const onChange = (value, name) => {
    if (typeof value === "object") {
      setChangedObjectInfo({
        ...changedObjectInfo,
        [name]: value.value,
        [name + "Key"]: value.key,
      });
      return;
    }

    setChangedObjectInfo({
      ...changedObjectInfo,
      [name]: value,
    });
  };

  const onPointSet = useCallback(({ longitude, latitude }) => {
    setChangedObjectInfo((changedObjectInfo) => ({
      ...changedObjectInfo,
      longitude,
      latitude,
    }));
  }, []);

  const onPolygonsChange = useCallback((polygons) => {
    setChangedObjectInfo((changedObjectInfo) => ({
      ...changedObjectInfo,
      polygons,
    }));
  }, []);

  const onValidationChange = (name, value) => {
    if (validationErrors[name] !== value) {
      setValidationErrors({
        ...validationErrors,
        [name]: value,
      });
    }
  };

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) {
        return true;
      }
    }
    return false;
  };

  const onSave = async () => {
    if (!isValidationErrors()) {
      setIsObjectEdit(false);
      await editObject();
      fetchObject();
    }
  };

  const onRespond = () => {
    sendRespond(selectedObject.id).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Отклик отправлен!</div>, {
          position: "top-right",
          duration: 2000,
        });
      } else {
        toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
          position: "top-right",
          duration: 2000,
        });
      }
      fetchObject();
    });
  };

  const onFreeze = (ev) => {
    setIsShowModal(true);
    // freezeTask(params.id).then((r) => {
    //   if (r === "success") {
    //     toaster.notify(<div className="success-toast">Объект заморожен!</div>, {
    //       position: "top-right",
    //       duration: 2000,
    //     });
    //     fetchObject();
    //   }
    // });
  };

  const setStatus = () => {
    setStatusToInProgress(selectedObject.id).then(() => {
      fetchObject();
    });
  };

  const onClickOkButtonModal = () => {
    freezeTask(params.id, { comment: commentModal }).then((r) => {
      if (r === "success") {
        toaster.notify(<div className="success-toast">Объект заморожен!</div>, {
          position: "top-right",
          duration: 2000,
        });
        fetchObject();
      }
    });
    setIsShowModal(false);
  };

  const onClickCancelButtonModal = () => {
    setIsShowModal(false);
  };

  const id = selectedObject ? selectedObject.id : null;
  const name = selectedObject ? selectedObject.name : "-";
  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="solo-object">
          <div className="content-card solo-object-header">
            <SoloObjectHead
              id={id}
              name={isObjectEdit ? changedObjectInfo.name : name}
              onEditClick={onEditClick}
              isEditable={isObjectEdit}
              onCancel={onCancel}
              onChange={onChange}
              onFreezeClick={onFreeze}
              onRespond={onRespond}
              havePermission={isHavePermission(
                "perm_construction-object_extended-update"
              )}
              onValidationChange={onValidationChange}
              onSummaryClick={() => setIsSummaryActive(true)}
              isRequest={selectedObject && selectedObject.isRequest}
              onOpenContractorList={() => setIsContractorListActive(true)}
              hasContractorsToObject={selectedObject?.hasAppContractor}
              statusCode={selectedObject && selectedObject.statusCode}
              onSetStatus={setStatus}
              isHavePermToSetStatus={!!isHavePermission(
                  "perm_construction-object_update-status-to-work"
              )}
            />

            {!isObjectInfoRoute ? null : !selectedObject ||
              selectedObject.isFetching ? (
              null
            ) : (
              <ObjectInfo
                onCancel={onCancel}
                havePermission={isHavePermission(
                  "perm_construction-object_extended-update"
                )}
                onSave={onSave}
                isEditable={isObjectEdit}
                info={isObjectEdit ? changedObjectInfo : selectedObject}
                onChange={onChange}
                onValidationChange={onValidationChange}
                statusColor={selectedObject && selectedObject.statusColor}
                onSetStatus={setStatus}
                isHavePermToSetStatus={isHavePermission(
                  "perm_construction-object_update-status-to-work"
                )}
              />
            )}
          </div>

          {!selectedObject || selectedObject.isFetching ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Switch>
                <Route exact path={ROUTES.OBJECT.INFO.ROUTE}>
                  <Info
                    hasContractor={
                      selectedObject.hasContractor &&
                      !selectedObject.contractor.builderId
                    }
                    havePermission={isHavePermission(
                      "perm_construction-object_extended-update"
                    )}
                    builderId={selectedObject.contractor.builderId}
                    info={isObjectEdit ? changedObjectInfo : selectedObject}
                    isObjectEditable={isObjectEdit}
                    onChange={onChange}
                    onSave={onSave}
                    onCancel={onCancel}
                    onValidationChange={onValidationChange}
                    onPointSet={onPointSet}
                    onPolygonsChange={onPolygonsChange}
                    selectedObjectId={selectedObject && selectedObject.id}
                    onOpenContractorList={() => setIsContractorListActive(true)}
                    status={selectedObject && selectedObject.statusCode}
                    statusColor={selectedObject && selectedObject.statusColor}
                    onChangeInfo={fetchObject}
                    links={selectedObject && selectedObject.links}
                    linksIds={selectedObject && selectedObject.linksIds}
                    icon={selectedObject && selectedObject.mapIcon}
                    onSetStatus={setStatus}
                    havePermToSetStatus={isHavePermission(
                      "perm_construction-object_update-status-to-work"
                    )}
                    hasContractorsToObject={selectedObject?.hasAppContractor}
                  />
                </Route>

                <Route exact path={ROUTES.OBJECT.STAGES.ROUTE}>
                  <Stages
                    id={params.id}
                    builderId={selectedObject.contractor.builderId}
                    status={selectedObject && selectedObject.statusCode}
                  />
                </Route>

                <Route path={ROUTES.OBJECT.PATH}>
                  <Redirect to={url + ROUTES.OBJECT.INFO.PATH} />
                </Route>
              </Switch>
            </React.Fragment>
          )}
        </div>

        <Summary
          isActive={isSummaryActive}
          onClose={() => setIsSummaryActive(false)}
          objectId={selectedObject && selectedObject.id}
        />

        <ContractorList
          isActive={isContractorListActive}
          onClose={() => setIsContractorListActive(false)}
          objectId={selectedObject && selectedObject.id}
          onSetContractor={fetchObject}
        />
        <Modal
          isShow={isShowModal}
          content={
            <div className="content-modal">
              <span className="content-modal__title">
                Вы уверены, что хотите заморозить объект?
                <p>Укажите причину в комментарии:</p>
              </span>
              <InputTextArea
                value={commentModal}
                onChange={(value) => setCommentModal(value)}
              />
            </div>
          }
          okButtonProps={{
            content: "Да",
            onClick: onClickOkButtonModal,
            disabled: commentModal.trim().length === 0,
          }}
          cancelButtonProps={{
            content: "Нет",
            onClick: onClickCancelButtonModal,
          }}
        />
      </div>
    </div>
  );
};

export default SoloObjectContainer;
