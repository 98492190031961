import React from "react";
import PropTypes from "prop-types";

import "./ModalWindow.scss";
import { close } from "./img/inlineSVGicons";

const ModalWindow = (props) => {
  if(props.isActive){
    return(
      <div className="modal-window-container">
        <div
            className="modal-window"
             style={{maxWidth: props.maxWidth, maxHeight: props.maxHeight }}
        >
          <div className="modal-window-header">
            <h3 className="modal-window-label">{props.label}</h3>
            <div className="modal-window-close" onClick={props.onClose}>{close}</div>
          </div>
          {props.children}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

ModalWindow.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number
}

export default ModalWindow;
