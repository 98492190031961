import React, { useContext } from "react";
import PropTypes from "prop-types";

import "./CheckPermissions.scss";

import { UserContext } from "../../App";

const CheckPermissions = (props) => {
  const userContext = useContext(UserContext);

  for (let i = 0; i < props.permissions.length; i++) {
    if (userContext.permissions.find((p) => props.permissions[i] === p.name)) {
      return props.children;
    }
  }

  return null;
};

CheckPermissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default CheckPermissions;
