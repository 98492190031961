import React, { useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./AddObjectContainer.scss";

import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

import AddObjectHeader from "./components/AddObjectHeader";
import { ROUTES } from "../../routes";
import AddObjectInfo from "./components/AddObjectInfo";
import AddObjectSections from "./components/AddObjectSections";
import {
  ADD_OBJECT_CHANGE_INFO,
  ADD_OBJECT_CHANGE_STAGE,
  ADD_OBJECT_DELETE_STAGE,
  ADD_OBJECT_SET_INITIAL,
} from "../../reducers/objectAdd";
import { addObject } from "../../actions/AddObjectActions";
import { fetchObjects } from "../../actions/ObjectsActions";
import InfoPopup from "../InfoPopup";

const AddObjectContainer = (props) => {
  const addObjectStore = useSelector((store) => store.objectAdd);
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  let history = useHistory();

  const add = () => {
    if (isValidationErrors()) {
      return;
    }
    dispatch(
      addObject(
        addObjectStore.info,
        addObjectStore.sections,
        addObjectStore.estimates,
        addObjectStore.projectEstimates,
        addObjectStore.designProject
      )
    ).then((r) => {
      if (r && !r.construction_object_id) {
        let html = { __html: Object.values(r).join("<br>") };
        toaster.notify(
          <div className="error-toast" dangerouslySetInnerHTML={html} />,
          { position: "top-right", duration: 4000 }
        );
      } else {
        toaster.notify(<div className="success-toast">Объект создан!</div>, {
          position: "top-right",
          duration: 2000,
        });
        dispatch({
          type: ADD_OBJECT_SET_INITIAL,
        });
        fetchObjects("private");
        history.replace(`/object/${r.construction_object_id}/info`);
      }
    });
  };

  const infoChange = (name, value) => {
    dispatch({
      type: ADD_OBJECT_CHANGE_INFO,
      payload: {
        name: name,
        value: value,
      },
    });
  };

  const saveStage = (sectionId, changedStage) => {
    dispatch({
      type: ADD_OBJECT_CHANGE_STAGE,
      payload: {
        sectionId: sectionId,
        changedStage: changedStage,
      },
    });
  };

  const deleteStage = (sectionId, stageId) => {
    dispatch({
      type: ADD_OBJECT_DELETE_STAGE,
      payload: {
        sectionId: sectionId,
        stageId: stageId,
      },
    });
  };

  const onValidationChange = (name, value) => {
    if (validationErrors[name] !== value) {
      setValidationErrors({
        ...validationErrors,
        [name]: value,
      });
    }
  };

  const isValidationErrors = () => {
    for (let key in validationErrors) {
      if (validationErrors[key]) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="add-object">
          <div className="content-card add-object-header-wrapper">
            <AddObjectHeader
              onChange={infoChange}
              onValidationChange={onValidationChange}
              info={addObjectStore.info}
            />
          </div>
          <Switch>
            <Route path={ROUTES.OBJECT.ADD.INFO.PATH}>
              <AddObjectInfo
                info={addObjectStore}
                onChange={infoChange}
                onAddObject={add}
                onValidationChange={onValidationChange}
              />
            </Route>
            <Route path={ROUTES.OBJECT.ADD.STAGES.PATH}>
              <AddObjectSections
                onStageSave={saveStage}
                onStageDelete={deleteStage}
                sections={addObjectStore.sections}
              />
            </Route>
            <Route path="*">
              <Redirect to={ROUTES.OBJECT.ADD.INFO.PATH} />
            </Route>
          </Switch>
        </div>
        <InfoPopup />
      </div>
    </div>
  );
};

export default AddObjectContainer;
