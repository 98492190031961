import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import "./MyObjects.scss";

import { ROUTES } from "../../../../routes";
import ObjectsSearch from "../ObjectsSearch";
import ObjectsList from "../ObjectsList";
import { fetchObjects } from "../../../../actions/ObjectsActions";
import { fetchFavorites } from "../../../../actions/FavoritesActions";
import ObjectsHeader from "../ObjectsHeader";
import ObjectsMap from "../ObjectsMap/ObjectsMap";
import Loader from "../../../../components/Loader";
import Paginator from "../../../../components/Paginator";
import { OBJECT_IS_AVAILABLE } from "../../../../reducers/objects";

const MyObjects = ({ onContentReady }) => {
  const history = useHistory();
  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();
  const requestType = "private";
  const objectsStore = useSelector((store) => store.objects);
  const getFavorites = () => dispatch(fetchFavorites());
  const { type: userType } = useSelector((store) => store.user);

  const getMyObjects = useCallback(
    (filters) =>
      dispatch(
        fetchObjects(requestType, filters, undefined, objectsStore.isAvailable)
      ),
    [dispatch, objectsStore.isAvailable]
  );

  const getMyObjectPaginator = useCallback(
    (page) =>
      dispatch(fetchObjects(requestType, [], page, objectsStore.isAvailable)),
    [dispatch]
  );

  useEffect(() => {
    if (history.location.search === "?isGrouped=true") {
      dispatch({
        type: OBJECT_IS_AVAILABLE,
        payload: true,
      });
    }
  }, []);

  useEffect(() => {
    getMyObjects(undefined);
  }, [objectsStore.isAvailable]);

  useEffect(() => {
    if (
      objectsStore.lastType !== requestType &&
      !objectsStore.isUpdated &&
      !objectsStore.isFetching &&
      !objectsStore.isError
    ) {
      getMyObjects();
    }
  }, [objectsStore, getMyObjects]);

  const tabItems = [
    {
      key: ROUTES.OBJECTS.MY.LIST.PATH,
      value: "Объекты",
    },
    {
      key: ROUTES.OBJECTS.MY.MAP.PATH,
      value: "Карта",
    },
  ];

  const navTabItems = [
    {
      key: "self",
      value: "Мои объекты",
    },
  ];

  if (userType !== "government_customer") {
    navTabItems.push({
      key: "org",
      value: "Объекты моей организации",
    });
  }

  const onSubscribe = () => {
    getMyObjects(filters);
    getFavorites();
  };

  const onFilter = (filter) => {
    getMyObjects(filter);
    setFilters(filter);
  };

  const onClickAvailable = (isAvailable) => {
    history.replace(
      isAvailable ? history.location.pathname : "?isGrouped=true"
    );
    dispatch({
      type: OBJECT_IS_AVAILABLE,
      payload: !isAvailable,
    });
  };

  return (
    <div className="container-wrapper">
      <div className="objects-container container">
        <div className="content-card">
          <ObjectsHeader
            tabItems={tabItems}
            navTabItems={navTabItems}
            title="Объекты"
            onClickAvailable={onClickAvailable}
          />

          <ObjectsSearch
            pageName={requestType}
            onFilter={onFilter}
            //filters={filters}
          />

          <Switch>
            <Route path={ROUTES.OBJECTS.MY.LIST.PATH}>
              <div className="objects-list-container">
                <ObjectsList
                  onItemSubscribe={onSubscribe}
                  objects={objectsStore.items}
                  onContentReady={onContentReady}
                />
                {objectsStore.pagination.totalCount >
                objectsStore.pagination.pageSize ? (
                  <Paginator
                    currentPage={objectsStore.pagination.currentPage}
                    totalPages={objectsStore.pagination.totalPageCount}
                    total={objectsStore.pagination.totalCount}
                    pageSize={objectsStore.pagination.pageSize}
                    onPageChange={getMyObjectPaginator}
                  />
                ) : null}
              </div>
            </Route>

            <Route exact path={ROUTES.OBJECTS.MY.MAP.PATH}>
              <ObjectsMap objects={objectsStore.items} />
            </Route>

            <Route path={ROUTES.OBJECTS.MY.PATH}>
              <Redirect to={ROUTES.OBJECTS.MY.LIST.PATH} />
            </Route>
          </Switch>

          {objectsStore.isFetching ? <Loader /> : null}
        </div>
      </div>
    </div>
  );
};

export default MyObjects;
