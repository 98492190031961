import {
  OPEN_REGISTRATION_POPUP,
  CLOSE_REGISTRATION_POPUP,
} from "../actions/RegistrationPopupAction";

const initialState = {
  isOpen: false,
};

const registrationPopup = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_REGISTRATION_POPUP: {
      return { ...state, isOpen: true };
    }
    case CLOSE_REGISTRATION_POPUP: {
      return { ...state, isOpen: false };
    }
    default:
      return state;
  }
};

export default registrationPopup;
