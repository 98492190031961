import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import "./ChooseUser.scss";
import ModalWindow from "../ModalWindow";
import UserSearch from "../UserSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomersOrBuilders,
  setUserToObject,
} from "../../actions/ChooseUser";
import Paginator from "../Paginator";
import Loader from "../Loader";
import CheckPermissions from "../CheckPermissions";
import CreateUser from "../CreateUser";
import toaster from "toasted-notes";
import { checkIcon } from "../../assets/img/sprite";

const ROLES_WITH_ORG_NAME = ["builder", "customer", "construction-control"];

const ChooseUser = (props) => {
  const dispatch = useDispatch();
  const chooseStore = useSelector((store) => store.chooseUser);
  const { isTabletSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );

  const getUsers = useCallback(
    (filters, page) => {
      dispatch(
        fetchCustomersOrBuilders(filters, page, props.type, props.objectId)
      );
    },
    [dispatch, props.objectId, props.type]
  );

  useEffect(() => {
    if (props.isActive && !chooseStore.isUpdated && !chooseStore.isFetching) {
      getUsers(undefined, chooseStore.pagination.currentPage);
    }
  });

  const setUser = ({
    id,
    name,
    email,
    phone,
    additionalInfo,
    orgName,
    orgContacts,
    orgAddress,
  }) => {
    if (props.objectId) {
      setUserToObject(props.type, props.objectId, id).then((r) => {
        if (r === "success") {
          toaster.notify(
            <div className="success-toast">Пользователь назначен!</div>,
            { position: "top-right", duration: 2000 }
          );
          clearStore();
        } else {
          toaster.notify(
            <div className="error-toast">{JSON.stringify(r)}</div>,
            { position: "top-right", duration: 2000 }
          );
        }
      });
    } else {
      props.onSelect({
        id,
        name,
        email,
        phone,
        additionalInfo,
        orgName,
        orgContacts,
        orgAddress,
      });
      clearStore();
    }
  };

  const clearStore = () => {
    chooseStore.isUpdated = false;
    props.onClose();
  };

  return (
    <ModalWindow
      isActive={props.isActive}
      onClose={clearStore}
      label={props.label}
      maxHeight={1080}
      maxWidth={1080}
    >
      {chooseStore.isFetching ? <Loader /> : null}
      <div className="choose-user">
        <UserSearch chooseUser={true} onFilter={(filter) => getUsers(filter)} />
        {chooseStore.items.length ? (
          <div className="choose-user-list">
            {isDesktopSize && (
              <div className="choose-user-list__head">
                <span>Имя</span>
                <span>Почта</span>
                <span>Телефон</span>
                {ROLES_WITH_ORG_NAME.includes(props.type) && (
                  <span>Организация</span>
                )}
                <span></span>
              </div>
            )}
            {chooseStore.items.map((item) => {
              return (
                <div className="choose-user-list__card" key={item.profile_id}>
                  <div className="name">
                    {isTabletSize && (
                      <span className="choose-user-list__label">Имя</span>
                    )}
                    <span>{item.profile_fio}</span>
                  </div>
                  <div className="email">
                    {isTabletSize && (
                      <span className="choose-user-list__label">Почта</span>
                    )}
                    <a href={`mailto:${item.user_email}`}>{item.user_email}</a>
                  </div>
                  <div className="phone">
                    {isTabletSize && (
                      <span className="choose-user-list__label">Телефон</span>
                    )}
                    <a href={`tel:${item.profile_phone_number}`}>
                      {item.profile_phone_number}
                    </a>
                  </div>
                  {ROLES_WITH_ORG_NAME.includes(props.type) && (
                    <div className="org-name">
                      {isTabletSize && (
                        <span className="choose-user-list__label">
                          Организация
                        </span>
                      )}
                      <span>{item.organization_name}</span>
                    </div>
                  )}
                  <div className="assign">
                    <CheckPermissions permissions={[props.setUserPerm]}>
                      <button
                        onClick={() =>
                          setUser({
                            id: item.profile_id,
                            name: item.profile_fio,
                            email: item.user_email,
                            phone: item.profile_phone_number,
                            additionalInfo: item.additional_information,
                            orgName: item.organization_name,
                            orgContacts: item.organization_contacts,
                            orgAddress: item.organization_address,
                          })
                        }
                      >
                        {checkIcon}
                        <span>Выбрать</span>
                      </button>
                    </CheckPermissions>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-notes">Нет пользователей</div>
        )}
        {chooseStore.pagination.totalCount > chooseStore.pagination.pageSize ? (
          <Paginator
            currentPage={chooseStore.pagination.currentPage}
            totalPages={chooseStore.pagination.totalPageCount}
            total={chooseStore.pagination.totalCount}
            pageSize={chooseStore.pagination.pageSize}
            onPageChange={(page) => getUsers(undefined, page)}
          />
        ) : null}
        {!props.withUserCreator ? null : (
          <CreateUser
            type={props.type}
            onCreate={() =>
              getUsers(undefined, chooseStore.pagination.currentPage)
            }
          />
        )}
      </div>
    </ModalWindow>
  );
};

ChooseUser.propTypes = {
  type: PropTypes.string,
  isActive: PropTypes.bool,
  withUserCreator: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  setUserPerm: PropTypes.string,
  objectId: PropTypes.number,
  onSelect: PropTypes.func,
};

export default ChooseUser;
