import {
  OBJECT_STAGE_SOLO_SUCCESS,
  OBJECT_STAGE_SOLO_REQUEST,
  OBJECT_STAGE_SOLO_ERROR,
  OBJECT_STAGE_HISTORIES_REQUEST,
  OBJECT_STAGE_HISTORIES_SUCCESS,
  OBJECT_STAGE_HISTORIES_ERROR,
  OBJECT_STAGE_SOLO_IMAGE_REQUEST,
  OBJECT_STAGE_SOLO_IMAGE_SUCCESS,
  OBJECT_STAGE_SOLO_IMAGE_ERROR,
  OBJECT_STAGE_SOLO_COMMENTS_REQUEST,
  OBJECT_STAGE_SOLO_COMMENTS_SUCCESS,
  OBJECT_STAGE_SOLO_COMMENTS_ERROR
} from "../reducers/stageSolo";

import api from "./api";

export const fetchStage = (
  object_id,
  section_id,
  stage_id
) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_STAGE_SOLO_REQUEST,
      payload: section_id,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_STAGE_SOLO_ERROR,
        payload: {
          id: section_id,
          value: ["Нет прав доступа. Пожалуйста войдите в систему"],
        },
      });
      return;
    }

    fetch(api + `/v1/object/${object_id}/section/${section_id}/task/${stage_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_STAGE_SOLO_ERROR,
            payload: {
              id: section_id,
              value: data.message,
            },
          });
          return;
        }

        dispatch({
          type: OBJECT_STAGE_SOLO_SUCCESS,
          payload: mapStages(data.data[0])
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_STAGE_SOLO_ERROR,
          payload: {
            sectionId: section_id,
            value: er,
          },
        });
      });
  };
};

export const fetchStageHistories = (object_id, section_id, task_id) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_STAGE_HISTORIES_REQUEST
    })

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_STAGE_HISTORIES_ERROR,
      });
      return;
    }

    fetch(`${api}/v1/object/${object_id}/section/${section_id}/task/${task_id}/work-history/index`, {
      method: "POST",
      headers: {
        [tokenName]: token
      }
    })
        .then(r => r.json())
        .then(data => {
          if (!data._meta.isSuccessful) {
            dispatch({
              type: OBJECT_STAGE_HISTORIES_ERROR,
            });
            return;
          }

          dispatch({
            type: OBJECT_STAGE_HISTORIES_SUCCESS,
            payload: {
              value: data.data.items,
              pagination: data.data.pagination
            }
          });
        })
        .catch(er => {
          dispatch({
            type: OBJECT_STAGE_HISTORIES_ERROR,
            payload: er.message
          });
        })
  }
}

export const fetchStageSoloImages = (objectId, sectionId, stageId, page) => {
  return dispatch => {
    dispatch({
      type: OBJECT_STAGE_SOLO_IMAGE_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_STAGE_SOLO_IMAGE_ERROR,
      });
      return;
    }

    fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${stageId}/image/view`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify({page: page})
    })
      .then((r) => r.json())
      .then(data => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_STAGE_SOLO_IMAGE_ERROR,
          });
          return;
        }

        dispatch({
          type: OBJECT_STAGE_SOLO_IMAGE_SUCCESS,
          payload: {
            items: data.data.items,
            pagination: data.data.pagination
          }
        });
      })
      .catch(() => {
        dispatch({
          type: OBJECT_STAGE_SOLO_IMAGE_ERROR,
        });
      })
  }
}

export const deleteStageSoloImage = (objectId, sectionId, taskId, imageId) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${taskId}/image/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
    body: JSON.stringify({images_id: [imageId]})
  }).then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return 'success'
      } else {
        return data.data.message[0]
      }
  })
}

export const fetchStageComments = (objectId, sectionId, stageId, page) => {
  return dispatch => {
    dispatch({
      type: OBJECT_STAGE_SOLO_COMMENTS_REQUEST,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_STAGE_SOLO_COMMENTS_ERROR,
      });
      return;
    }

    fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${stageId}/comment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify({page: page})
    })
      .then((r) => r.json())
      .then(data => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_STAGE_SOLO_COMMENTS_ERROR,
          });
          return;
        }

        dispatch({
          type: OBJECT_STAGE_SOLO_COMMENTS_SUCCESS,
          payload: {
            items: data.data.items,
            pagination: data.data.pagination
          }
        });
      })
      .catch(() => {
        dispatch({
          type: OBJECT_STAGE_SOLO_COMMENTS_ERROR,
        });
      })
  }
}

export const uploadStageComment = (objectId, sectionId, stageId, message) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${stageId}/comment/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token
    },
    body: JSON.stringify({
      comment_content: message
    })
  })
}

export const uploadStageImage = (objectId, sectionId, stageId, images) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  let body = new FormData();
  images.forEach((file) => {
    body.append("images[]", file);
  });

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${stageId}/image/load`, {
    method: "POST",
    headers: {
      [tokenName]: token
    },
    body: body
  }).then(r => r.json())
    .then(data => {
      if (data._meta.isSuccessful) {
        return 'success'
      } else {
        return data.data.message
      }
    }
  )
}

export const updateStage = (objectId, sectionId, stageId, changes) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/section/${sectionId}/task/${stageId}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token
    },
    body: JSON.stringify(stageToUpdate(changes))
  })
    .then(r => r.json())
    .then(data => {
      if (data._meta.isSuccessful) {
        return 'success'
      } else {
        return data.data.message
      }
    })
}

const mapStages = (raw) => {
  return {
    id: raw.construction_object_task_id,
    name: raw.construction_object_task_name,
    sectionId: raw.construction_object_section_id,
    factBeg: raw.construction_object_task_date_fact_beg,
    factEnd: raw.construction_object_task_date_fact_end,
    planBeg: raw.construction_object_task_date_plan_beg,
    planEnd: raw.construction_object_task_date_plan_end,
    createdAt: raw.construction_object_task_created_at,
    updatedAt: raw.construction_object_task_last_updated_at,
    requiredAmount: raw.construction_object_task_required_amount_work,
    completion: raw.construction_object_task_percent_completion,
    unconfirmedCompletion: raw.construction_object_task_unconfirmed_percent_completion,
    unit: raw.construction_object_task_unit,
    coefficient: raw.construction_object_task_unit_coefficient,
    isDirty: raw.is_dirty,
    isExist: raw.is_exist,
    statusCode: raw.construction_object_status_code,
    statusColor: raw.construction_object_task_status_color_code,
    approveControl: raw.works_history_approve_control,
    approveCustomer: raw.works_history_approve_customer,
  };
};

const stageToUpdate = (raw) => {
  return {
    construction_object_task_id: raw.id,
    construction_object_task_name: raw.name,
    construction_object_section_id: raw.sectionId,
    construction_object_task_date_fact_beg: raw.factBeg,
    construction_object_task_date_fact_end: raw.factEnd,
    construction_object_task_date_plan_beg: raw.planBeg,
    construction_object_task_date_plan_end: raw.planEnd,
    construction_object_task_created_at: raw.createdAt,
    construction_object_task_last_updated_at: raw.updatedAt,
    construction_object_task_required_amount_work: raw.requiredAmount,
    construction_object_task_percent_completion: raw.completion,
    construction_object_task_unit: raw.unit,
    construction_object_task_unit_coefficient: raw.coefficient,
    is_dirty: raw.isDirty,
    is_exist: raw.isExist,
  };
};

