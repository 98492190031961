export const OBJECT_FILES_REQUEST = "OBJECT_FILES_REQUEST";
export const OBJECT_FILES_SUCCESS = "OBJECT_FILES_SUCCESS";
export const OBJECT_FILES_ERROR = "OBJECT_FILES_ERROR";
export const GO_TO_NEW_OBJECT = "GO_TO_NEW_OBJECT";

const initialState = {
  objectId: null,
  designProject: {
    isFetching: false,
    items: null,
    isError: false,
    errors: [],
    isUpdated: false,
    pagination: {}
  },
  estimates: {
    isFetching: false,
    items: [],
    isError: false,
    errors: [],
    isUpdated: false,
    pagination: {}
  },
  projectEstimates: {
    isFetching: false,
    items: [],
    isError: false,
    errors: [],
    isUpdated: false,
    pagination: {}
  },
};

const objectFiles = (state = initialState, action) => {
  switch (action.type) {
    case OBJECT_FILES_REQUEST: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isFetching: true,
          isError: false,
          errors: [],
        },
      };
    }
    case OBJECT_FILES_SUCCESS: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          isFetching: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
          isUpdated: true,
        },
      };
    }
    case OBJECT_FILES_ERROR: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          isFetching: false,
          isError: true,
          errors: action.payload.errors,
        },
      };
    }
    case GO_TO_NEW_OBJECT: {
      return {
        ...initialState,
        objectId: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default objectFiles;
