import "./HelpTopics.scss";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchTopics } from "../../actions/HelpTopicsActions";
import Loader from "../../components/Loader";

const HelpTopics = () => {
  const dispatch = useDispatch();
  const topicsStore = useSelector((store) => store.helpTopics);

  useEffect(() => {
    if (
      !topicsStore.isFetching &&
      !topicsStore.isUpdated &&
      !topicsStore.isError
    ) {
      dispatch(fetchTopics());
    }
  }, [dispatch, topicsStore]);

  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="content-card">
          <header className="help-topics-header">
            <h1 className="help-topics-title">Справочный центр</h1>
          </header>
          {topicsStore.isFetching && <Loader />}
          {topicsStore.isError && (
            <p className="help-topics-error">Ошибка при загрузке инструкций</p>
          )}
          {topicsStore.isUpdated && (
            <ul className="help-topics-list">
              {topicsStore.items.map((topic) => (
                <li key={topic.id}>
                  <Link
                    to={`/helpCenter/topic/${topic.id}`}
                    className="help-topics-topic"
                  >
                    <h2 className="help-topics-topic-title">{topic.title}</h2>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpTopics;
