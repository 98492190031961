export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";
export const USER_CLEAR = "USER_CLEAR";
export const USER_DISABLE_HINTS = "USER_DISABLE_HINTS";

const initialState = {
  isSuccess: false,
  isFetching: false,
  isError: false,
  errors: [],
  name: null,
  type: null,
  type_name: null,
  profileId: null,
  permissions: null,
  contact: null,
  mail: null,
  have_notify: null,
  have_notify_email: null,
  has_extended_permission: null,
  unreadNotifications: 0,
  organization: '',
  organizationId: null,
  organizationAddress: '',
  organizationPhone: '',
  showHints: true,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USER_GET_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isFetching: true,
        isError: false,
        errors: [],
      };
    }
    case USER_GET_SUCCESS: {
      return {
        ...state,
        isSuccess: true,
        isFetching: false,
        name: action.payload.data.profile_fio,
        type_name: action.payload.data.item_description,
        type: action.payload.data.item_name,
        permissions: action.payload.data.permissions,
        contact: action.payload.data.profile_phone_number,
        mail: action.payload.data.user_email,
        profileId: action.payload.data.profile_id,
        have_notify: action.payload.data.have_notify,
        have_notify_email: action.payload.data.have_notify_email,
        unreadNotifications: action.payload.data.unread_notification,
        organization: action.payload.data.organization_name,
        organizationId: action.payload.data.organization_id,
        organizationAddress: action.payload.data.organization_address,
        organizationPhone: action.payload.data.organization_contacts,
        has_extended_permission: action.payload.data.has_extended_permission,
        showHints: action.payload.data.show_hints,
      };
    }
    case USER_GET_ERROR: {
      let errors = [];
      for (let key in action.payload.data) {
        errors.push(action.payload.data[key]);
      }

      return {
        ...state,
        isSuccess: false,
        isFetching: false,
        isError: true,
        errors: errors,
      };
    }
    case USER_CLEAR: {
      return {
        ...initialState,
      };
    }
    case USER_DISABLE_HINTS: {
      return {
        ...state,
        showHints: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
