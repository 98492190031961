import React, { useState } from "react";
import PropTypes from "prop-types";

import "./AddObjectUser.scss";
import Input from "../../../../../../components/Input";
import ChooseUser from "../../../../../../components/ChooseUser";
import CheckPermissions from "../../../../../../components/CheckPermissions";
import { RoleBasedInputLabels } from "../../../../../../assets/js/constants";
import { inputData as inputDataTypes } from "../../../../../../assets/js/propTypes";

const AddObjectUser = ({
  title,
  btnLabel,
  type,
  info,
  onChange,
  onValidationChange,
  withUserCreator,
  userInputs,
  orgInputs,
}) => {
  const handleUserDataChange = (e) => {
    onChange(e.target.name, e.target.value);
  };
  const [userActive, setUserActive] = useState(false);

  const selectUser = ({
    id = "",
    name = "",
    email = "",
    phone = "",
    additionalInfo = "",
    orgName = "",
    orgContacts = "",
    orgAddress = "",
  }) => {
    onChange(`${type}Id`, id);
    onChange(`${type}Name`, name);
    onChange(`${type}Email`, email);
    onChange(`${type}Phone`, phone);
    onChange(`${type}AdditionalInfo`, additionalInfo);
    onChange(`${type}OrgName`, orgName);
    onChange(`${type}OrgContacts`, orgContacts);
    onChange(`${type}OrgAddress`, orgAddress);
  };

  return (
    <div className="add-object-user">
      <div className="add-object-user-head">
        <h3 className="add-object-user-title">{title}</h3>
        <CheckPermissions permissions={[`perm_user_${type}`]}>
          <button onClick={() => setUserActive(true)}>{btnLabel}</button>
        </CheckPermissions>
      </div>

      {!orgInputs ? null : (
        <div className="add-object-user-info">
          {orgInputs.map((input, i) => (
            <div
              className={`add-object-user-${input.mixClass}`}
              key={type + input.postfixName + i}
            >
              <Input
                label={
                  input.label +
                  (!input.withoutAdditionalLabel && RoleBasedInputLabels[type]
                    ? ` ${RoleBasedInputLabels[type]}`
                    : "")
                }
                value={info[type + input.postfixName]}
                onChange={handleUserDataChange}
                name={type + input.postfixName}
                validators={input.validators || []}
                onValidationChange={onValidationChange}
              />
            </div>
          ))}
        </div>
      )}

      <div className="add-object-user-info">
        {userInputs.map((input, i) => (
          <div
            className={`add-object-user-${input.mixClass}`}
            key={type + input.postfixName + i}
          >
            <Input
              label={
                input.label +
                (!input.withoutAdditionalLabel && RoleBasedInputLabels[type]
                  ? ` ${RoleBasedInputLabels[type]}`
                  : "")
              }
              value={info[type + input.postfixName]}
              onChange={handleUserDataChange}
              name={type + input.postfixName}
              validators={input.validators || []}
              onValidationChange={onValidationChange}
            />
          </div>
        ))}
      </div>

      <ChooseUser
        setUserPerm={`perm_construction-object_assign-${type}`}
        type={type}
        withUserCreator={withUserCreator}
        onSelect={selectUser}
        objectId={null}
        onClose={() => setUserActive(false)}
        isActive={userActive}
      />
    </div>
  );
};

AddObjectUser.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.string,
    mail: PropTypes.string,
  }),
  userInputs: PropTypes.arrayOf(inputDataTypes).isRequired,
  orgInputs: PropTypes.arrayOf(inputDataTypes),
  type: PropTypes.string,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  withUserCreator: PropTypes.bool,
  onChange: PropTypes.func,
  onValidationChange: PropTypes.func,
};

export default AddObjectUser;
