import React from "react";

export const inlineStarEmpty = (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5085 4.94953L7.5 1L5.4915 4.94953L1 5.58675L4.25 8.65931L3.483 13L7.5 10.9495L11.517 13L10.75 8.65931L14 5.58675L9.5085 4.94953Z" stroke="#2189AF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const inlineStarFilled = (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 1L9.5085 4.94953L14 5.58675L10.75 8.65931L11.517 13L7.5 10.9495L3.483 13L4.25 8.65931L1 5.58675L5.4915 4.94953L7.5 1Z" fill="#2EAADC" stroke="#2189AF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const inlineStarHalfFilled = (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.483 13L7.5 10.9495V1L5.4915 4.94953L1 5.58675L4.25 8.65931L3.483 13Z" fill="#2EAADC"/>
      <path d="M7.5 1L9.5085 4.94953L14 5.58675L10.75 8.65931L11.517 13L7.5 10.9495M7.5 1L5.4915 4.94953L1 5.58675L4.25 8.65931L3.483 13L7.5 10.9495M7.5 1V10.9495" stroke="#2189AF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
