import React from "react";
import PropTypes from "prop-types";

import "./StageImages.scss";
import CheckPermissions from "../../../../components/CheckPermissions";
import { permissions } from "../../../../permissions";
import Paginator from "../../../../components/Paginator";

// import Paginator from "../../../../components/Paginator";

const StageImages = (props) => {
  let api;
  if (process.env.NODE_ENV === "production") {
    api = process.env.REACT_APP_PRODUCTION_API_URL;
  } else {
    api = process.env.REACT_APP_DEVELOP_API_URL;
  }

  return (
    <div className="stage-images-container">
      {(props.images.items && props.images.items.length === 0) ||
      !props.images.items ? (
        <div className="stage-images-empty">Нет фотографий</div>
      ) : (
        <ul className="stage-images">
          {props.images.items &&
            props.images.items.map((item) => {
              return (
                <li
                  key={item.id}
                  className="stage-images-item"
                  onClick={() => props.onImageClick(item.id)}
                >
                  <img src={api + "/" + item.small} alt={item.name} />
                </li>
              );
            })}
        </ul>
      )}

      <div className="stage-images-controls">
        <CheckPermissions permissions={[permissions.loadImage]}>
          {props.status === "WORKS_DONE" ||
          props.status === "STOPPED" ? null : (
            <label className="stage-images-add">
              <input
                type="file"
                multiple
                accept=".jpeg, .png, .gif, .bmp, .jpg"
                onChange={props.onUpload}
              />
              Добавить фотографию
            </label>
          )}
        </CheckPermissions>

        {props.pagination.totalCount > props.pagination.pageSize ? (
          <div className="stage-images-paginator">
            <Paginator
              currentPage={props.pagination.currentPage}
              pageSize={props.pagination.pageSize}
              total={props.pagination.totalCount}
              totalPages={props.pagination.totalPageCount}
              pageSizes={props.pagination.pageSize}
              onPageChange={props.onPageChange}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

StageImages.propTypes = {
  onImageClick: PropTypes.func,
  images: PropTypes.array,
  pagination: PropTypes.object,
  onUpload: PropTypes.func,
};

export default StageImages;
