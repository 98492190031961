import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./ObjectSection.scss";

import StageCard from "../StageCard";
import { inlineArrow } from "./img/inlineSVGicons";
import { editIcon, deleteIcon } from "../../../../../../assets/img/sprite";
import { fetchStagesFromSection } from "../../../../../../actions/ObjectStagesActions";
import Paginator from "../../../../../../components/Paginator/Paginator";
import Loader from "../../../../../../components/Loader";
import CheckPermissions from "../../../../../../components/CheckPermissions";
import Input from "../../../../../../components/Input";
import AddTask from "./components/AddTask";
import Select from "../../../../../../components/Select";
import { fetchStatuses } from "../../../../../../actions/stageStatusesActions";

const statuses = [
  { key: 1, value: "Планируется" },
  { key: 2, value: "В работе" },
  { key: 3, value: "Утверждено" },
  { key: 4, value: "Сделано" },
];

const ObjectSection = (props) => {
  const [isOpen, setIsOpen] = useState(props.isDefaultOpen);
  const dispatch = useDispatch();
  const stages = useSelector(
    (store) => store.objectStages.items[props.item.id] || {}
  );
  const isAvailable = useSelector((state) => state.objects.isAvailable);
  const stageStatusesStore = useSelector((store) => store.stageStatuses);
  // eslint-disable-next-line no-unused-vars
  const [changedStages, setChangedStages] = useState(stages);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [changeSectionName, setChangeSectionName] = useState(props.item.name);
  const [timeOutSearchName, setTimeOutSearchName] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [status, setStatus] = useState("");

  const getStages = useCallback(
    (page, pageSize, searchName, sortBy, sortOrder, statusID) => {
      dispatch(
        fetchStagesFromSection(
          props.item.object_id,
          props.item.id,
          searchName,
          statusID,
          sortBy,
          sortOrder,
          page,
          pageSize
        )
      );
    },
    [
      dispatch,
      props.item.object_id,
      props.item.id,
      searchName,
      sortBy,
      sortOrder,
    ]
  );

  useEffect(() => {
    if (isOpen && !stages.isUpdated && !stages.isFetching && !stages.isError) {
      getStages();
    }
  }, [stages, isOpen, getStages]);

  useEffect(() => {
    let stagesStatus = JSON.parse(localStorage.getItem("stagesStatus"));
    if (!stagesStatus) {
      localStorage.setItem(
        "stagesStatus",
        JSON.stringify({ [props.item.object_id]: {} })
      );
      return;
    }
    if (!stagesStatus[props.item.object_id]) {
      stagesStatus[props.item.object_id] = {};
      localStorage.setItem("stagesStatus", JSON.stringify(stagesStatus));
    }
    if (
      stagesStatus[props.item.object_id] &&
      stagesStatus[props.item.object_id][props.item.id]
    ) {
      setIsOpen(true);
    }
  }, [props.item.object_id, props.item.id]);

  useEffect(() => {
    let stagesStatus = JSON.parse(localStorage.getItem("stagesStatus"));
    stagesStatus[props.item.object_id][props.item.id] = isOpen;
    localStorage.setItem("stagesStatus", JSON.stringify(stagesStatus));
  }, [isOpen, props.item.object_id, props.item.id]);

  const getStagesList = () => {
    if (stages) {
      return stages.items.map((item) => {
        let stage;
        if (props.changedStages[props.item.id]) {
          stage = props.changedStages[props.item.id].filter((s) => {
            return s.id === item.id;
          });
        }
        if (!stage || !stage.length) {
          stage = item;
        } else {
          stage = stage.length && stage[stage.length - 1];
        }
        return (
          <div key={stage.id} className="object-section-stages">
            {stage.is_delete ? null : (
              <StageCard
                onClick={() => props.onStageClick(stage.id)}
                onSave={stageChange}
                onDelete={deleteTask}
                item={stage}
                status={props.status}
                onChangeAmount={changeAmount}
                onChangeUnit={changeUnit}
              />
            )}
          </div>
        );
      });
    } else return [];
  };

  const changeAmount = () => {
    props.item.error = Number(props.item.error) - 1;
  };

  const changeUnit = () => {
    props.item.error = Number(props.item.error) - 1;
  };

  const deleteTask = (value, amount, unit) => {
    if (amount <= 0 || unit === "") {
      props.item.error = Number(props.item.error) - 1;
    }
    stageDelete(value);
  };

  const getChangeStages = () => {
    // if (props.changedStages[props.item.id]) {
    //   let newStages = stages.items
    //   props.changedStages[props.item.id].forEach(stage => {
    //     if (!stage.is_delete) {
    //       newStages.forEach(item => {
    //         if (item.id === stage.id) {
    //           item = stage
    //         }
    //       })
    //     } else {
    //       for(let i in newStages) {
    //         if (newStages[i].id === stage.id) {
    //           newStages.splice(i, 1)
    //         }
    //       }
    //     }
    //   })
    //   setChangedStages(newStages)
    //   return
    // }
    // setChangedStages(stages.items)
    if (stages.items) {
      let newStages = [];
      stages.items.map((item) => {
        let stage;
        if (props.changedStages[props.item.id]) {
          stage = props.changedStages[props.item.id].find((s) => {
            return s.is_delete ? null : s.id === item.id;
          });
        }
        if (!stage) {
          stage = item;
        }
        return newStages.push(stage);
      });
      setChangedStages(newStages);
    }
  };

  const stageChange = (changedStage) => {
    let newStages = stages.items;
    let stage = newStages.find((el) => el.id === changedStage.id);
    newStages[newStages.indexOf(stage)] = changedStage;
    // stages.items = newStages
    changedStage.is_delete = false;
    getChangeStages();
    // props.onSectionChange(props.item.id, newStages);
    props.onSectionChange(props.item.id, changedStage);
  };

  const stageDelete = (stageId) => {
    let newStages = stages.items;
    let stage = newStages.find((el) => el.id === stageId);
    // stages.items[newStages.indexOf(stage)].is_delete = false;
    stage.is_delete = true;
    getChangeStages();

    props.onSectionChange(props.item.id, stage);
  };

  const pageChange = (page) => {
    getStages(page);
  };

  const saveSection = () => {
    setIsEditable(false);
    props.onSaveSection(props.objectId, props.sectionId, changeSectionName);
  };

  const cancelSelectionEdit = () => {
    setIsEditable(false);
    setChangeSectionName(props.item.name);
  };

  const onTaskAdd = () => {
    getStages();
  };

  const showAll = () => {
    setIsShowAll(true);
    getStages(1, stages.pagination.totalCount);
  };
  const hideAll = () => {
    setIsShowAll(false);
    getStages(1, 5);
  };

  const handleSearchNameChange = (ev) => {
    setSearchName(ev.target.value);

    clearTimeout(timeOutSearchName);
    setTimeOutSearchName(
      setTimeout(
        (value) => {
          getStages(1, 5, value);
        },
        1000,
        ev.target.value
      )
    );
  };

  const handleSetSort = (name) => {
    let val = "desc";
    if (sortBy === name) {
      if (sortOrder === "desc") {
        val = "asc";
      }
    }

    setSortBy(name);
    setSortOrder(val);

    getStages(1, 5, searchName, sortBy, sortOrder);
  };

  const handleStatusFilterChange = (statusVal) => {
    setStatus(statusVal.value);
    getStages(1, 5, searchName, sortBy, sortOrder, statusVal.key);
  };

  const onSelectOpen = () => {
    if (!stageStatusesStore.isFetching && !stageStatusesStore.isUpdated) {
      dispatch(fetchStatuses());
    }
  };

  const showSortArrow = (name) => {
    if (sortBy !== name) {
      return "";
    }

    if (sortOrder === "desc") {
      return <span>&#9660;</span>;
    }

    return <span>&#9650;</span>;
  };

  return (
    <div className="object-section">
      {isEditable ? (
        <div className="object-section-label-open">
          <Input
            value={changeSectionName}
            onChange={(ev) => setChangeSectionName(ev.target.value)}
          />
        </div>
      ) : (
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={
            isOpen ? "object-section-label-open" : "object-section-label"
          }
        >
          <div
            className={
              isOpen
                ? "object-section-label-arrow-open"
                : "object-section-label-arrow"
            }
          >
            {inlineArrow}
          </div>
          <div className="object-section-label-text">
            {props.item.name}
            {props.item.error ? <div className="indicator" /> : null}
          </div>
        </div>
      )}

      {isOpen ? (
        <div className="object-section-content">
          {stages.isFetching ? <Loader /> : null}

          <div className="edit-section">
            <CheckPermissions permissions={["perm_section_delete"]}>
              {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
                <button
                  onClick={() =>
                    props.onDeleteSection(props.objectId, props.sectionId)
                  }
                  className="delete _error"
                  disabled={isAvailable}
                >
                  {deleteIcon}
                  <span>Удалить</span>
                </button>
              ) : null}
            </CheckPermissions>

            <CheckPermissions permissions={["perm_section_update"]}>
              {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
                isEditable ? (
                  <button className="edit" onClick={saveSection}>
                    Сохранить
                  </button>
                ) : (
                  <button
                    className="edit"
                    onClick={() => setIsEditable(true)}
                    disabled={isAvailable}
                  >
                    {editIcon}
                    <span>Редактировать</span>
                  </button>
                )
              ) : null}
            </CheckPermissions>

            <CheckPermissions permissions={["perm_section_update"]}>
              {isEditable ? (
                <button onClick={cancelSelectionEdit} className="delete _error">
                  Отмена
                </button>
              ) : null}
            </CheckPermissions>
          </div>

          {stages && stages.isUpdated ? (
            <React.Fragment>
              <div className="object-section-filters">
                <div className="object-section-search">
                  <Input
                    type="text"
                    placeholder="Найти..."
                    value={searchName}
                    onChange={handleSearchNameChange}
                  />
                </div>
                <div className="object-section-status-select">
                  <Select
                    items={stageStatusesStore.items}
                    value={status}
                    isFetching={stageStatusesStore.isFetching}
                    placeholder={"Статус"}
                    name="statuses"
                    onChange={handleStatusFilterChange}
                    onOpen={onSelectOpen}
                    reset
                    isRounded
                  />
                </div>
              </div>

              <div className="object-section-list">
                <ul className="object-section-head">
                  <li className="object-section-head-name">
                    <a href="#" onClick={() => handleSetSort("name")}>
                      Название
                    </a>{" "}
                    {showSortArrow("name")}
                  </li>
                  <li className="object-section-head-begin">Начало работ</li>
                  <li className="object-section-head-end">Конец работ</li>
                  <li className="object-section-head-status">
                    <a href="#" onClick={() => handleSetSort("status")}>
                      Статус
                    </a>{" "}
                    {showSortArrow("status")}
                  </li>
                  <li className="object-section-head-amount">Объем работ</li>
                  {/*<li className="object-section-head-unit">*/}
                  {/*  Единицы измерения*/}
                  {/*</li>*/}
                  <li className="object-section-head-completion">Прогресс</li>
                </ul>

                {getStagesList().length ? (
                  getStagesList()
                ) : (
                  <div className="no-notes">Нет записей</div>
                )}
              </div>
              {stages.pagination.totalCount > stages.pagination.pageSize ? (
                <div className="object-section-paginator">
                  <div className="object-section-paginator-total">{stages.pagination.totalCount + " " + "Разделов"}</div>
                  <Paginator
                    currentPage={stages.pagination.currentPage}
                    pageSize={stages.pagination.pageSize}
                    total={stages.pagination.totalCount}
                    totalPages={stages.pagination.totalPageCount}
                    onPageChange={pageChange}
                  />

                  <button className="show-all" onClick={showAll}>
                    Показать все
                  </button>
                </div>
              ) : isShowAll ? (
                <button className="hide-all _error" onClick={hideAll}>
                  Скрыть
                </button>
              ) : null}
            </React.Fragment>
          ) : (
            <div style={{ height: 301 }} />
          )}

          <CheckPermissions permissions={["perm_task_create"]}>
            {props.status !== "WORKS_DONE" && props.status !== "STOPPED" ? (
              <AddTask
                objectId={props.objectId}
                sectionId={props.sectionId}
                onTaskAdd={onTaskAdd}
              />
            ) : null}
          </CheckPermissions>
        </div>
      ) : null}
    </div>
  );
};

ObjectSection.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    object_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFetching: PropTypes.bool,
    isUpdated: PropTypes.bool,
    isError: PropTypes.bool,
    errors: PropTypes.array,
  }).isRequired,
  isDefaultOpen: PropTypes.bool,
  onSectionChange: PropTypes.func,
  onStageClick: PropTypes.func,
  onTaskAdd: PropTypes.func,
  onDeleteSection: PropTypes.func,
  onSaveSection: PropTypes.func,
};

export default ObjectSection;
