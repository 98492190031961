import api from "./api";

export const USER_SINGIN_REQUEST = "USER_SINGIN_REQUEST";
export const USER_SINGIN_SUCCESS = "USER_SINGIN_SUCCESS";
export const USER_SINGIN_ERROR = "USER_SINGIN_ERROR";
export const USER_SINGIN_SET_TOKEN = "USER_SINGIN_SET_TOKEN";

export function singIn(login, password) {
  return (dispatch) => {
    dispatch({
      type: USER_SINGIN_REQUEST,
    });

    let userData = {
      user_email: login,
      user_password: password,
    };

    return fetch(api + "/v1/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          dispatch({
            type: USER_SINGIN_SUCCESS,
            payload: data,
          });
          return data.data;
        }

        dispatch({
          type: USER_SINGIN_ERROR,
          payload: data.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_SINGIN_ERROR,
          payload: error,
        });
      });
  };
}

export function setTokenInfo(tokenName, token) {
  return {
    type: USER_SINGIN_SET_TOKEN,
    payload: {
      name: tokenName,
      token: token,
    },
  };
}
