import React from "react";
import PropTypes from "prop-types";

import "./EditableField.scss";
import Input from "../Input";

const EditableField = (props) => {
  return (
    <div className="input-field">
      {props.isEditable ? (
        <Input
          label={props.label}
          value={props.value || ""}
          onChange={props.onChange}
          validators={props.validators}
          onValidationChange={props.onValidationChange}
          error={props.error}
          name={props.name}
        />
      ) : (
        <div className="input-field-container">
          <div className={`input-field-text ${props.isTitle ? "_title" : ""}`}>{props.value || "-"}</div>
          {!props.isTitle && <div className="input-field-label">{props.label}</div>}
        </div>
      )}
    </div>
  );
};

EditableField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  onValidationChange: PropTypes.func,
  error: PropTypes.string,
  name: PropTypes.string,
  isTitle: PropTypes.bool,
};

export default EditableField;
