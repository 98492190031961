import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import "./ObjectEstimates.scss";

import FileList from "../FileList";
import {
  fetchFiles,
  uploadFiles,
  deleteFiles,
  updateFiles,
  downloadFiles,
  removeUserFromFile,
} from "../../actions/ObjectFilesActions";
import { inlineXls, inlineXlsx, inlineUnnown } from "./img/inlineSVGicons";
import { OBJECT_SECTIONS_SET_IS_UPDATED } from "../../reducers/objectSections";
import { UserContext } from "../../App";
import Paginator from "../Paginator";
import Loader from "../Loader";
import toaster from "toasted-notes";
import CheckPermissions from "../CheckPermissions";

const ObjectEstimates = (props) => {
  const estimatesStore = useSelector(
    (store) => store.objectFiles.estimates || []
  );
  const dispatch = useDispatch();
  const upload = (files) => dispatch(uploadFiles("estimates", props.id, files));
  const onDelete = (ids) => deleteFiles("estimates", props.id, ids);
  const onUpdate = (estimate) => updateFiles("estimates", estimate);
  const onUserRemove = (estimateID, userRole) =>
    removeUserFromFile("estimate", { id: estimateID, role: userRole });
  const download = (ids, name, ext) =>
    downloadFiles("estimates", props.id, ids, name, ext);
  const userContext = useContext(UserContext);

  const getFiles = useCallback(
    (page, pageSize) =>
      dispatch(fetchFiles("estimates", props.id, page, pageSize)),
    [props.id, dispatch]
  );

  useEffect(() => {
    if (
      !estimatesStore.isUpdated &&
      !estimatesStore.isFetching &&
      !estimatesStore.isError
    ) {
      getFiles();
    }
  }, [estimatesStore, getFiles]);

  const getIcon = (ext) => {
    switch (ext) {
      case ".xls": {
        return inlineXls;
      }
      case ".xlsx": {
        return inlineXlsx;
      }
      default: {
        return inlineUnnown;
      }
    }
  };

  const isHavePermission = (permission) => {
    return !!userContext.permissions.filter((perm) => perm.name === permission)
      .length;
  };

  const onUpload = (files, callback) => {
    upload(files).then((r) => {
      typeof callback === "function" && callback();
      // if (r) {
      //   toaster.notify(<div className="error-toast">{JSON.stringify(r)}</div>, {
      //     position: "top-right",
      //     duration: 2000,
      //   });
      // }
      dispatch({
        type: OBJECT_SECTIONS_SET_IS_UPDATED,
        payload: false,
      });
      getFiles();
    });
  };

  const onDel = (ids) => {
    onDelete(ids).then(() => {
      getFiles();
    });
  };

  const onUpd = (estimate) => {
    onUpdate(estimate).then(() => {
      getFiles();
    });
  };

  const onRemove = (estimateID, userRole) => {
    onUserRemove(estimateID, userRole).then(() => {
      getFiles();
    });
  };

  const onDownload = (ids) => {
    let name = "";
    if (ids.length > 1) {
      name = "Сметный расчет";
    } else {
      name = estimatesStore.items.filter((item) => item.id === ids[0])[0].name;
    }
    let ext = ids.length > 1 ? ".zip" : ".xlsx";
    download(ids, name, ext);
  };

  let files = estimatesStore.items.map((item) => {
    return {
      ...item,
      icon: getIcon(item.extension),
    };
  });

  const pageChange = (page) => {
    getFiles(page);
  };
  return (
    <div className="solo-object-estimates">
      {/*<div className="content-card__head">*/}
      {/*  <h3 className="content-card__title">Сметный расчет</h3>*/}
      {/*  <CheckPermissions permissions={["perm_contractor_index"]}>*/}
      {/*    {props.hasContractors ? (*/}
      {/*        <button*/}
      {/*            className="solo-object-estimates-add-contractor"*/}
      {/*            onClick={props.onOpenContractorList}*/}
      {/*        >*/}
      {/*          Утвердить подрядчиков*/}
      {/*        </button>*/}
      {/*    ) : null}*/}
      {/*  </CheckPermissions>*/}
      {/*</div>*/}
      {estimatesStore.isFetching ? <Loader /> : null}
      <FileList
        files={files}
        onUpload={
          isHavePermission("perm_document_upload") &&
          props.status !== "STOPPED" &&
          props.status !== "WORKS_DONE"
            ? onUpload
            : null
        }
        onDownload={
          isHavePermission("perm_document_download") ? onDownload : null
        }
        onDelete={isHavePermission("perm_document_delete") ? onDel : null}
        onUpdate={isHavePermission("perm_update_estimate") ? onUpd : null}
        onRemove={isHavePermission("perm_update_estimate") ? onRemove: null}
        isFetching={estimatesStore.isFetching}
        isError={estimatesStore.isError}
        label="Добавить файлы (.xls, .xlsx)"
        format=".xls, .xlsx"
        status={props.status}
        isEstimate={true}
      />
      {estimatesStore.pagination.totalCount >
      estimatesStore.pagination.pageSize ? (
        <Paginator
          currentPage={estimatesStore.pagination.currentPage}
          pageSize={estimatesStore.pagination.pageSize}
          total={estimatesStore.pagination.totalCount}
          totalPages={estimatesStore.pagination.totalPageCount}
          onPageChange={pageChange}
        />
      ) : null}
    </div>
  );
};

ObjectEstimates.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // onOpenContractorList: PropTypes.func,
  // hasContractorsToObject: PropTypes.bool,
};

export default ObjectEstimates;
