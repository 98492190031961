import api from "./api";
import { adaptCreateUserDataToServer } from "../assets/js/adapters";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const createUser = (data) => {
  return (dispatch) => {
      dispatch({
          type: CREATE_USER_REQUEST,
      });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);

    return fetch(api + `/v1/user/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: JSON.stringify(adaptCreateUserDataToServer(data)),
    })
      .then((response) => response.json())
      .then((r) => {
        if (r._meta.isSuccessful) {
            dispatch({
                type: CREATE_USER_SUCCESS,
            });
            return "success";
        } else {
            dispatch({
                type: CREATE_USER_ERROR,
                payload: {
                    errorData: r.data.message,
                },
            });
            return r.data.message;
        }
      }).catch((er) => {
            dispatch({
                type: CREATE_USER_ERROR,
                payload: {
                    errorData: er,
                },
            });
        });
  };
};
