import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";

import "./sidemenu.scss";

import menu from "./img/Menu.svg";
import { UserContext } from "../../../../App";
import { useSelector } from "react-redux";
import { getUserManual } from "../../../../actions/UserActions";
import { ROUTES } from "../../../../routes";
import { closeIcon, logOutIcon } from "../../../../assets/img/sprite";
import Logo from "../../../../components/Logo/logo";
import userEvent from "@testing-library/user-event";

const SideMenu = (props) => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [route, setRoute] = useState(history.location.pathname);

  const sideMenu = useRef(null);

  const isHavePermission = (permission) => {
    return userContext.permissions.filter((perm) => perm.name === permission)
      .length;
  };
  const notificationsStore = useSelector((store) => store.notifications);
  const { isMobileSize, isTabletSize, isDesktopSize } = useSelector(
    (store) => store.responsive.window
  );

  const notificationsCount = notificationsStore.isUpdated
    ? notificationsStore.count
    : userContext.unreadNotifications;

  useEffect(() => {
    if (route !== history.location.pathname) {
      setRoute(history.location.pathname);
      if (!isDesktopSize) props.toggle(false);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!sideMenu || sideMenu.current.contains(e.target) || isDesktopSize) return;
      props.toggle(false);
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const getManual = () => {
    getUserManual()
      .then((r) => r.blob())
      .then((blob) => {
        let reader = new FileReader();
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'Руководство пользователя "Стройконтроль".pdf';
        link.click();
        reader.readAsArrayBuffer(blob);
      });
  };

  const handleUserLogout = () => {
    let tokenName = localStorage.getItem("token_name");
    localStorage.removeItem(tokenName);
    localStorage.removeItem("token_name");
    history.replace(ROUTES.LOGIN.PATH);
    // props.clearUser();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="sidemenu-filler" />
      <nav
        ref={sideMenu}
        className={!props.isOpen ? "sidemenu sidemenu-close" : "sidemenu"}
      >
        <div className="sidemenu-head">
          {/*<div className="sidemenu-logo">*/}
          {/*  <img*/}
          {/*    className="sidemenu-logo-img"*/}
          {/*    alt="logo"*/}
          {/*    src={require("../../../../assets/img/logo.svg")}*/}
          {/*  />*/}

          {/*  /!*<div className="sidemenu-logo-text">Департамент архитектуры и строительства Томской области</div>*!/*/}
          {/*</div>*/}
          <Logo className="sidemenu-logo" />
          <button onClick={props.toggle} className="sidemenu-toggle">
            {closeIcon}
          </button>
        </div>

        <ul className="sidemenu-tabs">
          {props.config.map((item) => {
            if (!item.permission || isHavePermission(item.permission)) {
              return (
                <li key={item.path}>
                  <NavLink
                    activeClassName="sidemenu-tabs-tab-active"
                    to={item.path}
                    className="sidemenu-tabs-tab"
                  >
                    <div className="sidemenu-tabs-tab-icon">{item.img}</div>

                    <span className="sidemenu-tabs-tab-text">{item.text}</span>
                    {item.path === ROUTES.NOTIFICATION.PATH &&
                    notificationsCount ? (
                      <div className="notification-count">
                        {notificationsCount}
                      </div>
                    ) : null}
                  </NavLink>
                </li>
              );
            }
            return null;
          })}
        </ul>

        {props.isLogged && (
          <div className="sidemenu-user-block">
            <div className="sidemenu-user-info">
              <NavLink className="sidemenu-user-name" to={"/profile"}>
                {props.name}
              </NavLink>
              <span className="sidemenu-user-role">{props.role}</span>
            </div>
            <button
              className="sidemenu-user-logout _icon"
              onClick={handleUserLogout}
            >
              {logOutIcon}
            </button>
          </div>
        )}

        {props.isOpen && (
          <div className="sidemenu-link">
            <div className="sidemenu-link__label" onClick={() => getManual()}>
              Руководство пользователя
            </div>
          </div>
        )}
      </nav>
    </React.Fragment>
  );
};

export default SideMenu;
