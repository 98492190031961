import React, { useState } from "react";
import PropTypes from "prop-types";

import "../../../../../SoloObject/components/Stages/components/ObjectSectionsBlock/ObjectSectionsBlock.scss";
import AddObjectSectionsList from "./components/AddObjectSectionsList";

const AddObjectSectionsBlock = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`object-sections-block ${isOpen ? "_open" : ""}`}>
      <div
        className="object-sections-block-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.name || "Разделы сметы"}
      </div>
      {isOpen ? (
        <AddObjectSectionsList
          sections={props.sections}
          onStageSave={props.onStageSave}
          onStageDelete={props.onStageDelete}
        />
      ) : null}
    </div>
  );
};

AddObjectSectionsBlock.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sections: PropTypes.object,
  onStageSave: PropTypes.func,
  onStageDelete: PropTypes.func,
};

export default AddObjectSectionsBlock;
