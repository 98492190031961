import React, {useEffect} from "react";
import "./GlobalModal.scss";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../actions/ModalAction";
import { ModalTypes } from "../../assets/js/constants";
import ObjectRating from "../../components/ObjectRating";
import UserRating from "../../components/UserRating";

const getContent = (type, data, render) => {
  switch (type) {
    case ModalTypes.OBJECT_RATING:
      return <ObjectRating objectID={data.objectID} />;
    case ModalTypes.PERSONAL_RATING:
      return <UserRating userID={data.userID} />;
    case ModalTypes.RENDER:
      return render();
    default:
      return <p>Не верный тип модального окна</p>;
  }
};

const GlobalModal = () => {
  const popupStore = useSelector((store) => store.modal);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());
  const handleEscKeydown = (e) => {
    if (e.code !== "Escape") return;
    handleClose();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeydown);
    return () => document.removeEventListener("keydown", handleEscKeydown);
  });

  return !popupStore.isOpen ? null : (
    <div className="global-modal" onMouseDown={handleClose}>
      <div
        className={`global-modal__content ${
          popupStore.isContainer ? "_container" : ""
        }`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {popupStore.withoutCloseBtn ? null : (
          <button
            className="global-modal__close"
            type="button"
            aria-label="Закрыть"
            onClick={handleClose}
          />
        )}
        {getContent(popupStore.type, popupStore.data, popupStore.render)}
      </div>
    </div>
  );
};

export default GlobalModal;
