import React from "react";
import PropTypes from "prop-types";

import "./Percentage.scss";
import ReactTooltip from "react-tooltip";

function Percentage(props) {
  return (
    <div className="percentage-container">
      <div className="percentage-line">
        <ReactTooltip id={"confirmed"} type="dark" place="top" effect="float">
          <span>Утвержденный объем работ</span>
        </ReactTooltip>
        <div
          style={{ width: props.value + "%" }}
          className="percentage-line-filler"
          data-tip
          data-for={"confirmed"}
        />
        {props.unconfirmedValue && Number(props.value) < 100 ? (
          <>
            <ReactTooltip
              id={"unconfirmed"}
              type="dark"
              place="top"
              effect="float"
            >
              <span>Объем работ, ожидающий подтверждения</span>
            </ReactTooltip>
            <div
              style={{ width: Number(props.unconfirmedValue) + Number(props.value) <= 100 ? props.unconfirmedValue + "%" : 100 - Number(props.value) + '%' }}
              className="percentage-line-filler-unconfirmed"
              data-tip
              data-for={"unconfirmed"}
            />
          </>
        ) : null}
      </div>
      <span className="percentage-text">{props.value}%</span>
    </div>
  );
}

Percentage.propTypes = {
  value: PropTypes.number,
  unconfirmedValue: PropTypes.number,
};

export default Percentage;
