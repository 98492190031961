import api from "./api";
import {
  OBJECT_CONTRACTORS_ERROR,
  OBJECT_CONTRACTORS_REQUEST,
  OBJECT_CONTRACTORS_SUCCESS,
} from "../reducers/objectsContractors";

export const fetchObjectContractors = (objectId) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_CONTRACTORS_REQUEST,
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return;
    }

    fetch(`${api}/v1/object/${objectId}/contractor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response._meta.isSuccessful) {
          dispatch({
            type: OBJECT_CONTRACTORS_SUCCESS,
            payload: response.data,
          });
          return;
        }
        dispatch({
          type: OBJECT_CONTRACTORS_ERROR,
          payload: response.data.message[0],
        });
      });
  };
};

export const setContractorToObject = (contractorId, estimatesId, objectId) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(`${api}/v1/object/${objectId}/approve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
    body: JSON.stringify({ profile_id: contractorId, estimate_id: estimatesId }),
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response._meta.isSuccessful) {
        return true;
      } else {
        return response.data.message;
      }
    });
};
