import "./Tutorial.scss";

import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { UserContext } from "../../App";
import { useHistory } from "react-router-dom";
import { ReactComponent as Tip } from "./img/tip.svg";

const Tutorial = ({ isPendingContent, onRedirection, onFinish, steps }) => {
  const isSteps = Array.isArray(steps) && steps.length > 0;
  const user = useContext(UserContext);
  const history = useHistory();

  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];

  useEffect(() => {
    if (!isSteps || !(`location` in step)) {
      return;
    }

    const location = step.location(user);
    if (history.location.pathname !== location) {
      history.push(location);
      onRedirection();
    }
  }, [history, onRedirection, step, user]);

  const [previousPopover, setPreviousPopover] = useState({});
  const { popover, tip } = useMemo(() => step?.style(isPendingContent) ?? {}, [
    isPendingContent,
    step,
  ]);

  useEffect(() => {
    if (popover) {
      setPreviousPopover(popover);
    }
  }, [popover]);

  const handleNext = useCallback(() => {
    if (stepIndex + 1 === steps.length) {
      onFinish();
    } else {
      setStepIndex(stepIndex + 1);
    }
  }, [onFinish, stepIndex]);


  if (!isSteps || isPendingContent) return null;

  return (
    <div className="tutorial-wrapper">
      <div className="tutorial" style={popover ?? previousPopover}>
        <h1 className="tutorial-title">{step.title}</h1>
        <div className="tutorial-content">{step.content}</div>
        <footer className="tutorial-footer">
          <p className="tutorial-nth">
            {stepIndex + 1}/{steps.length}
          </p>
          <div className="tutorial-controls">
            <button type="button" className="tutorial-skip" onClick={onFinish}>
              Пропустить
            </button>
            <button
              type="button"
              className="tutorial-next"
              onClick={handleNext}
            >
              Далее
            </button>
          </div>
        </footer>
        {tip && (
          <Tip
            className={`tutorial-tip -${tip.side}`}
            style={Object.fromEntries(
              Object.entries(tip).filter(([key]) => key !== "side")
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Tutorial;
