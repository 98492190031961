import {
  OBJECT_FILES_REQUEST,
  OBJECT_FILES_SUCCESS,
  OBJECT_FILES_ERROR,
} from "../reducers/objectFiles";

import { OPEN_INFO_POPUP } from "../reducers/infoPopup";

import api from "./api";

const getMethods = (type) => {
  let methods = null;
  switch (type) {
    case "designProject": {
      methods = {
        get: "view-design-project",
        post: "upload-design-project",
        delete: "delete-design-project",
        update: "update-design-project",
        download: "download-design-project",
      };
      break;
    }
    case "estimates": {
      methods = {
        get: "view-estimates",
        post: "upload-estimates",
        delete: "delete-estimates",
        update: "update-estimate",
        download: "download-estimates",
      };
      break;
    }
    case "projectEstimates": {
      methods = {
        get: "view-project-estimates",
        post: "upload-project-estimates",
        delete: "delete-project-estimates",
        update: "update-project-estimates",
        download: "download-project-estimates",
      };
      break;
    }
    default: {
    }
  }
  return methods;
};

export const fetchFiles = (type, id, page, pageSize) => {
  return (dispatch) => {
    let methods = getMethods(type);
    if (!methods) {
      return "Для экшена fetchFiles указан неверный параметр type";
    }

    dispatch({
      type: OBJECT_FILES_REQUEST,
      payload: type,
    });

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_FILES_ERROR,
        payload: {
          type: type,
          errors: ["Нет прав доступа. Пожалуйста войдите в систему"],
        },
      });
      return;
    }

    fetch(api + `/v1/object/${id}/document/${methods.get}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        [tokenName]: token,
      },
      body: JSON.stringify({
        page: page,
        pageSize: pageSize,
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_FILES_ERROR,
            payload: {
              type: type,
              errors: data.message,
            },
          });
          return;
        }

        dispatch({
          type: OBJECT_FILES_SUCCESS,
          payload: {
            type: type,
            items: data.data.items,
            pagination: data.data.pagination,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_FILES_ERROR,
          payload: {
            type: type,
            errors: er.message,
          },
        });
      });
  };
};

export const deleteFiles = (type, object_id, id) => {
  const methods = getMethods(type);
  if (!methods) {
    return "Для экшена deleteFiles указан неверный параметр type";
  }

  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return "Нет прав доступа. Пожалуйста войдите в систему";
  }

  let body = JSON.stringify({
    files_id: id,
  });
  return fetch(api + `/v1/object/${object_id}/document/${methods.delete}`, {
    method: "POST",
    headers: {
      [tokenName]: token,
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((r) => r.json())
    .then((data) => {
      if (!data._meta.isSuccessful) {
        return "Ошибка в процессе удаления";
      }
    })
    .catch((er) => {
      return er.message;
    });
};

export const updateFiles = (type, { id, name, control, builder }) => {
  const methods = getMethods(type);
  if (!methods) {
    return "Для экшена updateFiles указан неверный параметр type";
  }

  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return "Нет прав доступа. Пожалуйста войдите в систему";
  }

  //TODO Реализовать адаптер для всех типов файлов, данная реализация только для смет
  const fileData = {
    estimate_id: id,
    estimate_name: name,
    construction_object_construction_control_id: control.id,
    construction_object_construction_control_name: control.name,
    construction_object_construction_control_email: control.email,
    construction_object_construction_control_phone_number: control.phone,
    construction_object_construction_control_contact_info:
      control.additionalInfo,
    construction_object_construction_control_organization_name: control.orgName,
    construction_object_construction_control_organization_contacts:
      control.orgContacts,
    construction_object_construction_control_organization_address:
      control.orgAddress,
    construction_object_builder_id: builder.id,
    construction_object_builder_name: builder.name,
    construction_object_builder_email: builder.email,
    construction_object_builder_phone_number: builder.phone,
    construction_object_builder_contact_info: builder.additionalInfo,
    construction_object_builder_organization_name: builder.orgName,
    construction_object_builder_organization_contacts: builder.orgContacts,
    construction_object_builder_organization_address: builder.orgAddress,
  };

  const body = JSON.stringify(fileData);
  return fetch(api + `/v1/document/${methods.update}/`, {
    method: "POST",
    headers: {
      [tokenName]: token,
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((r) => r.json())
    .then((data) => {
      if (!data._meta.isSuccessful) {
        return "Ошибка в процессе обновления файла";
      }
    })
    .catch((er) => {
      return er.message;
    });
};

export const removeUserFromFile = (type, { id, role }) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return "Нет прав доступа. Пожалуйста войдите в систему";
  }

  //TODO Реализовать адаптер для всех типов файлов, данная реализация только для смет
  const fileData = {
    estimate_id: id,
  };

  const body = JSON.stringify(fileData);

  return fetch(
    api +
      `/v1/document/unassign-${
        role === "construction-control" ? "control" : role
      }/`,
    {
      method: "POST",
      headers: {
        [tokenName]: token,
        "Content-Type": "application/json",
      },
      body: body,
    }
  )
    .then((r) => r.json())
    .then((data) => {
      if (!data._meta.isSuccessful) {
        return "Ошибка в процессе открепления пользователя";
      }
    })
    .catch((er) => {
      return er.message;
    });
};

export const uploadFiles = (type, id, files) => {
  return (dispatch) => {
    const methods = getMethods(type);
    if (!methods) {
      return "Для экшена uploadFiles указан неверный параметр type";
    }

    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      return "Нет прав доступа. Пожалуйста войдите в систему";
    }

    let body = new FormData();
    files.forEach((file) => {
      body.append("files[]", file);
    });

    return fetch(api + `/v1/object/${id}/document/${methods.post}`, {
      method: "POST",
      headers: {
        [tokenName]: token,
      },
      body: body,
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OPEN_INFO_POPUP,
            payload: {
              text: data.data.message.files,
            },
          });
          return data.data.message.files;
        }
        dispatch({
          type: OPEN_INFO_POPUP,
          payload: {
            text: data.data.message,
          },
        });
      })
      .catch((er) => {
        return er.message;
      });
  };
};

export const downloadFiles = (type, object_id, id, file_name, ext) => {
  const methods = getMethods(type);
  if (!methods) {
    return "Для экшена downloadFiles указан неверный параметр type";
  }

  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return "Нет прав доступа. Пожалуйста войдите в систему";
  }

  let body = JSON.stringify({
    files_id: id,
  });
  return fetch(api + `/v1/object/${object_id}/document/${methods.download}`, {
    method: "POST",
    headers: {
      [tokenName]: token,
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((r) => r.blob())
    .then((data) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = file_name + ext;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return url;
    })
    .catch((er) => {
      return er.message;
    });
};
