import "./HelpTopic.scss";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { fetchTopic } from "../../actions/HelpTopicsActions";
import Loader from "../../components/Loader";

const HelpTopic = () => {
  const id = Number(useRouteMatch().params.id);
  const dispatch = useDispatch();
  const topicsStore = useSelector((store) => store.helpTopics);
  const topic = topicsStore.items.find((topic) => Number(topic.id) === id) ?? {
    isFetching: false,
  };

  useEffect(() => {
    if (!topic.isFetching && !topic.isUpdated && !topic.isError) {
      dispatch(fetchTopic(id));
    }
  }, [id, dispatch, topic]);

  if (topic.isFetching) {
    return <Loader />;
  }

  const handleAccordionToggle = (e) => {
    e.preventDefault();
    const target = e.target;

    if (target.closest(".accordion__toggle")) {
      target.classList.toggle("_open");
    }
  };

  if (topic.isError) {
    return (
      <div className="container-wrapper">
        <div className="help-topic container content-card">
          <p className="help-topic-error">Ошибка при загрузке инструкции</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container-wrapper">
      <div
        className="help-topic container content-card"
        dangerouslySetInnerHTML={{ __html: topic.content }}
        onClick={handleAccordionToggle}
      />
    </div>
  );
};

export default HelpTopic;
