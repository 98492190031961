import isNumber from "./isNumber";

export default (number, separator = ',') => {
    let changedNumber = number;
    if (isNumber(number)) {
        changedNumber = String(number)
            .split(".")
            .join(separator);
    }

    return changedNumber;
};
