import React, { useState } from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import { ratingCardItem as ratingCardItemType } from "../../assets/js/propTypes";

import "./RatingCard.scss";
import {
  inlineStarEmpty,
  inlineStarFilled,
} from "../RatingStars/img/inlineSVGicons";

const MAX_RATING = 10;

const RatingCard = ({ item, onChange }) => {
  const { id, title, subtitle, rating, review } = item;
  const isEditable = typeof onChange === "function";

  const { isMobileSize } = useSelector((store) => store.responsive.window);
  const [newReview, setNewReview] = useState(review);

  const handleRatingChange = (e) => {
    onChange({ id, value: +e.currentTarget.dataset.rating });
  };

  const handleReviewChange = (e) => {
    setNewReview(e.currentTarget.value);
  };

  const handleReviewSave = () => {
    onChange({ id, review: newReview });
  };

  const getStars = () => {
    const stars = [];
    for (let i = 1; i <= MAX_RATING; i++) {
      stars.push(
        <li
          key={`${id}-star-${i}`}
          className="rating-card__star"
          data-rating={i}
          onClick={isEditable ? handleRatingChange : null}
        >
          {rating >= i ? inlineStarFilled : inlineStarEmpty}
        </li>
      );
    }
    return stars;
  };

  return (
    <article className={`rating-card${isEditable ? " _editable" : ""}`}>
      <div className="rating-card__head">
        <h3 className="rating-card__title">{title}</h3>
        {!subtitle ? null : (
          <span className="rating-card__subtitle">{subtitle}</span>
        )}
      </div>
      <div>
        {isMobileSize && <span className="rating-card__subtitle">Оценка:</span>}
        <ul className="rating-card__list">{getStars()}</ul>
      </div>
      {isEditable ? (
        <textarea
          className="rating-card__review"
          name={`review-${id}`}
          id={`review-${id}`}
          defaultValue={newReview}
          placeholder="Комментарий"
          onChange={handleReviewChange}
          onBlur={handleReviewSave}
          rows="3"
        />
      ) : (
        <div className="rating-card__review">
          <span className="rating-card__subtitle">Отзыв:</span>
          <p>{newReview || "-"}</p>
        </div>
      )}
    </article>
  );
};

RatingCard.propTypes = {
  item: ratingCardItemType,
  onChange: PropTypes.func,
};

export default RatingCard;
