import {
  OBJECTS_REQUEST,
  OBJECTS_REQUEST_SUCCESS,
  OBJECTS_REQUEST_ERROR,
  OBJECT_REQUEST,
  OBJECT_REQUEST_SUCCESS,
  OBJECT_REQUEST_ERROR,
} from "../reducers/objects";
import GeoJSON from "ol/format/GeoJSON";

import api from "./api";

const geoJson = new GeoJSON();

export function fetchObjects(type, filters, page, isAvailable) {
  return (dispatch) => {
    dispatch({
      type: OBJECTS_REQUEST,
      payload: type,
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECTS_REQUEST_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    if (filters) {
      if (
        filters.filter((item) => item.value === "").length === filters.length
      ) {
        filters = undefined;
      }
    }

    let body = {
      filter: filters,
      page: page,
    };
    fetch(`${api}/v1/object/${type}${isAvailable ? `?isGrouped=true` : ``}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data._meta.isSuccessful) {
          let objects = [];
          data.data.items.forEach((item) => {
            let object = normalizeData(item);
            object.updated = false;
            objects.push(object);
          });

          dispatch({
            type: OBJECTS_REQUEST_SUCCESS,
            payload: { items: objects, pagination: data.data.pagination },
          });
          return;
        }

        dispatch({
          type: OBJECTS_REQUEST_ERROR,
          payload: data,
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECTS_REQUEST_ERROR,
          payload: er,
        });
      });
  };
}

export const fetchSoloObject = (id) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_REQUEST,
      payload: id,
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_REQUEST_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    fetch(`${api}/v1/object/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data._meta.isSuccessful) {
          let object = normalizeData(data.data);
          object.updated = true;

          dispatch({
            type: OBJECT_REQUEST_SUCCESS,
            payload: object,
          });
          return;
        }

        dispatch({
          type: OBJECT_REQUEST_ERROR,
          payload: {
            errors: data.message,
            id: id,
          },
        });
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_REQUEST_ERROR,
          payload: {
            errors: er,
            id: id,
          },
        });
      });
  };
};

export const editObjectInfo = (id, value) => {
  return (dispatch) => {
    dispatch({
      type: OBJECT_REQUEST,
      payload: id,
    });
    let tokenName = localStorage.getItem("token_name");
    let token = localStorage.getItem(tokenName);
    if (!tokenName || !token) {
      dispatch({
        type: OBJECT_REQUEST_ERROR,
        payload: "Нет прав доступа. Пожалуйста войдите в систему",
      });
      return;
    }

    return fetch(api + `/v1/object/${id}/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        [tokenName]: token,
      },
      body: JSON.stringify(objectInfoToSend(value)),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data._meta.isSuccessful) {
          dispatch({
            type: OBJECT_REQUEST_ERROR,
            payload: {
              errors: data.message,
              id: id,
            },
          });
          return data.data.message;
        }

        let object = normalizeData(data.data);
        object.updated = true;
        dispatch({
          type: OBJECT_REQUEST_SUCCESS,
          payload: object,
        });
        return "success";
      })
      .catch((er) => {
        dispatch({
          type: OBJECT_REQUEST_ERROR,
          payload: {
            errors: er,
            id: id,
          },
        });
      });
  };
};

export const sendRespond = (id) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(`${api}/v1/object/${id}/request`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response._meta.isSuccessful) {
        return "success";
      } else {
        return response.data.message;
      }
    });
};

export const setStatusToInProgress = (objectId) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);

  return fetch(`${api}/v1/object/${objectId}/update/status-to-work`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      [tokenName]: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response._meta.isSuccessful) {
        return true;
      } else {
        return response.data.message;
      }
    });
};

export const editCustomerContractorInfo = async (id, info, type, executors) => {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }
  let url;
  let body = {};
  switch (type) {
    case "customer": {
      url = "customer-contacts";
      body = {
        construction_object_customer_name: info.builderName || info.name,
        construction_object_customer_email: info.builderEmail || info.email,
        construction_object_customer_phone_number:
          info.builderContact || info.phone,
        construction_object_customer_contact_info: info.contact,
      };
      break;
    }
    case "contractor": {
      url = "builder-contacts";
      body = {
        construction_object_builder_phone_number: info.builderPhone,
        construction_object_builder_name: info.builderName,
        construction_object_builder_email: info.builderEmail,
        construction_object_builder_contact_info: info.builderContact,
        executors: executors.map((item) => {
          return JSON.stringify({
            construction_object_executor_fio: item.name,
            construction_object_executor_phone_number: item.phone,
            construction_object_executor_email: item.email,
            construction_object_executor_contact_info: item.contacts,
          });
        }),
      };
      break;
    }
    case "architect": {
      url = "architect-contacts";
      body = {
        construction_object_architect_phone_number: info.builderPhone,
        construction_object_architect_name: info.builderName,
        construction_object_architect_email: info.builderEmail,
        construction_object_architect_contact_info: info.builderContact,
        construction_object_architect_organization_name: info.organizationName,
        construction_object_architect_organization_contacts:
          info.organizationContacts,
        construction_object_architect_organization_address:
          info.organizationAddress,
      };
      break;
    }
    case "designer": {
      url = "designer-contacts";
      body = {
        construction_object_designer_phone_number: info.builderPhone,
        construction_object_designer_name: info.builderName,
        construction_object_designer_email: info.builderEmail,
        construction_object_designer_contact_info: info.builderContact,
        construction_object_designer_organization_name: info.organizationName,
        construction_object_designer_organization_contacts:
          info.organizationContacts,
        construction_object_designer_organization_address:
          info.organizationAddress,
      };
      break;
    }
    default: {
      return;
    }
  }

  return await fetch(api + `/v1/object/${id}/update/${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify(body),
  });
};

export function editObjectLinks(objectId, links) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/archive-object/${objectId}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify({ construction_object_archive_ids: links }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (data._meta.isSuccessful) {
        return "success";
      } else {
        return data.data.message[0];
      }
    });
}

export function deleteObject(objectId) {
  let tokenName = localStorage.getItem("token_name");
  let token = localStorage.getItem(tokenName);
  if (!tokenName || !token) {
    return;
  }

  return fetch(api + `/v1/object/${objectId}/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      [tokenName]: token,
    },
    body: JSON.stringify({}),
  })
      .then((r) => r.json())
      .then((data) => {
        if (data._meta.isSuccessful) {
          return "success";
        } else {
          return data.data.message[0];
        }
      }).catch((er) => er);
}

//Маперы
const normalizeData = (raw) => {
  let parsedLinksIds = [];
  let parsedLinks = [];
  if (raw.archives) {
    let links = JSON.parse(raw.archives);
    for (let i in links) {
      parsedLinks.push({
        id: links[i].construction_object_id,
        name: links[i].construction_object_name,
        address: links[i].construction_object_address,
        endDate: links[i].construction_object_date_plan_end,
        link: `/object/${links[i].construction_object_id}`,
      });
      parsedLinksIds.push(links[i].construction_object_id);
    }
  }
  return {
    id: raw.construction_object_id,
    name: raw.construction_object_name,
    address: raw.construction_object_address,
    longitude: raw.longitude,
    latitude: raw.latitude,
    polygons: raw.polygons ? geoJson.readFeatures(raw.polygons) : [],
    planBeg: raw.construction_object_date_plan_beg,
    planEnd: raw.construction_object_date_plan_end,
    factBeg: raw.construction_object_date_fact_beg,
    factEnd: raw.construction_object_date_fact_end,
    status: raw.construction_object_status,
    projectName: raw.national_project_name,
    projectNameKey: raw.national_project_id,
    objectType: raw.construction_object_type_name,
    objectTypeKey: raw.construction_object_type_id,
    jobType: raw.construction_object_work_type_name,
    jobTypeKey: raw.construction_object_work_type_id,
    isSubscribed: raw.is_subscribed,
    hasContractor: raw.has_contractor,
    hasAppContractor: raw.has_application_contractor,
    isRequest: raw.is_request,
    refVideo: raw.construction_object_ref_video,
    statusColor: raw.construction_object_status_color_code,
    statusCode: raw.construction_object_status_code,
    statusComment: raw.construction_object_status_comment,
    links: parsedLinks,
    linksIds: parsedLinksIds,
    mapIcon: raw.national_project_icon_name,
    completion:
      raw.percent_completion || raw.construction_object_percent_completion,
    description: raw.construction_object_description,
    customer: {
      name: raw.construction_object_customer_name,
      phone: raw.construction_object_customer_phone_number,
      email: raw.construction_object_customer_email,
      contact: raw.construction_object_customer_contact_info,
    },
    contractor: {
      builderId: raw.construction_object_builder_id,
      builderName: raw.construction_object_builder_name,
      builderPhone: raw.construction_object_builder_phone_number,
      builderContact: raw.construction_object_builder_contact_info,
      builderEmail: raw.construction_object_builder_email,
      organizationName: raw.organization_name,
      organizationContact: raw.organization_contacts,
      organizationAddress: raw.organization_address,
      executors:
        raw.executors &&
        JSON.parse(raw.executors).map((item) => {
          return {
            name: item.construction_object_executor_fio,
            phone: item.construction_object_executor_phone_number,
            email: item.construction_object_executor_email,
            contacts: item.construction_object_executor_contact_info,
          };
        }),
    },
    designer: Object.assign(
      {},
      raw.designer
        ? {
            builderId: raw.designer.id,
            builderName:
              raw.construction_object_designer_name || raw.designer.profile_fio,
            builderPhone:
              raw.construction_object_designer_phone_number ||
              raw.designer.profile_phone_number,
            builderContact:
              raw.construction_object_designer_contact_info ||
              raw.designer.additional_information,
            builderEmail:
              raw.construction_object_designer_email || raw.designer.user_email,
            additionalInfo: raw.designer.additional_information,
            organizationName:
              raw.construction_object_designer_organization_name ||
              raw.designer.organization_name,
            organizationContacts:
              raw.construction_object_designer_organization_contacts ||
              raw.designer.organization_contacts,
            organizationAddress:
              raw.construction_object_designer_organization_address ||
              raw.designer.organization_address,
            organizationId: raw.designer.organization_id,
            roleDescription: raw.designer.role_description,
            role: raw.designer.role_name,
          }
        : {
            builderName: raw.construction_object_designer_name,
            builderPhone: raw.construction_object_designer_phone_number,
            builderContact: raw.construction_object_designer_contact_info,
            builderEmail: raw.construction_object_designer_email,
            organizationName: raw.construction_object_designer_organization_name,
            organizationContacts: raw.construction_object_designer_organization_contacts,
            organizationAddress: raw.construction_object_designer_organization_address,
          }
    ),
    architect: Object.assign(
      {},
      raw.architect
        ? {
            builderId: raw.architect.id,
            builderName:
              raw.construction_object_architect_name ||
              raw.architect.profile_fio,
            builderPhone:
              raw.construction_object_architect_phone_number ||
              raw.architect.profile_phone_number,
            builderContact:
              raw.construction_object_architect_contact_info ||
              raw.architect.additional_information,
            builderEmail:
              raw.construction_object_architect_email ||
              raw.architect.user_email,
            additionalInfo: raw.architect.additionalInfo,
            organizationName:
                raw.construction_object_architect_organization_name ||
                raw.designer.organization_name,
            organizationContacts:
                raw.construction_object_architect_organization_contacts ||
                raw.designer.organization_contacts,
            organizationAddress:
                raw.construction_object_architect_organization_address ||
                raw.designer.organization_address,
            organizationId: raw.architect.organization_id,
            roleDescription: raw.architect.role_description,
            role: raw.architect.role_name,
          }
        : {
            builderName: raw.construction_object_architect_name,
            builderPhone: raw.construction_object_architect_phone_number,
            builderContact: raw.construction_object_architect_contact_info,
            builderEmail: raw.construction_object_architect_email,
            organizationName: raw.construction_object_architect_organization_name,
            organizationContacts: raw.construction_object_architect_organization_contacts,
            organizationAddress: raw.construction_object_architect_organization_address,
          }
    ),
  };
};

const objectInfoToSend = (raw) => {
  return {
    construction_object_name: raw.name,
    construction_object_address: raw.address,
    longitude: raw.longitude,
    latitude: raw.latitude,
    polygons: geoJson.writeFeaturesObject(raw.polygons ?? []),
    construction_object_date_plan_beg: raw.planBeg,
    construction_object_date_plan_end: raw.planEnd,
    construction_object_description: raw.description,
    construction_object_work_type_id: raw.jobTypeKey,
    construction_object_type_id: raw.objectTypeKey,
    national_project_id: raw.projectNameKey,
    construction_object_ref_video: raw.refVideo,
  };
};
