import React from "react";
import PropTypes from "prop-types";
import AccountWidget from "../../components/AccountWiget";
import Logo from "../../components/Logo/logo";
import UpdatePassForm from "./components/UpdatePassForm";

const UpdatePassContainer = ({match: {params: {token}}}) => {
  return (
    <React.Fragment>
      <AccountWidget />

      <Logo className="login-logo" />

      <div className="login-container">
        <h2 className="login-head">Обновление пароля</h2>

        <UpdatePassForm token={token}/>
      </div>
    </React.Fragment>
  );
};


UpdatePassForm.propTypes = {
  match: PropTypes.object,
};

export default UpdatePassContainer;
